import React from 'react'
import IntegrationsList from './IntegrationsList'
import IntegrationSide from './IntegrationSide'
import Header from './Header'

export default () => (
  <div>
    <Header />
    <IntegrationsList />
    <IntegrationSide />
  </div>
)
