import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchMyTargetPulseCampaignsCampaigns } from '../../api/dataSource'

const config = {
  type: 'myTarget',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/my-target',
    google_analytics: 'https://docs.segmentstream.com/datasources/mytarget'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'myTarget Account',
  authForm: [
    {
      label: 'Click to authenticate with myTarget',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My myTarget',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Default UTM source (optional)',
      type: 'string',
      name: 'defaultUtmSource',
      placeholder: 'mytarget',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium (optional)',
      type: 'string',
      name: 'defaultUtmMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM source for Pulse campaigns (optional)',
      type: 'string',
      name: 'pulseDefaultUtmSource',
      placeholder: 'pulse',
      tip: 'Defined UTM source value<br/>' +
        'will be applied to Pulse campaigns',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium for Pulse campaigns (optional)',
      type: 'string',
      name: 'pulseDefaultUtmMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value<br/>' +
        'will be applied to Pulse campaigns',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'URL parameters for Pulse campaigns (optional)',
      type: 'group array',
      name: 'pulseCampaignsUrlParamsMap',
      addButtonLabel: 'Add campaign',
      items: [
        {
          label: 'Campaign ID',
          type: 'dynamic select',
          name: 'campaignId',
          validation: ['required'],
          placeholder: 'Select campaign',
          optionsFetcher: fetchMyTargetPulseCampaignsCampaigns,
          description: 'No Pulse campaigns were found for this account',
          extendedView: true
        },
        {
          label: 'UTM campaign',
          type: 'string',
          name: 'utmCampaign',
          validation: ['required'],
          placeholder: 'Insert UTM campaign for the selected Pulse campaign'
        },
        {
          label: 'Override UTM source (optional)',
          type: 'string',
          name: 'utmSource',
          placeholder: 'Insert UTM source for the selected Pulse campaign'
        },
        {
          label: 'Override UTM medium (optional)',
          type: 'string',
          name: 'utmMedium',
          placeholder: 'Insert UTM medium for the selected Pulse campaign'
        }
      ],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Import banner statistics report',
      type: 'boolean',
      name: 'bannersStatisticsReport',
      defaultValue: false,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          enable: ['costMultiplier', 'defaultUtmSource', 'defaultUtmMedium'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source (optional)',
          type: 'string',
          name: 'defaultUtmSource',
          placeholder: 'mytarget',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        },
        {
          label: 'Default UTM medium (optional)',
          type: 'string',
          name: 'defaultUtmMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        },
        {
          label: 'Default UTM medium for Pulse campaigns (optional)',
          type: 'string',
          name: 'pulseDefaultUtmSource',
          placeholder: 'pulse',
          tip: 'Defined UTM source value<br/>' +
            'will be applied to pulse campaigns'
        },
        {
          label: 'Default UTM medium for Pulse campaigns (optional)',
          type: 'string',
          name: 'pulseDefaultUtmMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value<br/>' +
            'will be applied to Pulse campaigns'
        },
        {
          label: 'URL parameters for Pulse campaigns (optional)',
          type: 'group array',
          name: 'pulseCampaignsUrlParamsMap',
          addButtonLabel: 'Add campaign',
          items: [
            {
              label: 'Campaign ID',
              type: 'dynamic select',
              name: 'campaignId',
              validation: ['required'],
              placeholder: 'Select campaign',
              optionsFetcher: fetchMyTargetPulseCampaignsCampaigns,
              description: 'No Pulse campaigns were found for this account',
              extendedView: true
            },
            {
              label: 'UTM campaign',
              type: 'string',
              name: 'utmCampaign',
              validation: ['required'],
              placeholder: 'Insert UTM campaign for the selected Pulse campaign'
            },
            {
              label: 'Override UTM source (optional)',
              type: 'string',
              name: 'utmSource',
              placeholder: 'Insert UTM source for the selected Pulse campaign'
            },
            {
              label: 'Override UTM medium (optional)',
              type: 'string',
              name: 'utmMedium',
              placeholder: 'Insert UTM medium for the selected Pulse campaign'
            }
          ],
          configurations: {
            bigquery: {
              isVisible: false
            },
            rawReportsUnavailable: {
              isVisible: true
            }
          }
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        }
      ]
    }
  ]
}

export default config
