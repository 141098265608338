import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  font-size: 16px;
  background-color: #ffffff;
  border-radius: 8px;
  border: solid 1px #e1e7ed;
  margin-top: 21px;
  width: 100%;
`

export const Table = styled.div`
  width: 100%;
  text-align: left;
  border-spacing: 0;
  display: flex;
  flex-wrap: wrap;
`

export const TableHead = styled.div`
  display: inline-block;
  height: 78px;
  padding-top: 31px;
  font-weight: bold;
  color: #475666;
  &:first-child {
    width: 9%;
  }
  &:nth-child(2) {
    width: 35%;
  }
  &:nth-child(3) {
    width: 35%;
  }
  &:nth-child(4) {
    width: 21%;
  }
  ${props =>
    props.isEventEnrichment &&
    css`
      &:nth-child(3) {
        width: 56%;
      }
    `}
  ${({ size }) =>
    size === 6 &&
    css`
      &:nth-child(2) {
        width: 41.5%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 17.5%;
      }
      &:last-child {
        width: 9%;
      }
    `}
`

export const TableRow = styled.div`
  width: 100%;
  text-align: left;
  border-spacing: 0;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
`

export const TableData = styled.div`
  color: #657789;
  height: 45px;
  border-top: solid 1px #e1e7ed;
  padding-top: 14px;
  padding-right: 1%;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  &:first-child {
    width: 9%;
    padding-left: 38px;
    padding-top: 17px;
  }
  &:nth-child(2) {
    width: 35%;
  }
  &:nth-child(3) {
    width: 35%;
  }
  &:nth-child(4) {
    width: 21%;
  }
  ${props =>
    props.isEventEnrichment &&
    css`
      &:nth-child(3) {
        width: 56%;
      }
    `}
  ${({ size }) =>
    size === 6 &&
    css`
      &:nth-child(2) {
        width: 41.5%;
      }
      &:nth-child(3) {
        width: 10%;
      }
      &:nth-child(4),
      &:nth-child(5) {
        width: 17.5%;
      }
      &:last-child {
        width: 4%;
      }
    `}
`
