import reduceReducers from 'reduce-reducers'

import clearHandlerErrorReducer from './clearHandlerError'
import closeFormReducer from './closeForm'
import openFormReducer from './openForm'
import saveReducer, { save } from './save'
import { remove } from './remove'

export { save, remove }

export default reduceReducers(
  clearHandlerErrorReducer,
  closeFormReducer,
  openFormReducer,
  saveReducer
)
