const LOGOUT = 'logout'

export const logout = (pageName) => ({
  type: LOGOUT,
  pageName
})

export default (state = {}, action) => (
  action.type === LOGOUT
    ? {
      proMode: {
        status: state.proMode.status
      },
      locale: state.locale,
      page: {
        name: action.pageName
      },
      applicationSettings: state.applicationSettings
    }
    : state
)
