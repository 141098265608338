import reduceReducers from 'reduce-reducers'
import enablePublishReducer, { enablePublish } from './enablePublish'
import fetchProjectReducer, { fetchProject } from './fetchProject'
import publishReducer, { publish } from './publish'
import saveCustomIntegrationsReducer, { saveCustomIntegrations } from './saveCustomIntegrations'
import saveDataTransformationsReducer, { saveDataTransformations } from './saveDataTransformations'
import changeStatusDataTransformationsReducer, { changeStatusDataTransformations } from './changeStatusDataTransformations'
import saveCustomSDKUrlReducer, { saveCustomSDKUrl } from './saveCustomSDKUrl'
import saveOptionsReducer, { saveOptions } from './saveOptions'
import saveInfoReducer, { saveInfo } from './saveInfo'
import saveScriptsReducer, { saveScripts } from './saveScripts'
import { selectProject } from './selectProject'
import inviteProjectMemberReducer, { inviteProjectMember } from './inviteProjectMember'
import removeProjectMemberReducer, { removeProjectMember } from './removeProjectMember'
import removeProjectInvitationReducer, { removeProjectInvitation } from './removeProjectInvitation'
import { registerUser } from './registerUser'
import enableTipReducer, { enableTip } from './enableTip'
import dismissTipReducer, { dismissTip } from './dismissTip'

export const PROJECT_TYPES = {
  BigQuery: 'bigquery',
  GoogleAnalytics: 'google_analytics'
}
export const DDMANAGER_STREAMING_INTEGRATION = 'DDManager Streaming'
export const GA_DATA_DESTINATION = 'Google Analytics'

export {
  enablePublish,
  fetchProject,
  publish,
  saveCustomIntegrations,
  saveDataTransformations,
  changeStatusDataTransformations,
  saveCustomSDKUrl,
  saveOptions,
  saveScripts,
  selectProject,
  saveInfo,
  inviteProjectMember,
  removeProjectMember,
  removeProjectInvitation,
  registerUser,
  enableTip,
  dismissTip
}

export default reduceReducers(
  enablePublishReducer,
  fetchProjectReducer,
  publishReducer,
  saveCustomIntegrationsReducer,
  saveDataTransformationsReducer,
  changeStatusDataTransformationsReducer,
  saveCustomSDKUrlReducer,
  saveOptionsReducer,
  saveScriptsReducer,
  saveInfoReducer,
  inviteProjectMemberReducer,
  removeProjectMemberReducer,
  removeProjectInvitationReducer,
  enableTipReducer,
  dismissTipReducer
)
