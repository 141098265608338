export const saveCustomSDKUrl = (projectId, customSDKUrl) => ({
  url: `/projects/${projectId}/custom-sdk-url`,
  method: 'PUT',
  headers: { 'Content-Type': 'text/plain' },
  body: customSDKUrl
})

export const saveCustomIntegrations = (projectId, customIntegrations) => ({
  url: `/projects/${projectId}/custom-integrations`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(customIntegrations)
})

export const saveDataTransformations = (projectId, dataTransformations) => ({
  url: `/projects/${projectId}/data-transformations`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(dataTransformations)
})

export const saveOptions = (projectId, options) => ({
  url: `/projects/${projectId}/options`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(options)
})

export const saveInfo = (projectId, info) => ({
  url: `/projects/${projectId}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(info)
})

export const saveScripts = (projectId, scripts) => ({
  url: `/projects/${projectId}/global-scripts`,
  method: 'PUT',
  headers: { 'Content-Type': 'text/plain' },
  body: scripts
})

export const getProjects = () => ({
  url: '/projects'
})

export const getProject = projectId => ({
  url: `/projects/${projectId}`
})

export const publish = projectId => ({
  url: `/projects/${projectId}/publish`,
  method: 'POST'
})

export const savePriority = (projectId, priority) => ({
  url: `/projects/${projectId}/integrations-priority`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(priority)
})

export const createInvitation = (projectId, email) => ({
  url: `/projects/${projectId}/invitations`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ email })
})

export const revokeInvitation = (projectId, id) => ({
  url: `/projects/${projectId}/invitations/${id}`,
  method: 'DELETE'
})

export const revokePermission = (projectId, id) => ({
  url: `/projects/${projectId}/permissions/${id}`,
  method: 'DELETE'
})

export const registerUser = (projectId, invitationToken, userData) => ({
  url: `/projects/${projectId}/invitations/user/${invitationToken}`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(userData)
})

export const create = (projectName, type, billingAccountId, currency, timezone) => ({
  url: `/projects`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ projectName, type, billingAccountId, currency, timezone })
})
