import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  ${props =>
    props.theme === 'horizontal' &&
  css`
    display: flex;
    `}
`

export const RadioButton = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 20px;
  line-height: 20px;
  border: 1px solid #e1e7ed;
  border-radius: 8px;
  color: #1d2c4c;
  font-size: 18px;
  margin-bottom: 20px;
  cursor: pointer;
  ${props =>
    props.selected &&
    css`
      padding: 19px;
      color: #4D6DD3;
      border: 2px solid #4D6DD3;
    `}
  ${props =>
    props.theme === 'horizontal' &&
  css`
    margin: 0 20px 0 0;
    `}
`

export const Triangle = styled.div`
  margin-left: auto;
  margin-top: 4px;
  width: 4px;
  height: 4px;
  border: solid #cad3dc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
