import { useUpdateDataStreamMutation } from '../GraphQL/updateDataStream'
import { useDeleteDataStreamMutation } from '../GraphQL/deleteDataStream'
import { deletedEvent, savedEvent } from 'utils/digitalDataEvents'

export function useUpdateContext (projectId, listContext) {
  const [updateDataStreamMutation, { loading: updateLoading }] = useUpdateDataStreamMutation()
  const [deleteDataStreamMutation, { loading: deleteLoading }] = useDeleteDataStreamMutation(projectId)

  const closeUpdateSide = () => {
    listContext.selectDataStreamToUpdate(null)
  }

  const updateDataStream = async (dataStream) => {
    savedEvent(dataStream.type, 'dataStream')
    await updateDataStreamMutation({
      variables: {
        dataStream: {
          ...dataStream,
          uaIntegrationType: undefined,
          snippet: undefined
        }
      }
    })

    closeUpdateSide()
  }

  const deleteDataStream = async (dataStream) => {
    deletedEvent(dataStream.type, 'dataStream')
    await deleteDataStreamMutation({ variables: { id: dataStream.id } })
    closeUpdateSide()
  }

  return {
    isLoading: updateLoading || deleteLoading,
    isOpen: !!listContext.selectedDataStreamToUpdate,
    selectedDataStream: listContext.selectedDataStreamToUpdate,

    close: closeUpdateSide,
    updateDataStream,
    deleteDataStream
  }
}
