import styled from 'styled-components'

export const StyledGroup = styled.div`
  border: 1px solid;
  border-radius: 8px;
  border-color: #e1e7ed;
  padding: 22px 19px 17px 19px;
`

export const Label = styled.div`
  display: flex;
  margin-bottom: 16px;
`
