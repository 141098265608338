import styled from 'styled-components'
import lamp from './lamp.png'
import close from './close.svg'

export const TipContainer = styled.div`
  width: 0px;
  height: 0px;
  position: relative;
  left: ${props => props.left};
  z-index: 1;
`

export const TipContent = styled.div`
  width: 266px;
  border-radius: 5px;
  padding: 15px 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.15);
  background: #fff;
  color: #0C1533;  
  text-align: center;
  font-size: 14px;
  line-height: 18px;
`

export const Arrow = styled.div`
  position: absolute;
  bottom: 100%;
  border-width: 8px;
  border-style: solid;
  border-color: transparent transparent #fff transparent;
`

export const TipIcon = styled.div`
  width: 32px;
  height: 32px;
  margin-bottom: 6px;
  background: url(${lamp}) no-repeat;
  background-size: 32px 32px;
`

export const CloseButton = styled.div`
  width: 8px;
  height: 8px; 
  background: url(${close}) no-repeat;
  background-size: 8px 8px;
  top: 8px;
  left: 251px;
  position: absolute;
  cursor: pointer;
`
