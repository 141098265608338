import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import {
  fetchGoogleAnalyticsAccounts,
  fetchGoogleAnalyticsProperties,
  fetchGoogleAnalyticsViews,
  fetchGoogleAnalyticsDimensions,
  fetchGoogleAnalyticsMetrics
} from 'api/dataSource'

const config = {
  type: 'Google Analytics',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/google-analytics',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Analytics',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Account',
      type: 'dynamic select',
      name: 'account',
      validation: ['required'],
      placeholder: 'Select Account',
      optionsFetcher: fetchGoogleAnalyticsAccounts,
      description: 'There were no accounts found or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/analytics/answer/1008015" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      extendedView: true
    },
    {
      label: 'Property',
      type: 'dynamic select',
      name: 'property',
      validation: ['required'],
      placeholder: 'Select Property',
      optionsFetcher: fetchGoogleAnalyticsProperties,
      dependsOn: ['account'],
      description: 'There were no properties found for your Google Analytics account or access was not granted. Please, make sure you have access to the required properties or <a class="description" href="https://support.google.com/analytics/answer/1042508" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      extendedView: true
    },
    {
      label: 'View',
      type: 'dynamic select',
      name: 'viewId',
      validation: ['required'],
      placeholder: 'Select View',
      optionsFetcher: fetchGoogleAnalyticsViews,
      dependsOn: ['property', 'account'],
      description: 'There were no views found for your Google Analytics property or access was not granted. Please, make sure you have access to the required views or <a class="description" href="https://support.google.com/analytics/answer/1034823" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      extendedView: true
    },
    {
      label: 'Destination table name',
      type: 'string',
      name: 'destinationTableName',
      validation: ['required', /^[a-zA-Z0-9_]+$/]
    },
    {
      label: 'Dimensions',
      type: 'dynamic select array',
      name: 'dimensions',
      validation: ['required'],
      placeholder: 'Select dimension',
      optionsFetcher: fetchGoogleAnalyticsDimensions,
      description: 'Available dimensions could not be loaded, please make sure your internet connection is stable click “Refresh”.',
      addButtonLabel: 'Add dimension',
      uniqueValues: true,
      limit: 7,
      searchable: true,
      tip: 'Not all dimensions and metrics can be queried together,<br/> please use the <a href="https://ga-dev-tools.web.app/dimensions-metrics-explorer" target="_blank" rel="noopener noreferrer">Dimensions & Metrics Explorer</a><br/> to make sure that the settings used are valid.'
    },
    {
      label: 'Metrics',
      type: 'dynamic select array',
      name: 'metrics',
      validation: ['required'],
      placeholder: 'Select metric',
      optionsFetcher: fetchGoogleAnalyticsMetrics,
      description: 'Available metrics could not be loaded, please make sure your internet connection is stable click “Refresh”.',
      addButtonLabel: 'Add metric',
      uniqueValues: true,
      limit: 10,
      searchable: true,
      tip: 'Not all dimensions and metrics can be queried together,<br/> please use the <a href="https://ga-dev-tools.web.app/dimensions-metrics-explorer" target="_blank" rel="noopener noreferrer">Dimensions & Metrics Explorer</a><br/> to make sure that the settings used are valid.'
    }
  ]
}

export default config
