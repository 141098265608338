import React from 'react'
import ReactModal from 'react-modal'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import GrayButton from 'containers/Form/Button/GrayButton'
import { MessageText, ButtonsContainer, modalStyle } from './styles'

export default ({
  isOpen,
  onClose,
  onConfirm,
  messageText,
  confirmButtonText,
  cancelButtonText,
  dangerZone
}) => {
  const createMarkup = () => ({ __html: messageText })
  return (
    <ReactModal style={modalStyle} isOpen={isOpen} contentLabel='Confirmation window'>
      <MessageText dangerouslySetInnerHTML={createMarkup()} />
      <ButtonsContainer>
        <GrayButton onClick={onClose}>{cancelButtonText}</GrayButton>
        <ConfirmButton onClick={onConfirm} dangerZone={dangerZone}>
          {confirmButtonText}
        </ConfirmButton>
      </ButtonsContainer>
    </ReactModal>
  )
}
