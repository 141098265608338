import { saveOptions } from 'redux/modules/project/saveOptions'
import { pending } from 'redux/modules/proMode/modalWindow/pending'
import { success } from 'redux/modules/proMode/modalWindow/success'

export const save = (options, touchedFields) => async dispatch => {
  if (touchedFields) {
    dispatch(pending())
    await dispatch(saveOptions(options))
    dispatch(success())
  }
}
