import React, { useState } from 'react'
import { useQuery, useMutation } from '@apollo/react-hooks'
import { connect } from 'react-redux'
import uuid from 'uuid'
import _ from 'lodash'
import { loadStripe } from '@stripe/stripe-js/pure'

import ModalWindow from 'containers/ModalWindow'
import { closeModal } from 'redux/modules/blueHeader/projectBilling'

import { Spinner } from 'uiKit'

import { BillingStatusStep } from '../Components/billingStatusStep'
import { BillingCheckoutStep } from '../Components/billingCheckoutStep'
import {
  BILLING_QUERY, CREATE_BILLING_PORTAL_LINK,
  CREATE_CHECKOUT_SESSION_MUTATION
} from '../graphql'
import { BillingRedirectStep } from '../Components/billingRedirectStep'

const Steps = {
  Status: 'status',
  Checkout: 'checkout',
  Redirect: 'redirect'
}

function BillingRoot ({ projectType, countryCode, stripeKey, billingAccountId, availableProjects }) {
  const {
    data: {
      billingAccount
    } = {}
  } = useQuery(BILLING_QUERY, {
    variables: {
      id: billingAccountId
    }
  })

  const [createCheckoutSession] = useMutation(CREATE_CHECKOUT_SESSION_MUTATION)
  const [createBillingPortalLink] = useMutation(CREATE_BILLING_PORTAL_LINK)
  const [selectedPrice, setSelectedPrice] = useState(null)
  const [currentStep, setCurrentStep] = useState(Steps.Status)

  const handlePriceSelected = (price) => {
    setSelectedPrice(price)
    setCurrentStep(Steps.Checkout)
  }

  const handleCreateCheckoutSession = async billingInfo => {
    setCurrentStep(Steps.Redirect)

    const baseUrl = `${window.location.protocol}//${window.location.host}`
    const sessionId = uuid.v4()
    try {
      const checkoutSession = await createCheckoutSession({
        variables: {
          billingInfo: {
            ...billingInfo,
            successUrl: `${baseUrl}/checkout-success?sessionId=${sessionId}`,
            cancelUrl: `${baseUrl}/checkout-cancel?sessionId=${sessionId}`
          },
          id: sessionId,
          billingAccountId,
          pricingPlan: selectedPrice.pricingPlan.id,
          billingPeriod: selectedPrice.billingPeriod
        }
      })

      const checkoutSessionResult = checkoutSession.data.createCheckoutSession

      const stripe = await loadStripe(stripeKey)
      const res = await stripe.redirectToCheckout({ sessionId: checkoutSessionResult.stripeId })
      if (res.error) {
        setCurrentStep(Steps.Checkout)
        window.alert(res.error.message)
      }
    } catch (e) {
      console.log(e)
      setCurrentStep(Steps.Checkout)
      window.alert('Something went wrong, please try again!')
    }
  }

  const handleCancelCheckout = () => {
    setCurrentStep(Steps.Status)
  }

  const handleRedirectToBillingPortal = () => {
    setCurrentStep(Steps.Redirect)

    try {
      const windowReference = window.open()

      createBillingPortalLink({ variables: {
        billingAccountId
      } }).then((link) => {
        windowReference.location = link.data.createBillingPortalLink
      })
    } catch (e) {
      setCurrentStep(Steps.Status)
      console.error(e)
      window.alert('Failed to redirect to billing portal page. Please try again later or contact support')
    } finally {
      setCurrentStep(Steps.Status)
    }
  }

  const step = () => {
    if (currentStep === Steps.Checkout) {
      return (
        <BillingCheckoutStep
          billingAccount={billingAccount}
          selectedPrice={selectedPrice}
          onCancel={handleCancelCheckout}
          onSubmit={handleCreateCheckoutSession}
        />
      )
    }

    if (currentStep === Steps.Redirect) {
      return (
        <BillingRedirectStep />
      )
    }

    return (
      <BillingStatusStep
        availablePricingPlans={billingAccount.availablePricingPlans}
        billingAccount={{ ...billingAccount, countryCode }}
        projectType={projectType}
        onPriceSelected={handlePriceSelected}
        onOpenBillingPortal={handleRedirectToBillingPortal}
        availableProjects={availableProjects}
      />
    )
  }

  if (!billingAccount || !billingAccount.availablePricingPlans) {
    return <Spinner />
  }

  return (
    <React.Fragment>
      { step() }
    </React.Fragment>
  )
}

function BillingModal ({
  isOpen,
  onClose,
  projectType,
  countryCode,
  stripeKey,
  billingAccountId,
  availableProjects
}) {
  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header='Billing'>
      <BillingRoot
        countryCode={countryCode}
        projectType={projectType}
        stripeKey={stripeKey}
        billingAccountId={billingAccountId}
        availableProjects={availableProjects}
      />
    </ModalWindow>
  )
}

export const BillingContainer = connect(
  state => {
    const { isOpen } = state.blueHeader.projectBilling
    const { id: projectId, type } = state.project || {}

    return {
      isOpen,
      projectId,
      billingAccountId: _.get(state, 'user.billingAccount.id'),
      countryCode: _.get(state, 'user.billingAccount.countryCode'),
      projectType: type,
      proMode: !!state.proMode.status,
      stripeKey: _.get(state, 'applicationSettings.stripe.publishableKey'),
      availableProjects: state.allProjects
    }
  },
  {
    onClose: closeModal
  }
)(BillingModal)
