import path from './path'
import { revokeInvitation } from 'api/project'

const REMOVE_PROJECT_INVITATION = `removeProjectInvitation@${path}`

export const removeProjectInvitation = id => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(await revokeInvitation(projectId, id))
  dispatch({
    type: REMOVE_PROJECT_INVITATION,
    invitationId: id
  })
}

export default (state = {}, action) =>
  action.type === REMOVE_PROJECT_INVITATION
    ? {
      ...state,
      invitations: state.invitations.filter(({ id }) => id !== action.invitationId)
    }
    : state
