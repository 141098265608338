import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchMicrosoftAdvertisingAccounts } from 'api/dataSource'

const config = {
  type: 'MicrosoftAdvertising',
  displayName: 'Microsoft Advertising',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/microsoft-ads',
    google_analytics: 'https://docs.segmentstream.com/datasources/microsoft-ads'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Microsoft Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Microsoft',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Microsoft Advertising',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Accounts',
      type: 'dynamic select array',
      name: 'accountIds',
      validation: ['required'],
      placeholder: 'Select Account',
      optionsFetcher: fetchMicrosoftAdvertisingAccounts,
      description: 'There were no accounts found or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://help.ads.microsoft.com/#apex/3/en/53090/0" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      addButtonLabel: 'Add account',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Import "Keyword" report',
      type: 'boolean',
      name: 'keywordPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Campaign" report',
      type: 'boolean',
      name: 'campaignPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Ad" report',
      type: 'boolean',
      name: 'adPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Ad group" report',
      type: 'boolean',
      name: 'adGroupPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Search term" report',
      type: 'boolean',
      name: 'searchQueryPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Share of voice" report',
      type: 'boolean',
      name: 'shareOfVoiceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Audience" report',
      type: 'boolean',
      name: 'audiencePerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Geo" report',
      type: 'boolean',
      name: 'geographicPerformanceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Age & Gender" report',
      type: 'boolean',
      name: 'ageGenderAudienceReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: false,
          configurations: {
            google_analytics: {
              isVisible: false,
              defaultValue: true
            },
            rawReportsUnavailable: {
              isVisible: false,
              defaultValue: true
            }
          }
        }
      ]
    }
  ]
}

export default config
