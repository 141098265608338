export default (locale, translations) => (text, substitutions = {}) => {
  let translatedText = (translations && translations[locale] && translations[locale][text]) || text
  Object.keys(substitutions).forEach(substitutionKey => {
    translatedText = translatedText.replace(
      new RegExp(`{{${substitutionKey}}}`, 'g'),
      substitutions[substitutionKey]
    )
  })
  return translatedText
}
