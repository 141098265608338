import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Recreativ',
  color: '#FF3830',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Shop ID',
      type: 'string',
      name: 'shopId',
      validation: ['required']
    }
  ]
}

export default config
