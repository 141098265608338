import styled from 'styled-components'

export const Button = styled.button`
  display: inline-block;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 170px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;
  cursor: ${props => (props.pending ? 'wait' : 'pointer')};

  &:disabled {
    border-color: #cacdd1;
    background-color: #cacdd1;
    cursor: auto;
  }
`
