import path from './path'

const FETCH = `fetch@${path}`

export const fetch = enrichments => ({
  type: FETCH,
  enrichments
})

export default (state = [], action) => (action.type === FETCH ? action.enrichments : state)
