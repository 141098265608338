import React from 'react'
import { connect } from 'react-redux'
import { open } from 'redux/modules/page/dataDestinations/dataDestinationsForm/open'
import IntegrationLogo from 'containers/IntegrationLogo'
import EllipsisText from 'react-ellipsis-text'
import Status from 'containers/Status'
import { isProMode } from 'utils/proMode'
import { DataDestination, Title, Name, Signal, Plus, LogoContainer } from './styles'

const DataDestinationItem = ({ dataDestinationConfig, isEnabled, onClick, id, title, isProMode }) => {
  const handleClick = () => {
    onClick(id, dataDestinationConfig.type)
  }

  return (
    <DataDestination onClick={handleClick}>
      <LogoContainer>
        <IntegrationLogo logo={dataDestinationConfig.logo} />
        <Signal>{isEnabled ? <Status isEnabled={isEnabled} /> : <Plus />}</Signal>
      </LogoContainer>
      <Title>
        <EllipsisText text={title || `My ${dataDestinationConfig.type}`} length={16} />
      </Title>
      <Name>{dataDestinationConfig.type}{isProMode ? ` (#${id})` : ''}</Name>
    </DataDestination>
  )
}

export default connect(
  state => ({
    isProMode: isProMode(state.proMode.status, state.user.role)
  }),
  { onClick: open }
)(DataDestinationItem)
