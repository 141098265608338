import reduceReducers from 'reduce-reducers'
import addReducer from './add'
import fetchReducer from './fetch'
import removeReducer from './remove'
import saveReducer from './save'

export default reduceReducers(
  (
    state = {
      before: [],
      after: []
    }
  ) => state,
  addReducer,
  fetchReducer,
  removeReducer,
  saveReducer
)
