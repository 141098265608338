import { save as saveDataDestination } from 'redux/modules/dataDestinations/save'
import { close as closeForm } from './dataDestinationsForm/close'
import { enableTip } from 'redux/modules/project/enableTip'
import { DATA_DESTINATIONS } from '../changePage'

export const save = (dataDestination, type, isEnabled) => async dispatch => {
  let enableOnSave

  if (!isEnabled) {
    enableOnSave = true
    dispatch(enableTip(type, DATA_DESTINATIONS))
  }
  await dispatch(saveDataDestination(dataDestination, type, enableOnSave))
  dispatch(closeForm())
}
