import logo from './logo.png'
import sideLogo from './logo-side.png'
import { TAG_MANAGEMENT } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Google Tag Manager',
  color: '#67d1fc',
  category: [TAG_MANAGEMENT],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/google-tag-manager',
  form: [
    {
      label: 'No conflict',
      type: 'boolean',
      name: 'noConflict',
      disable: ['containerId']
    },
    {
      label: 'Container ID',
      type: 'string',
      name: 'containerId',
      validation: ['required']
    }
  ]
}

export default config
