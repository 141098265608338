import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'ML-based attribution',
  icon,
  locale: { ru },
  description:
    'Enriches the session table with information about how much the user has advanced to the purchase during a particular session. The degree of advance to the purchase is evaluated by a machine learning model based on 20+ factors of visitor behavior. The greater the probability of a visitor buying in a session, the greater the value attributed to the source of this session.',
  form: []
}

export default config
