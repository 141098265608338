export default (validations, value) => {
  if (!validations) {
    return undefined
  }

  const regexValidations = validations.filter(validation => {
    return validation instanceof RegExp
  })

  if (validations.includes('required') && (value === undefined || value === '' || value.length === 0)) {
    return 'Field is required'
  }

  const uuidRegexp = /^[0-9a-f]{8}-[0-9a-f]{4}-[0-9a-f]{4}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i
  if (validations.includes('uuid') && !uuidRegexp.test(value)) {
    return 'Value must be UUID'
  }

  if (validations.includes('no-double-quotes') && value && value.includes('"')) {
    return 'Invalid symbol "'
  }

  const emailRegexp = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  if (validations.includes('email') && !emailRegexp.test(value)) {
    return 'Invalid email'
  }

  const decimalRegexp = /^\d+\.\d+$/
  if (validations.includes('decimal') && !decimalRegexp.test(value)) {
    return 'Only decimal value is available'
  }

  if (validations.includes('json')) {
    try {
      JSON.parse(value)
    } catch (e) {
      return 'Value must be an valid JSON'
    }
  }

  if (value && regexValidations.length && regexValidations.some(regex => !regex.test(value))) {
    return 'Invalid input'
  }

  return undefined
}
