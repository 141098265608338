import React from 'react'
import getValidationError from 'containers/Form/getValidationError'
import { StyledTextArea } from './styles'

const TextArea = ({
  disabled,
  hasFocus,
  name,
  onBlur,
  onChange,
  onFocus,
  onTouch,
  placeholder,
  style,
  touched,
  value = '',
  validation,
  maxLength,
  autocomplete
}) => {
  const handleChange = event => {
    const inputValue = event.target.value
    if (inputValue === '') {
      onChange(undefined)
      return
    }
    onChange(inputValue)
  }

  const handleFocus = () => {
    if (!hasFocus && onFocus) {
      onFocus()
    }
    if (!touched && onTouch) {
      onTouch()
    }
  }

  const handleBlur = event => {
    if (event.target.value !== '') {
      const trimmedValue = event.target.value.trim()
      if (trimmedValue !== value) {
        onChange(trimmedValue)
      }
    }

    if (hasFocus && onBlur) {
      onBlur()
    }
  }

  const error =
    touched && !disabled && !hasFocus && validation
      ? getValidationError(validation, value)
      : undefined

  return (
    <StyledTextArea
      style={style}
      error={error}
      name={name}
      value={String(value)}
      placeholder={!hasFocus ? placeholder : ''}
      disabled={disabled}
      onChange={handleChange}
      onClick={handleFocus}
      onBlur={handleBlur}
      autoFocus={hasFocus}
      maxLength={maxLength}
      autoComplete={autocomplete}
    />
  )
}

export default TextArea
