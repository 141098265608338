import { universalAnalyticsConfig } from './universalAnalytics/universalAnalytics'
import { webhookConfig } from './webhook/webhook'

export const dataStreamConfigurations = [
  universalAnalyticsConfig,
  webhookConfig
]

export const getDataStreamConfiguration = (type) => {
  return dataStreamConfigurations.find(config => config.type === type)
}
