import path from './path'

const TYPE = `fetch@${path}`

export const fetch = (allProjects, billingAccountId) => dispatch => {
  const availableProjects = allProjects
    .filter(item => !billingAccountId || item.billingAccountId === parseInt(billingAccountId))
    .map(item => ({
      id: item.projectId,
      name: item.projectName,
      type: item.projectType
    }))

  dispatch({
    type: TYPE,
    allProjects: availableProjects
  })

  return availableProjects
}

export default (state = [], action) => (
  action.type === TYPE
    ? action.allProjects
    : state
)
