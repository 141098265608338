import { setLanguageVariable } from 'utils/digitalDataVariables'

const TYPE = 'fetch@locale'

export const fetch = (userLocale, browserLocale) => {
  const locale = userLocale || browserLocale
  setLanguageVariable(locale)
  return {
    type: TYPE,
    locale
  }
}

export default (state, action) =>
  action.type === TYPE && action.locale ? action.locale : state
