import path from './path'

const FETCH_INTEGRATIONS = `fetchIntegrations@${path}`

export const fetchIntegrations = integrations => ({
  type: FETCH_INTEGRATIONS,
  integrations
})

export default (state = {}, action) =>
  action.type === FETCH_INTEGRATIONS
    ? action.integrations.reduce(
      (acc, integration) => ({
        ...acc,
        [integration.integrationName]: {
          isEnabled: integration.isEnabled,
          options: integration.options,
          overrideFunctions: integration.overrideFunctions,
          eventEnrichments: integration.eventEnrichments,
          isAuthenticated: integration.isAuthenticated,
          authMeta: integration.authMeta
        }
      }),
      {}
    )
    : state
