export const dimensions = [
  'dfa:activeViewCustomMetricId',
  'dfa:activeViewCustomMetricName',
  'dfa:activity',
  'dfa:activityGroup',
  'dfa:activityGroupId',
  'dfa:activityId',
  'dfa:ad',
  'dfa:adId',
  'dfa:adStatus',
  'dfa:adType',
  'dfa:advertiser',
  'dfa:advertiserGroup',
  'dfa:advertiserId',
  'dfa:app',
  'dfa:appId',
  'dfa:asset',
  'dfa:assetCategory',
  'dfa:assetId',
  'dfa:assetOrientation',
  'dfa:attributedEventConnectionType',
  'dfa:attributedEventEnvironment',
  'dfa:audienceTargeted',
  'dfa:browserPlatform',
  'dfa:campaign',
  'dfa:campaignEndDate',
  'dfa:campaignExternalId',
  'dfa:campaignId',
  'dfa:campaignStartDate',
  'dfa:city',
  'dfa:clickThroughUrl',
  'dfa:companionCreative',
  'dfa:companionCreativeId',
  'dfa:companionCreativeSize',
  'dfa:connectionType',
  'dfa:contentCategory',
  'dfa:contentClassification',
  'dfa:country',
  'dfa:creative',
  'dfa:creativeEndDate',
  'dfa:creativeField1',
  'dfa:creativeField2',
  'dfa:creativeField3',
  'dfa:creativeField4',
  'dfa:creativeField5',
  'dfa:creativeField6',
  'dfa:creativeField7',
  'dfa:creativeField8',
  'dfa:creativeField9',
  'dfa:creativeField10',
  'dfa:creativeField11',
  'dfa:creativeField12',
  'dfa:creativeGroup1',
  'dfa:creativeGroup2',
  'dfa:creativeId',
  'dfa:creativeSize',
  'dfa:creativeStartDate',
  'dfa:creativeType',
  'dfa:creativeVersion',
  'dfa:dataSource',
  'dfa:date',
  'dfa:dbmAdvertiser',
  'dfa:dbmAdvertiserId',
  'dfa:dbmCampaign',
  'dfa:dbmCampaignId',
  'dfa:dbmCreative',
  'dfa:dbmCreativeId',
  'dfa:dbmInsertionOrder',
  'dfa:dbmInsertionOrderId',
  'dfa:dbmLineItem',
  'dfa:dbmLineItemId',
  'dfa:dbmPartner',
  'dfa:dbmPartnerId',
  'dfa:dbmSite',
  'dfa:dbmSiteId',
  'dfa:digitalContentLabel',
  'dfa:dmaRegion',
  'dfa:domain',
  'dfa:dynamicElement1Field1Value',
  'dfa:dynamicElement1Field2Value',
  'dfa:dynamicElement1Field3Value',
  'dfa:dynamicElement1Field4Value',
  'dfa:dynamicElement1Field5Value',
  'dfa:dynamicElement1Field6Value',
  'dfa:dynamicElement2Field1Value',
  'dfa:dynamicElement2Field2Value',
  'dfa:dynamicElement2Field3Value',
  'dfa:dynamicElement2Field4Value',
  'dfa:dynamicElement2Field5Value',
  'dfa:dynamicElement2Field6Value',
  'dfa:dynamicElement3Field1Value',
  'dfa:dynamicElement3Field2Value',
  'dfa:dynamicElement3Field3Value',
  'dfa:dynamicElement3Field4Value',
  'dfa:dynamicElement3Field5Value',
  'dfa:dynamicElement3Field6Value',
  'dfa:dynamicElement4Field1Value',
  'dfa:dynamicElement4Field2Value',
  'dfa:dynamicElement4Field3Value',
  'dfa:dynamicElement4Field4Value',
  'dfa:dynamicElement4Field5Value',
  'dfa:dynamicElement4Field6Value',
  'dfa:dynamicElement5Field1Value',
  'dfa:dynamicElement5Field2Value',
  'dfa:dynamicElement5Field3Value',
  'dfa:dynamicElement5Field4Value',
  'dfa:dynamicElement5Field5Value',
  'dfa:dynamicElement5Field6Value',
  'dfa:dynamicElement6Field1Value',
  'dfa:dynamicElement6Field2Value',
  'dfa:dynamicElement6Field3Value',
  'dfa:dynamicElement6Field4Value',
  'dfa:dynamicElement6Field5Value',
  'dfa:dynamicElement6Field6Value',
  'dfa:dynamicElement7Field1Value',
  'dfa:dynamicElement7Field2Value',
  'dfa:dynamicElement7Field3Value',
  'dfa:dynamicElement7Field4Value',
  'dfa:dynamicElement7Field5Value',
  'dfa:dynamicElement7Field6Value',
  'dfa:dynamicElement8Field1Value',
  'dfa:dynamicElement8Field2Value',
  'dfa:dynamicElement8Field3Value',
  'dfa:dynamicElement8Field4Value',
  'dfa:dynamicElement8Field5Value',
  'dfa:dynamicElement8Field6Value',
  'dfa:dynamicElement9Field1Value',
  'dfa:dynamicElement9Field2Value',
  'dfa:dynamicElement9Field3Value',
  'dfa:dynamicElement9Field4Value',
  'dfa:dynamicElement9Field5Value',
  'dfa:dynamicElement9Field6Value',
  'dfa:dynamicElement10Field1Value',
  'dfa:dynamicElement10Field2Value',
  'dfa:dynamicElement10Field3Value',
  'dfa:dynamicElement10Field4Value',
  'dfa:dynamicElement10Field5Value',
  'dfa:dynamicElement10Field6Value',
  'dfa:dynamicElement11Field1Value',
  'dfa:dynamicElement11Field2Value',
  'dfa:dynamicElement11Field3Value',
  'dfa:dynamicElement11Field4Value',
  'dfa:dynamicElement11Field5Value',
  'dfa:dynamicElement11Field6Value',
  'dfa:dynamicElement12Field1Value',
  'dfa:dynamicElement12Field2Value',
  'dfa:dynamicElement12Field3Value',
  'dfa:dynamicElement12Field4Value',
  'dfa:dynamicElement12Field6Value',
  'dfa:dynamicElement13Field1Value',
  'dfa:dynamicElement13Field2Value',
  'dfa:dynamicElement13Field4Value',
  'dfa:dynamicElement13Field5Value',
  'dfa:dynamicElement13Field6Value',
  'dfa:dynamicElement14Field1Value',
  'dfa:dynamicElement14Field2Value',
  'dfa:dynamicElement14Field3Value',
  'dfa:dynamicElement14Field4Value',
  'dfa:dynamicElement14Field5Value',
  'dfa:dynamicElement14Field6Value',
  'dfa:dynamicElement15Field1Value',
  'dfa:dynamicElement15Field2Value',
  'dfa:dynamicElement15Field3Value',
  'dfa:dynamicElement15Field4Value',
  'dfa:dynamicElement15Field5Value',
  'dfa:dynamicElement15Field6Value',
  'dfa:dynamicElement16Field1Value',
  'dfa:dynamicElement16Field2Value',
  'dfa:dynamicElement16Field3Value',
  'dfa:dynamicElement16Field4Value',
  'dfa:dynamicElement16Field5Value',
  'dfa:dynamicElement16Field6Value',
  'dfa:dynamicElement17Field1Value',
  'dfa:dynamicElement17Field2Value',
  'dfa:dynamicElement17Field3Value',
  'dfa:dynamicElement17Field4Value',
  'dfa:dynamicElement17Field5Value',
  'dfa:dynamicElement17Field6Value',
  'dfa:dynamicElement18Field1Value',
  'dfa:dynamicElement18Field2Value',
  'dfa:dynamicElement18Field3Value',
  'dfa:dynamicElement18Field4Value',
  'dfa:dynamicElement18Field5Value',
  'dfa:dynamicElement18Field6Value',
  'dfa:dynamicElement19Field1Value',
  'dfa:dynamicElement19Field2Value',
  'dfa:dynamicElement19Field3Value',
  'dfa:dynamicElement19Field4Value',
  'dfa:dynamicElement19Field5Value',
  'dfa:dynamicElement19Field6Value',
  'dfa:dynamicElement20Field1Value',
  'dfa:dynamicElement20Field2Value',
  'dfa:dynamicElement20Field3Value',
  'dfa:dynamicElement20Field4Value',
  'dfa:dynamicElement20Field5Value',
  'dfa:dynamicElement20Field6Value',
  'dfa:dynamicProfile',
  'dfa:dynamicProfileId',
  'dfa:environment',
  'dfa:eventPlatformType',
  'dfa:exitUrl',
  'dfa:feed1',
  'dfa:feed1ReportingLabel',
  'dfa:feed1UniqueId',
  'dfa:feed2',
  'dfa:feed2ReportingLabel',
  'dfa:feed2UniqueId',
  'dfa:feed3',
  'dfa:feed3ReportingLabel',
  'dfa:feed3UniqueId',
  'dfa:feed4',
  'dfa:feed4ReportingLabel',
  'dfa:feed4UniqueId',
  'dfa:feed5',
  'dfa:feed5ReportingLabel',
  'dfa:feed5UniqueId',
  'dfa:feed6',
  'dfa:feed6ReportingLabel',
  'dfa:feed6UniqueId',
  'dfa:feed7',
  'dfa:feed7ReportingLabel',
  'dfa:feed7UniqueId',
  'dfa:feed8',
  'dfa:feed8ReportingLabel',
  'dfa:feed8UniqueId',
  'dfa:feed9',
  'dfa:feed9ReportingLabel',
  'dfa:feed9UniqueId',
  'dfa:feed10',
  'dfa:feed10ReportingLabel',
  'dfa:feed10UniqueId',
  'dfa:feed11',
  'dfa:feed11ReportingLabel',
  'dfa:feed11UniqueId',
  'dfa:feed12',
  'dfa:feed12ReportingLabel',
  'dfa:feed12UniqueId',
  'dfa:feed13',
  'dfa:feed13ReportingLabel',
  'dfa:feed13UniqueId',
  'dfa:feed14',
  'dfa:feed14ReportingLabel',
  'dfa:feed14UniqueId',
  'dfa:feed15',
  'dfa:feed15ReportingLabel',
  'dfa:feed15UniqueId',
  'dfa:feed16',
  'dfa:feed16ReportingLabel',
  'dfa:feed16UniqueId',
  'dfa:feed17',
  'dfa:feed17ReportingLabel',
  'dfa:feed17UniqueId',
  'dfa:feed18',
  'dfa:feed18ReportingLabel',
  'dfa:feed18UniqueId',
  'dfa:feed19',
  'dfa:feed19ReportingLabel',
  'dfa:feed19UniqueId',
  'dfa:feed20',
  'dfa:feed20ReportingLabel',
  'dfa:feed20UniqueId',
  'dfa:flightBookedCost',
  'dfa:flightBookedRate',
  'dfa:flightBookedUnits',
  'dfa:flightEndDate',
  'dfa:flightStartDate',
  'dfa:floodlightConfigId',
  'dfa:hour',
  'dfa:impressionCountingMethod',
  'dfa:keyword',
  'dfa:landingPageUrl',
  'dfa:measurementSource',
  'dfa:mobileCarrier',
  'dfa:month',
  'dfa:naturalSearchEngineCountry',
  'dfa:naturalSearchEngineProperty',
  'dfa:naturalSearchLandingPage',
  'dfa:naturalSearchLandingPageQueryString',
  'dfa:naturalSearchProcessedLandingPage',
  'dfa:naturalSearchProcessedLandingPageQueryString',
  'dfa:naturalSearchQuery',
  'dfa:omSdkAvailable',
  'dfa:operatingSystem',
  'dfa:operatingSystemVersion',
  'dfa:packageRoadblock',
  'dfa:packageRoadblockId',
  'dfa:packageRoadblockStrategy',
  'dfa:packageRoadblockTotalBookedUnits',
  'dfa:paidSearchAd',
  'dfa:paidSearchAdGroup',
  'dfa:paidSearchAdGroupId',
  'dfa:paidSearchAdId',
  'dfa:paidSearchAdvertiser',
  'dfa:paidSearchAdvertiserId',
  'dfa:paidSearchAgency',
  'dfa:paidSearchAgencyId',
  'dfa:paidSearchBidStrategy',
  'dfa:paidSearchBidStrategyId',
  'dfa:paidSearchCampaign',
  'dfa:paidSearchCampaignId',
  'dfa:paidSearchEngineAccount',
  'dfa:paidSearchEngineAccountCategory',
  'dfa:paidSearchEngineAccountId',
  'dfa:paidSearchExternalAdGroupId',
  'dfa:paidSearchExternalAdId',
  'dfa:paidSearchExternalCampaignId',
  'dfa:paidSearchExternalKeywordId',
  'dfa:paidSearchKeyword',
  'dfa:paidSearchKeywordId',
  'dfa:paidSearchLandingPageUrl',
  'dfa:paidSearchLegacyKeywordId',
  'dfa:paidSearchMatchType',
  'dfa:paymentSource',
  'dfa:placement',
  'dfa:placementCompatibility',
  'dfa:placementCostStructure',
  'dfa:placementEndDate',
  'dfa:placementExternalId',
  'dfa:placementId',
  'dfa:placementRate',
  'dfa:placementSize',
  'dfa:placementStartDate',
  'dfa:placementStrategy',
  'dfa:placementTagType',
  'dfa:placementTotalBookedUnits',
  'dfa:placementTotalPlannedMediaCost',
  'dfa:platformType',
  'dfa:playbackMethod',
  'dfa:renderingId',
  'dfa:richMediaCustomVariable',
  'dfa:richMediaEvent',
  'dfa:richMediaVideo4asaId',
  'dfa:richMediaVideoLength',
  'dfa:servedPixelDensity',
  'dfa:site',
  'dfa:siteDirectory',
  'dfa:siteDirectoryId',
  'dfa:siteId',
  'dfa:siteKeyname',
  'dfa:state',
  'dfa:twitterCampaignId',
  'dfa:twitterCreativeId',
  'dfa:twitterCreativeMediaId',
  'dfa:twitterCreativeType',
  'dfa:twitterImpressionType',
  'dfa:twitterLineItemId',
  'dfa:twitterPlacementType',
  'dfa:verificationVideoPlayerSize',
  'dfa:verificationVideoProminenceScoreDimension',
  'dfa:videoContinuousPlay',
  'dfa:week',
  'dfa:zipCode'
]
