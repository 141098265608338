export const getUser = () => ({
  url: '/users/me'
})

export const sendResetPasswordToken = email => ({
  url: '/password/send-reset-token',
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify({ email })
})

export const resetPassword = (password, resetToken) => ({
  url: '/password/reset',
  method: 'POST',
  headers: {
    'Content-Type': 'application/json',
    Authorization: `bearer ${resetToken}`
  },
  body: JSON.stringify({ password })
})

export const signUp = (data) => ({
  url: '/users/sign-up',
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(data)
})
