import { fetchTwitterAccounts } from 'api/dataSource'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Twitter',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/twitter',
    google_analytics: 'https://docs.segmentstream.com/datasources/twitter'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Twitter Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Twitter',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Twitter',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Account ID(s)',
      type: 'dynamic select array',
      name: 'accountIds',
      validation: ['required'],
      placeholder: 'Select Account Id',
      optionsFetcher: fetchTwitterAccounts,
      description: 'No ads accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://business.twitter.com/en/help/account-setup/ads-account-creation.html" target="_blank" rel="noopener noreferrer">create a new one</a> and click "Refresh".',
      addButtonLabel: 'Add account ID',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Default UTM source (optional)',
      type: 'string',
      name: 'defaultUTMSource',
      placeholder: 'twitter',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website<br/>' +
        '(for example Twitter followers promo).',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium (optional)',
      type: 'string',
      name: 'defaultUTMMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website<br/>' +
        '(for example Twitter followers promo).',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedReport',
          enable: ['defaultUTMSource', 'defaultUTMMedium'],
          defaultValue: true,
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source (optional)',
          type: 'string',
          name: 'defaultUTMSource',
          placeholder: 'twitter',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website<br/>' +
            '(for example Twitter followers promo).'
        },
        {
          label: 'Default UTM medium (optional)',
          type: 'string',
          name: 'defaultUTMMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website<br/>' +
            '(for example Twitter followers promo).'
        }
      ]
    }
  ]
}

export default config
