import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const config = {
  name: 'RTB House',
  color: '#ef4136',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/rtb-house',
  form: [
    {
      label: 'Account key',
      type: 'string',
      name: 'accountKey',
      validation: ['required']
    },
    {
      label: 'Custom tags',
      type: 'variable mapping',
      name: 'customTags',
      typeOptions: [DIGITAL_DATA, EVENT]
    },
    {
      label: 'Enable custom deduplication',
      type: 'boolean',
      name: 'customDeduplication'
    }
  ],
  compatibilityMap: {
    userSegmentVar: data => ({
      customTags: {
        user: {
          type: DIGITAL_DATA,
          value: data.userSegmentVar
        }
      },
      userSegmentVar: undefined
    })
  }
}

export default config
