import { parse } from 'query-string'

const PRO_MODE = 'pro_mode'

const getQueryProMode = query => parse(query)[PRO_MODE]

export const getProMode = cookies => !!cookies.get(PRO_MODE)

export const setProMode = (query, cookies) => {
  const cookieProMode = getProMode(cookies)
  const queryProMode = getQueryProMode(query)
  if (queryProMode === '1' && !cookieProMode) {
    cookies.set(PRO_MODE, '1')
  } else if (queryProMode === '0' && cookieProMode) {
    cookies.remove(PRO_MODE)
  }
}

export function filterProModeItems (list, isProMode) {
  return isProMode ? list.slice(0) : list.slice(0).filter(elem => !elem.proModeOnly)
}

export function isProMode (status, userRole) {
  return (status && userRole === 'super_admin')
}
