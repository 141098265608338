import React from 'react'
import { useField } from 'formik'

import { FieldError } from './fieldError'
import FieldSuccess from 'containers/Form/FieldSuccess'

import { Label } from './label'
import { Tooltip } from '../tooltip'

export function FormikFieldContainer ({ error, style, success, label, tip, tipPlacement, children, ...props }) {
  const [field, meta] = useField(props)

  return (
    <div style={style}>
      { label
        ? (
          <Label>
            <label>{label}</label>
            { tip ? <Tooltip tip={tip} place={tipPlacement || 'right'} /> : null }
          </Label>
        )
        : null }

      { React.Children.map(children, (child) => React.cloneElement(child, {
        name: field.name,
        onChange: child.props.onChange || field.onChange,
        onBlur: child.props.onBlur || field.onBlur,
        error: meta.touched && meta.error,
        value: meta.value
      })) }

      { success
        ? <FieldSuccess message={success || ''} />
        : null }

      { error
        ? <FieldError message={error || ''} />
        : null }

      { meta.touched && meta.error
        ? <FieldError message={meta.error || ''} />
        : null }
    </div>
  )
}
