import path from './path'
import { save as saveEnrichment } from 'redux/modules/enrichments/save'
import { closeForm } from './closeForm'

const ERROR = `saveError@${path}`

const getDependencies = (field, handler) => {
  const regex = /_digitalData\(['"]([\w.]+)['"]\)/g
  const output = []
  let matches = []
  while (matches) {
    matches = regex.exec(handler)
    const match = matches && matches[1].replace('.length', '')
    if (match && !output.includes(match) && match !== field) {
      output.push(match)
    }
  }
  return output.length > 0 ? output : undefined
}

const findEnrichmentByFieldName = (field, allEnrichments) =>
  allEnrichments.find(enrichment => enrichment.field === field)

const hasCyclicDependencies = (field, dependencies, allEnrichments) => {
  if (!dependencies) {
    return false
  }
  if (dependencies.includes(field)) {
    return true
  }
  const cyclicDependency = dependencies.find(dependency => {
    const enrichment = findEnrichmentByFieldName(dependency, allEnrichments)
    if (!enrichment) {
      return false
    }
    return hasCyclicDependencies(field, enrichment.dependencies, allEnrichments)
  })
  return cyclicDependency !== undefined
}

export const save = (enrichment, touchedFields) => async (dispatch, getState) => {
  if (touchedFields) {
    const allEnrichments = getState().enrichments
    const dependencies = getDependencies(enrichment.field, enrichment.handler)
    if (hasCyclicDependencies(enrichment.field, dependencies, allEnrichments)) {
      dispatch({
        type: ERROR
      })
      return
    }
    await dispatch(
      saveEnrichment({
        ...enrichment,
        dependencies
      })
    )
  }
  dispatch(closeForm())
}

export default (state = {}, action) =>
  action.type === ERROR
    ? {
      ...state,
      handlerError: true
    }
    : state
