import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'Last non-direct click attribution',
  icon,
  locale: { ru },
  description:
    'Generates a table with sessions attributed to the last indirect click within the lookback window. 100% of the conversion value is assigned to the last indirect channel through which the client came before the conversion.',
  form: [
    {
      label: 'Lookback window',
      type: 'number',
      name: 'lookbackWindow',
      units: 'days'
    }
  ]
}

export default config
