export const mappingRowProps = ({
  locale,
  focusedRow,
  index,
  onFocus,
  placeholderKey,
  placeholderValue,
  onChangeRow,
  row,
  touched
}) => ({
  locale,
  mappingKey: row[0],
  mappingValue: row[1],
  hasFocusLeft: focusedRow !== undefined && focusedRow[0] === index && focusedRow[1] === 'left',
  hasFocusRight: focusedRow !== undefined && focusedRow[0] === index && focusedRow[1] === 'right',
  onFocusLeft: () => onFocus([index, 'left']),
  onFocusRight: () => onFocus([index, 'right']),
  placeholderKey,
  placeholderValue,
  onChangeKey: key => onChangeRow(index, [key, row[1]]),
  onChangeValue: val => onChangeRow(index, [row[0], val]),
  touched
})
