import React from 'react'
import { connect } from 'react-redux'
import { SUCCESS, PENDING } from 'redux/modules/blueHeader/modalWindow'
import getValidationError from 'containers/Form/getValidationError'
import { inviteProjectMember } from 'redux/modules/project'
import InputField from 'containers/Form/InputField'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import Space from 'containers/Space'
import { Title, SubmitBox, SuccessMessage, ErrorMessage } from './styles'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

class InviteForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value,
      touched: false,
      submitedEmail: ''
    }
    this.onChange = this.onChange.bind(this)
    this.onTouch = this.onTouch.bind(this)
    this.handleInvite = this.handleInvite.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      })
    }
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen === false) {
      this.setState({
        value: nextProps.value,
        touched: false,
        invalidEmail: false
      })
    }
  }

  onChange (value) {
    this.setState({ value })
  }

  onTouch () {
    this.setState({ touched: true })
  }

  async handleInvite (event) {
    const { value: email } = this.state
    const { onSubmit, locale } = this.props
    this.setState({ submitedEmail: '' })
    if (email && !getValidationError(['email'], email, locale)) {
      await onSubmit(email)
      this.setState({ touched: false, invalidEmail: false, submitedEmail: email, value: '' })
    } else {
      this.setState({ invalidEmail: true })
    }
  }

  render () {
    const { locale, status } = this.props
    const { touched, value, invalidEmail, submitedEmail } = this.state

    const translate = getTranslate(locale, { ru })

    const showInformationalMessage = !invalidEmail && !submitedEmail
    const showSuccessMessage = status === SUCCESS && submitedEmail

    return (<div>
      <Space />
      <Title>{translate('You can invite people to this project')}</Title>
      <Space />
      <SubmitBox>
        <InputField
          type='email'
          value={value}
          onChange={this.onChange}
          onTouch={this.onTouch}
          touched={touched}
          style={{ width: '98%' }}
        />
        <ConfirmButton onClick={this.handleInvite} pending={status === PENDING}>
          {translate('Invite')}
        </ConfirmButton>
      </SubmitBox>
      <Space />
      {showInformationalMessage && (
        <div>{translate('Your teammates will get an email that gives them access to this project.')}</div>
      )}
      {showSuccessMessage && (
        <SuccessMessage>{submitedEmail} {translate('was invited to this project')}</SuccessMessage>
      )}
      {invalidEmail && (
        <ErrorMessage>* {translate('Invalid email')}</ErrorMessage>
      )}
    </div>
    )
  }
}

const mapStateToProps = state => {
  const { status } = state.blueHeader.modalWindow
  return {
    locale: state.locale,
    status
  }
}

const mapDispatchToProps = () => ({
  onSubmit: inviteProjectMember
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(InviteForm)
