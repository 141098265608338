import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'GdeSlon',
  color: '#96A5AE',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/gdeslon',
  form: [
    {
      label: 'Merchant ID',
      type: 'string',
      name: 'merchantId',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    },
    {
      label: 'Default action code',
      type: 'string',
      name: 'defaultCode',
      validation: ['required']
    },
    {
      label: 'Cookie with click ID',
      type: 'string',
      name: 'clickRefCookieName',
      validation: ['required'],
      defaultValue: 'gdeslon_ref'
    },
    {
      label: 'Cookie with webmaster ID',
      type: 'string',
      name: 'aidCookieName',
      validation: ['required'],
      defaultValue: 'gdeslon_aid'
    },
    {
      label: 'Cookie auto tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: ['cookieDomain']
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain',
      validation: ['required']
    },
    {
      label: 'utm_source from Gde Slon',
      type: 'string',
      name: 'utmSource',
      validation: ['required'],
      defaultValue: 'gdeslon'
    },
    {
      label: 'Enable deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which require deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    },
    {
      label: 'Activate retargeting',
      type: 'boolean',
      name: 'isRetargetingEnabled',
      defaultValue: false
    }
  ]
}

export default config
