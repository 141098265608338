import logo from './logo.png'
import { snippetCode } from '@segmentstream/segmentstream-tracking-snippet'

export const universalAnalyticsConfig = {
  type: 'Universal Analytics',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datastreams/universal-analytics',
  form: dataStream => [
    {
      label: 'Display name',
      type: 'string',
      name: 'displayName',
      defaultValue: 'My Universal Analytics',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data stream in SegmentStream.'
    },
    {
      label: 'Destination table name',
      type: 'string',
      name: 'destinationTableName',
      defaultValue: 'universalAnalyticsStream',
      validation: ['required'],
      tip: 'BigQuery table name'
    },
    {
      label: 'Integration type',
      type: 'select',
      name: 'uaIntegrationType',
      defaultValue: 'gtm',
      options: [
        'gtm',
        'analytics'
      ]
    },
    {
      label: 'Website snippet code',
      type: 'html',
      name: 'snippet',
      disabled: true,
      defaultValue: dataStream.id && dataStream.type
        ? snippetCode('track.segmentstream.com', dataStream.id, [], dataStream.type)
        : ''
    }
  ]
}
