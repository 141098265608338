import styled, { css } from 'styled-components'
import close from './close-button.svg'

export const Item = styled.div`
  margin-top: 15px;
  position: relative;
`

export const DeleteButton = styled.div`
  position: absolute;
  top: 19px;
  right: -31px;
  width: 13px;
  height: 13px;
  background: url(${close}) no-repeat;
  cursor: pointer;
  outline: none;
  ${props =>
    props.nestedStyle &&
    css`
      right: 19px;
    `}
`
