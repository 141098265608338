import { fetchQuoraAccounts } from 'api/dataSource'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Quora',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/quora',
    google_analytics: 'https://docs.segmentstream.com/datasources/quora'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Quora Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Quora',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Quora',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Accounts',
      type: 'dynamic select array',
      name: 'accountIds',
      validation: ['required'],
      placeholder: 'Select Account',
      extendedView: true,
      uniqueValues: true,
      optionsFetcher: fetchQuoraAccounts,
      description: 'No accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or create a new one and click "Refresh".'
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        }
      ]
    }
  ]
}

export default config
