import React from 'react'
import Modal from 'react-modal'
import CloseButton from 'containers/CloseButton'

const style = {
  overlay: {
    backgroundColor: 'rgba(29, 44, 76, 0.57)'
  },
  content: {
    top: '0',
    right: '0',
    left: 'initial',
    height: '100vh',
    width: '717px',
    background: '#fafafb',
    padding: '0',
    border: 'none',
    borderRadius: 'none'
  }
}

export default ({ isOpen, children, onClose }) => (
  <div>
    <CloseButton isOpen={isOpen} onClick={onClose} />
    <Modal
      shouldCloseOnOverlayClick={false}
      isOpen={isOpen}
      onRequestClose={onClose}
      closeTimeoutMS={250}
      contentLabel='Modal'
      style={style}
    >
      {isOpen && <div>{children}</div>}
    </Modal>
  </div>
)
