import path from './path'
import { publish as apiPublish } from 'api/project'
import { publishedVersion } from 'utils/digitalDataEvents'
import { setHasUnpublishedChangesVariable } from 'utils/digitalDataVariables'

const PUBLISH = `publish@${path}`

export const publish = () => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiPublish(projectId))
  dispatch({ type: PUBLISH })
  publishedVersion()
  setHasUnpublishedChangesVariable(false)
}

export default (state = {}, action) =>
  action.type === PUBLISH
    ? {
      ...state,
      isPublished: true
    }
    : state
