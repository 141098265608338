import path from './path'
import { add as addPriority } from 'redux/modules/priority/add'
import { savedEvent, changedStatusEvent } from 'utils/digitalDataEvents'

const SAVE_INTEGRATION_TO_INTEGRATIONS = `saveIntegrationToIntegrations@${path}`

export const saveIntegrationToIntegrations = (integrationName, options, isEnabled) => dispatch => {
  dispatch(addPriority(integrationName))
  dispatch({
    type: SAVE_INTEGRATION_TO_INTEGRATIONS,
    integrationName,
    options
  })
  if (!isEnabled) changedStatusEvent(integrationName, path, true)
  savedEvent(integrationName, path)
}

export default (state = {}, action) =>
  action.type === SAVE_INTEGRATION_TO_INTEGRATIONS
    ? {
      ...state,
      [action.integrationName]: {
        ...state[action.integrationName],
        isEnabled: true,
        options: action.options
      }
    }
    : state
