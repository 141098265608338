import styled from 'styled-components'
import arrow from './arrow.png'

export const Icon = styled.div`
  width: 60px;
  height: 60px;
  background: url(${props => props.icon}) no-repeat;
  background-size: 60px 60px;
  margin: 0 auto 30px;
`

export const Arrow = styled.div`
  width: 18px;
  height: 8px;
  background: url(${arrow}) no-repeat;
  background-size: 18px 8px;
  margin: 28px auto 32px;
`

export const Loader = styled.div`
  margin: 20px 0;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4D6DD3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const CheckoutModal = styled.div`
  height: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`
