import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Google Sheets',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/google-sheets',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Sheets',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Google Spreadsheet URL',
      type: 'string',
      name: 'spreadsheetUrl',
      validation: ['required', /^(https:\/\/docs.google.com\/spreadsheets\/d\/)([-a-zA-Z0-9()@:%_+.~#?&//=]*)(#gid=)[0-9]+$/],
      tip: 'Copy this <a href="https://docs.google.com/spreadsheets/d/1u0PjANpTUZTfs9PpNYC5bFiaS_j2pNqwfEegfb_2miI/edit#gid=0" target="_blank" rel="noopener noreferrer">template</a> to your Google Drive and fill it with your expenses.<br>This field should contain the full URL that leads to a particular sheet.<br>To get the full URL, open the required sheet inside your <br>Google Spreadsheet and copy the URL from the address bar.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Google Spreadsheet URL',
      type: 'string',
      name: 'spreadsheetUrl',
      validation: ['required', /^(https:\/\/docs.google.com\/spreadsheets\/d\/)([-a-zA-Z0-9()@:%_+.~#?&//=]*)(#gid=)[0-9]+$/],
      tip: 'This field should contain the full URL that leads to a particular sheet.<br>To get the full URL, open the required sheet inside your <br>Google Spreadsheet and copy the URL from the address bar.',
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Report type',
      type: 'select',
      name: 'reportType',
      options: [
        {
          label: 'Daily cost data grouped by UTM',
          value: 'daily'
        },
        {
          label: 'Monthly cost data grouped by UTM',
          value: 'monthly'
        },
        {
          label: 'Custom',
          value: 'custom'
        }
      ],
      defaultValue: 'custom',
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Range',
      type: 'string',
      name: 'range',
      validation: [/^([a-z0-9]+?):([a-z0-9]+?)$/i],
      showIf: (state) => state.reportType === 'custom',
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Column types',
      type: 'string mapping',
      name: 'columnTypes',
      valueOptions: [
        'STRING',
        'INTEGER',
        'FLOAT',
        'DATE',
        'DATETIME'
      ],
      defaultValueOption: 'STRING',
      showIf: (state) => state.reportType === 'custom',
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Destination table name',
      type: 'string',
      name: 'destinationTableName',
      validation: ['required', /^[a-zA-Z0-9_]+$/],
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    }
  ]
}

export default config
