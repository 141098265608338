import React, { PureComponent } from 'react'
import Modal from 'containers/Modal'
import { Container, Header, HeaderButtonsWrapper, HeaderButton, Top } from './styles'

class ModalWindow extends PureComponent {
  render () {
    const {
      header,
      middle,
      isOpen,
      onClose,
      children,
      docsLink,
      statusBox,
      modalTop
    } = this.props

    return (
      <Modal isOpen={isOpen} onClose={onClose}>
        <div>
          {modalTop}
          <Top>
            <Header>{header}</Header>
            <HeaderButtonsWrapper>
              {docsLink && <HeaderButton>{docsLink}</HeaderButton>}
              {statusBox && <HeaderButton>{statusBox}</HeaderButton>}
            </HeaderButtonsWrapper>
          </Top>
          {middle}
          <Container>{children}</Container>
        </div>
      </Modal>
    )
  }
}

export default ModalWindow
