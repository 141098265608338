import { closeForm } from './closeForm'
import { remove as removeEnrichment } from 'redux/modules/events/remove'
import { remove as apiRemove } from 'api/event'
import { enablePublish } from 'redux/modules/project'

export const remove = (eventId, eventName) => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiRemove(projectId, eventId))
  await dispatch(closeForm())
  dispatch(removeEnrichment(eventId, eventName))
  dispatch(enablePublish())
}
