import { connect } from 'react-redux'

import { open as integrationOpen } from '../../../../../redux/modules/page/integrations/integrationForm'
import { DataStreamsContainer } from './Containers/DataStreamsContainer'

export const DataStreams = connect(
  state => ({
    integrations: state.integrations,
    locale: state.locale,
    projectId: state.project.id,
    trackingBaseUri: state.applicationSettings.trackingBaseUri
  }),
  {
    onIntegrationOpen: integrationOpen
  }
)(DataStreamsContainer)
