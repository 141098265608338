import path from './path'

export const SETTINGS = 'settings'
export const OVERRIDE_FUNCTIONS = 'overrideFunctions'
export const EVENT_ENRICHMENTS = 'eventEnrichments'

const CHANGE_TAB = `changeTab@${path}`

export const changeTab = activeTab => ({
  type: CHANGE_TAB,
  activeTab
})

export default (state = {}, action) =>
  action.type === CHANGE_TAB
    ? {
      ...state,
      activeTab: action.activeTab
    }
    : state
