import path from './path'

const TYPE = `clearHandlerError@${path}`

export const clearHandlerError = () => ({
  type: TYPE
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      ...state,
      handlerError: undefined
    }
    : state
