import React from 'react'
import OnboardingPlaceholder from '../OnboardingPlaceholder'
import lamp from './lamp.png'
import { PROJECT_TYPES } from 'redux/modules/project'

const DataSourceOnboarding = ({ onAddButtonClick, projectType }) => {
  return (
    <OnboardingPlaceholder
      title='Add your first data source<br> by clicking the "Add Data Source" button below'
      icon={lamp}
      showArrow
      buttonTitle='Add Data Source'
      buttonAction={onAddButtonClick}
      linkTitle='Read more about data sources'
      linkUrl={
        projectType === PROJECT_TYPES.BigQuery
          ? 'https://docs.segmentstream.com/datasources/'
          : 'https://docs.segmentstream.com/datasources/'
      }
    />
  )
}

export default DataSourceOnboarding
