import logo from './logo.png'
import sideLogo from './logo-side.png'
import { EMAIL, PRODUCT_RECOMMENDATION } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Emarsys',
  color: '#5b87da',
  category: [EMAIL, PRODUCT_RECOMMENDATION],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Merchant ID',
      type: 'string',
      name: 'merchantId',
      validation: ['required']
    },
    {
      label: 'Category separator',
      type: 'string',
      name: 'categorySeparator'
    }
  ]
}

export default config
