import { useGetDataStreamsQuery } from '../GraphQL/getDataStreams'
import { useState } from 'react'

export function useListContext (projectId) {
  const { data, loading } = useGetDataStreamsQuery(projectId)
  const [selectedDataStreamToUpdate, selectDataStreamToUpdate] = useState(null)

  return {
    isLoading: loading,
    dataStreams: data?.project?.dataStreams,
    selectedDataStreamToUpdate,
    selectDataStreamToUpdate
  }
}
