import * as _ from 'lodash'
import loadScript from 'load-script'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import { Button, Icon, DeleteButton } from './styles'
import logo from './google-drive-logo.png'

const GOOGLE_SDK_URL = 'https://apis.google.com/js/api.js'

let scriptLoadingStarted = false

class GoogleDriveFilePicker extends PureComponent {
  constructor (props) {
    super(props)

    this.onApiLoad = this.onApiLoad.bind(this)
    this.onChoose = this.onChoose.bind(this)
    this.onPickerChange = this.onPickerChange.bind(this)
    this.clearSelection = this.clearSelection.bind(this)
  }

  componentDidMount () {
    if (this.isGoogleReady()) {
      // google api is already exists
      // init immediately
      this.onApiLoad()
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true
      loadScript(GOOGLE_SDK_URL, this.onApiLoad)
    } else {
      // is loading
    }
  }

  isGoogleReady () {
    return !!window.gapi
  }

  isGoogleAuthReady () {
    return !!window.gapi.auth
  }

  isGooglePickerReady () {
    return !!window.google.picker
  }

  onApiLoad () {
    window.gapi.load('auth')
    window.gapi.load('picker')
  }

  doAuth (callback) {
    window.gapi.auth.authorize({
      client_id: this.props.clientId,
      scope: ['https://www.googleapis.com/auth/drive.readonly'],
      immediate: false
    },
    callback
    )
  }

  onChoose () {
    if (!this.isGoogleReady() || !this.isGoogleAuthReady() || !this.isGooglePickerReady()) {
      return null
    }

    const token = window.gapi.auth.getToken()
    const oauthToken = token && token.access_token

    if (oauthToken) {
      this.createPicker(oauthToken)
    } else {
      this.doAuth(response => {
        if (response.access_token) {
          this.createPicker(response.access_token)
        } else {
          console.error(response)
        }
      })
    }
  }

  onPickerChange (obj) {
    if (obj.action !== window.google.picker.Action.PICKED) {
      return
    }

    const folder = obj.docs[0]
    this.props.onChange({
      id: folder.id,
      name: folder.name
    })
  }

  clearSelection () {
    this.props.onChange(undefined)
  }

  createPicker (oauthToken) {
    const picker = window.google.picker

    if (!this.pickerInstance) {
      const translate = getTranslate(this.props.locale, { ru })

      const foldersView = (new picker.DocsView(picker.ViewId.FOLDERS))
        .setMimeTypes('application/vnd.google-apps.folder')
        .setParent('root')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)

      const googleDriveView = (new picker.DocsView())
        .setMimeTypes('application/vnd.google-apps.folder')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)

      const sharedDrivesView = (new picker.DocsView())
        .setMimeTypes('application/vnd.google-apps.folder')
        .setIncludeFolders(true)
        .setSelectFolderEnabled(true)
        .setEnableDrives(true)

      this.pickerInstance = (new picker.PickerBuilder())
        .setSelectableMimeTypes('application/vnd.google-apps.folder')
        .setOAuthToken(oauthToken)
        .setDeveloperKey(this.props.browserKey)
        .setLocale(this.props.locale)
        .setOrigin(window.location.protocol + '//' + window.location.host)
        .enableFeature(picker.Feature.SUPPORT_DRIVES)
        .setTitle(translate('Pick a folder'))
        .addView(foldersView)
        .addView(googleDriveView)
        .addView(sharedDrivesView)
        .addView(picker.ViewId.RECENTLY_PICKED)
        .setCallback(this.onPickerChange)
        .build()
    }

    this.pickerInstance.setVisible(true)
  }

  render () {
    const { value } = this.props

    return (
      <div>
        <label>{this.props.label}</label>
        <div>
          <Button onClick={this.onChoose} full={!value}>
            {value ? value.name : <Icon logo={logo} />}
          </Button>
          {value ? <DeleteButton onClick={this.clearSelection} /> : ''}
        </div>
      </div>
    )
  }
}

export default connect(state => ({
  clientId: _.get(state, 'applicationSettings.google.clientId'),
  browserKey: _.get(state, 'applicationSettings.google.browserKey'),
  locale: state.locale
}))(GoogleDriveFilePicker)
