import React, { PureComponent } from 'react'
import {
  Wrapper,
  Option,
  Select,
  SelectedValue,
  Triangle,
  Input,
  Label,
  ExtendedViewValue,
  Placeholder,
  OptionLabel,
  ValueWrapper,
  THEME_FORM_ITEM,
  THEME_FILTER,
  THEME_FORM_FIELD,
  THEME_BILLING_FIELD
} from './styles'
import Tooltip from 'containers/Tooltip'
import { THEME_FORM_SINGLE_ARRAY_ITEM } from '../DynamicSelectArray'

class SelectBox extends PureComponent {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleToggle () {
    const { onTouch } = this.props
    this.setState({
      isOpen: !this.state.isOpen
    })
    if (onTouch) onTouch()
  }

  handleBlur () {
    this.setState({
      isOpen: false
    })
  }

  handleSelect (value) {
    this.props.onChange(value === '' ? undefined : value)
  }

  render () {
    const { label, options, value, theme = { THEME_FORM_ITEM }, styles = {}, placeholder, tip, disabled, extendedView, uniqueValues, arrayValues = [] } = this.props
    const { isOpen } = this.state
    let componentStyle =
      { width: '50%', display: 'inline-block' }
    if (theme === THEME_FORM_FIELD || theme === THEME_BILLING_FIELD) {
      componentStyle = { width: '100%', marginBottom: '17px' }
    } else if (theme === THEME_FILTER) {
      componentStyle = { width: '140px', marginLeft: '17px' }
    } else if (theme === THEME_FORM_SINGLE_ARRAY_ITEM) {
      componentStyle = { width: '100%', display: 'inline-block' }
    }

    const selectOptions = uniqueValues ? options.filter(option => !arrayValues.includes(extendedView ? option.value : option)) : options
    const hasLabel = options[0] && typeof options[0].label === 'string'
    const hasExtendedOptions = typeof options[0] === 'object'
    const valueLabel = hasLabel ? (options.find(opt => opt.value === value) || {}).label || value : value
    return (
      <div style={{ ...componentStyle, ...styles }}>
        {
          label &&
          <Label>
            {<label>{label}</label>}
            {tip && <Tooltip tip={tip} place='right' />}
          </Label>
        }
        <Wrapper
          tabIndex='0'
          onBlur={this.handleBlur}
          onClick={!disabled && selectOptions.length ? this.handleToggle : undefined}
          theme={theme}
          disabled={disabled}>
          <Input isOpen={isOpen} theme={theme} extendedView={extendedView}>
            <SelectedValue theme={theme} extendedView={extendedView}>
              {
                <ValueWrapper>
                  {valueLabel ? <OptionLabel>{valueLabel}</OptionLabel> : <Placeholder>{placeholder}</Placeholder>}
                  {extendedView && hasExtendedOptions && valueLabel && <ExtendedViewValue>{value}</ExtendedViewValue>}
                </ValueWrapper>
              }
            </SelectedValue>
            <Triangle theme={theme} extendedView={extendedView} />
          </Input>
          {isOpen && (
            <Select theme={theme}>
              {selectOptions.map(opt => {
                const val = hasLabel ? opt.value : opt
                const lab = hasLabel ? opt.label : opt
                return (
                  <Option key={val} onClick={() => this.handleSelect(val)} theme={theme}>
                    <OptionLabel>{lab || val}</OptionLabel>
                    {extendedView && <ExtendedViewValue>{val}</ExtendedViewValue>}
                  </Option>
                )
              })}
            </Select>
          )}
        </Wrapper>
      </div>
    )
  }
}

export default SelectBox
