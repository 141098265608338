import React from 'react'
import {
  TipContainer,
  TipContent,
  TipIcon,
  Arrow,
  CloseButton
} from './styles'

const Tip = ({ text, left, dismissTip }) => {
  return (
    <TipContainer left={left}>
      <TipContent>
        <Arrow />
        <CloseButton onClick={dismissTip} />
        <TipIcon />
        {text}
      </TipContent>
    </TipContainer>
  )
}

export default Tip
