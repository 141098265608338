import { enable, disable } from 'api/dataDestination'
import path from './path'
import { changedStatusEvent } from 'utils/digitalDataEvents'

const CHANGE_STATUS = `changeStatus@${path}`

export const setStatus = (id, isEnabled) => async dispatch => {
  dispatch({
    type: CHANGE_STATUS,
    isEnabled,
    id
  })
}

export const changeStatus = (id, isEnabled, type) => async (dispatch, getState) => {
  const projectId = getState().project.id
  if (isEnabled) {
    const apiResponse = await dispatch(enable(projectId, id))
    if (apiResponse.errors) {
      window.alert(apiResponse.errors[0].message)
    }
  } else {
    await dispatch(disable(projectId, id))
  }
  dispatch(setStatus(id, isEnabled))
  changedStatusEvent(type, path, isEnabled)
}

export default (state = [], action) => {
  if (action.type === CHANGE_STATUS) {
    return {
      ...state,
      [action.id]: {
        ...state[action.id],
        isEnabled: action.isEnabled
      }
    }
  }
  return state
}
