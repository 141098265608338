export const createDatasetLink = ({ options }) => {
  return options
    ? [
      `https://console.cloud.google.com`,
      `/bigquery`,
      `?p=${options.projectId}`,
      `&d=${options.datasetName}`,
      `&page=dataset`
    ].join('')
    : null
}
