import reduceReducers from 'reduce-reducers'
import dataTransformationsForm from './dataTransformationsForm'

import { save } from './save'

export { save }

export default reduceReducers((state = {}, action) => ({
  ...state,
  dataTransformationsForm: dataTransformationsForm(state.dataTransformationsForm, action)
}))
