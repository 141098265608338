import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import Table from 'containers/Table'
import NameCell from 'containers/NameCell'
import Status from 'containers/Status'
import EventsForm from './EventsForm'
import { openForm } from 'redux/modules/page/events/openForm'
import Date from 'containers/Date'
import types, { EVENT } from './types'
import ru from './ru'
import getTranslate from 'utils/getTranslate'
import { addedEvent } from 'utils/digitalDataEvents'
import { isProMode, filterProModeItems } from 'utils/proMode'

const Events = ({ locale, list, isProMode, onOpenForm }) => {
  const translate = getTranslate(locale, { ru })
  const handleOpenForm = () => {
    addedEvent('event')
    onOpenForm()
  }
  const showTrigger = event =>
    event.type === EVENT
      ? `${translate(types[EVENT])}: ${event.event}`
      : translate(types[event.type])

  const data = filterProModeItems(list, isProMode)
    .sort((itemA, itemB) => {
      const nameA = itemA.name.toLowerCase()
      const nameB = itemB.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map(item => [
      <Status isEnabled={item.isEnabled} />,
      <NameCell
        name={item.name}
        isEnabled={item.isEnabled}
        onClick={() => onOpenForm(item.id, item.name)}
      />,
      showTrigger(item),
      <Date date={item.updatedAt} />
    ])
  const confirmButton = (
    <ConfirmButton onClick={handleOpenForm}>{translate('Add')}</ConfirmButton>
  )
  return (
    <div>
      <SectionHeader leftContent={confirmButton} />
      <Table
        headers={['', translate('Title'), translate('Trigger'), translate('Updated')]}
        data={data}
      />
      <EventsForm />
    </div>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    list: state.events,
    isProMode: isProMode(state.proMode.status, state.user.role)
  }),
  {
    onOpenForm: openForm
  }
)(Events)
