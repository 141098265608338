import React from 'react'
import getTranslate from 'utils/getTranslate'
import { Text, Icon, Button, LeftContainer, RightContainer, AuthIcon, AuthName } from './styles'
import icon from './icon.png'
import ru from './ru'

const AuthenticationStatusBox = ({ locale, authIcon, authName, onChange, authMeta }) => {
  const translate = getTranslate(locale, { ru })

  const handleClick = () => {
    if (window.confirm(translate('Are you sure?'))) {
      onChange(false)
    }
  }

  const getAuthName = () => {
    if (authMeta) return authMeta.email || authMeta.name
    return authName
  }

  return (
    <Button>
      <LeftContainer>
        <AuthIcon src={authIcon} />
        <AuthName>{getAuthName()}</AuthName>
      </LeftContainer>
      <RightContainer onClick={handleClick}>
        <Text>{translate('Disconnect')}</Text>
        <Icon src={icon} />
      </RightContainer>
    </Button>
  )
}

export default AuthenticationStatusBox
