import path from './path'
import { setUserVariable } from 'utils/digitalDataVariables'

const TYPE = `fetch@${path}`

export const fetch = user => {
  setUserVariable(user)
  return {
    type: TYPE,
    user
  }
}

export default (state = [], action) => (action.type === TYPE ? action.user : state)
