import path from './path'
import { auth as apiAuth } from 'api/auth'
import { authenticate } from './authenticate'
import { getUser as apiGetUser } from 'api/user'
import { fetch as fetchLocale } from 'redux/modules/locale'
import { fetch as fetchUser } from 'redux/modules/user/fetch'
import { loggedIn } from 'utils/digitalDataEvents'
import { selectBillingAccount } from 'redux/modules/user/selectBillingAccount'
import { getUrlParam } from 'utils/getUrlParam'

export const SUCCESS = 'success'
export const PENDING = 'pending'
export const FAILED = 'failed'

const TYPE_PENDING = `pending@${path}`
const TYPE_SUCCESS = `success@${path}`
const TYPE_FAILED = `failed@${path}`

export const login = (email, password, billingAccountId) => async dispatch => {
  dispatch({ type: TYPE_PENDING })
  try {
    const res = await dispatch(apiAuth(email, password))
    await dispatch(authenticate(res.access_token, res.refresh_token))

    const redirectTo = getUrlParam('redirectTo')

    if (redirectTo) {
      window.location.replace(redirectTo)
    }

    const user = await dispatch(apiGetUser())
    await dispatch(fetchUser(user))

    await dispatch(selectBillingAccount(user, billingAccountId))

    await dispatch(fetchLocale('en'))
    dispatch({ type: TYPE_SUCCESS })

    loggedIn()
  } catch (error) {
    console.error(error)
    dispatch({ type: TYPE_FAILED })
  }
}

export default (state = {}, action) => {
  switch (action.type) {
    case TYPE_SUCCESS:
      return {
        ...state,
        status: SUCCESS
      }
    case TYPE_PENDING:
      return {
        ...state,
        status: PENDING
      }
    case TYPE_FAILED:
      return {
        ...state,
        status: FAILED
      }
    default:
      return state
  }
}
