import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'JSON Feed',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/json-feed',
  authType: 'Basic',
  authIcon,
  authName: 'JSON Feed Authorization',
  isPublished: true,
  authForm: [
    {
      label: 'Username',
      type: 'string',
      name: 'username'
    },
    {
      label: 'Password',
      type: 'password',
      name: 'password',
      autocomplete: 'new-password'
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My JSON Feed',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Feed URL',
      type: 'string',
      name: 'url',
      validation: ['required']
    },
    {
      label: 'Example record',
      type: 'text',
      name: 'exampleRecord',
      validation: ['required', 'json'],
      tip: 'Example record of JSON feed which will be used for BigQuery schema auto-detection.'
    },
    {
      label: 'Destination table name',
      type: 'string',
      name: 'destinationTableName',
      validation: ['required', /^[a-zA-Z0-9_]+$/]
    },
    {
      label: 'Partition table by date',
      type: 'boolean',
      name: 'destinationTableDatePartition',
      defaultValue: true
    }
  ]
}

export default config
