import styled from 'styled-components'
import help from './help.svg'
import ReactTooltip from 'react-tooltip'

export const FormWrapper = styled.div`
  position: absolute;
  width: 422px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SignUpForm = styled.form`
  padding: 23px 16px 0px;
`

export const SimpleLink = styled.a`
  cursor: pointer;
  color: #406DFB;
  text-decoration-line: underline;
  font-size: 16px;
  line-height: 19px;
`
export const InputLabel = styled.div`
  position: relative;
  top: 10px;
  left: 19px;
  height: 0px;
  width: 0px;
  font-size: 12px;
  color: #657789;
  text-align: left;
`

export const SelectBoxWrapper = styled.div`
  position: relative;
`

export const SelectBoxInputLabel = styled(InputLabel)`
  z-index: 1;
`
export const SelectBoxHelpWrapper = styled.div`
  position: absolute;
  top: 0;
  right: -30px;
  display: flex;
  height: 100%;
`

export const SelectBoxHelpIcon = styled.img.attrs({ src: help })`
  height: 15px;
  margin: auto;
`
export const HeaderWrapper = styled.div`
  text-align: center;
  margin: 15px 0;
`
export const Header = styled.div`
  display: inline-flex;
  margin-top: 5px;
`
export const StyledTooltip = styled(ReactTooltip)`
  width: 20vw;
`
