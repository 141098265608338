import { useCreateDataStreamMutation } from '../GraphQL/createDataStream'
import { useState } from 'react'
import { savedEvent } from 'utils/digitalDataEvents'

export function useCreateContext (projectId) {
  const [createDataStreamMutation, { loading: createLoading }] = useCreateDataStreamMutation(projectId)

  const [selectedDataStreamType, setSelectedDataStreamType] = useState(null)
  const selectDataStreamType = type => setSelectedDataStreamType(type)

  const [createSideOpen, setCreateSideOpen] = useState(false)
  const openCreateSide = () => setCreateSideOpen(true)
  const closeCreateSide = () => {
    setSelectedDataStreamType(null)
    setCreateSideOpen(false)
  }

  const createDataStream = async (type, dataStreamOptions) => {
    const dataStream = {
      projectId,
      type: type.type,
      isEnabled: true,
      ...dataStreamOptions,
      uaIntegrationType: undefined,
      snippet: undefined
    }

    savedEvent(dataStream.type, 'dataStream')
    await createDataStreamMutation({ variables: { dataStream } })
    closeCreateSide()
    setSelectedDataStreamType(null)
  }

  return {
    isLoading: createLoading,
    isOpen: createSideOpen,
    selectedDataStreamType,

    open: openCreateSide,
    close: closeCreateSide,
    createDataStream,
    selectDataStreamType
  }
}
