import React, { Component } from 'react'
import { DropTarget as dropTarget } from 'react-dnd'
import Card from './Card'
import { CARD } from './ItemType'
import { Block, DropArea, Integration } from './styles'
import allIntegrations, { sandboxIntegrations } from 'integrations'

const blockTarget = {
  hover: (props, monitor) => {
    const dragItem = monitor.getItem().item
    const hoverItem = props.array[props.array.length - 1]
    if (dragItem === hoverItem || hoverItem === null) {
      return
    }
    props.moveCard(dragItem, hoverItem)
  }
}

class CardBlock extends Component {
  constructor (props) {
    super(props)
    this.handleDropCard = this.handleDropCard.bind(this)
  }

  handleDropCard (dragItem) {
    const { array, dropCard, dropNewCard } = this.props
    if (!array.includes(dragItem)) {
      dropNewCard(dragItem)
    } else {
      dropCard()
    }
  }

  render () {
    const { priority, array, connectDropTarget, moveCard, isSaleMode } = this.props

    const integrations = isSaleMode
      ? [...allIntegrations, ...sandboxIntegrations]
      : allIntegrations

    const cards =
      array &&
      array
        .filter(item => !!integrations.some(integration => integration.name === item))
        .map(item =>
          item === null ? (
            <Integration fake key='null' />
          ) : (
            <Card
              key={item.name}
              moveCard={moveCard}
              dropCard={this.handleDropCard}
              item={item}
              isSaleMode={isSaleMode}
            />
          )
        )
    const dropDiv = connectDropTarget(
      <div style={{ display: 'inline-block' }}>
        <DropArea />
      </div>
    )
    return (
      <Block priority={priority}>
        {cards}
        {dropDiv}
      </Block>
    )
  }
}

export default dropTarget(CARD, blockTarget, connect => ({
  connectDropTarget: connect.dropTarget()
}))(CardBlock)
