import React from 'react'

import { Spinner } from '../../../../../../uiKit'
import { InitializingMessage } from './styles'

export function BillingRedirectStep () {
  return (
    <React.Fragment>
      <InitializingMessage>
        <Spinner />
      </InitializingMessage>
    </React.Fragment>
  )
}
