import styled from 'styled-components'
import messageIcon from './message-icon.svg'

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-left: 1.6em;
`

export const MessageIcon = styled.div`
  height: 41px;
  width: 41px;
  background: url(${messageIcon}) no-repeat;
  background-size: 41px 41px;  
  margin-top: 10px;
`

export const RequestText = styled.div`
  font-size: 14px;
  line-height: 17px;
  padding-top: 20px;
`

export const CallToActionText = styled.div`
  color: #4D6DD3;
  margin-top: 15px;
`
