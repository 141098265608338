import React, { createContext } from 'react'

import { useListContext } from './listContext'
import { useUpdateContext } from './updateContext'
import { useCreateContext } from './createContext'

export const dataStreamsContext = createContext({})
const { Provider } = dataStreamsContext

export const DataStreamsProvider = ({ projectId, trackingBaseUri, children }) => {
  const list = useListContext(projectId)
  const update = useUpdateContext(projectId, list)
  const create = useCreateContext(projectId)

  return (
    <Provider value={{
      projectId,
      trackingBaseUri,

      list,
      create,
      update
    }}>
      { children }
    </Provider>
  )
}
