import logo from './logo.svg'
import { fetchFivetranTables } from 'api/dataSource'

const config = {
  type: 'Microsoft Dynamics 365',
  enabledForGoogleAnalyticsProjects: false,
  poweredByFivetran: true,
  betaFeature: true,
  logo,
  authForm: [
    {
      label: 'Click to connect Microsoft Dynamics 365 via Fivetran',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: logo
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Dynamics 365',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      type: 'group array',
      name: 'dynamicsTables',
      addButtonLabel: 'Add Microsoft Dynamics table',
      items: [
        {
          label: 'Microsoft Dynamics table',
          type: 'dynamic select',
          name: 'tableName',
          validation: ['required'],
          placeholder: 'Select table',
          optionsFetcher: fetchFivetranTables,
          description: 'No tables were found. Please, make sure you have access to the required tables.'
        },
        {
          label: 'Partition column',
          type: 'string',
          name: 'partitionColumn',
          validation: ['required']
        },
        {
          label: 'Destination table name',
          type: 'string',
          name: 'destinationTableName',
          validation: ['required']
        }
      ]
    }
  ]
}

export default config
