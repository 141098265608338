import path from './path'

const TYPE = `remove@${path}`

export const remove = integrationName => ({
  type: TYPE,
  integrationName
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      before: state.before.filter(item => item !== action.integrationName),
      after: state.after.filter(item => item !== action.integrationName)
    }
    : state
