import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'

const config = {
  name: 'Adnetic',
  color: '#22174e',
  category: [ADVERTISING],
  sideLogo,
  logo,
  requiresSetup: false
}

export default config
