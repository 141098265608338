import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import Form from 'containers/Form'
import { closeForm } from 'redux/modules/page/scripts/closeForm'
import { changeStatus } from 'redux/modules/scripts/changeStatus'

import { save, remove } from 'redux/modules/page/scripts'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'
import ru from './ru.json'

const ScriptsForm = ({
  locale,
  script,
  isOpen,
  isProMode,
  onClose,
  onSave,
  onRemove,
  onChangeStatus,
  projectName
}) => {
  const translate = getTranslate(locale, { ru })
  const handleSave = (newScript, touchedFields) => {
    onSave(newScript, touchedFields)
  }
  const handleRemove = () => {
    if (script) {
      onRemove(script.id, script.name)
    }
  }
  const form = [
    {
      label: translate('Script name'),
      type: 'string',
      name: 'name',
      validation: ['required', 'no-double-quotes']
    },
    {
      label: translate('Event name'),
      type: 'string',
      name: 'event',
      validation: ['no-double-quotes', 'required'],
      defaultValue: script && !script.event ? 'Viewed Page' : undefined
    },
    {
      label: translate('Script'),
      type: 'javascript',
      name: 'handler',
      validation: ['required']
    },
    {
      label: translate('Once per page'),
      type: 'boolean',
      name: 'fireOnce',
      value: false
    },
    {
      label: translate('Priority'),
      type: 'number',
      name: 'priority',
      description: translate(
        'Scripts with higher numbers for priority will be fired first. Priority defaults to 0 if none is specified.'
      )
    },
    {
      label: translate('Run after page loaded'),
      type: 'boolean',
      name: 'runAfterPageLoaded',
      value: false
    }
  ]
  if (isProMode) {
    form.push({
      label: 'Pro mode only',
      type: 'boolean',
      name: 'proModeOnly',
      defaultValue: false
    })
  }

  const header = script !== undefined ? script.name : translate('Add script')

  const handleChangeStatus = () => {
    if (script) {
      onChangeStatus(script.id, script.name, !script.isEnabled)
    }
  }

  const messageTemplate = translate(
    'Are you sure that you want to <b>{{ACTION}}</b> the "{{SCRIPT_NAME}}" script in the <b>{{PROJECT_NAME}}</b> project?'
  )
  const disableMessage = translate(messageTemplate, {
    SCRIPT_NAME: script ? script.name : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DISABLE')
  })
  const deleteMessage = translate(messageTemplate, {
    SCRIPT_NAME: script ? script.name : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DELETE')
  })

  const statusBox =
    script !== undefined ? (
      <StatusBox
        locale={locale}
        isEnabled={script.isEnabled}
        onChange={handleChangeStatus}
        disableMessage={disableMessage}
      />
    ) : null

  const docsLink = <DocsLink locale={locale} url='https://docs.segmentstream.com/for-analyst/scripts' />

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={header} statusBox={statusBox} docsLink={docsLink}>
      <Form
        locale={locale}
        form={form}
        data={script}
        onSave={handleSave}
        onRemove={script !== undefined ? handleRemove : undefined}
        deleteMessage={deleteMessage}
      />
    </ModalWindow>
  )
}

export default connect(
  state => {
    const { isOpen, id } = state.page.scripts
    return {
      locale: state.locale,
      projectName: state.project.name,
      isOpen,
      isProMode: isProMode(state.proMode.status, state.user.role),
      script: id !== undefined ? state.scripts.find(item => item.id === id) : undefined
    }
  },
  {
    onClose: closeForm,
    onSave: save,
    onRemove: remove,
    onChangeStatus: changeStatus
  }
)(ScriptsForm)
