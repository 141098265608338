import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru'

const config = {
  name: 'CityAds',
  color: '#00a2c2',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/citiads',
  form: [
    {
      label: 'Default target name',
      type: 'string',
      name: 'defaultTargetName',
      validation: ['required']
    },
    {
      label: 'Campaign identifier in CityAds',
      type: 'string',
      name: 'partnerId',
      validation: ['required']
    },
    {
      label: 'Click id cookie name',
      type: 'string',
      name: 'clickIdCookieName',
      defaultValue: 'cityads_click_id'
    },
    {
      label: 'aip cookie name',
      type: 'string',
      name: 'aipCookieName',
      defaultValue: 'cityads_aip'
    },
    {
      label: 'Cookie auto tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: ['cookieDomain', 'cookieTtl']
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain',
      validation: ['required']
    },
    {
      label: 'Cookie time to live',
      type: 'number',
      name: 'cookieTtl',
      defaultValue: 90
    },
    {
      label: 'utm_source from CityAds',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'cityads'
    },
    {
      label: 'Activate deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which require deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    }
  ]
}

export default config
