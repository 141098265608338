import path from './path'
import { enablePublish } from 'redux/modules/project'

const SAVE_OVERRIDE_FUNCTIONS = `saveOverrideFunctions@${path}`

export const saveOverrideFunctions = (integrationName, overrideFunctions) => dispatch => {
  dispatch({
    type: SAVE_OVERRIDE_FUNCTIONS,
    integrationName,
    overrideFunctions
  })
  dispatch(enablePublish())
}

export default (state = {}, action) =>
  action.type === SAVE_OVERRIDE_FUNCTIONS
    ? {
      ...state,
      [action.integrationName]: {
        ...state[action.integrationName],
        overrideFunctions: action.overrideFunctions
      }
    }
    : state
