import React from 'react'
import { TextareaFieldInput } from './styles'
import getValidationError from 'containers/Form/getValidationError'
import FieldError from 'containers/Form/FieldError'

const InputText = ({
  label,
  value = '',
  onChange,
  onTouch,
  onFocus,
  onBlur,
  style,
  disabled = false,
  placeholder,
  validation,
  touched,
  hasFocus
}) => {
  const handleChange = event => {
    const inputValue = event.target.value
    if (inputValue === '') {
      onChange(undefined)
    } else {
      onChange(inputValue)
    }
  }

  const error =
    touched && !disabled && !hasFocus &&
    validation
      ? getValidationError(validation, value)
      : undefined

  return (
    <div>
      {label && <label>{label}</label>}
      <TextareaFieldInput
        style={style}
        value={value}
        onChange={handleChange}
        onClick={onTouch}
        onFocus={onFocus}
        onBlur={onBlur}
        disabled={disabled}
        placeholder={placeholder}
        error={error}
      />
      {error && <FieldError message={error} />}
    </div>
  )
}

export default InputText
