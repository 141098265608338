const HUBSPOT_PORTAL_ID = '5806956'
const HUBSPOT_FORM_ID = '3abc5e2a-9d8b-4ffc-9caa-6611c4124f13'

export const handleHubSpotFormSubmit = (user, billingAccount) => {
  const formData = [
    {
      name: 'firstname',
      value: user.name
    },
    {
      name: 'lastname',
      value: user.lastName
    },
    {
      name: 'email',
      value: user.email
    },
    {
      name: 'country',
      value: billingAccount.countryCode
    },
    {
      name: 'company',
      value: billingAccount.accountName
    },
    {
      name: 'segmentstream_account_id',
      value: billingAccount.id
    }
  ]

  const googleAnalyticsCookie = document.cookie.match(/_ga=(.+?);/)
  const googleClientId = googleAnalyticsCookie && googleAnalyticsCookie.length > 1 ? googleAnalyticsCookie[1].split('.').slice(-2).join('.') : undefined
  if (googleClientId) {
    formData.push({
      name: 'google_client_id',
      value: googleClientId
    })
  }

  const facebookCookie = document.cookie.match(/_fbp=(.+?);/)
  const facebookClientId = facebookCookie && facebookCookie.length > 1 ? facebookCookie[1].split('.')[3] : undefined
  if (facebookClientId) {
    formData.push({
      name: 'facebook_pixel_id',
      value: facebookClientId
    })
  }

  const xhr = new window.XMLHttpRequest()
  xhr.open('POST', `https://api.hsforms.com/submissions/v3/integration/submit/${HUBSPOT_PORTAL_ID}/${HUBSPOT_FORM_ID}`, true)
  xhr.setRequestHeader('Content-Type', 'application/json')
  xhr.send(JSON.stringify({
    fields: formData
  }))
}
