import path from './path'
import { openedEvent } from 'utils/digitalDataEvents'

const OPEN = `openModal@${path}`

export const openModal = (component, feature) => {
  openedEvent(undefined, path, 'Project')
  return {
    type: OPEN,
    component,
    feature
  }
}

export default (state = {}, action) =>
  action.type === OPEN
    ? {
      ...state,
      component: action.component,
      feature: action.feature
    }
    : state
