import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchYandexShopNames } from 'api/dataSource'

const config = {
  type: 'Yandex.Market',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/yandex-market',
    google_analytics: 'https://docs.segmentstream.com/datasources/yandex-market'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Yandex Account',
  authForm: [
    {
      label: 'Click to authenticate with Yandex',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Yandex.Market',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'All shops',
      type: 'boolean',
      name: 'allShops',
      disable: ['shopsNames'],
      optionsFetcher: fetchYandexShopNames,
      description: 'There are no shops for the account or access is not granted. Please, <a class="description" href="https://yandex.com/support/partnermarket/registration/authorization.html" target="_blank" rel="noopener noreferrer">register on Yandex.Market</a> or make sure you have “Read” permission to the shop.',
      errorDescription: 'Partner API is not enabled for your account. Please, <a class="description" href="https://yandex.ru/support/market-agency/accesses.html#partner-api" target="_blank" rel="noopener noreferrer">submit an API access request</a> to be able to import data from Yandex.Market API.'
    },
    {
      label: 'Shop names',
      type: 'dynamic select array',
      name: 'shopsNames',
      placeholder: 'Select shop name',
      optionsFetcher: fetchYandexShopNames,
      addButtonLabel: 'Add shop name',
      uniqueValues: true,
      validation: ['required'],
      showIf: (state) => state.allShops === false
    },
    {
      label: 'Import offers statistics report',
      type: 'boolean',
      name: 'offersStatisticsReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Billing currency',
      type: 'select',
      name: 'normalizedCostsReportBillingCurrency',
      options: ['RUB', 'USD'],
      defaultValue: 'RUB',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Exclude VAT',
      type: 'boolean',
      name: 'excludeVAT',
      enable: ['vatRate'],
      showIf: (state) => state.normalizedCostsReportBillingCurrency === 'RUB',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      },
      tip: 'By default, Yandex.Market cost data includes VAT.<br/>' +
        'To exclude VAT from imported costs switch on this<br/>' +
        'option and define the VAT rate to be excluded.<br/>' +
        'You can read more about why we recommend excluding VAT <a href="https://docs.segmentstream.com/datasources/#vat-handling" target="_blank" rel="noopener noreferrer">here</a>.'
    },
    {
      label: 'VAT rate',
      type: 'number',
      name: 'vatRate',
      units: '%',
      showIf: (state) => state.normalizedCostsReportBillingCurrency === 'RUB',
      validation: ['required'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          enable: ['normalizedCostsReportBillingCurrency', 'costMultiplier', 'excludeVAT', 'vatRate'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Billing currency',
          type: 'select',
          name: 'normalizedCostsReportBillingCurrency',
          options: ['RUB', 'USD'],
          defaultValue: 'RUB'
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        },
        {
          label: 'Exclude VAT',
          type: 'boolean',
          name: 'excludeVAT',
          enable: ['vatRate'],
          tip: 'By default, Yandex.Market cost data includes VAT.<br/>' +
            'To exclude VAT from imported costs switch on this<br/>' +
            'option and define the VAT rate to be excluded.<br/>',
          showIf: (state) => state.additionalTransformationSettings && state.additionalTransformationSettings.normalizedCostsReportBillingCurrency === 'RUB'
        },
        {
          label: 'VAT rate',
          type: 'number',
          name: 'vatRate',
          units: '%',
          showIf: (state) => state.additionalTransformationSettings && state.additionalTransformationSettings.normalizedCostsReportBillingCurrency === 'RUB',
          validation: ['required']
        }
      ]
    }
  ]
}

export default config
