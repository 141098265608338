import { apiCall } from './apiCall'

export const enable = (projectId, integrationName) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/enable`,
  method: 'PUT'
})

export const disable = (projectId, integrationName) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/disable`,
  method: 'PUT'
})

export const saveOverrideFunctions = (projectId, integrationName, overrideFunctions) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/override-functions`,
  method: 'PUT',
  headers: { 'Content-Type': 'text/plain' },
  body: overrideFunctions
})

export const saveEventEnrichments = (projectId, integrationName, eventEnrichments) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/event-enrichments`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(eventEnrichments)
})

export const saveOptions = (projectId, integrationName, options) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/options`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(options)
})

export const removeAuth = (projectId, integrationName) => ({
  url: `/projects/${projectId}/integrations/${integrationName}/auth_options`,
  method: 'DELETE'
})

export const fetchBigQueryProjects = (context) =>
  apiCall({
    url: `/projects/${context.projectId}/integrations/${context.integration.name}/options/projectId/values`,
    method: 'GET'
  }).then(resp => resp.text())
    .then(resp => JSON.parse(resp))
    .catch(r => [])

export const fetchBigQueryDataSets = (context) =>
  apiCall({
    url: `/projects/${context.projectId}/integrations/${context.integration.name}/options/datasetName/values?projectId=${context.dependentFieldValues.projectId}`,
    method: 'GET'
  }).then(resp => resp.text())
    .then(resp => JSON.parse(resp))
    .catch(r => [])

export const checkAuthOptions = (context) =>
  apiCall({
    url: `/projects/${context.projectId}/integrations/${context.integration.name}/checkAuthOptions`,
    method: 'GET'
  }).then(resp => resp.text())
    .then(resp => JSON.parse(resp))
    .catch(e => console.error(e))
