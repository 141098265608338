import React from 'react'
import { ButtonTitle, ButtonContainer, StatusEnabled } from './styles'

const EnabledStatusButton = ({ text, handleClick }) => (
  <ButtonContainer onClick={handleClick}>
    <StatusEnabled />
    <ButtonTitle>{text}</ButtonTitle>
  </ButtonContainer>
)

export default EnabledStatusButton
