import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import { Title, ButtonContainer, ButtonTitle, OnboardingContainer } from './styles'
import getTranslate from 'utils/getTranslate'
import DataTransformationsList from './DataTransformationsList'
import DataTransformationSide from './DataTransformationSide'
import ru from './ru.json'
import Status from 'containers/Status'
import { Wrapper, Box } from 'containers/StatusBox/styles'

import OnboardingPlaceholder from '../OnboardingPlaceholder'
import { open as integrationOpen } from 'redux/modules/page/integrations/integrationForm'
import IntegrationSide from '../Integrations/IntegrationSide'
import bq from '../DataSources/bq.png'

const DDMANAGER_STREAMING_INTEGRATION = 'DDManager Streaming'

const DataTransformations = ({ integrations, locale, onIntegrationOpen }) => {
  const translate = getTranslate(locale, { ru })
  const title = (
    <Title>{translate('Data Transformations')}</Title>
  )
  const streamingIntegration = integrations[DDMANAGER_STREAMING_INTEGRATION]
  const editBQButton = (
    <ButtonContainer onClick={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }} >
      <Box>
        <Wrapper>
          <Status isEnabled />
        </Wrapper>
        <ButtonTitle>{translate('BigQuery enabled')}</ButtonTitle>
      </Box>
    </ButtonContainer>
  )

  return (
    <div>
      <IntegrationSide displayMode={'simple'} />
      {(!streamingIntegration || !streamingIntegration.isEnabled)
        ? (
          <OnboardingContainer>
            <OnboardingPlaceholder
              title={translate('To use data transformations, you need to configure the BigQuery integration and connect data sources.')}
              icon={bq}
              showArrow
              buttonTitle={translate('Enable BigQuery integration')}
              buttonAction={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }}
              linkTitle='Getting Started Tutorial ▸'
              linkUrl='https://docs.segmentstream.com/bigquery/overview'
            />
          </OnboardingContainer>
        ) : (
          <div>
            <SectionHeader leftContent={title} rightContent={editBQButton} />
            <DataTransformationsList />
            <DataTransformationSide />
          </div>
        )}
    </div>
  )
}

export default connect(
  state => ({
    integrations: state.integrations,
    locale: state.locale
  }),
  {
    onIntegrationOpen: integrationOpen
  }
)(DataTransformations)
