import React from 'react'
import ArrayBox from 'containers/Form/ArrayBox'

const Mapping = ({
  locale,
  description,
  label,
  onCreateRow,
  value,
  style,
  isValidRow,
  onChange,
  onTouch,
  onValidate,
  isValid,
  tip
}) => {
  const defaultRow = [undefined, undefined]

  const getRowsFromValue = () => {
    if (!value) {
      return [defaultRow]
    }
    return Object.keys(value).map(key => [key, value[key]])
  }

  const isInvalidRow = row => (row[0] || row[1]) && !(row[0] && row[1])

  const change = newRows => {
    const nextValue = newRows.reduce(
      (acc, row) => (isValidRow(row) ? { ...acc, [row[0]]: row[1] } : acc),
      {}
    )

    if (Object.keys(nextValue).length > 0) {
      onChange(nextValue)
    } else {
      onChange(undefined)
    }

    const invalidRow = newRows.find(row => isInvalidRow(row))
    const isNextStateValid = !invalidRow
    if ((!isValid && isNextStateValid) || (isValid && !isNextStateValid)) {
      onValidate(isNextStateValid)
    }
    onTouch()
  }

  return (
    <ArrayBox
      locale={locale}
      defaultRow={defaultRow}
      description={description}
      label={label}
      onChange={change}
      onCreateRow={onCreateRow}
      rows={getRowsFromValue()}
      style={style}
      tip={tip}
    />
  )
}

export default Mapping
