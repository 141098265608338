import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchVKAccounts, fetchVKClients } from 'api/dataSource'

const config = {
  type: 'VK',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/vk',
    google_analytics: 'https://docs.segmentstream.com/datasources/vk'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'VK Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with VK',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My VK',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Account',
      type: 'dynamic select',
      name: 'accountId',
      validation: ['required'],
      placeholder: 'Select Account',
      optionsFetcher: fetchVKAccounts,
      description: 'There were no accounts found or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/analytics/answer/1008015" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      extendedView: true,
      enable: ['clientId'],
      secondaryValue: 'accountType'
    },
    {
      label: 'Client',
      type: 'dynamic select',
      name: 'clientId',
      validation: ['required'],
      placeholder: 'Select Client',
      optionsFetcher: fetchVKClients,
      description: 'There were no clients found or access was not granted. Please, make sure you have access to the required clients or <a class="description" href="https://support.google.com/analytics/answer/1008015" target="_blank" rel="noopener noreferrer">add a new one</a> and click “Refresh”.',
      extendedView: true,
      dependsOn: ['accountId'],
      showIf: (state) => !state.accountType || state.accountType === 'agency'
    },
    {
      label: 'Default UTM source (optional)',
      type: 'string',
      name: 'defaultUTMSource',
      placeholder: 'vk',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium (optional)',
      type: 'string',
      name: 'defaultUTMMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Import ads statistics report',
      type: 'boolean',
      name: 'adsStatisticsReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Exclude VAT',
      type: 'boolean',
      name: 'excludeVAT',
      enable: ['vatRate'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      },
      tip: 'By default, VK cost data includes VAT.<br/>' +
        'To exclude VAT from imported costs switch on this<br/>' +
        'option and define the VAT rate to be excluded.<br/>' +
        'You can read more about why we recommend excluding VAT <a href="https://docs.segmentstream.com/datasources/#vat-handling" target="_blank" rel="noopener noreferrer">here</a>.'
    },
    {
      label: 'VAT rate',
      type: 'number',
      name: 'vatRate',
      units: '%',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      },
      validation: ['required']
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          enable: ['costMultiplier', 'excludeVAT', 'vatRate'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source (optional)',
          type: 'string',
          name: 'defaultUTMSource',
          placeholder: 'vk',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        },
        {
          label: 'Default UTM medium (optional)',
          type: 'string',
          name: 'defaultUTMMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal'],
          configurations: {
            google_analytics: {
              isVisible: false
            },
            rawReportsUnavailable: {
              isVisible: false
            }
          }
        },
        {
          label: 'Exclude VAT',
          type: 'boolean',
          name: 'excludeVAT',
          enable: ['vatRate'],
          tip: 'By default, VK cost data includes VAT.<br/>' +
            'To exclude VAT from imported costs switch on this<br/>' +
            'option and define the VAT rate to be excluded.<br/>'
        },
        {
          label: 'VAT rate',
          type: 'number',
          name: 'vatRate',
          units: '%',
          validation: ['required']
        }
      ]
    }
  ]
}

export default config
