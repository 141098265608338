import React, { Component } from 'react'
import getValidationError from 'containers/Form/getValidationError'
import Input from 'containers/Form/Input'
import FieldError from 'containers/Form/FieldError'
import FieldSuccess from 'containers/Form/FieldSuccess'
import Tooltip from 'containers/Tooltip'
import { Description, UnitsDisplay, Label, UnitContainer } from './styles'

class InputField extends Component {
  constructor (props) {
    super(props)
    this.state = { value: undefined }
  }

  componentDidMount () {
    this.loadValue()
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    const { context, dependsOn = [] } = this.props

    console.log({
      dependsOn,
      state: this.state
    })
    dependsOn.forEach(dependency => {
      const current = (this.state.dependentFieldValues || {})[dependency] || ''
      const next = (nextProps.context.dependentFieldValues || {})[dependency] || ''

      if (
        this.state.dependentFieldValues &&
        current.toString() !== next.toString()
      ) {
        this.setState({
          dependentFieldValues: {
            ...this.state.dependentFieldValues,
            [dependency]: (context.dependentFieldValues || {})[dependency]
          }
        })
        this.loadValue()
      }
    })
  }

  loadValue () {
    const {
      value,
      valueFetcher,
      context
    } = this.props
    if (valueFetcher) {
      this.setState({ loading: true })

      valueFetcher(context)
        .then(res => this.valueLoadStateHandler(res, context))
        .catch(() => this.valueLoadStateHandler(undefined, context))
    } else {
      this.valueLoadStateHandler(value, context)
    }
  }

  valueLoadStateHandler (res, context) {
    if (res) {
      this.setState({
        dependentFieldValues: context?.dependentFieldValues,
        loading: false,
        value: res
      })
    }
  }

  render () {
    const {
      locale,
      description,
      disabled,
      hasFocus,
      isNumber,
      label,
      name,
      onBlur,
      onChange,
      onFocus,
      onTouch,
      placeholder,
      units,
      style = { width: '100%' },
      touched,
      type = 'text',
      validation,
      maxLength,
      autocomplete,
      theme,
      tip,
      tipPlacement,
      fieldError,
      fieldSuccess
    } = this.props

    const { value: inputValue } = this.state

    const error =
      touched && !disabled && !hasFocus && validation
        ? getValidationError(validation, inputValue, locale)
        : undefined

    const themeStyle = {
      ...style,
      marginRight: theme === 'horizontal' ? '20px' : '0px'
    }

    const onChangeInternal = (newValue) => {
      this.setState({
        value: newValue
      })
      if (onChange) {
        onChange(newValue)
      }
    }

    return (
      <div style={themeStyle}>
        {
          label &&
          <Label>
            <label>{label}</label>
            {tip && <Tooltip tip={tip} place={tipPlacement || 'right'} />}
          </Label>
        }
        <div style={{ display: 'flex' }}>
          <Input
            isNumber={isNumber}
            style={{
              width: style.width,
              fontSize: style.fontSize,
              lineHeight: style.lineHeight
            }}
            type={type}
            name={name}
            value={inputValue || ''}
            placeholder={placeholder}
            disabled={disabled}
            onChange={onChangeInternal}
            onFocus={onFocus}
            onTouch={onTouch}
            onBlur={onBlur}
            hasFocus={hasFocus}
            touched={touched}
            validation={validation}
            maxLength={maxLength}
            autocomplete={autocomplete}
            fieldError={fieldError}
          />
          {
            units && isNumber &&
            <UnitContainer>
              <UnitsDisplay>{units}</UnitsDisplay>
            </UnitContainer>
          }
        </div>
        {(error || fieldError) && <FieldError width={style.width} message={error || fieldError} />}
        {fieldSuccess && <FieldSuccess width={style.width} message={fieldSuccess} />}
        {description && <Description>{description}</Description>}
      </div>
    )
  }
}

export default InputField
