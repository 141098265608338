import { queries } from '@segmentstream/cubejs-client'

export const adContentTable = (dateRange, filters) => {
  return {
    id: `tables_ad_content`,
    name: queries.legacy.attributionsTables.adContentTable.query.name,
    vizState: {
      query: queries.legacy.attributionsTables.adContentTable.query(dateRange, filters),
      size: 12,
      chartType: 'table'
    }
  }
}
