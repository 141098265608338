import React from 'react'
import { NORMALIZED_COSTS_DEPENDENCY } from 'dataTransformations'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import { Message, Link } from './styles'

export default ({ missingDependency, onClick, locale }) => {
  const translate = getTranslate(locale, { ru })
  if (missingDependency === NORMALIZED_COSTS_DEPENDENCY) {
    return (
      <Message>
        {translate('Costs imports are not configured or normalized. Configure a normalized costs report in the')} <Link onClick={onClick}>{translate('Data Sources section')}</Link>.
      </Message>
    )
  }
}
