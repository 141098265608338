import styled from 'styled-components'

export const StyledTextArea = styled.textarea`
  width: 100%;
  height: 125px;
  padding: 30px 0 20px 17px;
  border: 1px solid;
  border-radius: 8px;
  line-height: 20px;
  color: #1d2c4c;
  font-size: 16px;
  background-color: #fff;
  outline: none;
  cursor: text;

  &:disabled {
    background-color: #fcfcff;
    color: #777;
  }

  &:focus {
    border-color: #3bc569;
  }

  ::placeholder {
    color: #d4d4d4;
  }

  border-color: ${props => (!props.error ? '#e1e7ed' : '#ff706b')};
`
