import React, { Component } from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import { changePage, DATA_SOURCES } from 'redux/modules/page/changePage'
import InputField from 'containers/Form/InputField'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import { create as createProject } from 'redux/modules/project/create'
import ErrorMessage from 'containers/ErrorMessage'
import { getProjects as apiGetProjects } from 'api/project'
import { fetchProject } from 'redux/modules/project/fetchProject'
import { fetch as fetchAllProjects } from 'redux/modules/allProjects/fetch'
import { logout } from 'redux/modules/auth'
import { CONNECT_TRIAL, GOOGLE_ANALYTICS_PRICING_PLANS } from 'utils/pricingPlans'
import bq from './bq.png'
import ga from './ga.png'
import currencies from 'dataSources/Fixer/currencies'

import { setProjectVariable } from 'utils/digitalDataVariables'
import { createdProjectEvent } from 'utils/digitalDataEvents'

import {
  Step,
  CloseButton,
  BackButton,
  Heading,
  ProjectTypes,
  ProjectType,
  Icon,
  Title,
  Feature,
  Button,
  Loader
} from './styles'
import { PROJECT_TYPES } from 'redux/modules/project'
import { setCookie } from 'redux-cookie'
import SearchableSelect from 'containers/Form/SearchableSelect'
import { THEME_PROJECT_CREATION } from 'containers/Form/SearchableSelect/styles'
import { validForGoNodeJSAndBrowsersTimeZones } from 'timezones'

const DEMO_BOOKING_LINK = 'https://meetings.hubspot.com/segmentstream/discovery-call'

const SENDING = 'sending'
const FAILED = 'failed'
const SUCCESS = 'success'

class SignUp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      step: 1
    }

    this.handleChangeStep = this.handleChangeStep.bind(this)
    this.handleSelectType = this.handleSelectType.bind(this)
    this.onChangeProjectName = this.onChangeProjectName.bind(this)
    this.onChangeCurrency = this.onChangeCurrency.bind(this)
    this.onChangeTimezone = this.onChangeTimezone.bind(this)
    this.handleCreateProject = this.handleCreateProject.bind(this)
  }

  handleSelectType (type) {
    const updates = {
      type,
      timezone: type !== PROJECT_TYPES.BigQuery ? 'UTC' : undefined
    }
    this.setState(updates)
    this.handleChangeStep(2)
  }

  handleChangeStep (step) {
    this.setState({ step })
  }

  onChangeProjectName (projectName) {
    this.setState({ projectName })
  }

  onChangeCurrency (currency) {
    this.setState({ currency })
  }

  onChangeTimezone (timezone) {
    this.setState({ timezone })
  }

  async handleCreateProject (event) {
    const { projectName, type, currency, timezone } = this.state
    const { onCreateProject, onFetchProject, onFetchAllProjects, onGetProjects, onSetCookie, billingAccountId } = this.props
    event.preventDefault()

    try {
      this.setState({ status: SENDING })
      const result = await onCreateProject(projectName, type, billingAccountId, currency, timezone)
      if (result.projectId) {
        onSetCookie('project_id', result.projectId, { httpOnly: false })
        this.setState({ status: SUCCESS, loadingProject: true })

        const allProjects = await onGetProjects()
        onFetchAllProjects(allProjects, billingAccountId)

        onFetchProject(result.projectId)
        setProjectVariable({
          id: result.projectId,
          name: result.projectName,
          hasUnpublishedChanges: !result.isPublished,
          type: result.type
        })
        createdProjectEvent(result)
      } else {
        this.setState({ status: FAILED })
      }
    } catch (e) {
      console.log(e)
      this.setState({ status: FAILED })
    }
  }

  render () {
    const {
      step,
      projectName,
      timezone,
      status,
      loadingProject,
      type,
      currency
    } = this.state
    const { pricingPlan, onChangePage, onLogout, allProjects, billingAccounts = [] } = this.props
    if (loadingProject) {
      return (
        <Step visible style={{ flexDirection: 'column' }}>
          <Loader />
          <div>Creating project</div>
        </Step>
      )
    }
    return (
      <div>
        <Step visible={step === 1}>
          <CloseButton onClick={() => (billingAccounts.length === 1 && !allProjects.length) ? onLogout() : onChangePage(DATA_SOURCES)} />
          <div>
            <Heading>Please, select project type</Heading>
            <ProjectTypes>
              <ProjectType>
                <Icon src={bq} />
                <Title>BigQuery project</Title>
                <Feature>Automatically collect all your marketing data into Google BigQuery. Build insightful dashboards in Data Studio or other BI tools.</Feature>
                {
                  GOOGLE_ANALYTICS_PRICING_PLANS.includes(pricingPlan)
                    ? <a href={DEMO_BOOKING_LINK} target='_blank' rel='noopener noreferrer'>
                      <Button>Book a demo to get a free trial</Button>
                    </a>
                    : <Button onClick={() => this.handleSelectType(PROJECT_TYPES.BigQuery)}>
                      {pricingPlan === CONNECT_TRIAL ? 'Start 14-day free trial' : 'Create BigQuery Project'}
                    </Button>
                }
              </ProjectType>
              <ProjectType>
                <Icon src={ga} />
                <Title>Google Analytics project</Title>
                <Feature>Automatically import cost data for<br /> non-Google marketing campaigns<br /> into Google Analytics.</Feature>
                <Button onClick={() => this.handleSelectType(PROJECT_TYPES.GoogleAnalytics)}>
                  {pricingPlan === CONNECT_TRIAL ? 'Start 14-day free trial' : 'Create Google Analytics Project'}
                </Button>
              </ProjectType>
            </ProjectTypes>
          </div>
        </Step>
        <Step visible={step === 2}>
          <BackButton onClick={() => { this.handleChangeStep(1) }}>Back</BackButton>
          <form onSubmit={this.handleCreateProject} style={{ width: '450px', textAlign: 'center' }} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault() }}>
            <InputField
              value={projectName}
              onChange={this.onChangeProjectName}
              placeholder='Project name'
              label='How would you like to name your project?'
              name='projectName'
              validation={['required']}
            />
            {
              type === PROJECT_TYPES.BigQuery &&
                <>
                  <SearchableSelect
                    theme={THEME_PROJECT_CREATION}
                    onChange={this.onChangeCurrency}
                    onBlur={this.handleBlur}
                    name='currency'
                    options={currencies}
                    value={currency}
                    placeholder='Base currency'
                    label='Which currency would you like to use for reporting?'
                    validation={['required']}
                  />
                </>
            }
            {
              type === PROJECT_TYPES.BigQuery
                ? <>
                  <SearchableSelect
                    theme={THEME_PROJECT_CREATION}
                    value={timezone}
                    onChange={this.onChangeTimezone}
                    onBlur={this.handleBlur}
                    validation={['required']}
                    name='timezone'
                    options={validForGoNodeJSAndBrowsersTimeZones.map(value => ({ label: value, value }))}
                    placeholder='Timezone'
                    label='Which timezone should we use for reporting?'
                  />
                </> : null
            }
            <ConfirmButton
              disabled={!projectName || !timezone || (!currency && type === PROJECT_TYPES.BigQuery) || status === SENDING}
              style={{ fontSize: '24px', padding: '17px 47px', margin: '36px 0' }}
              type='submit'>
              {status !== SENDING ? 'Create Project' : 'Loading...'}
            </ConfirmButton>
            {
              status === FAILED &&
              <ErrorMessage>
                Something went wrong, please contact support at <a style={{ color: '#4D6DD3' }} href='mailto:support@segmentstream.com' rel='noopener noreferrer' target='_blank'>support@segmentstream.com</a>
              </ErrorMessage>
            }
          </form>
        </Step>
      </div>
    )
  }
}

export default connect(
  state => ({
    pricingPlan: _.get(state, 'user.billingAccount.pricingPlan'),
    billingAccountId: _.get(state, 'user.billingAccount.id'),
    projectType: state.project.type,
    userId: state.user.userId,
    allProjects: state.allProjects,
    billingAccounts: _.get(state, 'user.billingAccounts'),
    currency: state.project.currency
  }),
  {
    onChangePage: changePage,
    onCreateProject: createProject,
    onFetchProject: fetchProject,
    onFetchAllProjects: fetchAllProjects,
    onGetProjects: apiGetProjects,
    onSetCookie: setCookie,
    onLogout: logout
  }
)(SignUp)
