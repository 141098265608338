import logo from './logo.png'
import sideLogo from './logo-side.png'
import ru from './ru.json'
import { ADVERTISING } from 'integrations/categories'

export default {
  name: 'Criteo',
  color: '#ff9900',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/criteo',
  form: [
    {
      label: 'Criteo Account ID',
      type: 'string',
      name: 'account',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    },
    {
      label: 'User segment',
      type: 'string',
      name: 'userSegmentVar',
      placeholder: 'Example: user.criteoSegment'
    },
    {
      label: 'Use custom deduplication',
      type: 'boolean',
      name: 'customDeduplication',
      description: 'If true, will be used digitalData.context.source for deduplication'
    },
    {
      label: 'Website has multiple currencies',
      type: 'boolean',
      name: 'multiCurrency'
    }
  ]
}
