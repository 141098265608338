import React, { useEffect } from 'react'
import { connect } from 'react-redux'

import { EmailConfirmationFailPage } from '../Components/email-confirmation-fail-page'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { logout } from '../../../../redux/modules/auth'
import { changePage, CONFIRM_EMAIL_SUCCESS } from '../../../../redux/modules/page/changePage'

function EmailConfirmationFailPageContainer ({ user, logout, changePage }) {
  const [sendEmailConfirmationLetter, { loading, called, error }] = useMutation(gql`
    mutation SendEmailConfirmationLetter { sendEmailConfirmationLetter }
  `, {
    onError: ({ networkError }) => {
      if (networkError && networkError.response && networkError.response.status === 401) {
        logout()
      }
    }
  })

  useEffect(() => {
    if (user && user.emailConfirmed) {
      changePage(CONFIRM_EMAIL_SUCCESS)
    }
  })

  const handleResend = async () => {
    if (!user || user.length === 0) {
      await logout()
    }
    await sendEmailConfirmationLetter()
  }

  return (
    <EmailConfirmationFailPage
      user={user}
      onResend={handleResend}
      resendError={error}
      resendInProgress={loading}
      resendDisabled={called}
      resendSuccess={called && !error && !loading}
    />
  )
}

export default connect(
  state => ({
    user: state.user
  }),
  {
    logout: logout,
    changePage: changePage
  }
)(EmailConfirmationFailPageContainer)
