import React from 'react'

import OnboardingPlaceholder from '../../OnboardingPlaceholder'
import lamp from '../../DataSources/lamp.png'
import { OnboardingContainer } from './styles'

export const DataStreamsOnboarding = ({ onAddButtonClick }) => {
  return (
    <OnboardingContainer streamingEnabled>
      <OnboardingPlaceholder
        title='Add your first data stream<br> by clicking the "Add Data Stream" button below'
        icon={lamp}
        showArrow
        buttonTitle='Add Data Stream'
        buttonAction={onAddButtonClick}
        linkTitle='Read more about data streams'
        linkUrl={'https://docs.segmentstream.com/datastreams/index'}
      />
    </OnboardingContainer>
  )
}
