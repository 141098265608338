import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Soloway',
  color: '#894199',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Website ID',
      type: 'string',
      name: 'siteId',
      validation: ['required']
    },
    {
      label: 'DigitalData variable with user segment',
      type: 'string',
      name: 'userSegmentVar',
      placeholder: 'Example: user.solowaySegment'
    }
  ]
}

export default config
