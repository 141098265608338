import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Actionpay',
  color: '#ff7500',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/actionpay',
  form: [
    {
      label: 'Default goal ID',
      type: 'string',
      name: 'defaultGoalId',
      validation: ['required']
    },
    {
      label: 'Cookie, which stores partner ID',
      type: 'string',
      name: 'cookieName',
      defaultValue: 'actionpay',
      validation: ['required']
    },
    {
      label: 'Automatic cookie tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: ['cookieTtl', 'cookieDomain']
    },
    {
      label: 'Cookie time to live',
      type: 'number',
      name: 'cookieTtl',
      defaultValue: 90,
      validation: ['required']
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain',
      validation: ['required']
    },
    {
      label: 'Activate deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which required deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    },
    {
      label: 'utm_source from Actionpay',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'actionpay'
    },
    {
      label: 'Enable APRT',
      type: 'boolean',
      name: 'aprt',
      defaultValue: false,
      enable: ['aprtPartnerId']
    },
    {
      label: 'APRT Partner ID',
      type: 'string',
      name: 'aprtPartnerId'
    }
  ]
}

export default config
