import path from './path'

const TYPE = `fetch@${path}`

export const fetch = applicationSettings => ({
  type: TYPE,
  applicationSettings
})

export default (state = {}, action) => (action.type === TYPE ? action.applicationSettings : state)
