import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'AppMetrica',
  logo,
  locale: { ru },
  authType: 'OAuth2',
  authIcon,
  authName: 'Yandex Account',
  authForm: [
    {
      label: 'Click to authenticate with Yandex',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My AppMetrica',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Application ID',
      type: 'string',
      name: 'applicationId',
      validation: ['required']
    },
    {
      label: 'Import "Clicks" report',
      type: 'boolean',
      name: 'clicksReport'
    },
    {
      label: 'Import "App installations" report',
      type: 'boolean',
      name: 'installationsReport'
    },
    {
      label: 'Import "Postbacks" report',
      type: 'boolean',
      name: 'postbacksReport'
    },
    {
      label: 'Import "Events" report',
      type: 'boolean',
      name: 'eventsReport'
    },
    {
      label: 'Import "Profiles" report',
      type: 'boolean',
      name: 'profilesReport'
    },
    {
      label: 'Import "In-app purchases" report',
      type: 'boolean',
      name: 'revenueEventsReport'
    },
    {
      label: 'Import "Openings via deeplink" report',
      type: 'boolean',
      name: 'deeplinksReport'
    },
    {
      label: 'Import "Errors" report',
      type: 'boolean',
      name: 'errorsReport'
    },
    {
      label: 'Import "Crashes" report',
      type: 'boolean',
      name: 'crashesReport'
    },
    {
      label: 'Import "Push tokens" report',
      type: 'boolean',
      name: 'pushTokensReport'
    },
    {
      label: 'Import "Sessions starts" report',
      type: 'boolean',
      name: 'sessionsStartsReport'
    }
  ]
}

export default config
