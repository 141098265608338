import styled from 'styled-components'

import logo from './logo.png'
import ConfirmButton from '../../../Form/Button/ConfirmButton'

export const Container = styled.div`
  background: #F6F9FC;
  justify-content: center;
  height: 100vh;
  width: 100%;
  display: flex;
`

export const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

export const Logo = styled.div`
  width: 304px;
  height: 62px;
  min-height: 62px;
  background: url(${logo}) no-repeat;
  background-size: contain;
`

export const MessageContainer = styled.div`
  background: #FFFFFF;

  margin-top: 43px;
  
  width: 515px;
  height: 438px;
  
  min-height: 430px;
  
  padding: 50px 27px;
  
  display: flex;
  flex-direction: column;
  
  justify-content: space-evenly;
  align-items: center;
    
  border-radius: 5px;
`

export const HeaderText = styled.div`
  font-family: 'ProxmiaNovaBold';
  
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 29px;
  text-align: center;
  
  max-width: 400px;
    
  color: #0C1533;
`

export const Text = styled.div`  
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  
  max-width: 315px;
  
  color: #6E6E6E;
`

export const SecondaryText = styled(Text)`
  font-size: 12px;
  font-weight: lighter;
  min-height: 2em;
`

export const TextWider = styled(Text)`
  max-width: 400px;
`

export const BoldText = styled.b`
  color: #0C1533;
`

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Button = styled(ConfirmButton)`
  width: 229px;
  height: 60px;
`
