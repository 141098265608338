import path from './path'
import { saveDataTransformations as apiSaveDataTransformations } from 'api/project'
import { changedStatusEvent } from 'utils/digitalDataEvents'

const CHANGE_STATUS_DATA_TRANSFORMATIONS = `changeStatusDataTransformations@${path}`

export const changeStatusDataTransformations = (jobName, isEnabled) => async (dispatch, getState) => {
  const dataTransformations = JSON.parse(JSON.stringify(getState().project.dataTransformations || []))
  dataTransformations.forEach(dt => {
    if (dt.jobName === jobName) {
      if (dt.isDisabled === undefined) {
        dt.isDisabled = true
      } else {
        dt.isDisabled = isEnabled
      }
    }
  })

  const projectId = getState().project.id
  await dispatch(apiSaveDataTransformations(projectId, dataTransformations))
  dispatch({
    type: CHANGE_STATUS_DATA_TRANSFORMATIONS,
    dataTransformations
  })
  changedStatusEvent(jobName, path, !isEnabled)
}

export default (state = {}, action) =>
  action.type === CHANGE_STATUS_DATA_TRANSFORMATIONS
    ? {
      ...state,
      dataTransformations: action.dataTransformations
    }
    : state
