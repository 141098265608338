import styled from 'styled-components'

export const MessageText = styled.div`
  padding-top: 25px;
`

export const ButtonsContainer = styled.div`
  padding-top: 33px;
  display: flex;
  justify-content: space-between;
  width: 350px;
  margin: 0 auto;
`

export const modalStyle = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 12
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center'
  }
}
