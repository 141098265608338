import path from './path'
import { create as apiCreate, update as apiUpdate } from 'api/event'
import { enablePublish } from 'redux/modules/project'
import { savedEvent } from 'utils/digitalDataEvents'

const CREATE = `create@${path}`
const UPDATE = `update@${path}`

export const save = event => async (dispatch, getState) => {
  const projectId = getState().project.id
  const eventLabel = event.name
  if (event.id === undefined) {
    const createdEvent = await dispatch(apiCreate(projectId, event))
    dispatch({
      type: CREATE,
      event: createdEvent
    })
  } else {
    const updatedEvent = await dispatch(apiUpdate(projectId, event))
    dispatch({
      type: UPDATE,
      event: updatedEvent
    })
  }
  dispatch(enablePublish())
  savedEvent(eventLabel, path)
}

export default (state = [], action) => {
  if (action.type === CREATE) {
    return [...state, { ...action.event }]
  }
  if (action.type === UPDATE) {
    return state.map(item => (item.id === action.event.id ? action.event : item))
  }
  return state
}
