import path from './path'
import { saveOptions as apiSaveOptions } from 'api/project'
import { savedEvent } from 'utils/digitalDataEvents'

const SAVE_PROJECT_OPTIONS = `saveProjectOptions@${path}`

export const saveOptions = options => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiSaveOptions(projectId, options))
  dispatch({
    type: SAVE_PROJECT_OPTIONS,
    options
  })
  savedEvent(undefined, path, 'Project')
}

export default (state = {}, action) =>
  action.type === SAVE_PROJECT_OPTIONS
    ? {
      ...state,
      options: action.options,
      isPublished: false
    }
    : state
