import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import {
  closeModal,
  PENDING,
  SUCCESS,
  DATA_TRANSFORMATIONS
} from 'redux/modules/proMode/modalWindow'
import { saveDataTransformations } from 'redux/modules/project'
import AceEditor from 'containers/AceEditor'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import SuccessMessage from 'containers/SuccessMessage'
import Space from 'containers/Space'
import ru from './ru'
import getTranslate from 'utils/getTranslate'

class DataTransformations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: JSON.stringify(this.props.value, 0, 2),
      saved: true
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleTouch = this.handleTouch.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: JSON.stringify(nextProps.value, 0, 2)
      })
    }
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen === false) {
      this.setState({
        value: JSON.stringify(nextProps.value, 0, 2),
        saved: true
      })
    }
  }

  handleChange (value) {
    this.setState({ value })
  }

  handleTouch () {
    this.setState({ saved: false })
  }

  handleSave () {
    const value = (this.state.value && JSON.parse(this.state.value)) || []
    this.props.onSave(value)
    this.setState({ saved: true })
  }

  render () {
    const { saved, value } = this.state
    const { isOpen, onClose, status, locale } = this.props

    const translate = getTranslate(locale, { ru })

    return (
      <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Data transformations')}>
        {status === SUCCESS && saved && (
          <SuccessMessage>{translate('Data transformations was saved')}</SuccessMessage>
        )}
        <AceEditor
          mode='json'
          height='560px'
          style={{ marginBottom: '20px' }}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleTouch}
        />
        <Space />
        <ConfirmButton onClick={this.handleSave} pending={status === PENDING}>
          {translate('Save')}
        </ConfirmButton>
      </ModalWindow>
    )
  }
}

const mapStateToProps = state => {
  const { component, status } = state.proMode.modalWindow
  return {
    locale: state.locale,
    isOpen: component === DATA_TRANSFORMATIONS,
    value: state.project.dataTransformations || [],
    status
  }
}

const mapDispatchToProps = () => ({
  onClose: closeModal,
  onSave: saveDataTransformations
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(DataTransformations)
