import styled from 'styled-components'
import segmentStreamLogo from './segmentstream_logo.png'
import ParentConfirmButton from 'containers/Form/Button/ConfirmButton'
import arrow from './arrow.svg'

export const FormWrapper = styled.div`
  position: absolute;
  width: 422px;
  height: 400px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const SegmentStreamLogo = styled.div`
  margin: 0 auto;
  background: url(${segmentStreamLogo}) no-repeat;
  background-size: 304px 61px;
  width: 304px;
  height: 61px;
`

export const Form = styled.form`
  padding: 63px 16px 0px;
`

export const ResetPasswordForm = styled.form`
  padding: 23px 16px 0px;
`

export const Header = styled.h3`
  text-align: center;
`

export const Description = styled.div`
  text-align: center;
  margin-bottom: 20px;
`

export const InputLabel = styled.div`
  position: relative;
  top: 10px;
  left: 19px;
  height: 0px;
  width: 0px;
  font-size: 12px;
  color: #657789;
  text-align: left;
`

export const SimpleLink = styled.a`
  margin-top: 20px;
  cursor: pointer;
  display: block;
  text-align: center;
`

export const SimpleBlueLink = styled(SimpleLink)`
  color: #406DFB;
  text-decoration-line: underline;
  font-size: 16px;
  line-height: 19px;
`
export const SimpleLinkWithArrow = styled(SimpleLink)`
  margin-top: 0;
  text-decoration: underline;
  :after {
    content: '';
    display: inline-flex;
    height: 8px;
    width: 8px;
    margin-left: 4px;
    background: url(${arrow}) no-repeat;
  }
`

export const ConfirmButton = styled(ParentConfirmButton)`
  padding-top: 20px;
  padding-bottom: 20px;
  width: 100%;
  font-size: 16px;
  line-height: 20px;
`
