import { fetchLinkedInAdAccounts } from 'api/dataSource'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'LinkedIn',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/linkedin',
    google_analytics: 'https://docs.segmentstream.com/datasources/linkedin'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'LinkedIn Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with LinkedIn',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My LinkedIn',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Ad Accounts',
      type: 'dynamic select array',
      name: 'adAccountIds',
      validation: ['required'],
      placeholder: 'Select Ad Account',
      extendedView: true,
      uniqueValues: true,
      optionsFetcher: fetchLinkedInAdAccounts,
      description: 'No ad accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://www.linkedin.com/help/lms/answer/5749" target="_blank" rel="noopener noreferrer">create a new one</a> and click "Refresh".'
    },
    {
      label: 'Default UTM source (optional)',
      type: 'string',
      name: 'defaultUtmSource',
      placeholder: 'linkedin',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium (optional)',
      type: 'string',
      name: 'defaultUtmMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source (optional)',
          type: 'string',
          name: 'defaultUtmSource',
          placeholder: 'linkedin',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        },
        {
          label: 'Default UTM medium (optional)',
          type: 'string',
          name: 'defaultUtmMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website.'
        }
      ]
    }
  ]
}

export default config
