import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Fixer',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/fixer',
  authType: 'Basic',
  authIcon,
  authName: 'Fixer API token',
  isPublished: true,
  authForm: [
    {
      label: 'API token',
      type: 'string',
      name: 'apiToken',
      validation: ['required']
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Fixer',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Base currency',
      type: 'select',
      name: 'baseCurrency',
      options: ['EUR', 'USD', 'RUB', 'KZT', 'BYN', 'CHF', 'GBP', 'HUF'],
      defaultValue: 'EUR'
    }
  ]
}

export default config
