import { fetchTikTokAdvertisers } from 'api/dataSource'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'TikTok',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/tiktok',
    google_analytics: 'https://docs.segmentstream.com/datasources/tiktok'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'TikTok Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with TikTok',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My TikTok',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Advertisers',
      type: 'dynamic select array',
      name: 'advertiserIds',
      validation: ['required'],
      placeholder: 'Select Advertiser',
      optionsFetcher: fetchTikTokAdvertisers,
      description: 'No advertiser accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://ads.tiktok.com/help/article?aid=6681431650871541766" target="_blank" rel="noopener noreferrer">create a new one</a> and click "Refresh".',
      addButtonLabel: 'Add advertiser',
      extendedView: true,
      uniqueValues: true
    }
  ]
}

export default config
