import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import ReCaptcha from 'reaptcha'
import AuthField from 'containers/Form/AuthField'
import SearchableSelect from 'containers/Form/SearchableSelect'
import getValidationError from 'containers/Form/getValidationError'
import { THEME_FORM_FIELD } from 'containers/Form/SelectBox/styles'
import ErrorMessage from 'containers/ErrorMessage'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import countriesList from './countries'
import { login } from 'redux/modules/auth/login'
import {
  SegmentStreamLogo,
  ConfirmButton
} from '../Login/styles'
import {
  InputLabel,
  SelectBoxInputLabel,
  SelectBoxWrapper,
  SelectBoxHelpIcon,
  SelectBoxHelpWrapper,
  FormWrapper,
  SimpleLink,
  SignUpForm,
  HeaderWrapper,
  Header,
  StyledTooltip
} from './styles'

import { changePage, LOGIN, PROJECT_CREATION } from 'redux/modules/page/changePage'
import { signUp } from 'redux/modules/user/signUp'

import { setUserVariable, setBillingAccountVariable } from 'utils/digitalDataVariables'
import { registeredEvent } from 'utils/digitalDataEvents'
import { handleHubSpotFormSubmit } from 'utils/hubspot'

const SENDING = 'sending'
const FAILED = 'failed'
const SUCCESS = 'success'

class SignUp extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: null,
      touchedFields: {},
      data: {}
    }

    this.captcha = null

    this.handleBlur = this.handleBlur.bind(this)
    this.handleLoginPage = this.handleLoginPage.bind(this)
    this.createFieldFocusHandler = this.createFieldFocusHandler.bind(this)
    this.createFieldTouchHandler = this.createFieldTouchHandler.bind(this)
    this.createFieldChangeHandler = this.createFieldChangeHandler.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.sendForm = this.sendForm.bind(this)
  }

  componentDidMount () {
    this.fetchCountryCode()
  }

  fetchCountryCode () {
    const {
      countryCodeFetcher
    } = this.props

    countryCodeFetcher()
      .then(countryCode => {
        if (!countryCode || this.props.countryCode) {
          return
        }

        this.setState({
          data: {
            ...this.state.data,
            countryCode: countryCode
          }
        })
      })
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  handleLoginPage () {
    const { onChangePage } = this.props
    onChangePage(LOGIN)
  }

  createFieldFocusHandler (field) {
    return () => this.setState({
      focus: field,
      status: null
    })
  }

  createFieldTouchHandler (field) {
    return () => this.markFieldAsTouched(field)
  }

  createFieldChangeHandler (field) {
    return (value) => this.setState({
      data: {
        ...this.state.data,
        [field]: value
      }
    })
  }

  markFieldAsTouched (field) {
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [field]: true
      }
    })
  }

  async handleSubmit (event) {
    const {
      name,
      lastName,
      email,
      password
    } = this.state.data

    event.preventDefault()

    if (!name || getValidationError(['email'], email)) {
      this.markFieldAsTouched('name')
      return
    }

    if (!lastName || getValidationError(['email'], email)) {
      this.markFieldAsTouched('lastName')
      return
    }

    if (!email) {
      this.markFieldAsTouched('email')
      return
    }

    if (!password) {
      this.markFieldAsTouched('password')
      return
    }

    await this.captcha.execute()
  }

  async sendForm () {
    const { onSubmitForm, onLogin, onChangePage } = this.props
    const {
      data: {
        email,
        password
      }
    } = this.state

    try {
      this.setState({ status: SENDING })
      const result = await onSubmitForm(this.state.data)

      if (result.success === true) {
        setUserVariable(result.data.user)
        if (result.data.billingAccount) {
          setBillingAccountVariable(result.data.billingAccount)
        }
        registeredEvent(result.data.user, result.data.billingAccount)
        handleHubSpotFormSubmit(result.data.user, result.data.billingAccount)
        this.setState({ status: SUCCESS })
        onLogin(email, password)
        onChangePage(PROJECT_CREATION)
      } else {
        this.setState({ status: FAILED, errors: result.errors || null, errorMessage: result.errorMessage })
        await this.captcha.reset()
      }
    } catch (e) {
      console.log(e)
      this.setState({ status: FAILED })
      await this.captcha.reset()
    }
  }

  render () {
    const {
      focus,
      status,
      touchedFields,
      data: {
        email,
        name,
        lastName,
        company,
        countryCode,
        password
      }
    } = this.state

    const { locale, recaptchaSiteKey } = this.props

    const translate = getTranslate(locale, { ru })

    return (
      <div>
        <ReCaptcha
          ref={e => (this.captcha = e)}
          badge={'bottomleft'}
          sitekey={recaptchaSiteKey}
          onVerify={async token => {
            this.createFieldChangeHandler('captchaToken')(token)
            await this.sendForm()
          }}
          size='invisible' />
        <FormWrapper>
          <SegmentStreamLogo />

          {status !== SUCCESS && (
            <HeaderWrapper>
              <Header>
                <span>{translate('Already have a SegmentStream account?')}&nbsp;</span>
                <SimpleLink onClick={this.handleLoginPage}>
                  {translate('Sign in')}
                </SimpleLink>
              </Header>
            </HeaderWrapper>
          )}

          <SignUpForm onSubmit={this.handleSubmit}>
            {status === FAILED && (
              <div style={{ textAlign: 'center' }}>
                {this.state.errorMessage && (<ErrorMessage>{translate(this.state.errorMessage)}</ErrorMessage>)}
                {Array.isArray(this.state.errors) && this.state.errors.map((v, i) => {
                  return <ErrorMessage key={i}>{translate(v.message)}</ErrorMessage>
                })}
              </div>
            )}

            <div>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputLabel>First&nbsp;Name</InputLabel>
                <AuthField
                  value={name}
                  onChange={this.createFieldChangeHandler('name')}
                  onFocus={this.createFieldFocusHandler('name')}
                  onTouch={this.createFieldTouchHandler('name')}
                  onBlur={this.handleBlur}
                  hasFocus={focus === 'name'}
                  touched={touchedFields.name}
                  validation={['required']}
                  name='name'
                  placeholder='First Name'
                  autocomplete='name'
                  style={{ marginRight: '17px' }}
                />

                <InputLabel>Last&nbsp;Name</InputLabel>
                <AuthField
                  value={lastName}
                  onChange={this.createFieldChangeHandler('lastName')}
                  onFocus={this.createFieldFocusHandler('lastName')}
                  onTouch={this.createFieldTouchHandler('lastName')}
                  onBlur={this.handleBlur}
                  hasFocus={focus === 'lastName'}
                  touched={touchedFields.lastName}
                  validation={['required']}
                  name='lastName'
                  placeholder='Last Name'
                  autocomplete='lastName'
                />
              </div>

              <InputLabel>Company</InputLabel>
              <AuthField
                value={company}
                onChange={this.createFieldChangeHandler('company')}
                onFocus={this.createFieldFocusHandler('company')}
                onTouch={this.createFieldTouchHandler('company')}
                onBlur={this.handleBlur}
                hasFocus={focus === 'company'}
                touched={touchedFields.company}
                validation={['required']}
                name='company'
                placeholder='Company'
                autocomplete='company'
              />

              <InputLabel>Work&nbsp;email</InputLabel>
              <AuthField
                value={email}
                onChange={this.createFieldChangeHandler('email')}
                onFocus={this.createFieldFocusHandler('email')}
                onTouch={this.createFieldTouchHandler('email')}
                onBlur={this.handleBlur}
                hasFocus={focus === 'email'}
                touched={touchedFields.email}
                validation={['required', 'email']}
                name='email'
                placeholder='Work email'
                autocomplete='new-password'
              />

              <SelectBoxInputLabel>Billing&nbsp;country</SelectBoxInputLabel>
              <SelectBoxWrapper>
                <SearchableSelect
                  value={countryCode}
                  onChange={this.createFieldChangeHandler('countryCode')}
                  onFocus={this.createFieldFocusHandler('countryCode')}
                  onTouch={this.createFieldTouchHandler('countryCode')}
                  onBlur={this.handleBlur}
                  hasFocus={focus === 'countryCode'}
                  touched={touchedFields.countryCode}
                  validation={['required']}
                  name='countryCode'
                  options={countriesList.map(v => ({ label: v.name, value: v.alpha2 }))}
                  theme={THEME_FORM_FIELD}
                  placeholder={'Billing country'}
                />
                <SelectBoxHelpWrapper>
                  <SelectBoxHelpIcon
                    data-tip={translate('Important! Please, define a billing country. It is required to define billing currency, calculate sales tax and send proper invoices for your accounting once you decide to buy a subscription after the free trial.')} />
                  <StyledTooltip place='right' effect='solid' />
                </SelectBoxHelpWrapper>
              </SelectBoxWrapper>

              <InputLabel>{translate('Password')}</InputLabel>
              <AuthField
                value={password}
                onChange={this.createFieldChangeHandler('password')}
                onFocus={this.createFieldFocusHandler('password')}
                onTouch={this.createFieldTouchHandler('password')}
                onBlur={this.handleBlur}
                hasFocus={focus === 'password'}
                touched={touchedFields.password}
                validation={['required']}
                type='password'
                name='password'
                placeholder={translate('Password')}
                autocomplete='new-password'
              />

              <ConfirmButton type='submit' pending={status === SENDING}>
                {translate('Sign Up')}
              </ConfirmButton>

              <div style={{ textAlign: 'center', marginTop: '15px' }}>
                {translate('By clicking "Sign Up" you agree to the')}&nbsp;
                <SimpleLink target='_blank' rel='noopener noreferrer' href='https://segmentstream.com/terms/'>{translate('Terms')}</SimpleLink>
                &nbsp;{translate('and')}&nbsp;
                <SimpleLink target='_blank' rel='noopener noreferrer' href='https://segmentstream.com/privacy/'>{translate('Privacy Policy')}</SimpleLink>.
              </div>

            </div>
          </SignUpForm>
        </FormWrapper>
      </div>
    )
  }
}

export default connect(
  state => ({
    locale: state.locale,
    recaptchaSiteKey: _.get(state, 'applicationSettings.google.recaptchaSiteKey')
  }),
  {
    onSubmitForm: signUp,
    onChangePage: changePage,
    onLogin: login
  }
)(SignUp)
