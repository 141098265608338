export const auth = (email, password) => ({
  url: '/oauth/token',
  method: 'POST',
  headers: {
    Authorization: 'Basic ZGRtOnNlY3JldA==',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    grant_type: 'password',
    username: email,
    password
  })
})

export const getNewTokens = refreshToken => ({
  url: '/oauth/token',
  method: 'POST',
  headers: {
    Authorization: 'Basic ZGRtOnNlY3JldA==',
    'Content-Type': 'application/json'
  },
  body: JSON.stringify({
    grant_type: 'refresh_token',
    refresh_token: refreshToken
  })
})
