import styled from 'styled-components'

export const Iframe = styled.iframe`
  width: ${props => props.width}px;
  position: absolute;
  left: ${props => props.left}px;
  height: ${props => props.height}px;
  margin-top: -36px;
  border: 0;
`
