import path from './path'
import { enablePublish } from 'redux/modules/project'

const SAVE_EVENT_ENRICHMENTS = `saveEventEnrichments@${path}`

export const saveEventEnrichments = (integrationName, eventEnrichments) => dispatch => {
  dispatch({
    type: SAVE_EVENT_ENRICHMENTS,
    integrationName,
    eventEnrichments
  })
  dispatch(enablePublish())
}

export default (state = {}, action) =>
  action.type === SAVE_EVENT_ENRICHMENTS
    ? {
      ...state,
      [action.integrationName]: {
        ...state[action.integrationName],
        eventEnrichments: action.eventEnrichments
      }
    }
    : state
