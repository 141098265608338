import React from 'react'
import ArrayBox from 'containers/Form/ArrayBox'
import Group from 'containers/Form/Group'

const GroupArray = ({
  locale,
  description,
  label,
  style,
  value,
  items,
  addButtonLabel,
  onTouch,
  onFocus,
  onChange,
  onValidate,
  context,
  dependsOn,
  tip,
  touched
}) => {
  const defaultRowValue = undefined

  const getRowsFromValue = () => {
    if (!value || value.length === 0) {
      return [defaultRowValue]
    }
    return value
  }

  const change = newRows => {
    onChange(newRows)
    onTouch()
  }

  const createRow = ({ row, index, onChangeRow }) => (
    <Group
      items={items}
      value={row}
      onFocus={onFocus}
      onTouch={onTouch}
      onChange={val => onChangeRow(index, val)}
      onValidate={onValidate}
      locale={locale}
      context={context}
      dependsOn={dependsOn}
      touched={touched}
    />
  )

  return (
    <ArrayBox
      locale={locale}
      defaultRow={defaultRowValue}
      description={description}
      label={label}
      onChange={change}
      onCreateRow={createRow}
      rows={getRowsFromValue()}
      style={style}
      addButtonLabel={addButtonLabel}
      nestedStyle
      tip={tip}
    />
  )
}

export default GroupArray
