import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import { allDataSources, sandboxDataSources } from 'dataSources'
import Form from 'containers/Form'
import { close } from 'redux/modules/page/dataSources/dataSourcesForm/close'
import { changeStatus } from 'redux/modules/dataSources/changeStatus'
import getTranslate from 'utils/getTranslate'
import { save } from 'redux/modules/page/dataSources'
import { saveAuth, removeAuth, setAuthStatus, remove, setAuthCheckError } from 'redux/modules/dataSources'
import { fetchProject } from 'redux/modules/project'
import ru from './ru.json'
import AuthenticationStatusBox from '../AuthenticationStatusBox/index'
import { getFreshAccessToken } from '../../../../../api/apiCall'
import _ from 'lodash'
import merge from 'deepmerge'
import { checkAuthOptions } from 'api/dataSource'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

class DataSourcesForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.handleChangeStatus = this.handleChangeStatus.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleAuthentication = this.handleAuthentication.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.handleAuthorizationCheck = this.handleAuthorizationCheck.bind(this)
  }

  async handleAuthorizationCheck () {
    const {
      onAuthCheckError,
      project,
      initialData: { id },
      dataSource: { authError }
    } = this.props

    if (authError) {
      this.setState({
        loading: true
      })

      const authOptions = await checkAuthOptions({
        projectId: project.id,
        id
      })

      if (authOptions && authOptions.error && authOptions.error.code === 'UNAUTHORIZED') {
        await onAuthCheckError(id, authError)
      }

      this.setState({
        loading: false
      })
    }
  }

  handleChangeStatus () {
    const {
      initialData,
      onChangeStatus,
      dataSource: { type }
    } = this.props
    if (initialData) {
      onChangeStatus(initialData.id, !initialData.isEnabled, type, initialData.options)
    }
  }

  async handleSave (options) {
    const {
      onSave,
      dataSource: { type },
      initialData
    } = this.props
    const firstSave = !!initialData.options
    onSave(options, type, firstSave)
  }

  handleAuthentication (authOptions) {
    const {
      onUpdateAuth,
      onRemoveAuth,
      initialData: { id, options },
      dataSource: { type }
    } = this.props
    if (authOptions) {
      onUpdateAuth(id, authOptions)
    } else {
      onRemoveAuth(id, type, options)
    }
  }

  handleRemove () {
    const {
      onRemove,
      initialData: { id },
      dataSource: { type }
    } = this.props
    onRemove(id, type)
  }

  render () {
    const {
      dataSource,
      isDataSourceAuthenticated,
      locale,
      project,
      initialData,
      isOpen,
      onClose,
      projectName,
      page,
      userRole,
      onFetchProject
    } = this.props
    const { loading } = this.state
    if (!dataSource) {
      return null
    }

    const form = !isDataSourceAuthenticated ? dataSource.authForm : dataSource.form

    const handleFormDataChange = (data) => {
      if (data && data.isAuthenticated === true) {
        onFetchProject(project.id, page, userRole)
      }
      context.formData = { ...initialData.options, ...data }
    }

    const rawReportsUnavailable = !project.features.includes(ProjectFeature.RawReports)

    const defaultValues = {}

    const findDefaultValue = (item) => _.get(item, `configurations.${project.type}.defaultValue`) || (rawReportsUnavailable ? _.get(item, `configurations.rawReportsUnavailable.defaultValue`) : undefined)

    form.forEach((formItem) => {
      const defaultValue = findDefaultValue(formItem)

      if (defaultValue !== undefined) {
        defaultValues[formItem.name] = defaultValue
      }
      if (formItem.type === 'group') {
        formItem.items.forEach(item => {
          const defaultValue = findDefaultValue(item)
          if (defaultValue !== undefined) {
            if (formItem.flattenOptions) {
              defaultValues[item.name] = defaultValue
            } else {
              defaultValues[formItem.name] = defaultValues[formItem.name] || {}
              defaultValues[formItem.name][item.name] = defaultValue
            }
          }
        })
      }
    })

    const initData = !isDataSourceAuthenticated ? {} : merge(initialData.options, defaultValues)

    const checkIfVisible = (formItem) => {
      const visibleBeforeConfig = _.get(formItem, `configurations.${project.type}.visibleBefore`)
      const isVisibleBefore = visibleBeforeConfig ? new Date(visibleBeforeConfig) > new Date(project.createdAt) : true

      return (rawReportsUnavailable ? _.get(formItem, `configurations.rawReportsUnavailable.isVisible`, true) : _.get(formItem, `configurations.${project.type}.isVisible`, true)) && isVisibleBefore
    }

    const filteredForm = _.cloneDeepWith(form).filter((formItem) => {
      const isVisible = checkIfVisible(formItem)
      if (isVisible && formItem.type === 'group') {
        formItem.items = formItem.items.filter(item => checkIfVisible(item))
      }
      return isVisible
    })

    const enabledStatusBox = initialData ? initialData.isEnabled : false
    const translate = getTranslate(locale, { ru })
    const messageTemplate =
      'Are you sure that you want to <b>{{ACTION}}</b> the {{SOURCE_NAME}} source in the <b>{{PROJECT_NAME}}</b> project?'
    const disableMessage = translate(messageTemplate, {
      SOURCE_NAME: dataSource ? dataSource.type : '',
      PROJECT_NAME: projectName,
      ACTION: translate('DISABLE')
    })
    const deleteMessage = translate(messageTemplate, {
      SOURCE_NAME: dataSource ? dataSource.type : '',
      PROJECT_NAME: projectName,
      ACTION: translate('DELETE')
    })

    const link = typeof dataSource.docsUrl === 'object' ? dataSource.docsUrl[project.type] : dataSource.docsUrl
    const docsLink = link && (
      <DocsLink url={link} />
    )

    const statusBox = isDataSourceAuthenticated && initialData.options && !dataSource.hideEnabledButtom && (
      <StatusBox
        locale={locale}
        isEnabled={enabledStatusBox}
        onChange={this.handleChangeStatus}
        disableMessage={disableMessage}
      />
    )

    const authenticationStatusBox = isDataSourceAuthenticated && (
      <AuthenticationStatusBox
        locale={locale}
        authIcon={dataSource.authIcon}
        authName={dataSource.authName}
        onChange={this.handleAuthentication}
        authMeta={initialData.authMeta}
      />
    )

    const handleAuthentication =
      dataSource.authType !== 'OAuth2' ? this.handleAuthentication : undefined

    const context = {
      authorizeEndpoint: `/projects/${project.id}/data_sources/${initialData.id}/authorize`,
      getFreshAccessToken,
      id: initialData.id,
      formData: initialData.options || {},
      projectId: project.id,
      authenticationFailMessage: initialData.authenticationFailMessage
    }

    return (
      <ModalWindow
        isOpen={isOpen}
        onClose={onClose}
        header={dataSource.displayName || dataSource.type}
        middle={authenticationStatusBox}
        docsLink={docsLink}
        statusBox={statusBox}
      >
        <Form
          context={context}
          locale={locale}
          form={filteredForm}
          data={initData}
          onSave={isDataSourceAuthenticated ? this.handleSave : handleAuthentication}
          onChange={handleFormDataChange}
          onRemove={this.handleRemove}
          deleteMessage={deleteMessage}
          handleAuthorizationCheck={isDataSourceAuthenticated ? this.handleAuthorizationCheck : null}
          loading={loading}
        />
      </ModalWindow>
    )
  }
}

const mapStateToProps = state => {
  const { isOpen, id } = state.page.dataSources.dataSourcesForm
  const initialData = state.dataSources[id] || undefined
  const dataSource = initialData
    ? [...allDataSources, ...sandboxDataSources].filter(ds => initialData.type === ds.type)[0]
    : undefined
  const isDataSourceAuthenticated = !!((initialData || {}).isAuthenticated === true)
  return {
    isOpen,
    dataSource,
    initialData,
    locale: state.locale,
    projectName: state.project.name,
    isDataSourceAuthenticated,
    project: state.project,
    page: state.page.name,
    userRole: state.user.role
  }
}

const mapDispatchToProps = () => ({
  onClose: close,
  onSave: save,
  onUpdateAuth: saveAuth,
  onSetAuthStatus: setAuthStatus,
  onFetchProject: fetchProject,
  onRemoveAuth: removeAuth,
  onChangeStatus: changeStatus,
  onRemove: remove,
  onAuthCheckError: setAuthCheckError
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(DataSourcesForm)
