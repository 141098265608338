import path from './path'
import { saveInfo as apiSaveInfo } from 'api/project'
import { savedEvent } from 'utils/digitalDataEvents'

const SAVE_PROJECT_INFO = `saveProjectInfo@${path}`

export const saveInfo = info => async (dispatch, getState) => {
  const state = getState()
  const projectId = state.project.id

  // update timezone only if superadmin
  if (state.user.role !== 'super_admin') {
    delete info.timezone
  }

  await dispatch(apiSaveInfo(projectId, info))

  // rename projectName to name to match state signature
  info.name = info.projectName
  delete info.projectName
  dispatch({ type: SAVE_PROJECT_INFO, info })

  savedEvent(undefined, path, 'Project')
}

export default (state = {}, action) => action.type === SAVE_PROJECT_INFO
  ? {
    ...state,
    ...action.info
  }
  : state
