import styled, { css } from 'styled-components'

export const THEME_FORM_ITEM = 'form_item'
export const THEME_FORM_ARRAY_ITEM = 'form_array_item'
export const THEME_FILTER = 'filter'
export const THEME_FORM_FIELD = 'form_field'
export const THEME_BILLING_FIELD = 'form_billing_field'

export const Wrapper = styled.div`
  position: relative;
  cursor: default;
  outline: none;
  width: 100%;
  opacity: ${props => props.disabled ? '0.5' : '1'};
`

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 20px 20px 0 23px;
  line-height: 20px;
  border: 1px solid #e1e7ed;
  border-radius: 8px;
  background-color: #fff;
  color: #1d2c4c;
  ${props =>
    props.isOpen &&
    css`
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    `}
  ${props =>
    props.theme === THEME_FORM_ARRAY_ITEM &&
    css`
      height: 51px;
      padding-top: 16px;
    `}
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      height: 40px;
      padding: 10px 17px 0 17px;
    `}
    ${props =>
    props.theme === THEME_FORM_FIELD &&
    css`
      padding: ${props.isOpen ? '18px' : '19px'} 0px 0px 17px;
      align-items: center;
      justify-content: center;
    `}
  ${props =>
    props.extendedView &&
    css`
        padding: ${props => props.isOpen ? '12px 12px 13px 23px' : '12px 12px 12px 23px'}; 
      `}        
`

export const SelectedValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: '#1d2c4c';
  ${props =>
    props.theme === THEME_FORM_FIELD &&
    css`
      color: rgb(71, 86, 102);
    `}
  ${props =>
    props.extendedView &&
    css`
        display: flex;
        align-items: center;
      `}    
`

export const Select = styled.div`
  position: absolute;
  margin-top: -1px;
  border: solid 1px #e3e4e6;
  border-top: none;
  width: 100%;
  overflow-y: scroll;
  max-height: 360px;
  background-color: #fff;
  z-index: 10;
  border-radius: 0 0 8px 8px;
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      max-height: 500px;
    `}
`

export const Option = styled.div`
  padding: 12px 23px;
  min-height: 34px;
  line-height: 18px;
  white-space: nowrap;
  &:hover {
    background-color: #fafbfe;
  }
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      padding: 8px 17px;
    `}
   ${props =>
    props.theme === THEME_FORM_FIELD &&
    css`
      padding: 8px 17px;
    `}
`

export const Triangle = styled.div`
  margin-left: auto;
  margin-top: ${props => props.extendedView ? '10' : '4'}px;
  width: 4px;
  height: 4px;
  border: solid #cad3dc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      margintop: 5px;
      padding: 3px;
    `}
  ${props =>
    props.theme === THEME_FORM_FIELD &&
    css`
      margin-top: -19px;
      margin-right: 17px;
    `}
`

export const Label = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const ExtendedViewValue = styled.div`
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;    
`

export const Placeholder = styled.div`
  color: #808080;
`

export const OptionLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ValueWrapper = styled.div`
  width: 100%;
`
