export const create = (projectId, script) => ({
  url: `/projects/${projectId}/scripts/`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(script)
})

export const update = (projectId, script) => ({
  url: `/projects/${projectId}/scripts/${script.id}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(script, (k, v) => v === undefined ? null : v)
})

export const remove = (projectId, scriptId) => ({
  url: `/projects/${projectId}/scripts/${scriptId}`,
  method: 'DELETE'
})

export const enable = (projectId, scriptId) => ({
  url: `/projects/${projectId}/scripts/${scriptId}/enable`,
  method: 'PUT'
})

export const disable = (projectId, scriptId) => ({
  url: `/projects/${projectId}/scripts/${scriptId}/disable`,
  method: 'PUT'
})
