import { fetchSnapchatOrganizations, fetchSnapchatAdAccounts } from 'api/dataSource'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Snapchat',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datasources/snapchat',
  authType: 'OAuth2',
  authIcon,
  authName: 'Snapchat Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Snapchat',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Snapchat',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Organization',
      type: 'dynamic select',
      name: 'organizationId',
      validation: ['required'],
      placeholder: 'Select Organization',
      optionsFetcher: fetchSnapchatOrganizations,
      description: 'No organizations were found for this user or access was not granted. Please, make sure you have access to the required organizations or create a new one and click "Refresh".'
    },
    {
      label: 'Account',
      type: 'dynamic select',
      name: 'adAccountId',
      validation: ['required'],
      placeholder: 'Select Account',
      dependsOn: ['organizationId'],
      optionsFetcher: fetchSnapchatAdAccounts,
      description: 'No accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or create a new one and click "Refresh".'
    },
    {
      label: 'UTM source',
      type: 'string',
      name: 'defaultUtmSource',
      placeholder: 'snapchat',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      validation: ['required'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'UTM medium',
      type: 'string',
      name: 'defaultUtmMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website.',
      validation: ['required'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          enable: ['defaultUtmSource', 'defaultUtmMedium'],
          defaultValue: true,
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'UTM source',
          type: 'string',
          name: 'defaultUtmSource',
          placeholder: 'snapchat',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website.',
          validation: ['required']
        },
        {
          label: 'UTM medium',
          type: 'string',
          name: 'defaultUtmMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website.',
          validation: ['required']
        }
      ]
    }
  ]
}

export default config
