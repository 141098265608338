import React from 'react'
import { connect } from 'react-redux'
import { IntegrationSearchBox } from './styles'
import { changeNameFilter } from 'redux/modules/page/integrations'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

const IntegrationSearch = ({ filters, locale, dispatch }) => {
  const translate = getTranslate(locale, { ru })

  return (
    <div>
      <IntegrationSearchBox
        placeholder={translate('Search')}
        type='text'
        value={filters.name || ''}
        onChange={e => {
          dispatch(changeNameFilter(e.target.value))
        }}
      />
    </div>
  )
}

const mapStateToProps = state => {
  return {
    filters: state.page.integrations.filter,
    locale: state.locale
  }
}

export default connect(mapStateToProps)(IntegrationSearch)
