import React, { useCallback, useEffect } from 'react'
import { connect } from 'react-redux'
import BlueHeader from './BlueHeader'
import ProMode from './ProMode'
import SideMenu from './SideMenu'
import Body from './Body'
import { loadZendeskWidget } from 'utils/loadZendeskWidget'
import { PROJECT_TYPES } from 'redux/modules/project'
import { isProMode } from 'utils/proMode'
import { OnboardingContainer } from './Body/DataSources/styles'
import OnboardingPlaceholder from './Body/OnboardingPlaceholder'
import { changePage, PROJECT_CREATION } from 'redux/modules/page/changePage'

import { ContentStyled } from './styled'

const Dashboard = (props) => {
  const {
    pricingPlan,
    user,
    accountName,
    projectType,
    projectName,
    isProMode,
    advancedLevelOfSecurity,
    allProjects,
    onChangePage
  } = props

  useEffect(() => {
    if (pricingPlan) {
      loadZendeskWidget(
        pricingPlan,
        user,
        accountName,
        projectType,
        projectName
      )
    }
  }, [pricingPlan, user, accountName, projectType, projectName])

  const renderContent = useCallback(() => {
    if (!allProjects.length) {
      return (
        <OnboardingContainer>
          <OnboardingPlaceholder
            title='There are no projects in this account yet'
            body='Please, create your first project by clicking on the button below'
            showArrow
            buttonTitle='Create Project'
            buttonAction={() => onChangePage(PROJECT_CREATION)}
          />
        </OnboardingContainer>
      )
    }

    return (
      <>
        {isProMode && !advancedLevelOfSecurity && <ProMode />}

        <ContentStyled>
          {projectType !== PROJECT_TYPES.GoogleAnalytics && <SideMenu />}

          <Body />
        </ContentStyled>
      </>
    )
  }, [allProjects, isProMode, advancedLevelOfSecurity, projectType, onChangePage])

  return (
    <>
      <BlueHeader />

      {renderContent()}
    </>
  )
}

export default connect(
  (state) => ({
    isProMode: isProMode(state.proMode.status, state.user.role),
    advancedLevelOfSecurity: state.project.advancedLevelOfSecurity,
    pricingPlan: (state.project.billingAccount || {}).pricingPlan,
    projectType: state.project.type,
    projectName: state.project.name,
    user: state.user,
    accountName: (state.project.billingAccount || {}).accountName,
    allProjects: state.allProjects,
    page: state.page.name
  }),
  {
    onChangePage: changePage
  }
)(Dashboard)
