import styled from 'styled-components'
import closeIcon from './close.png'

export const modalContentStyle = {
  width: '677px',
  padding: '70px 110px',
  position: 'absolute',
  left: '50%',
  top: '50%',
  transform: 'translate(-50%, -50%)',
  border: 'none',
  borderRadius: '5px',
  textAlign: 'center',
  color: '#000',
  bottom: 'auto'
}

export const modalOverlayStyle = {
  backgroundColor: 'rgba(0,0,0,0.7)',
  zIndex: 999
}

export const CloseButton = styled.div`
  position: fixed;
  right: 6px;
  top: 6px;
  width: 34px;
  height: 34px; 
  background: url(${closeIcon}) no-repeat center center;
  background-size: 34px 34px;
  cursor: pointer;
  outline: none;
`
