import { gql } from 'apollo-boost'

export const BILLING_QUERY = gql`
    query GetBillingAccount($id: Int) {
        billingAccount(id: $id) {
            id
            userId
            legalName
            pricingPlan
            
            trialStatus {
                expiresAt
                activatedAt
            }
            
            subscriptionStatus {
                activatedAt
                expiresAt
                price {
                    billingPeriod
                    price
                    totalPrice
                    currency
                    pricingPlan {
                        id
                        name
                        group
                        monthlySpendLimit
                    }
                }
            }
            
            billingInfo {
                legalEntity
                companyName
                taxId
                companyEmail
                firstName
                lastName
                personalEmail
                countryCode
                addressLine1
                addressLine2
                city
                postalCode
                phone
            }

            availablePricingPlans {
                id
                name
                group
                monthlySpendLimit
                prices {
                    billingPeriod
                    currency
                    price
                }
            }
        }
    }
`

export const CREATE_CHECKOUT_SESSION_MUTATION = gql`
    mutation CreateCheckoutSession(
        $billingInfo: BillingInfoInput!, 
        $id: String!,
        $billingAccountId: Int!,
        $pricingPlan: String!,
        $billingPeriod: String!,
    ) {
        createCheckoutSession(
            id: $id,
            billingAccountId: $billingAccountId,
            billingInfo: $billingInfo,
            pricingPlan: $pricingPlan,
            billingPeriod: $billingPeriod,
        ) {
            stripeId
            id
        }
    }
`

export const CREATE_BILLING_PORTAL_LINK = gql`
    mutation CreateBillingPortalLink($billingAccountId: Int!) {
        createBillingPortalLink(billingAccountId:$billingAccountId)
    }
`
