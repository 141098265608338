import logo from './logo.png'
import sideLogo from './logo-side.png'
import { LIVE_CHAT } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'JivoChat',
  color: '#00c05a',
  category: [LIVE_CHAT],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Widget ID',
      type: 'string',
      name: 'widgetId',
      validation: ['required']
    }
  ]
}

export default config
