import styled from 'styled-components'
import icon from './icon.png'
import requestFeatureIcon from './request-feature-icon.png'

export const Icon = styled.img.attrs({ src: icon })`
  width: 164px;
  height: 161px;
  margin-bottom: 30px;
`

export const RequestForm = styled.form`
  padding: 23px 16px 0px;
`

export const FeatureRequestIcon = styled.img.attrs({ src: requestFeatureIcon })`
  width: 90px;
  height: 90px;
  margin-bottom: 30px;
`

export const FeatureRequestHeader = styled.div`
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  text-align: center;
  color: #0C1533;
`
