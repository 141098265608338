import path from './path'

const TYPE = `closeForm@${path}`

export const closeForm = () => ({
  type: TYPE
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      isOpen: false
    }
    : state
