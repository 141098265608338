import { queries } from '@segmentstream/cubejs-client'
import { adContentTable } from './adContent'

export const adTermTable = (dateRange, filters) => {
  const adContentTableInstance = adContentTable(dateRange, filters)

  return {
    id: `tables_ad_term`,
    name: queries.legacy.attributionsTables.adTermTable.name,
    vizState: {
      child: {
        name: adContentTableInstance.name,
        data: (adTerm) => {
          const adContentTableChildInstance = adContentTable(dateRange, [
            ...filters,
            {
              member: 'Attribution.adTerm',
              operator: 'equals',
              values: [adTerm]
            }
          ])
          return adContentTableChildInstance.vizState
        }
      },
      query: queries.legacy.attributionsTables.adTermTable.query(dateRange, filters),
      size: 12,
      chartType: 'table'
    }
  }
}
