import styled, { css } from 'styled-components'

export const ButtonDanger = styled.div`
  display: inline-block;
  padding-top: 14px;
  padding-bottom: 14px;
  width: 170px;
  border: none;
  border-radius: 5px;
  color: #fff;
  font-size: 16px;
  outline: none;
  text-align: center;
  cursor: ${props => (props.pending ? 'wait' : 'pointer')};

  &:disabled {
    border-color: #cacdd1;
    background-color: #cacdd1;
    cursor: auto;
  }
  background-color: ${props => (props.pending ? '#FF8B88' : '#FF504B')};
  ${props =>
    !props.disabled &&
  !props.pending &&
  css`
      &:hover {
        background-color: #d25450;
      }
    `}
`
