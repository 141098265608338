import logo from './logo.png'
import authIcon from './authIcon.png'
import {
  fetchGoogleCampaignManagerProfiles,
  fetchGoogleCampaignManagerSites,
  fetchGoogleCampaignManagerAdvertisers
} from 'api/dataSource'
import { dimensions } from './dimensions'
import { metrics } from './metrics'

const config = {
  type: 'Google Campaign Manager',
  color: '#00c814',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datasources/google-campaign-manager',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Campaign Manager',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Profile',
      type: 'dynamic select',
      name: 'profileId',
      validation: ['required'],
      placeholder: 'Select profile',
      optionsFetcher: fetchGoogleCampaignManagerProfiles,
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Advertiser',
      type: 'dynamic select',
      name: 'advertiserId',
      validation: ['required'],
      placeholder: 'Select advertiser',
      optionsFetcher: fetchGoogleCampaignManagerAdvertisers,
      extendedView: true,
      uniqueValues: true,
      dependsOn: ['profileId']
    },
    {
      label: 'Sites (DCM)',
      type: 'dynamic select array',
      name: 'sitesIds',
      validation: ['required'],
      placeholder: 'Select site',
      optionsFetcher: fetchGoogleCampaignManagerSites,
      searchable: true,
      uniqueValues: true,
      dependsOn: ['profileId', 'advertiserId']
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          enable: ['costMultiplier'],
          tip: '<a href="https://docs.segmentstream.com/datasources/google-campaign-manager#additional-transformation-settings" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
          tipPlacement: 'right',
          defaultValue: true
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        }
      ]
    },
    {
      label: 'Custom reports',
      type: 'group array',
      name: 'customReports',
      addButtonLabel: 'Add custom report',
      items: [
        {
          label: 'Destination table name',
          type: 'string',
          name: 'destinationTableName',
          validation: ['required']
        },
        {
          label: 'Advertiser filter',
          type: 'dynamic select array',
          name: 'advertiserIds',
          placeholder: 'Select advertiser',
          optionsFetcher: fetchGoogleCampaignManagerAdvertisers,
          searchable: true,
          extendedView: true,
          uniqueValues: true
        },
        {
          label: 'Sites (DCM) filter',
          type: 'dynamic select array',
          name: 'sitesIds',
          placeholder: 'Select site',
          optionsFetcher: fetchGoogleCampaignManagerSites,
          searchable: true,
          uniqueValues: true
        },
        {
          label: 'Dimensions',
          type: 'dynamic select array',
          name: 'dimensions',
          validation: ['required'],
          placeholder: 'Select dimension',
          optionsFetcher: async () => dimensions,
          addButtonLabel: 'Add dimension',
          uniqueValues: true,
          searchable: true
        },
        {
          label: 'Metrics',
          type: 'dynamic select array',
          name: 'metrics',
          validation: ['required'],
          placeholder: 'Select metric',
          optionsFetcher: async () => metrics,
          addButtonLabel: 'Add metric',
          uniqueValues: true,
          searchable: true
        }
      ]
    }
  ]
}

export default config
