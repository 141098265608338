import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Salesforce',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/salesforce'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Salesforce Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Salesforce',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Salesforce',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import Opportunity report',
      type: 'boolean',
      name: 'opportunityReport',
      defaultValue: true
    },
    {
      label: 'Import OpportunityHistory report',
      type: 'boolean',
      name: 'opportunityHistoryReport',
      defaultValue: true
    },
    {
      label: 'Import Lead report',
      type: 'boolean',
      name: 'leadReport',
      defaultValue: true
    },
    {
      label: 'Import LeadHistory report',
      type: 'boolean',
      name: 'leadHistoryReport',
      defaultValue: true
    }
  ]
}

export default config
