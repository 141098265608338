import path from './path'
import { publish as projectPublish } from 'redux/modules/project/publish'

export const PENDING = 'pending'
export const PUBLISHED = 'published'

const PUBLISH_PENDING = `publishPending@${path}`
const PUBLISH = `publish@${path}`

export const publish = () => async dispatch => {
  dispatch({ type: PUBLISH_PENDING })
  await dispatch(projectPublish())
  dispatch({ type: PUBLISH })
}

export default (state = {}, action) => {
  switch (action.type) {
    case PUBLISH_PENDING:
      return {
        ...state,
        publishStatus: PENDING
      }
    case PUBLISH:
      return {
        ...state,
        publishStatus: PUBLISHED
      }
    default:
      return state
  }
}
