import React from 'react'
import Scripts from './Scripts'
import ProjectOptions from './ProjectOptions'
import CustomIntegrations from './CustomIntegrations'
import DataTransformations from './DataTransformations'
import CustomSDKUrl from './CustomSDKUrl'
import Header from './Header'

export default () => (
  <div>
    <Header />
    <Scripts />
    <ProjectOptions />
    <CustomIntegrations />
    <DataTransformations />
    <CustomSDKUrl />
  </div>
)
