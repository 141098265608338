import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { close } from 'redux/modules/page/integrations/integrationForm'
import IntegrationSideLogo from 'containers/IntegrationSideLogo'
import DocsLink from 'containers/DocsLink'
import StatusBox from 'containers/StatusBox'
import {
  SETTINGS,
  OVERRIDE_FUNCTIONS,
  EVENT_ENRICHMENTS
} from 'redux/modules/page/integrations/integrationForm/changeTab'
import integrations, { sandboxIntegrations } from 'integrations'
import { changeStatus, removeAuth } from 'redux/modules/integrations'
import getTranslate from 'utils/getTranslate'
import AuthenticationStatusBox from '../../AuthenticationStatusBox'
import IntegrationForm from './IntegrationForm'
import OverrideFunctions from './OverrideFunctions'
import EventEnrichments from './EventEnrichments'
import SettingTabs from './SettingTabs'
import ru from './ru.json'

const IntegrationSide = ({
  projectName,
  locale,
  activeTab,
  displayMode = 'default',
  isOpen,
  isSaleMode,
  isEnabled,
  integrationName,
  integrationAuthMeta,
  isAuthenticated,
  onClose,
  onChangeStatus,
  wasSaved,
  onRemoveAuth
}) => {
  const allIntegrations = isSaleMode ? [...sandboxIntegrations, ...integrations] : integrations
  const index = allIntegrations.findIndex(item => item.name === integrationName)
  const integration = allIntegrations[index]
  const translate = getTranslate(locale, { ru })
  const handleStatusChange = () => {
    const firstEnable = isEnabled === undefined
    onChangeStatus(integrationName, !isEnabled, firstEnable)
  }

  if (!isOpen) {
    return <ModalWindow isOpen={false} />
  }

  const header = <IntegrationSideLogo logo={integration.sideLogo} />

  const enabledStatusBox = integration.requiresSetup !== false ? isEnabled : isEnabled || false

  const disableMessageTemplate = translate(
    'Are you sure that you want to <b>DISABLE</b> the {{INTEGRATION_NAME}} integration in the <b>{{PROJECT_NAME}}</b> project?'
  )
  const disableMessage = translate(disableMessageTemplate, {
    INTEGRATION_NAME: integrationName,
    PROJECT_NAME: projectName
  })

  const docsLink = integration.docsUrl && (
    <DocsLink
      locale={locale}
      url={integration.docsUrl}
    />
  )

  const statusBox = (isAuthenticated || !integration.authForm) && enabledStatusBox !== undefined && (
    <StatusBox
      locale={locale}
      isEnabled={enabledStatusBox}
      onChange={handleStatusChange}
      name={integrationName}
      projectName={projectName}
      type='integration'
      disableMessage={disableMessage}
    />
  )

  const handleAuthentication = () => {
    onRemoveAuth(integrationName)
  }

  const tabs = wasSaved && <SettingTabs hasSettings={integration.form !== undefined} />
  const disconnect = isAuthenticated && (
    <AuthenticationStatusBox
      locale={locale}
      authIcon={integration.authIcon}
      authName={integration.authName}
      onChange={handleAuthentication}
      authMeta={integrationAuthMeta}
    />
  )

  const middle = (
    <div>
      {integration.authForm && isAuthenticated && disconnect}
      {isAuthenticated && displayMode !== 'simple' && tabs}
    </div>
  )

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      header={header}
      middle={middle}
      docsLink={docsLink}
      statusBox={statusBox}
    >
      {isEnabled === undefined && activeTab === undefined && !integration.form && (
        <span>{translate("This integration doesn't require settings")}</span>
      )}

      {(activeTab === undefined || activeTab === SETTINGS) && integration.form && (
        <IntegrationForm integration={integration} displayMode={displayMode} />
      )}

      {(activeTab === EVENT_ENRICHMENTS ||
        (isEnabled !== undefined && activeTab === undefined && !integration.form)) && (
        <EventEnrichments />
      )}

      {activeTab === OVERRIDE_FUNCTIONS && <OverrideFunctions />}
    </ModalWindow>
  )
}

const mapStateToProps = state => {
  const {
    page: {
      integrations: {
        integrationForm: { name, isOpen, activeTab }
      }
    }
  } = state
  const isEnabled = (state.integrations[name] || {}).isEnabled
  const isAuthenticated = (state.integrations[name] || {}).isAuthenticated

  return {
    projectName: state.project.name,
    locale: state.locale,
    wasSaved: !!state.integrations[name],
    isOpen,
    integrationName: name,
    integrationAuthMeta: (state.integrations[name] || {}).authMeta,
    activeTab,
    isSaleMode: state.saleMode.status,
    isAuthenticated: isAuthenticated || isEnabled,
    isEnabled: state.integrations[name] ? state.integrations[name].isEnabled : undefined
  }
}

const mapDispatchToProps = () => ({
  onClose: close,
  onChangeStatus: changeStatus,
  onRemoveAuth: removeAuth
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(IntegrationSide)
