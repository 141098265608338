import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchGoogleAdsCustomers } from 'api/dataSource'

const config = {
  type: 'Google Ads',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/google-ads',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Ads',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Advertising accounts',
      type: 'dynamic select array',
      name: 'customerIds',
      validation: ['required'],
      placeholder: 'Select advertising account',
      optionsFetcher: fetchGoogleAdsCustomers,
      description: 'No advertising accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/google-ads/answer/6366720?hl=en-GB" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      addButtonLabel: 'Add advertising account',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Import "Keyword Performance" report',
      type: 'boolean',
      name: 'keywordsPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#keyword-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Audience Performance" report',
      type: 'boolean',
      name: 'audiencePerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#audience-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Criteria Performance" report',
      type: 'boolean',
      name: 'criteriaPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#criteria-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Placement Performance" report',
      type: 'boolean',
      name: 'placementPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#placement-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Click Performance" report',
      type: 'boolean',
      name: 'clickPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#click-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Campaign Performance" report',
      type: 'boolean',
      name: 'campaignPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#campaign-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import "Ad Performance" report',
      type: 'boolean',
      name: 'adPerformanceReport',
      tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#ad-performance" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#additional-transformation-settings" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
          tipPlacement: 'right',
          configurations: {
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Import cost data grouped by gclid',
          type: 'boolean',
          name: 'clickIdGroupedCostsReport',
          tip: '<a href="https://docs.segmentstream.com/datasources/google-ads#additional-transformation-settings" target="_blank" rel="noopener noreferrer">Read more about this report ▸</a>',
          tipPlacement: 'right',
          configurations: {
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal'],
          showIf: state =>
            (state.additionalTransformationSettings || {}).clickIdGroupedCostsReport ||
            state.clickIdGroupedCostsReport ||
            (state.additionalTransformationSettings || {}).normalizedCostsReport ||
            state.normalizedCostsReport
        }
      ]
    }
  ]
}

export default config
