import { NORMALIZED_COSTS_DEPENDENCY } from 'dataTransformations'
import { getNormalizedCostsDataSources } from 'utils/getNormalizedCostsDataSources'

export const checkDataTransformationDependencies = (dataSources, dependencies = []) => {
  let missingDependency
  if (dependencies.includes(NORMALIZED_COSTS_DEPENDENCY) && !getNormalizedCostsDataSources(dataSources).length) {
    missingDependency = NORMALIZED_COSTS_DEPENDENCY
  }
  return missingDependency
}
