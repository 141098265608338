import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'Affiliate Fraud Detection',
  locale: { ru },
  icon,
  description: 'Prepares a table of order ids that have a high probability of being fraudulent.',
  form: [
    {
      label: 'Mininum time interval between source change and transaction',
      type: 'number',
      name: 'timeBetweenSourceChangeAndTransaction',
      units: 'minutes'
    }
  ]
}

export default config
