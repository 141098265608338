import styled, { css } from 'styled-components'
import segmentstreamlogo from './segmentstreamlogo.png'
import logout from './logout.svg'
import documentation from './documentation.svg'

export const HEADER_HEIGHT = 62
export const Container = styled.div`
  background-color: #4d6dd3;
`

export const HeaderWrapper = styled.div`
  position: relative;
  min-width: 1050px;
  margin: 0 auto;
  height: ${HEADER_HEIGHT}px;
  padding: 0 27px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: ProxmiaNovaLight;
  color: #fff;
  background-color: #4d6dd3;
`

export const SegmentStreamLogo = styled.div`
  width: 26px;
  height: 37px;
  background: url(${segmentstreamlogo}) no-repeat;
  background-size: 26px 37px;
`

export const MenuLink = styled.a`
  margin: 0 25px 0 0;
  padding: 0 25px 0 0;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-right: solid 1px rgba(255, 255, 255, 0.2);
  ${props =>
    props.position === 'left' &&
  css`
      margin: 0 0 0 20px;
    `}  
  &:last-child {
    border: none;
  }    
`

export const DocumentationLink = styled.a`
  margin-right: 18px;
  padding-left: 34px;
  background: url(${documentation}) no-repeat;
  background-size: 21px 19px;
  background-position: left center;
  font-size: 14px;
  color: #fff;
  text-decoration: none;
  max-width: 200px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
`

export const FlexContainer = styled.div`
  display: flex;
  align-items: center;
`

export const Logout = styled.div`
  width: 24px;
  height: 21px;
  background: url(${logout}) no-repeat;
  background-size: 13px 16px;
  background-position: right center;
  cursor: pointer;
`
