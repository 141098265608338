import React from 'react'
import { connect } from 'react-redux'
import { changeStatusFilter, changeCategory } from 'redux/modules/page/integrations'
import categories, { ALL } from 'integrations/categories'
import getTranslate from 'utils/getTranslate'
import statuses, { STATUS_ALL } from 'integrations/statuses'
import SelectBox from 'containers/Form/SelectBox'
import { THEME_FILTER } from 'containers/Form/SelectBox/styles'
import ru from './ru.json'
import { FilterGroup } from './styles'

const IntegrationFilters = ({
  filters,
  locale,
  dispatch,
  activeCategory = ALL,
  activeStatus = STATUS_ALL
}) => {
  const translate = getTranslate(locale, { ru })
  const onChangeStatus = value => {
    dispatch(changeStatusFilter(value))
  }
  const onChangeCategory = value => {
    dispatch(changeCategory(value))
  }
  const statusOptions = statuses.map(status => ({
    label: translate(status),
    value: status
  }))
  const categoryOptions = categories.map(category => ({
    label: translate(category),
    value: category
  }))

  return (
    <FilterGroup>
      <SelectBox
        locale={locale}
        onChange={onChangeStatus}
        value={filters.status || activeStatus}
        options={statusOptions}
        theme={THEME_FILTER}
      />
      <SelectBox
        locale={locale}
        onChange={onChangeCategory}
        value={filters.category || activeCategory}
        options={categoryOptions}
        theme={THEME_FILTER}
        styles={{ width: '216px' }}
      />
    </FilterGroup>
  )
}

const mapStateToProps = state => ({
  filters: state.page.integrations.filter,
  locale: state.locale
})

export default connect(mapStateToProps)(IntegrationFilters)
