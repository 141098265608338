import React, { Component } from 'react'
import ArrayBox from 'containers/Form/ArrayBox'
import SelectBox from 'containers/Form/SelectBox'
import SearchableSelect from 'containers/Form/SearchableSelect'
import FieldError from 'containers/Form/FieldError'
import getValidationError from 'containers/Form/getValidationError'
import { Description } from 'containers/Form/DynamicSelectBox/styles'

export const THEME_FORM_SINGLE_ARRAY_ITEM = 'form_single_array_item'

class DynamicSelectArray extends Component {
  constructor (props) {
    super(props)
    this.state = { options: [] }

    this.loadOptions = this.loadOptions.bind(this)

    this._isMounted = false
  }

  componentDidMount () {
    this._isMounted = true
    this._isMounted && this.loadOptions('initialLoad')
  }

  componentWillUnmount () {
    this._isMounted = false
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    const { context, dependsOn = [] } = this.props

    dependsOn.forEach(dependency => {
      if (
        this.state.dependentFieldValues &&
        (nextProps.context.dependentFieldValues[dependency] !== this.state.dependentFieldValues[dependency])
      ) {
        this.setState({
          dependentFieldValues: {
            ...this.state.dependentFieldValues,
            [dependency]: (context.dependentFieldValues || {})[dependency]
          }
        })
        this.loadOptions()
      }
    })
  }

  optionsLoadStateHandler (res, initialLoad) {
    const {
      context,
      dependsOn = []
    } = this.props
    this.setState({
      loading: false,
      options: res
    })
    if (initialLoad) {
      const dependentFieldValues = {}
      dependsOn.forEach(dependency => {
        dependentFieldValues[dependency] = (context.dependentFieldValues || {})[dependency]
      })
      this.setState({
        initialLoad: true,
        dependentFieldValues
      })
    }
  }

  loadOptions (initialLoad) {
    const { optionsFetcher, context } = this.props

    this.setState({ loading: true })

    optionsFetcher(context)
      .then(res => this._isMounted && this.optionsLoadStateHandler(res, initialLoad))
      .catch(() => {
        this._isMounted && this.optionsLoadStateHandler([], initialLoad)
        this._isMounted && this.setState({
          fetchError: true
        })
      })
  }

  render () {
    const {
      locale,
      description,
      errorDescription,
      label,
      onChange,
      onTouch,
      style,
      value,
      placeholder,
      inputStyle,
      limit,
      validation,
      touched,
      tip,
      extendedView,
      disabled,
      addButtonLabel,
      uniqueValues,
      searchable
    } = this.props
    const { options, loading, initialLoad, fetchError } = this.state

    const optionsWithLabels = options && options.length && options.some(option => option.label)
    const normalizedOptions = optionsWithLabels ? options.map(({ value, label }) => ({ value, label: label || value })) : options

    const disable = disabled || !options.length
    const error = touched && getValidationError(validation, value, locale)
    const descriptionHTML = () => ({ __html: description })
    const errorDescriptionHTML = () => ({ __html: errorDescription })
    const defaultRow = ''

    const getRowsFromValue = () => {
      if (!value || value.length === 0) {
        return [defaultRow]
      }
      return value
    }

    const change = newRows => {
      const nextValue = newRows.filter(row => row !== defaultRow)
      onChange(nextValue)
      onTouch()
    }

    const createRow = ({ row, index, onChangeRow }) => (
      !searchable
        ? <SelectBox
          style={inputStyle}
          theme={THEME_FORM_SINGLE_ARRAY_ITEM}
          value={loading && !initialLoad ? 'Loading...' : row}
          options={normalizedOptions}
          onChange={val => onChangeRow(index, val)}
          placeholder={placeholder}
          extendedView={extendedView}
          disabled={disable}
          uniqueValues={uniqueValues}
          arrayValues={value}
        />
        : <SearchableSelect
          style={inputStyle}
          theme={THEME_FORM_SINGLE_ARRAY_ITEM}
          value={loading && !initialLoad ? 'Loading...' : row}
          options={normalizedOptions}
          onChange={val => onChangeRow(index, val)}
          placeholder={placeholder}
          disabled={disable}
          uniqueValues={uniqueValues}
          arrayValues={value}
        />
    )

    return (
      <div>
        <ArrayBox
          locale={locale}
          defaultRow={defaultRow}
          label={label}
          loading={loading}
          onRefresh={this.loadOptions}
          onChange={change}
          onCreateRow={createRow}
          rows={getRowsFromValue()}
          style={style}
          limit={limit}
          error={error}
          tip={tip}
          disabled={disable}
          addButtonLabel={loading ? 'Loading...' : addButtonLabel}
          options={normalizedOptions}
          uniqueValues={uniqueValues}
        />
        {
          (initialLoad && !options.length) &&
          <Description dangerouslySetInnerHTML={(fetchError && errorDescription) ? errorDescriptionHTML() : descriptionHTML()} />
        }
        {error && <FieldError message={error} />}
      </div>
    )
  }
}

export default DynamicSelectArray
