import React from 'react'
import styled from 'styled-components'

import {
  Container,
  HeaderText,
  Logo,
  MessageContainer,
  Button,
  ButtonContainer,
  RowContainer
} from './styles'

import successIcon from './success-icon.svg'

const Message = styled(MessageContainer)`
  justify-content: space-around;
  align-items: center;
`

const SuccessIcon = styled.div`
  background: url(${successIcon}) no-repeat;
  width: 42px;
  height: 42px;
  background-size: contain;
`

const Text = styled.div`
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  text-align: center;
  
  max-width: 315px;
  
  color: #6E6E6E;
`

export function EmailConfirmationSuccessPage ({ onGetStarted }) {
  return (
    <Container>
      <RowContainer>
        <Logo />
        <Message>
          <SuccessIcon />
          <HeaderText>Email verified</HeaderText>

          <Text>Congratulations! Your account has been successfully activated!</Text>
          <Text>We hope you enjoy using SegmentStream!</Text>

          <ButtonContainer>
            <Button onClick={onGetStarted}>Get started</Button>
          </ButtonContainer>
        </Message>
      </RowContainer>
    </Container>
  )
}
