export const metrics = [
  'dfa:actiewViewPercentAdPlayed',
  'dfa:activeViewAverageViewableTimeSecond',
  'dfa:activeViewCustomMetricMeasurableImpressions',
  'dfa:activeViewCustomMetricViewableImpressions',
  'dfa:activeViewCustomMetricViewableRate',
  'dfa:activeViewEligibleImpressions',
  'dfa:activeViewGroupmMeasurableImpressions',
  'dfa:activeViewGroupmTrvMeasurableImpressions',
  'dfa:activeViewGroupmTrvViewableImpressions',
  'dfa:activeViewGroupmViewableImpressions',
  'dfa:activeViewImpressionDistributionNotMeasurable',
  'dfa:activeViewImpressionDistributionNotViewable',
  'dfa:activeViewImpressionDistributionViewable',
  'dfa:activeViewImpressionsAudibleAndVisibleAtCompletion',
  'dfa:activeViewImpressionsVisible10Seconds',
  'dfa:activeViewMeasurableImpressions',
  'dfa:activeViewNotMeasurableImpressions',
  'dfa:activeViewNotViewableImpressions',
  'dfa:activeViewPercentAudibleAndVisibleAtCompletion',
  'dfa:activeViewPercentAudibleAndVisibleAtFirstQuartile',
  'dfa:activeViewPercentAudibleAndVisibleAtMidpoint',
  'dfa:activeViewPercentAudibleAndVisibleAtStart',
  'dfa:activeViewPercentAudibleAndVisibleAtThirdQuartile',
  'dfa:activeViewPercentAudibleImpressions',
  'dfa:activeViewPercentBackground',
  'dfa:activeViewPercentFullscreen',
  'dfa:activeViewPercentImpressionsAudibleAndFullyOnscreen',
  'dfa:activeViewPercentImpressionsAudibleAndFullyOnscreenForHalfDuration',
  'dfa:activeViewPercentImpressionsAudibleAndVisibleAtCompletion',
  'dfa:activeViewPercentImpressionsAudibleAndVisibleAtFirstQuartile',
  'dfa:activeViewPercentImpressionsAudibleAndVisibleAtMidpoint',
  'dfa:activeViewPercentImpressionsAudibleAndVisibleAtThirdQuartile',
  'dfa:activeViewPercentImpressionsVisibleAtCompletion',
  'dfa:activeViewPercentImpressionsVisibleAtFirstQuartile',
  'dfa:activeViewPercentImpressionsVisibleAtMidpoint',
  'dfa:activeViewPercentImpressionsVisibleAtThirdQuartile',
  'dfa:activeViewPercentPlayTimeAudible',
  'dfa:activeViewPercentPlayTimeAudibleAndVisible',
  'dfa:activeViewPercentPlayTimeVisible',
  'dfa:activeViewPercentVisible10Seconds',
  'dfa:activeViewPercentVisibleAtCompletion',
  'dfa:activeViewPercentVisibleAtFirstQuartile',
  'dfa:activeViewPercentVisibleAtMidpoint',
  'dfa:activeViewPercentVisibleAtStart',
  'dfa:activeViewPercentVisibleAtThirdQuartile',
  'dfa:activeViewPercentageMeasurableImpressions',
  'dfa:activeViewPercentageViewableImpressions',
  'dfa:activeViewViewableImpressions',
  'dfa:activityClickThroughConversions',
  'dfa:activityClickThroughRevenue',
  'dfa:activityDeliveryStatus',
  'dfa:activityPerClick',
  'dfa:activityPerThousandImpressions',
  'dfa:activityViewThroughConversions',
  'dfa:activityViewThroughRevenue',
  'dfa:audioCompanionClicks',
  'dfa:audioCompanionImpressions',
  'dfa:beginToRenderEligibleImpressions',
  'dfa:beginToRenderImpressions',
  'dfa:bookedActivities',
  'dfa:bookedClicks',
  'dfa:bookedImpressions',
  'dfa:bookedViewableImpressions',
  'dfa:clickDeliveryStatus',
  'dfa:clickRate',
  'dfa:clicks',
  'dfa:cookieUnconsentedImpressions',
  'dfa:costPerActivity',
  'dfa:costPerClick',
  'dfa:costPerRevenue',
  'dfa:crossDeviceClickThroughConversionQuantity',
  'dfa:crossDeviceClickThroughConversionRevenue',
  'dfa:crossDeviceClickThroughConversions',
  'dfa:crossDeviceTotalConversionQuantity',
  'dfa:crossDeviceTotalConversionRevenue',
  'dfa:crossDeviceTotalConversions',
  'dfa:crossDeviceViewThroughConversionQuantity',
  'dfa:crossDeviceViewThroughConversionRevenue',
  'dfa:crossDeviceViewThroughConversions',
  'dfa:dbmCost',
  'dfa:dbmCostUsd',
  'dfa:downloadedImpressions',
  'dfa:effectiveCpm',
  'dfa:generalInvalidTrafficActiveViewEligibleImpressions',
  'dfa:generalInvalidTrafficActiveViewMeasurableImpressions',
  'dfa:generalInvalidTrafficActiveViewViewableImpressions',
  'dfa:generalInvalidTrafficBeginToRenderImpressions',
  'dfa:generalInvalidTrafficClicks',
  'dfa:generalInvalidTrafficImpressions',
  'dfa:generalInvalidTrafficTrackedAds',
  'dfa:impressionDeliveryStatus',
  'dfa:impressions',
  'dfa:invalidActiveViewEligibleImpressions',
  'dfa:invalidActiveViewMeasurableImpressions',
  'dfa:invalidActiveViewViewableImpressions',
  'dfa:invalidBeginToRenderImpressions',
  'dfa:invalidClicks',
  'dfa:invalidImpressions',
  'dfa:invalidTrackedAds',
  'dfa:mediaCost',
  'dfa:naturalSearchActions',
  'dfa:naturalSearchClicks',
  'dfa:naturalSearchRevenue',
  'dfa:naturalSearchTransactions',
  'dfa:paidSearchActionConversionPercentage',
  'dfa:paidSearchActions',
  'dfa:paidSearchAverageCostPerAction',
  'dfa:paidSearchAverageCostPerTransaction',
  'dfa:paidSearchAverageDfaTransactionAmount',
  'dfa:paidSearchAveragePosition',
  'dfa:paidSearchClickRate',
  'dfa:paidSearchClicks',
  'dfa:paidSearchCost',
  'dfa:paidSearchImpressions',
  'dfa:paidSearchRevenue',
  'dfa:paidSearchSpendPerTransactionRevenue',
  'dfa:paidSearchTransactionConversionPercentage',
  'dfa:paidSearchTransactionRevenuePerSpend',
  'dfa:paidSearchTransactions',
  'dfa:paidSearchVisits',
  'dfa:percentInvalidClicks',
  'dfa:percentInvalidImpressions',
  'dfa:plannedMediaCost',
  'dfa:provisionalImpressions',
  'dfa:revenuePerClick',
  'dfa:revenuePerThousandImpressions',
  'dfa:richMediaAudioCompletions',
  'dfa:richMediaAudioFirstQuartileCompletes',
  'dfa:richMediaAudioMidpoints',
  'dfa:richMediaAudioMutes',
  'dfa:richMediaAudioPauses',
  'dfa:richMediaAudioPlays',
  'dfa:richMediaAudioReplays',
  'dfa:richMediaAudioThirdQuartileCompletes',
  'dfa:richMediaAudioUnmutes',
  'dfa:richMediaAverageDisplayTime',
  'dfa:richMediaAverageExpansionTime',
  'dfa:richMediaAverageFullScreenViewTime',
  'dfa:richMediaAverageInteractionTime',
  'dfa:richMediaAverageVideoViewTime',
  'dfa:richMediaBackupImages',
  'dfa:richMediaClickRate',
  'dfa:richMediaClicks',
  'dfa:richMediaCodeServes',
  'dfa:richMediaCustomAverageTime',
  'dfa:richMediaCustomCounters',
  'dfa:richMediaCustomExits',
  'dfa:richMediaCustomTimers',
  'dfa:richMediaCustomVariableCount1',
  'dfa:richMediaCustomVariableCount2',
  'dfa:richMediaDisplayTime',
  'dfa:richMediaEngagements',
  'dfa:richMediaEventCounters',
  'dfa:richMediaEventTimers',
  'dfa:richMediaExpansionTime',
  'dfa:richMediaExpansions',
  'dfa:richMediaFullScreenImpressions',
  'dfa:richMediaFullScreenVideoCompletes',
  'dfa:richMediaFullScreenVideoPlays',
  'dfa:richMediaHtml5Impressions',
  'dfa:richMediaImpressions',
  'dfa:richMediaInteractionRate',
  'dfa:richMediaInteractionTime',
  'dfa:richMediaInteractions',
  'dfa:richMediaInteractiveImpressions',
  'dfa:richMediaManualCloses',
  'dfa:richMediaScrolls',
  'dfa:richMediaTrueViewViews',
  'dfa:richMediaVideoCompanionClicks',
  'dfa:richMediaVideoCompletions',
  'dfa:richMediaVideoFirstQuartileCompletes',
  'dfa:richMediaVideoFullScreens',
  'dfa:richMediaVideoInteractionRate',
  'dfa:richMediaVideoInteractions',
  'dfa:richMediaVideoMidpoints',
  'dfa:richMediaVideoMutes',
  'dfa:richMediaVideoPauses',
  'dfa:richMediaVideoPlays',
  'dfa:richMediaVideoProgressEvents',
  'dfa:richMediaVideoReplays',
  'dfa:richMediaVideoSkips',
  'dfa:richMediaVideoStops',
  'dfa:richMediaVideoThirdQuartileCompletes',
  'dfa:richMediaVideoUnmutes',
  'dfa:richMediaVideoViewRate',
  'dfa:richMediaVideoViews',
  'dfa:roadblockImpressions',
  'dfa:socialEngagementRate',
  'dfa:socialFollows',
  'dfa:socialLeadSubmissions',
  'dfa:socialLikes',
  'dfa:socialOfferAccepts',
  'dfa:socialOtherInteractions',
  'dfa:socialReplies',
  'dfa:socialSharesExternal',
  'dfa:socialSharesInternal',
  'dfa:socialTotalEngagements',
  'dfa:totalConversions',
  'dfa:totalConversionsRevenue',
  'dfa:trackedAds',
  'dfa:twitterAppClicks',
  'dfa:twitterBuyNowCardClicks',
  'dfa:twitterBuyNowPurchaseSuccessfuls',
  'dfa:twitterUrlClicks',
  'dfa:twitterVideo50PercentInViewFor2Seconds',
  'dfa:twitterVideo100PercentInViewFor3Seconds',
  'dfa:vendorBlockedAds',
  'dfa:verificationBlockedImpressions',
  'dfa:verificationClassifiedImpressions',
  'dfa:verificationMeasurableImpressionsForAudio',
  'dfa:verificationMeasurableImpressionsForVideoPlayerLocation',
  'dfa:verificationMeasurableImpressionsForVideoPlayerSize',
  'dfa:verificationPercentageMeasurableImpressionsForAudio',
  'dfa:verificationPercentageMeasurableImpressionsForVideoPlayerLocation',
  'dfa:verificationPercentageMeasurableImpressionsForVideoPlayerSize',
  'dfa:verificationPublisherProblems',
  'dfa:verificationReportingProblems',
  'dfa:verificationServingProblems',
  'dfa:verificationVerifiableImpressions',
  'dfa:verificationVideoMutedAtStart',
  'dfa:verificationVideoPlayerHdSizeImpressions',
  'dfa:verificationVideoPlayerLargeSizeImpressions',
  'dfa:verificationVideoPlayerLocationAvgPixelsFromLeft',
  'dfa:verificationVideoPlayerLocationAvgPixelsFromTop',
  'dfa:verificationVideoPlayerSizeAvgHeight',
  'dfa:verificationVideoPlayerSizeAvgWidth',
  'dfa:verificationVideoPlayerSmallSizeImpressions',
  'dfa:verificationVideoProminenceScore',
  'dfa:verificationWarnings',
  'dfa:videoCompanionClicks',
  'dfa:videoCompanionImpressions'
]
