import React from 'react'
import ReactModal from 'react-modal'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import CloseButton from 'containers/CloseButton'

const style = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.45)',
    zIndex: 12
  },
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    backgroundColor: '#ffffff',
    borderRadius: '5px',
    fontSize: '18px',
    textAlign: 'center'
  }
}

export default ({ message, onClose }) => (
  <ReactModal style={style} isOpen={message !== undefined} contentLabel='Confirmation window'>
    <CloseButton onClick={onClose} style={{ top: '15px', right: '15px' }} />
    <div style={{ paddingTop: '25px' }}>{message}</div>
    <div style={{ paddingTop: '33px' }}>
      <ConfirmButton onClick={onClose}>OK</ConfirmButton>
    </div>
  </ReactModal>
)
