import { expireCookie } from 'redux-cookie'
import Cookies from 'js-cookie'
import { PROJECT_ID } from './constants'
import { logout as globalLogout } from 'redux/modules/logout'
import { getDomain, getAccessTokenName, getRefreshTokenName } from 'redux/modules/auth/utils'

export const logout = (page) => async dispatch => {
  const { pageName } = page || {}

  let domain = getDomain()
  let accessTokenName = getAccessTokenName()
  let refreshTokenName = getRefreshTokenName()

  Cookies.remove(accessTokenName, { domain })
  Cookies.remove(refreshTokenName, { domain })

  await dispatch(expireCookie(PROJECT_ID))
  dispatch(globalLogout(pageName))
}
