import styled from 'styled-components'
import book from './book.svg'

export const DocsLinkButton = styled.a`
  display: block;
  text-decoration: none;
  position: relative;
  width: 171px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #e3e4e6;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
  user-select: none;
  padding: 12px 0 12px;
  cursor: pointer;
  color: #1d2c4c;
  font-size: 16px;
`

export const BookIcon = styled.div`    
  position: absolute;
  top: 12px;
  left: 12px;
  width: 18px;
  height: 18px;
  background: url(${book}) no-repeat;
`

export const Text = styled.div`
  left: 10px;
  position: relative;
`
