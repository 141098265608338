import { update as apiUpdate, removeAuth as apiRemoveAuth } from 'api/dataSource'
import path from './path'

const SET_AUTH_STATUS = `setAuthStatus@${path}`

export const setAuthStatus = (id, isAuthenticated) => async dispatch => {
  dispatch({
    type: SET_AUTH_STATUS,
    isAuthenticated,
    id
  })
}

export const saveAuth = (id, authOptions) => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiUpdate(projectId, id, { authOptions }))
  dispatch(setAuthStatus(id, true))
}

export const removeAuth = (id, type, options) => async (dispatch, getState) => {
  const projectId = getState().project.id

  await dispatch(apiRemoveAuth(projectId, id))
  dispatch(setAuthStatus(id, false))
}

export default (state = {}, action) =>
  action.type === SET_AUTH_STATUS
    ? {
      ...state,
      [action.id]: {
        ...state[action.id],
        isAuthenticated: action.isAuthenticated,
        authMeta: action.authMeta,
        isEnabled: false
      }
    }
    : state
