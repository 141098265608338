import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import Table from 'containers/Table'
import NameCell from 'containers/NameCell'
import Status from 'containers/Status'
import EnrichmentForm from './EnrichmentForm'
import { openForm } from 'redux/modules/page/enrichments/openForm'
import Date from 'containers/Date'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'
import { addedEvent } from 'utils/digitalDataEvents'
import { isProMode, filterProModeItems } from 'utils/proMode'

const Enrichments = ({ locale, list, isProMode, onOpenForm }) => {
  const translate = getTranslate(locale, { ru })
  const handleOpenForm = () => {
    addedEvent('enrichment')
    onOpenForm()
  }
  const data = filterProModeItems(list, isProMode)
    .sort((itemA, itemB) => {
      const nameA = itemA.field.toLowerCase()
      const nameB = itemB.field.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map(item => [
      <Status isEnabled={item.isEnabled} />,
      <NameCell
        name={item.field}
        isEnabled={item.isEnabled}
        onClick={() => onOpenForm(item.id, item.field)}
      />,
      `${
        item.beforeEvent || !item.event ? translate('Before event') : translate('After event')
      }: ${item.event || 'Viewed Page'}`,
      <Date date={item.updatedAt} />
    ])

  const confirmButton = (
    <ConfirmButton onClick={handleOpenForm}>{translate('Add')}</ConfirmButton>
  )

  return (
    <div>
      <SectionHeader leftContent={confirmButton} />
      <Table
        headers={[
          '',
          translate('DigitalData variable'),
          translate('Trigger'),
          translate('Updated')
        ]}
        data={data}
      />
      <EnrichmentForm />
    </div>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    list: state.enrichments,
    isProMode: isProMode(state.proMode.status, state.user.role)
  }),
  {
    onOpenForm: openForm
  }
)(Enrichments)
