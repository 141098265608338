import path from './path'

const FETCH_DATA_SOURCES = `fetch@${path}`

export const fetch = dataSources => ({
  type: FETCH_DATA_SOURCES,
  dataSources
})

export default (state = {}, action) =>
  action.type === FETCH_DATA_SOURCES
    ? action.dataSources.reduce(
      (acc, dataSource) => ({
        ...acc,
        [dataSource.id]: dataSource
      }),
      {}
    )
    : state
