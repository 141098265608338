import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import Box from '@material-ui/core/Box'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableRow from '@material-ui/core/TableRow'
import TableCell from '@material-ui/core/TableCell'
import StatusBox from 'containers/StatusBox'
import { save } from 'redux/modules/proMode/projectOptions/save'

const ChartsSettings = ({ locale, projectOptions, dashboardsDatasetName, handleSave }) => {
  const [projectBaseDashboards, setProjectBaseDashboards] = useState(projectOptions?.baseDashboards || [])
  const [chaged, setChanged] = useState(false)
  const saveBaseDashboards = (baseDashboards) => {
    handleSave({
      ...projectOptions,
      baseDashboards
    }, chaged)
    setChanged(false)
  }

  useEffect(() => {
    setProjectBaseDashboards(projectOptions?.baseDashboards || [])
    setChanged(false)
  }, [projectOptions])

  useEffect(() => {
    saveBaseDashboards(projectBaseDashboards)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [projectBaseDashboards])

  const changeDashboardState = (dashboard) => {
    if (!dashboardsDatasetName) {
      return
    }
    setChanged(true)
    if (projectBaseDashboards.includes(dashboard)) {
      setProjectBaseDashboards(projectBaseDashboards.filter(projectDasbboard => projectDasbboard !== dashboard))
    } else {
      setProjectBaseDashboards([
        ...projectBaseDashboards,
        dashboard
      ])
    }
  }

  const dashboards = {
    'base': 'Base dashboard'
  }
  return (
    <Box margin={1}>
      <Typography style={{ textAlign: 'center' }} color='textSecondary' gutterBottom>
        Project default dashboards
      </Typography>

      <Table>
        <TableBody>
          {Object.entries(dashboards).map((row, index) => (
            <TableRow key={index}>
              <TableCell key={row[0]}>{row[1]}</TableCell>
              <TableCell key={`${row[0]}_state`}>
                <StatusBox
                  locale={locale}
                  isEnabled={projectBaseDashboards.includes(row[0])}
                  onChange={() => changeDashboardState(row[0])}
                  disableMessage={'Do you want to diavle this dashboard for user'}
                />
              </TableCell>
              <TableCell key={`${row[0]}_dashboardsDatasetName`}>{dashboardsDatasetName || 'Please select BigQuery dashboards dataset'}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}

export default connect(
  state => {
    const { options: projectOptions } = state.project || {}
    const bq = Object.entries(state.integrations).find(integration => integration[0] === 'DDManager Streaming')?.[1]
    return {
      projectOptions,
      locale: state.locale,
      dashboardsDatasetName: bq?.options?.dashboardsDatasetName
    }
  },
  {
    handleSave: save
  }
)(ChartsSettings)
