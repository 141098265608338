import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import IntegrationSearch from './IntegrationSearch'
import IntegrationFilters from './IntegrationFilters'
import { Title, IntegrationFiltersContainer } from './styles'

const Header = ({ locale }) => {
  const translate = getTranslate(locale, { ru })
  const title = (
    <Title>{translate('Integrations')}</Title>
  )
  const integrationFilters = (
    <IntegrationFiltersContainer>
      {translate('Filter by')}
      <IntegrationFilters />
      <IntegrationSearch />
    </IntegrationFiltersContainer>
  )
  return (
    <SectionHeader leftContent={title} rightContent={integrationFilters} />
  )
}

export default connect(state => ({
  locale: state.locale
}))(Header)
