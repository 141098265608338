import GoogleAnalytics from './GoogleAnalytics'
import GoogleDrive from './GoogleDrive'
import GoogleAds from './GoogleAds'
import Facebook from './Facebook'
import YandexMetrica from './YandexMetrica'

const allDataDestinations = [
  GoogleAnalytics,
  GoogleDrive,
  GoogleAds,
  Facebook,
  YandexMetrica
]

const sandboxDataDestinations = []

export { allDataDestinations, sandboxDataDestinations }
