import reduceReducers from 'reduce-reducers'
import dataSourcesForm from './dataSourcesForm'
import createDataSourceSide from './createDataSourceSide'

import { save } from './save'

export { save }

export default reduceReducers((state = {}, action) => ({
  ...state,
  dataSourcesForm: dataSourcesForm(state.dataSourcesForm, action),
  createDataSourceSide: createDataSourceSide(state.createDataSourceSide, action)
}))
