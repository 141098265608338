import React from 'react'

import { Help } from './styles'
import ReactARIAToolTip from 'react-aria-tooltip'

export const Tooltip = ({ tip, place }) => {
  return (
    <ReactARIAToolTip
      message={<div dangerouslySetInnerHTML={{ __html: tip }} />}
      eventType='hover'
      direction={place}
      bgcolor='#e1e7ed'
    >
      <Help />
    </ReactARIAToolTip>
  )
}
