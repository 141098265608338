import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchAdRollAdvertisers } from 'api/dataSource'

const config = {
  type: 'AdRoll',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/adroll',
    google_analytics: 'https://docs.segmentstream.com/datasources/adroll'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'AdRoll Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with AdRoll',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My AdRoll',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Advertiser profiles',
      type: 'dynamic select array',
      name: 'advertiserIds',
      validation: ['required'],
      placeholder: 'Select Advertiser profile',
      optionsFetcher: fetchAdRollAdvertisers,
      description: 'No advertiser profiles were found for this user or access was not granted. Please, make sure you have access to the required advertiser profiles or create a new one and click “Refresh”.',
      addButtonLabel: 'Add advertiser profile',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'utmGroupedReport',
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        }
      ]
    }
  ]
}

export default config
