import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Get4Click',
  color: '#b3d66b',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Shop ID',
      type: 'string',
      name: 'shopId',
      validation: ['required']
    },
    {
      label: 'Banner ID',
      type: 'string',
      name: 'bannerId',
      validation: ['required']
    }
  ]
}

export default config
