import { setCookie } from 'redux-cookie'
import { fetchProject } from './fetchProject'
import { getLastProjectKey } from './constants'

export const selectProject = projectIds => async (dispatch, getState) => {
  const { userId, role } = getState().user
  const page = getState().page.name
  let projectId

  /** TODO: replace with Cookie.get(PROJECT_ID) once user management is implemented */
  const lastProjectKey = getLastProjectKey(userId)
  if (!Array.isArray(projectIds)) {
    projectId = projectIds
    window.localStorage.setItem(lastProjectKey, projectId)
  } else {
    const lastProjectId = window.localStorage.getItem(lastProjectKey)
    projectId =
      lastProjectId && projectIds.indexOf(lastProjectId) >= 0 ? lastProjectId : projectIds[0]
  }
  /** TODO END */

  await dispatch(setCookie('project_id', projectId, { httpOnly: false }))
  await dispatch(fetchProject(projectId, page, role))
}
