import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import Form from 'containers/Form'
import { closeForm } from 'redux/modules/page/enrichments/closeForm'
import { clearHandlerError } from 'redux/modules/page/enrichments/clearHandlerError'
import { changeStatus } from 'redux/modules/enrichments/changeStatus'

import { save, remove } from 'redux/modules/page/enrichments'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'

const EnrichmentForm = ({
  locale,
  enrichment,
  isOpen,
  isProMode,
  handlerError,
  onClose,
  onSave,
  onRemove,
  onChangeStatus,
  onClearHandlerError,
  projectName
}) => {
  const translate = getTranslate(locale, { ru })
  const handleSave = (newEnrichment, touchedFields) => {
    onSave(newEnrichment, touchedFields)
  }
  const handleRemove = () => {
    onRemove(enrichment.id, enrichment.field)
  }
  const form = [
    {
      label: translate('Variable name'),
      type: 'string',
      name: 'field',
      validation: ['required', 'no-double-quotes']
    },
    {
      label: translate('Trigger'),
      type: 'select',
      name: 'beforeEvent',
      options: [
        {
          label: translate('Before event'),
          value: true
        },
        {
          label: translate('After event'),
          value: false
        }
      ],
      defaultValue: !enrichment || !enrichment.event
    },
    {
      label: translate('Event name'),
      type: 'string',
      name: 'event',
      validation: ['required', 'no-double-quotes'],
      defaultValue: enrichment ? 'Viewed Page' : undefined
    },
    {
      label: translate('Handler function'),
      type: 'javascript',
      name: 'handler',
      validation: ['required']
    },
    {
      label: translate('Persist to local storage'),
      type: 'boolean',
      name: 'persist',
      enable: ['persistTtl']
    },
    {
      label: translate('Persistent time (seconds)'),
      type: 'number',
      name: 'persistTtl',
      placeholder: translate('Forever')
    }
  ]

  if (isProMode) {
    form.push({
      label: 'Pro mode only',
      type: 'boolean',
      name: 'proModeOnly',
      defaultValue: false
    })
  }

  const header =
    enrichment !== undefined ? enrichment.field : translate('Add DigitalData variable')

  const handleChangeStatus = () => {
    onChangeStatus(enrichment.id, enrichment.field, !enrichment.isEnabled)
  }
  const messageTemplate = translate(
    'Are you sure that you want to <b>{{ACTION}}</b> the "{{VARIABLE_NAME}}" variable in the <b>{{PROJECT_NAME}}</b> project?'
  )
  const disableMessage = translate(messageTemplate, {
    VARIABLE_NAME: enrichment ? enrichment.field : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DISABLE')
  })
  const deleteMessage = translate(messageTemplate, {
    VARIABLE_NAME: enrichment ? enrichment.field : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DELETE')
  })

  const statusBox =
    enrichment !== undefined ? (
      <StatusBox
        locale={locale}
        isEnabled={enrichment.isEnabled}
        onChange={handleChangeStatus}
        disableMessage={disableMessage}
      />
    ) : null

  const docsLink = <DocsLink locale={locale} url='https://docs.segmentstream.com/for-analyst/variables' />

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={header} statusBox={statusBox} docsLink={docsLink}>
      <Form
        locale={locale}
        form={form}
        data={enrichment}
        onSave={handleSave}
        onRemove={enrichment !== undefined ? handleRemove : undefined}
        errorMessage={
          handlerError
            ? translate("You can't use that variables inside handler function")
            : undefined
        }
        onCloseErrorMessage={onClearHandlerError}
        deleteMessage={deleteMessage}
      />
    </ModalWindow>
  )
}

export default connect(
  state => {
    const { isOpen, id, handlerError } = state.page.enrichments
    return {
      locale: state.locale,
      projectName: state.project.name,
      isOpen,
      isProMode: isProMode(state.proMode.status, state.user.role),
      handlerError,
      enrichment: id !== undefined ? state.enrichments.find(item => item.id === id) : undefined
    }
  },
  {
    onClose: closeForm,
    onSave: save,
    onRemove: remove,
    onChangeStatus: changeStatus,
    onClearHandlerError: clearHandlerError
  }
)(EnrichmentForm)
