import React, { PureComponent } from 'react'
import { Description, RowListGroup, Label } from './styles'
import AddButton from './AddButton'
import ArrayRow from './ArrayRow'
import Tooltip from 'containers/Tooltip'
import { Icon, Reload } from 'containers/Form/DynamicSelectBox/styles'
import refresh from 'containers/Form/DynamicSelectBox/refresh.svg'

class ArrayBox extends PureComponent {
  constructor (props) {
    super(props)
    this.addRow = this.addRow.bind(this)
    this.changeRow = this.changeRow.bind(this)
    this.removeRow = this.removeRow.bind(this)
    this.focus = this.focus.bind(this)
    this.blur = this.blur.bind(this)
  }

  UNSAFE_componentWillMount () { // eslint-disable-line
    this.setState({ rows: this.props.rows })
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) { // eslint-disable-line
    if (nextState.rows !== this.state.rows) {
      this.props.onChange(nextState.rows.filter(x => x))
    }
  }

  addRow () {
    this.setState(prevState => ({
      rows: [...prevState.rows, this.props.defaultRow],
      focusedRow: undefined
    }))
  }

  changeRow (rowIndex, newRow) {
    this.setState({
      rows: this.state.rows.map((row, index) => (index === rowIndex ? newRow : row))
    })
  }

  removeRow (rowIndex) {
    this.setState(prevState => {
      const rows = prevState.rows.filter((row, index) => index !== rowIndex)
      return {
        rows: rows.length > 0 ? rows : [this.props.defaultRow],
        focusedRow: undefined
      }
    })
  }

  focus (focusedRow) {
    this.setState({ focusedRow })
  }

  blur () {
    this.setState({ focusedRow: undefined })
  }

  render () {
    const {
      locale,
      description,
      label,
      onCreateRow,
      style,
      addButtonLabel,
      nestedStyle,
      limit,
      error,
      tip,
      onRefresh,
      loading,
      disabled,
      options = [],
      uniqueValues
    } = this.props
    const { rows, focusedRow } = this.state
    const rowsList =
      rows &&
      rows.map((row, index) => (
        <ArrayRow
          row={row}
          key={index}
          onRemoveRow={!disabled ? () => this.removeRow(index) : undefined}
          nestedStyle={nestedStyle}
        >
          {onCreateRow({
            row,
            index,
            focusedRow,
            onFocus: this.focus,
            onChangeRow: this.changeRow
          })}
        </ArrayRow>
      ))

    const addButtonEnabled =
      (!limit || rows.length < limit) &&
      (!uniqueValues || rows.length < options.length) &&
      !disabled &&
      !loading

    return (
      <div style={style} onBlur={this.blur}>
        <Label>
          {label && <label>{label}</label>}
          {tip && <Tooltip tip={tip} place={'right'} />}
          {
            onRefresh &&
            <Reload onClick={() => onRefresh()}>
              <Icon isLoading={!!loading} src={refresh} />
              Refresh
            </Reload>
          }
        </Label>
        <RowListGroup nestedStyle={nestedStyle} error={error}>
          {rowsList}
          <AddButton disabled={!addButtonEnabled} label={addButtonLabel} locale={locale} onClick={addButtonEnabled ? this.addRow : undefined} />
        </RowListGroup>
        {description && <Description>{description}</Description>}
      </div>
    )
  }
}

export default ArrayBox
