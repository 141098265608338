import path from './path'

const TYPE = `error@${path}`
export const ERROR = 'error'

export const error = () => ({
  type: TYPE
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      ...state,
      status: ERROR
    }
    : state
