import React, { Component } from 'react'
import ReactAce from 'react-ace'
import getValidationError from 'containers/Form/getValidationError'
import FieldError from 'containers/Form/FieldError'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

import 'brace'
import 'brace/mode/javascript'
import 'brace/mode/json'
import 'brace/theme/tomorrow'
import 'brace/ext/language_tools'

const DEFAULT_FORM_OFFSET = 240
const DEFAULT_EDITOR_HEIGHT = 300
const DEFAULT_EDITOR_WIDTH = '651px'

class AceEditor extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount () {
    this.updateDimensions()
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions () {
    const formOffsets = this.props.formOffsets || DEFAULT_FORM_OFFSET
    const availableHeight = window.document.documentElement.clientHeight - formOffsets
    const editorHeight = availableHeight > DEFAULT_EDITOR_HEIGHT ? availableHeight : DEFAULT_EDITOR_HEIGHT
    this.setState({
      height: `${editorHeight}px`
    })
  }

  render () {
    const { height } = this.state
    const {
      hasFocus,
      touched,
      isValid,
      validation,
      locale,
      value,
      label,
      mode,
      onChange,
      onFocus,
      onBlur,
      onTouch,
      onValidate,
      disabled = false,
      wrapEnabled = false
    } = this.props

    const translate = getTranslate(locale, { ru })

    const handleFocus = () => {
      if (!hasFocus && onFocus) {
        onFocus()
      }
      if (!touched && onTouch) {
        onTouch()
      }
    }

    const handleBlur = () => {
      if (hasFocus && onBlur) {
        onBlur()
      }
    }

    const handleChange = (value) => {
      onChange(value)
    }

    const handleValidate = annotations => {
      const hasAnnotations = !annotations.some(annotation => annotation.type === 'error' || annotation.raw === 'Unrecoverable syntax error.')
      if (isValid !== hasAnnotations) {
        onValidate(hasAnnotations)
      }
    }

    let error
    if (touched && !hasFocus) {
      error = !isValid
        ? translate('Fix all warnings, please')
        : validation && getValidationError(validation, value, locale)
    }

    let borderColor
    if (hasFocus) {
      borderColor = '#3bc569'
    } else if (error) {
      borderColor = 'red'
    } else {
      borderColor = '#e3e4e6'
    }
    return (
      <div>
        {label && <label>{label}</label>}
        <ReactAce
          mode={mode || 'javascript'}
          theme='tomorrow'
          fontSize={16}
          tabSize={2}
          enableLiveAutocompletion
          editorProps={{ $blockScrolling: true }}
          setOptions={{ showLineNumbers: false }}
          value={value}
          height={height}
          width={DEFAULT_EDITOR_WIDTH}
          style={{
            borderColor,
            borderWidth: '1px',
            borderStyle: 'solid',
            borderRadius: '8px'
          }}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onValidate={onValidate && handleValidate}
          onChange={handleChange}
          readOnly={disabled}
          wrapEnabled={wrapEnabled}
        />
        {error && <FieldError message={error} />}
      </div>
    )
  }
}

export default AceEditor
