import logo from './logo.png'
import sideLogo from './logo-side.png'
import { PRODUCT_RECOMMENDATION, EMAIL } from 'integrations/categories'
import { DIGITAL_DATA, EVENT, CONSTANT, PRODUCT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const config = {
  name: 'Retail Rocket',
  color: '#19addd',
  category: [PRODUCT_RECOMMENDATION, EMAIL],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/retail-rocket',
  form: [
    {
      label: 'Retail Rocket Partner ID',
      type: 'string',
      name: 'partnerId',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'useGroupView',
      defaultValue: false
    },
    {
      label: 'Track all emails',
      type: 'boolean',
      name: 'trackAllEmails'
    },
    {
      label: 'User ID property',
      type: 'string',
      name: 'userIdProperty'
    },
    {
      label: 'Recommendation algorythms methods',
      type: 'string mapping',
      name: 'listMethods'
    },
    {
      label: 'Product variables',
      type: 'variable mapping',
      name: 'productVariables',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT]
    },
    {
      label: 'Custom variables',
      type: 'variable mapping',
      name: 'customVariables',
      typeOptions: [DIGITAL_DATA, EVENT, CONSTANT]
    }
  ]
}

export default config
