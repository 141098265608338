import path from './path'
import { setHasUnpublishedChangesVariable } from 'utils/digitalDataVariables'

const ENABLE_PUBLISH = `enablePublish@${path}`

export const enablePublish = () => {
  setHasUnpublishedChangesVariable(true)
  return {
    type: ENABLE_PUBLISH
  }
}

export default (state = {}, action) =>
  action.type === ENABLE_PUBLISH
    ? {
      ...state,
      isPublished: false
    }
    : state
