import path from './path'
import { openedEvent } from 'utils/digitalDataEvents'
import { dismissTip } from 'redux/modules/project/dismissTip'

const OPEN = `open@${path}`

export const open = name => dispatch => {
  openedEvent(name, path)
  dispatch(dismissTip())
  dispatch({
    type: OPEN,
    name
  })
}

export default (state = {}, action) =>
  action.type === OPEN
    ? {
      ...state,
      isOpen: true,
      name: action.name
    }
    : state
