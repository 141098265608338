import React from 'react'
import getTranslate from 'utils/getTranslate'
import { Button } from './styles'
import ru from './ru.json'

const AddButton = ({ label, locale, onClick, disabled }) => {
  const translate = getTranslate(locale, { ru })
  return <Button disabled={disabled} onClick={onClick}>{label || translate('Add')}</Button>
}

export default AddButton
