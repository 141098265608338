import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { fetchProject, NO_REDIRECT } from 'redux/modules/project/fetchProject'
import InformationModal from 'containers/InformationModal'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import {
  DATA_SOURCES,
  CHECKOUT_SUCCESS,
  changePage
} from 'redux/modules/page/changePage'
import { waitForSubscriptionUpdate as apiWaitForSubscriptionUpdate } from 'api/billing-accounts'
import check from './check.svg'
import warning from './warning.svg'
import {
  CheckoutModal,
  Icon,
  Arrow,
  Loader
} from './styles'

const INITIALIZING = 'initializing'
const READY = 'ready'
const ERROR = 'error'

class CheckoutNotificationModal extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  async waitForSubscriptionUpdate () {
    const { projectId, onFetchProject } = this.props
    this.setState({ status: INITIALIZING })
    if (await apiWaitForSubscriptionUpdate(projectId)) {
      onFetchProject(projectId, NO_REDIRECT)
      this.setState({ status: READY })
    } else {
      this.setState({ status: ERROR })
    }
  }

  componentDidMount () {
    const { page, subscriptionExpiresAt } = this.props
    if (page === CHECKOUT_SUCCESS && (!subscriptionExpiresAt || new Date(subscriptionExpiresAt) <= new Date())) {
      this.waitForSubscriptionUpdate()
    } else {
      this.setState({ status: READY })
    }
  }

  render () {
    const {
      page,
      onChangePage
    } = this.props
    const { status } = this.state
    let statusMessage
    if (status === READY) {
      statusMessage = (
        <CheckoutModal>
          <Icon icon={check} />
          <h2>You have subscribed to SegmentStream</h2>
          <p>Subscription renewal funds will be charged automatically while the card is active and contains sufficient funds, otherwise the subscription will automatically end and data collection will be suspended.</p>
          <Arrow />
          <ConfirmButton onClick={() => onChangePage(DATA_SOURCES)}>Continue</ConfirmButton>
        </CheckoutModal>
      )
    } else if (status === INITIALIZING) {
      statusMessage = (
        <CheckoutModal>
          <Loader />
          <div>Updating Account</div>
        </CheckoutModal>
      )
    } else if (status === ERROR) {
      statusMessage = (
        <CheckoutModal>
          <Icon icon={warning} />
          <h2>Something went wrong!</h2>
          <p>We couldn't update your account, please contact support at <a
            style={{ color: '#4D6DD3' }} href='mailto:support@segmentstream.com' rel='noopener noreferrer'
            target='_blank'>support@segmentstream.com</a>.</p>
        </CheckoutModal>
      )
    }
    return (
      <InformationModal isOpen>
        {
          page === CHECKOUT_SUCCESS ? (
            statusMessage
          ) : (
            <div>
              <Icon icon={warning} />
              <h2>Payment has failed</h2>
              <p>Unfortunately, the payment could not be completed. Perhaps you've interrupted the payment process, or an error occurred in the payment system. Try completing the payment process again or contact support for more information.</p>
              <Arrow />
              <ConfirmButton onClick={() => onChangePage(DATA_SOURCES)}>Continue</ConfirmButton>
            </div>
          )
        }
      </InformationModal>
    )
  }
}

export default connect(
  state => {
    const { id: projectId, billingAccount } = state.project || {}
    const { subscriptionExpiresAt } = billingAccount || {}
    return {
      projectId,
      subscriptionExpiresAt,
      page: state.page.name,
      locale: state.locale
    }
  },
  {
    onChangePage: changePage,
    onFetchProject: fetchProject
  }
)(CheckoutNotificationModal)
