import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { closeModal, SCRIPTS, PENDING, SUCCESS } from 'redux/modules/proMode/modalWindow'
import { saveScripts } from 'redux/modules/project'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import SuccessMessage from 'containers/SuccessMessage'
import AceEditor from 'containers/AceEditor'
import Space from 'containers/Space'

class Scripts extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: this.props.value,
      touched: false
    }
    this.handleTouch = this.handleTouch.bind(this)
    this.handleSave = this.handleSave.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: nextProps.value
      })
    }
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen === false) {
      this.setState({
        value: nextProps.value,
        touched: false
      })
    }
  }

  handleTouch () {
    this.setState({ touched: true })
  }

  handleSave () {
    const touchedFields = this.state.touched
    this.props.onSave(this.state.value, touchedFields)
    this.setState({ touched: false })
  }

  handleChange (value) {
    this.setState({ value })
  }

  render () {
    const { touched, value } = this.state
    const { isOpen, onClose, status } = this.props
    return (
      <ModalWindow isOpen={isOpen} onClose={onClose} header='Scripts'>
        {status === SUCCESS && !touched && <SuccessMessage>Scripts was saved</SuccessMessage>}
        <AceEditor
          height='560px'
          style={{ marginBottom: '20px' }}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleTouch}
        />
        <Space />
        <ConfirmButton onClick={this.handleSave} pending={status === PENDING}>
          Save
        </ConfirmButton>
      </ModalWindow>
    )
  }
}

export default connect(
  state => {
    const { component, status } = state.proMode.modalWindow
    return {
      isOpen: component === SCRIPTS,
      value: state.project.scripts,
      status
    }
  },
  {
    onClose: closeModal,
    onSave: saveScripts
  }
)(Scripts)
