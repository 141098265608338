import path from './path'
import { savePriority as apiSavePriority } from 'api/project'
import { enablePublish } from 'redux/modules/project'
import { switchedPriorities } from 'utils/digitalDataEvents'

const TYPE = `save@${path}`

export const save = priority => (dispatch, getState) => {
  const projectId = getState().project.id
  dispatch(apiSavePriority(projectId, priority))
  dispatch({
    type: TYPE,
    priority
  })
  dispatch(enablePublish())
  switchedPriorities()
}

export default (state = {}, action) => (action.type === TYPE ? action.priority : state)
