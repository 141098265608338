export const EVENT = 'event'
export const CLICK = 'click'
export const IMPRESSION = 'impression'
export const SCROLL = 'scroll'
export const CHANGE = 'change'
export const ACTIVE_TIME_ON_SITE = 'activeTimeOnSite'
export const ACTIVE_TIME_ON_PAGE = 'activeTimeOnPage'
export const TIME_ON_SITE = 'timeOnSite'
export const TIME_ON_PAGE = 'timeOnPage'

export default {
  [EVENT]: 'Event',
  [CLICK]: 'Click',
  [IMPRESSION]: 'Impression',
  [SCROLL]: 'Scroll',
  [CHANGE]: 'Form field change',
  [ACTIVE_TIME_ON_SITE]: 'Active time on site',
  [ACTIVE_TIME_ON_PAGE]: 'Active time on page',
  [TIME_ON_SITE]: 'Time on site',
  [TIME_ON_PAGE]: 'Time on page'
}
