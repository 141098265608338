import AdRoll from './AdRoll'
import JSONFeed from './JSONFeed'
import GoogleAds from './GoogleAds'
import GoogleSheets from './GoogleSheets'
import GoogleCampaignManager from './GoogleCampaignManager'
import YandexDirect from './YandexDirect'
import YandexMarket from './YandexMarket'
import VK from './VK'
import Facebook from './Facebook'
import Criteo from './Criteo'
import myTarget from './myTarget'
import Recreativ from './Recreativ'
import Fixer from './Fixer'
import RTBHouse from './RTBHouse'
import AppMetrica from './AppMetrica'
import Calltouch from './Calltouch'
import GoogleAnalytics from './GoogleAnalytics'
import GoogleAnalytics4 from './GoogleAnalytics4'
import GoogleAnalytics360 from './GoogleAnalytics360'
import amoCRM from './amoCRM'
import retailCRM from './retailCRM'
import MicrosoftAdvertising from './MicrosoftAdvertising'
import AppsFlyer from './AppsFlyer'
import TikTok from './TikTok'
import LinkedIn from './LinkedIn'
import Quora from './Quora'
import Twitter from './Twitter'
import Salesforce from './Salesforce'
import Snapchat from './Snapchat'
import Hubspot from './Hubspot'
import Awin from './Awin'
import Reddit from './Reddit'
import Pipedrive from './Pipedrive'
import GoogleAds2 from './GoogleAds2'
import Pinterest from './Pinterest'
import MicrosoftDynamics365 from './MicrosoftDynamics365'
import SQLServer from './SQLServer'
import SQLServerAzure from './SQLServerAzure'
import SQLServerRDS from './SQLServerRDS'
import GoogleDisplayAndVideo360 from './GoogleDisplayAndVideo360'

// By request dataSources
// import AmazonAdvertising from './byRequest/AmazonAdvertising'
import Taboola from './byRequest/Taboola'
// import YouTube from './byRequest/YouTube'

const allDataSources = [
  GoogleAnalytics4,
  GoogleAnalytics360,
  GoogleAnalytics,
  GoogleAds,
  Facebook,
  GoogleSheets,
  Criteo,
  RTBHouse,
  GoogleCampaignManager,
  YandexDirect,
  YandexMarket,
  VK,
  myTarget,
  Calltouch,
  Recreativ,
  Fixer,
  AppMetrica,
  JSONFeed,
  amoCRM,
  retailCRM,
  MicrosoftAdvertising,
  AppsFlyer,
  TikTok,
  LinkedIn,
  Quora,
  Twitter,
  Salesforce,
  Snapchat,
  Hubspot,
  Awin,
  Reddit,
  Pipedrive,
  AdRoll,
  GoogleAds2,
  Pinterest,
  GoogleDisplayAndVideo360,
  MicrosoftDynamics365,
  SQLServer,
  SQLServerAzure,
  SQLServerRDS
]

const costsDataSources = [
  Facebook,
  VK,
  GoogleCampaignManager,
  YandexDirect,
  YandexMarket,
  GoogleAds,
  GoogleSheets,
  Criteo,
  myTarget,
  Recreativ,
  RTBHouse,
  MicrosoftAdvertising,
  TikTok,
  LinkedIn,
  Quora,
  Twitter,
  Snapchat,
  Reddit,
  AdRoll
]

const sandboxDataSources = []

const byRequestDataSources = [
  Taboola
]

export { allDataSources, sandboxDataSources, costsDataSources, byRequestDataSources }
