import reduceReducers from 'reduce-reducers'
import dataDestinationsForm from './dataDestinationsForm'
import createDataDestinationSide from './createDataDestinationSide'

import { save } from './save'

export { save }

export default reduceReducers((state = {}, action) => ({
  ...state,
  dataDestinationsForm: dataDestinationsForm(state.dataDestinationsForm, action),
  createDataDestinationSide: createDataDestinationSide(state.createDataDestinationSide, action)
}))
