import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'

export const THEME_FORM_ITEM = 'form_item'
export const THEME_FORM_ARRAY_ITEM = 'form_array_item'
export const THEME_FILTER = 'filter'

export const Wrapper = styled.div`
  position: relative;
  cursor: default;
  outline: none;
  width: 100%;
  ${props =>
    props.disabled &&
    css`
      opacity: 0.5;
    `}  
`

export const Input = styled.div`
  display: flex;
  justify-content: space-between;
  height: 60px;
  padding: 20px 20px 0 23px;
  line-height: 20px;
  border: 1px solid #e1e7ed;
  border-radius: 8px;
  border-color: ${props => (!props.error ? '#e1e7ed' : '#ff706b')};
  background-color: #fff;
  color: #1d2c4c;
  ${props =>
    props.isOpen &&
    css`
      border-radius: 8px 8px 0 0;
      border-bottom: none;
    `}
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      height: 40px;
      padding: 10px 17px 0 17px;
    `}
  ${props =>
    props.extendedView &&
  css`
      padding: ${props => props.isOpen ? '12px 12px 13px 23px' : '12px 12px 12px 23px'}; 
    `}    
`

export const SelectedValue = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  ${props =>
    props.extendedView &&
  css`
      display: flex;
      align-items: center;
    `}
`

export const Select = styled.div`
  position: absolute;
  margin-top: -1px;
  border: solid 1px #e3e4e6;
  border-top: none;
  width: 100%;
  overflow-y: scroll;
  max-height: 360px;
  background-color: #fff;
  z-index: 10;
  border-radius: 0 0 8px 8px;
  margin-bottom: 20px;
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      max-height: 500px;
    `}
`

export const Option = styled.div`
  padding: 12px 23px;
  min-height: 34px;
  line-height: 18px;
  white-space: nowrap;
  &:hover {
    background-color: #fafbfe;
  }
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      padding: 8px 17px;
    `}
`

export const OptionLabel = styled.div`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const ValueWrapper = styled.div`
  width: 100%;
`

export const Triangle = styled.div`
  margin-left: auto;
  margin-top: ${props => props.extendedView ? '10' : '4'}px;
  width: 4px;
  height: 4px;
  border: solid #cad3dc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  ${props =>
    props.theme === THEME_FILTER &&
    css`
      margintop: 5px;
      padding: 3px;
    `}
`

export const DynamicLabel = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const Icon = styled.img`
  width: 18px;
  height: 18px;
  margin: 0 8px 0 10px;
  ${props => props.isLoading ? css`
    animation-name: spin;
    animation-duration: 1000ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;

    @keyframes spin { 
        from { 
            transform: rotate(0deg); 
        } to { 
            transform: rotate(360deg); 
        }
    }
   ` : ''}
`

Icon.propTypes = {
  isLoading: PropTypes.bool
}

export const Description = styled.div`
  margin-bottom: 16px;
  width: 560px;
`

export const Reload = styled.div`
  display: flex;
  cursor: pointer;
`

export const ExtendedViewValue = styled.div`
  font-size: 12px;
  line-height: 12px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;  
`

export const Placeholder = styled.div`
  color: #808080;
`
