import React, { Component } from 'react'
import styled from 'styled-components'
import { connect } from 'react-redux'
import getTranslate from 'utils/getTranslate'
import { getEnabledFixerCurrencies } from 'utils/fixerUtils'
import { getFieldsUnavailableForPricingPlan } from 'utils/pricingPlans'
import FieldGenerator from 'containers/Form/FieldGenerator'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import DeleteButton from 'containers/Form/Button/DeleteButton'
import SuccessMessage from 'containers/SuccessMessage'
import ErrorMessage from 'containers/ErrorMessage'
import Space from 'containers/Space'
import ConfirmationWindow from 'containers/ConfirmationWindow'
import AlertWindow from 'containers/AlertWindow'

import getDisabledFields from './getDisabledFields'
import isValidForm from './isValidForm'
import getDefaultData from './getDefaultData'
import ru from './ru.json'
import { AuthError } from './OAuth2Button/styles'

export const SAVED = 'saved'
export const PENDING = 'pending'
export const FAILED = 'failed'

const LoaderContainer = styled.div`
  height: 75vh;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Loader = styled.div`
  margin: 20px 0;
  border: 8px solid #f3f3f3;
  border-top: 8px solid #4D6DD3;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

class Form extends Component {
  constructor (props) {
    super(props)
    this.state = {
      data: {},
      touchedFields: [],
      invalidFields: []
    }
    this.handleSave = this.handleSave.bind(this)
    this.handleRemove = this.handleRemove.bind(this)
    this.closeConfirmation = this.closeConfirmation.bind(this)
    this.confirmDelete = this.confirmDelete.bind(this)
    this.handleUpdateField = this.handleUpdateField.bind(this)
    this.handleTouch = this.handleTouch.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.validate = this.validate.bind(this)
    this.handleDisable = this.handleDisable.bind(this)
  }

  componentDidMount () {
    const { handleAuthorizationCheck } = this.props
    if (handleAuthorizationCheck) handleAuthorizationCheck()
  }

  UNSAFE_componentWillMount () { // eslint-disable-line
    const { form, compatibilityMap } = this.props
    let data = this.props.data || {}
    data = this.prefillFormGroupOptions(data, form)
    let formData = { ...getDefaultData(form), ...data }
    if (compatibilityMap && data) {
      formData = Object.keys(compatibilityMap)
        .filter(option => data[option])
        .reduce((acc, option) => {
          return { ...acc, ...compatibilityMap[option](formData) }
        }, formData)
    }
    this.setState({ data: formData })
  }

  prefillFormGroupOptions (data, form) {
    form.forEach(field => {
      if (field.type === 'group') {
        field.items
          .forEach(i => (data = {
            ...data,
            [field.name]: {
              ...data[field.name],
              [i.name]: field.flattenOptions
                ? (data[i.name] !== undefined ? data[i.name] : i.defaultValue)
                : (data[field.name] && data[field.name][i.name] !== undefined ? data[field.name][i.name] : i.defaultValue)
            }
          }))
      }
    })
    return data
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (nextProps.errorMessage && this.state.status) {
      this.setState({
        status: undefined
      })
    }
    // Reset data after switch form (Example: authForm -> form)
    if (nextProps.form) {
      let data = { ...getDefaultData(nextProps.form), ...nextProps.data }
      if (data) {
        data = this.prefillFormGroupOptions(data, nextProps.form)
      }
      this.setState({ data })
    }
  }

  handleSave (event) {
    event.preventDefault()
    const { form, pricingPlan } = this.props
    const invalidFields = this.state.invalidFields
    const disabledFields = getDisabledFields(form, this.state.data)
    const unavailableFields = getFieldsUnavailableForPricingPlan(form, pricingPlan)

    const data = Object.keys(this.state.data).reduce(
      (acc, val) => {
        if (!disabledFields.includes(val) && !unavailableFields.includes(val)) {
          const formField = form.find(f => f.name === val) || {}

          if (formField.type === 'group' && formField.items) {
            const formFieldValues = {}

            formField.items.forEach(i => {
              if (!disabledFields.includes(i.name) && !unavailableFields.includes(i.name)) {
                formFieldValues[i.name] = this.state.data[val][i.name]
              }
            })
            return formField.flattenOptions ? {
              ...acc,
              ...formFieldValues
            } : {
              ...acc,
              [val]: formFieldValues
            }
          } else {
            return {
              ...acc,
              [val]: this.state.data[val]
            }
          }
        } else {
          return acc
        }
      },
      {}
    )
    const isValid =
      isValidForm(form, data) && (invalidFields === undefined || invalidFields.length === 0)

    if (!isValid) {
      const allFields = form.map(field => field.name)
      this.setState({
        status: FAILED,
        touchedFields: allFields
      })
    } else {
      const touchedFields = !!this.state.touchedFields.length
      this.setState({
        touchedFields: [],
        status: PENDING
      })
      this.props.onSave(data, touchedFields)
    }
  }

  handleRemove (event) {
    event.preventDefault()
    this.setState({
      isConfirmationOpen: true
    })
  }

  closeConfirmation (event) {
    event.preventDefault()
    this.setState({
      isConfirmationOpen: false
    })
  }

  confirmDelete (event) {
    event.preventDefault()
    this.setState({
      removeStatus: PENDING,
      isConfirmationOpen: false
    })
    this.props.onRemove()
  }

  handleUpdateField (name, value, secondaryValue) {
    const data = {
      ...this.state.data,
      [name]: secondaryValue ? value.value : value
    }
    Object.assign(data, secondaryValue && { [secondaryValue]: value[secondaryValue] })
    this.setState({ data })
    if (this.props.onChange) {
      this.props.onChange(data)
    }
  }

  handleTouch (name) {
    const touchedFields = this.state.touchedFields
    if (!touchedFields.includes(name)) {
      this.setState({
        touchedFields: [...touchedFields, name],
        status: undefined
      })
    }
  }

  handleFocus (name) {
    this.setState({
      fieldWithFocus: name,
      status: undefined
    })
  }

  // add name to invalidFields
  // if value is undefined, remove from invalid field
  validate (name, value) {
    const invalidFields = this.state.invalidFields
    if (!value && !invalidFields.includes(name)) {
      this.setState({
        invalidFields: [...invalidFields, name]
      })
    } else if (value && invalidFields.includes(name)) {
      this.setState({
        invalidFields: invalidFields.filter(field => field !== name)
      })
    }
  }

  handleDisable (status) {
    this.setState({
      disableSave: status
    })
  }

  render () {
    const {
      locale,
      form,
      context,
      onSave,
      onRemove,
      errorMessage,
      onCloseErrorMessage,
      deleteMessage,
      dataSources,
      pricingPlan,
      loading
    } = this.props
    const {
      data,
      invalidFields,
      fieldWithFocus,
      touchedFields,
      status,
      removeStatus,
      isConfirmationOpen,
      disableSave
    } = this.state
    if (loading) return <LoaderContainer><Loader /></LoaderContainer>

    const { authenticationFailMessage } = context || {}

    const translate = getTranslate(locale, { ru })
    const disabledFields = getDisabledFields(form, data)
    const fields = form.map((field, index) => {
      if (field.optionsSource === 'Fixer') {
        getEnabledFixerCurrencies(dataSources).forEach(currency => {
          if (!field.options.map(option => option.label).includes(currency)) {
            field.options.push({
              label: currency,
              value: currency
            })
          }
        })
      }
      const hidden = disabledFields && disabledFields.includes(field.name)
      const unavailableFields = getFieldsUnavailableForPricingPlan(form, pricingPlan)
      const unavailableForPricingPlan = unavailableFields && unavailableFields.includes(field.name)
      if (hidden || unavailableForPricingPlan) {
        return null
      }

      const dependentFieldValues = (field.dependsOn || []).reduce((acc, value) => {
        const fieldValue = context.formData[value]
        return { ...acc, [value]: fieldValue }
      }, {})

      const disabled = Object.values(dependentFieldValues)
        .find(dependentFieldValue => dependentFieldValue === undefined)

      if (!disabled && field.dependsOn && !!field.dependsOn.length) {
        context.dependentFieldValues = dependentFieldValues
      }

      field.disabled = disabled || field.disabled

      return (
        <div key={field.name}>
          {index !== 0 && <Space />}
          <FieldGenerator
            context={context}
            locale={locale}
            field={field}
            data={data}
            value={data[field.name]}
            hasFocus={field.name === fieldWithFocus}
            touched={touchedFields && touchedFields.includes(field.name)}
            isValid={!invalidFields || !invalidFields.includes(field.name)}
            onChange={this.handleUpdateField}
            onFocus={this.handleFocus}
            onTouch={this.handleTouch}
            onValidate={this.validate}
            disabledFields={disabledFields}
            unavailableFields={unavailableFields}
            disableFormSave={this.handleDisable}
          />
        </div>
      )
    })

    const authenticationFailMessageMarkup = () => ({ __html: authenticationFailMessage })

    return (
      <form>
        {status === SAVED && touchedFields.length === 0 && (
          <SuccessMessage>{translate('Saved successfully')}</SuccessMessage>
        )}
        {status === FAILED && (
          <ErrorMessage>{translate('There are some validation errors')}</ErrorMessage>
        )}
        {authenticationFailMessage && (
          <AuthError dangerouslySetInnerHTML={authenticationFailMessageMarkup()} />
        )}
        {fields}
        <Space />
        {onSave && (
          <ConfirmButton type='button' onClick={this.handleSave} pending={status === PENDING} disabled={disableSave}>
            Save
          </ConfirmButton>
        )}
        {onRemove && (
          <DeleteButton
            type='button'
            style={{ marginLeft: '10px' }}
            onClick={this.handleRemove}
            pending={removeStatus === PENDING}
          >
            Remove
          </DeleteButton>
        )}
        {onRemove && (
          <ConfirmationWindow
            messageText={deleteMessage}
            confirmButtonText={translate('Yes')}
            cancelButtonText={translate('No')}
            isOpen={isConfirmationOpen}
            onClose={this.closeConfirmation}
            onConfirm={this.confirmDelete}
            dangerZone
          />
        )}
        <AlertWindow message={errorMessage} onClose={onCloseErrorMessage} />
      </form>
    )
  }
}

export default connect(state => ({
  projectName: state.project.name,
  dataSources: state.dataSources,
  pageName: state.page.name,
  pricingPlan: (state.project.billingAccount || {}).pricingPlan
}))(Form)
