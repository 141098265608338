import styled from 'styled-components'
import plus from './plus.png'

export const DataDestination = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  vertical-align: top;
  width: 177px;
  height: 177px;
  margin: 0 23px 23px 0;
  padding: 0 10px 22px;
  box-sizing: border-box;
  border: 1px solid #e1e7ed;
  border-radius: 15px;
  cursor: pointer;
  background: #fff;
  background-size: contain;
  text-align: center;
  &:hover {
    box-shadow: 0 7px 10px 0 rgba(24, 88, 196, 0.12);
  }
`

export const ItemContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  margin-right: -23px;
`

export const LogoContainer = styled.div`
  width: 100%;
  height: 120px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`

export const Title = styled.div`
  font-size: 16px;
  color: #0c1533;
`

export const Name = styled.div`
  font-size: 14px;
`

export const Signal = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const Plus = styled.div`
  width: 15px;
  height: 15px;
  background: url(${plus}) no-repeat;
  background-size: 15px 15px;
`

export const OnboardingContainer = styled.div`
  min-height: calc(100vh - 164px);
  display: flex;
  align-items: center;
  justify-content: center;
`
