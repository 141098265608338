import { remove as apiRemove } from 'api/dataDestination'
import path from './path'
import { deletedEvent } from 'utils/digitalDataEvents'

const REMOVE = `remove@${path}`

export const remove = (id, type) => async (dispatch, getState) => {
  const projectId = getState().project.id
  deletedEvent(type, path)
  await dispatch(apiRemove(projectId, id))
  dispatch({
    type: REMOVE,
    id
  })
}

export default (state = {}, action) => {
  if (action.type === REMOVE) {
    return Object.keys(state).reduce((acc, dataDestinationId) => {
      const dataDestination = state[dataDestinationId]
      if (action.id !== dataDestination.id) {
        acc[dataDestination.id] = dataDestination
      }
      return acc
    }, {})
  }
  return state
}
