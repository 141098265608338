import React, { useContext } from 'react'

import { dataStreamsContext } from '../Context'
import { DataStreamsList } from '../Components/DataStreamsList'

export function ListContainer ({ integrations, onIntegrationOpen }) {
  const { create, list } = useContext(dataStreamsContext)

  const handleAddDataStreamClicked = () => create.open()

  return (
    <DataStreamsList
      list={list}
      onAddClicked={handleAddDataStreamClicked}
      integrations={integrations}
      onIntegrationOpen={onIntegrationOpen}
    />
  )
}
