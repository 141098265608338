import styled, { css } from 'styled-components'

export const Description = styled.div`
  display: block;
  padding-top: 10px;
  font-size: 14px;
  line-height: 16px;
  color: #727272;
`

export const RowListGroup = styled.div`
  width: 100%;
  border-radius: 8px;
  background-color: #fff;
  padding: 7px 49px 22px 19px;
  border: 1px solid #e1e7ed;
  margin-bottom: 21px;
  ${props =>
    props.nestedStyle &&
    css`
      background-color: transparent;
      padding: 0;
      border: none;
    `}

  border-color: ${props => (!props.error ? '#e1e7ed' : '#ff706b')};
`

export const Label = styled.div`
  display: flex;
  margin-bottom: 16px;
`
