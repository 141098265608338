import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const config = {
  name: 'Facebook Pixel',
  color: '#39579a',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/facebook',
  form: [
    {
      label: 'Facebook Pixel ID',
      type: 'string',
      name: 'pixelId',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      defaultValue: false
    },
    {
      label: 'Use product price as event value',
      type: 'boolean',
      name: 'usePriceAsEventValue',
      defaultValue: false
    },
    {
      label: 'Custom events',
      type: 'string mapping',
      name: 'customEvents',
      placeholderKey: 'DigitalData event',
      placeholderValue: 'Facebook event'
    },
    {
      label: 'Event parameters',
      type: 'variable mapping',
      name: 'eventParameters',
      typeOptions: [DIGITAL_DATA, EVENT]
    }
  ]
}

export default config
