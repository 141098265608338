import React from 'react'

import { dataStreamForms } from '../Forms'

export function DataStreamForm ({
  dataStreamConfiguration,
  dataStreamOptions,
  onSubmit,
  onRemove,
  trackingBaseUri
}) {
  const dataStreamForm = dataStreamForms[dataStreamConfiguration.type]

  if (!dataStreamForm) {
    return null
  }

  return (
    <React.Fragment>
      { React.createElement(dataStreamForm, {
        dataStream: dataStreamOptions || {},
        onSubmit: onSubmit,
        onRemove: onRemove,
        trackingBaseUri
      }) }
    </React.Fragment>
  )
}
