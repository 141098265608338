import React, { useCallback } from 'react'
import { connect } from 'react-redux'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

import { publish, PENDING } from 'redux/modules/page/publish'
import { RESTRICTED_FEATURE_MODAL, DATA_SOURCES, DATA_DESTINATIONS, DATA_TRANSFORMATIONS, DATA_STREAMS, openModal } from 'redux/modules/blueHeader/modalWindow'
import getTranslate from 'utils/getTranslate'
import { changePage } from 'redux/modules/page/changePage'
import PublishButton from './PublishButton'
import { Container, Menu, MenuItem, Lock, Beta } from './styles'
import menuConfig from './menuConfig'
import { isProMode } from 'utils/proMode'
import ru from './ru.json'

const SMALL_SCREEN_WIDTH = 1440

const SideMenu = ({
  locale,
  publishStatus,
  page,
  advancedLevelOfSecurity,
  onPublish,
  isPublished = false,
  isProMode,
  saleMode,
  reports,
  onChangePage,
  onOpenModal,
  isForceAllow,
  project,
  isUserSuperAdmin
}) => {
  const translate = getTranslate(locale, { ru })
  const menuItems = menuConfig(
    advancedLevelOfSecurity,
    isProMode,
    saleMode,
    isUserSuperAdmin,
    reports,
    onChangePage,
    project
  ).items
  const reportsPage = page && page.includes('-report')
  const smallScreen = window.document.documentElement.clientWidth <= SMALL_SCREEN_WIDTH

  const createRestrictedModalOpenerFor = useCallback((page) => () => {
    if (!page) return
    const feature = mapPageNameToRestrictionConstant[page]
    if (feature) onOpenModal(RESTRICTED_FEATURE_MODAL, feature)
  }, [onOpenModal])

  const isFeatureEnabled = useCallback(feature => {
    return (project.features || []).includes(feature)
  }, [project.features])

  const isFeatureOfPageEnabled = useCallback(page => {
    if (!page) return
    const feature = mapPageNameToProjectFeature[page]
    if (!feature) return true
    return isFeatureEnabled(feature)
  }, [isFeatureEnabled])

  return (
    <Container proMode={isProMode} compact={reportsPage && smallScreen}>
      <Menu compact={reportsPage && smallScreen}>
        {
          isFeatureEnabled(ProjectFeature.Tracking) &&
            <PublishButton
              pending={publishStatus === PENDING}
              onPublish={onPublish}
              isPublished={isPublished}
              locale={locale}
              compact={reportsPage && smallScreen}
            />
        }
        {menuItems
          .filter(menuItem => !menuItem.hidden)
          .map(menuItem => {
            return (
              // <MenuGroup key={menuItem.label} compact={reportsPage && smallScreen}>
              //   <MenuGroupLabel thumbnail={menuItem.thumbnail} key={menuItem.label}>
              //     {translate(menuItem.label)}
              //   </MenuGroupLabel>
              <>
                {menuItem.items
                  .filter(subItem => !subItem.hidden)
                  .map(subItem => {
                    const isSubItemAvailable = isFeatureOfPageEnabled(subItem.page)

                    return (
                      <MenuItem
                        key={subItem.label}
                        active={!subItem.preventActiveState && page === subItem.page}
                        compact={reportsPage && smallScreen}
                        onClick={
                          isSubItemAvailable || isForceAllow
                            ? subItem.onClick
                            : createRestrictedModalOpenerFor(subItem.page)}
                      >
                        {translate(subItem.label)} {!isSubItemAvailable && (<Lock />)}
                        {subItem.beta ? <Beta>β</Beta> : null}
                      </MenuItem>
                    )
                  })}
                  </>
            // </MenuGroup>
            )
          })}
      </Menu>
    </Container>
  )
}

export default connect(
  state => ({
    page: state.page.name,
    isPublished: state.project.isPublished,
    publishStatus: state.page.publishStatus,
    locale: state.locale,
    advancedLevelOfSecurity: state.project.advancedLevelOfSecurity,
    isProMode: isProMode(state.proMode.status, state.user.role),
    saleMode: state.saleMode.status,
    reports: state.project.options && state.project.options.reports,
    // Book24 and super_admin
    isForceAllow: state.user.role === 'super_admin' && isProMode(state.proMode.status, state.user.role),
    isUserSuperAdmin: state.user.role === 'super_admin',
    project: state.project
  }),
  {
    onPublish: publish,
    onChangePage: changePage,
    onOpenModal: openModal
  }
)(SideMenu)

const mapPageNameToRestrictionConstant = {
  dataSources: DATA_SOURCES,
  dataDestinations: DATA_DESTINATIONS,
  dataTransformations: DATA_TRANSFORMATIONS,
  dataStreams: DATA_STREAMS
}

const mapPageNameToProjectFeature = {
  dataSources: ProjectFeature.DataSources,
  dataDestinations: ProjectFeature.DataDestinations,
  dataTransformations: ProjectFeature.DataTransformations,
  dataStreams: ProjectFeature.DataStreams
}
