import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import countriesList from 'containers/App/SignUp/countries'
import { fetchCountryCode } from 'api/utils'

import {
  SegmentStreamLogo,
  ConfirmButton
} from '../Login/styles'
import {
  FormWrapper,
  HeaderWrapper,
  Header
} from './styles'
import { CloseButton } from '../ProjectCreation/styles'
import { StyledInput } from 'containers/Form/Input/styles'

import { changePage, PROJECT_CREATION } from 'redux/modules/page/changePage'
import { updateUser } from 'redux/modules/user/updateUser'
import { logout } from 'redux/modules/auth'
import { useCreateBillingAccountMutation } from './createBillingAccountGraphQL'

import { registeredEvent } from 'utils/digitalDataEvents'
import { handleHubSpotFormSubmit } from 'utils/hubspot'

import * as Yup from 'yup'
import { Formik } from 'formik'
import { FormikFieldContainer } from 'uiKit/formik'
import { FormikSearchableSelect } from 'uiKit/formik/formikSearchableSelect'

const SENDING = 'sending'
const FAILED = 'failed'
const SUCCESS = 'success'

function CreateBillingAccount ({ user, onSubmitForm, onChangePage, onLogout }) {
  const [countryCode, setCountryCode] = useState('')
  const [company] = useState('')
  const [status, setStatus] = useState(null)
  const [createBillingAccount] = useCreateBillingAccountMutation()

  useEffect(() => {
    fetchCountryCode()
      .then(countryCode => {
        setCountryCode(countryCode)
      })
  })

  const initialValues = {
    company,
    countryCode
  }

  const validationSchema = Yup.object().shape({
    company: Yup.string()
      .required('Company name is required'),

    countryCode: Yup.string()
      .required('Country is required')
  })

  const onFormSubmit = async (values) => {
    setStatus(SENDING)

    try {
      const billingAccountResponse = await createBillingAccount({ variables: values })
      const billingAccount = billingAccountResponse.data.createBillingAccount

      setStatus(SUCCESS)
      await onSubmitForm()
      registeredEvent(user, billingAccount)
      handleHubSpotFormSubmit(user, billingAccount)

      onChangePage(PROJECT_CREATION)
    } catch (e) {
      console.log(e)
      setStatus(FAILED)
    }
  }

  return (
    <div>
      <CloseButton onClick={() => onLogout()} />
      <FormWrapper>
        <SegmentStreamLogo />

        <HeaderWrapper>
          <Header>
            In order to create your first project, please, activate your account by defining your billing country and company name.
          </Header>
        </HeaderWrapper>

        <Formik
          initialValues={initialValues}
          enableReinitialize
          onSubmit={onFormSubmit}
          validationSchema={validationSchema}
        >
          { props => {
            const { handleSubmit } = props
            return (
              <React.Fragment>
                <form onSubmit={handleSubmit}>
                  <FormikFieldContainer
                    style={{ marginBottom: '20px' }}
                    label='Company'
                    name='company'
                  >
                    <StyledInput placeholder='Enter your company name' type='text' />
                  </FormikFieldContainer>

                  <FormikFieldContainer
                    style={{ marginBottom: '20px' }}
                    name='countryCode'
                    label='Billing Country'
                    tip={
                      `Important! Please, define a billing country. It is required to define billing<br />
                      currency, calculate sales tax and send proper invoices for your accounting<br />
                      once you decide to buy a subscription after the free trial`
                    }
                  >
                    <FormikSearchableSelect
                      placeholder='Billing country'
                      options={countriesList.map(v => ({ label: v.name, value: v.alpha2 }))}
                    />
                  </FormikFieldContainer>

                  <ConfirmButton
                    type='submit'
                    pending={status === SENDING}
                  >
                    Create Billing Account
                  </ConfirmButton>
                </form>
                {status === FAILED && (
                  <div style={{ textAlign: 'center', marginTop: '20px' }}>
                    Something went wrong, please contact support at <a style={{ color: '#4D6DD3' }} href='mailto:support@segmentstream.com' rel='noopener noreferrer' target='_blank'>support@segmentstream.com</a>.
                  </div>
                )}
              </React.Fragment>
            )
          }}
        </Formik>
      </FormWrapper>
    </div>
  )
}

export default connect(
  state => ({
    user: state.user
  }),
  {
    onSubmitForm: updateUser,
    onChangePage: changePage,
    onLogout: logout
  }
)(CreateBillingAccount)
