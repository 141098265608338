import styled from 'styled-components'

export const Content = styled.div`
  margin: 0 auto;
  width: 1177px;

  @media (max-width: 1680px) {
    width: 977px;
  }
  @media (max-width: 1300px) {
    width: 777px;
  }
  @media (max-width: 1050px) {
    min-width: 777px;
  }
`

export const Container = styled.div`
  margin-top: 36px;
  padding-bottom: 150px;
  min-width: 777px;
  width: 100%;
  overflow: hidden;
`
