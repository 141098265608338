import path from './path'

const ENABLE_TIP = `enableTip@${path}`

export const enableTip = (name, tipType) => {
  return {
    type: ENABLE_TIP,
    name,
    tipType
  }
}

export default (state = {}, action) =>
  action.type === ENABLE_TIP
    ? {
      ...state,
      enabledTips: [...state.enabledTips, {
        name: action.name,
        type: action.tipType
      }]
    }
    : state
