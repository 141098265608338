import React from 'react'
import { connect } from 'react-redux'
import InformationModal from 'containers/InformationModal'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import { RESTRICTED_FEATURE_MODAL, DATA_DESTINATIONS, DATA_TRANSFORMATIONS, BIG_QUERY, DATA_SOURCES, DATA_STREAMS, closeModal } from 'redux/modules/blueHeader/modalWindow'
import SendRequestForm from '../SendRequestForm'
import dataSourceImage from './data-source.png'
import dataTransformationsImage from './data-transformations.png'
import dataDestinationsImage from './data-destinations.png'
import bigQueryImage from './bigquery.png'

class RestrictedFeatureModal extends React.PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      step: 1
    }

    this.onRequestAccess = this.onRequestAccess.bind(this)
    this.onClose = this.onClose.bind(this)
  }

  onRequestAccess () {
    this.setState({ step: 2 })
  }

  onClose () {
    const { onCloseModal } = this.props
    this.setState({ step: 1 })
    onCloseModal()
  }

  render () {
    const { isOpened, locale, feature } = this.props
    const { step } = this.state
    const translate = getTranslate(locale, { ru })

    let featureName
    let firstContent

    if (feature === DATA_DESTINATIONS) {
      featureName = 'Data Destinations'
      firstContent = (
        <div>
          <img alt='' src={dataDestinationsImage} width='247' height='93' style={{ marginBottom: '20px' }} />
          <h2>{ translate('Unlock the possibility to send data from your Google BigQuery back to the marketing tools!') }</h2>
          <p>{ translate('Send customer segments to advertising platforms for better targeting, or enable automatic bid adjustments based on the custom attribution model.') }</p>
          <p>{ translate('Get in touch with our sales team to learn more.') }</p>
          <a href='https://meetings.hubspot.com/segmentstream/feature-unlock' target='_blank' rel='noopener noreferrer'>
            <ConfirmButton style={{ marginTop: '20px' }} onClick={this.onClose}>{ translate('Request Access') }</ConfirmButton>
          </a>
        </div>
      )
    } else if (feature === DATA_SOURCES) {
      featureName = 'Data Import'
      firstContent = (
        <div>
          <img alt='' src={dataSourceImage} width='347' height='101' style={{ marginBottom: '20px' }} />
          <h2>{translate('Collect all your advertising & CRM data to Google BigQuery')}</h2>
          <p>{translate('Connect your advertising networks to automatically collect marketing data to your Google BigQuery: clicks, costs, impressions and more. As well, to enrich your Google BigQuery with the data from your CRM.')}</p>
          <p>{translate('Get in touch with our product specialist to learn more:')}</p>
          <ConfirmButton style={{ marginTop: '20px' }} onClick={this.onRequestAccess}>{translate('Request Access')}</ConfirmButton>
        </div>
      )
    } else if (feature === BIG_QUERY) {
      featureName = 'BigQuery Integration'
      firstContent = (
        <div>
          <img alt='' src={bigQueryImage} width='347' height='97' style={{ marginBottom: '20px' }} />
          <h2>{ translate('Stream all your website events into your Google BigQuery') }</h2>
          <p>{ translate('Automatically collect real-time events on your website in your Google BigQuery data warehouse. Without any sampling, limits and delays.') }</p>
          <p>{ translate('Get in touch with our product specialist to learn more:') }</p>
          <ConfirmButton style={{ marginTop: '20px' }} onClick={this.onRequestAccess}>{ translate('Request Access') }</ConfirmButton>
        </div>
      )
    } else if (feature === DATA_TRANSFORMATIONS) {
      featureName = 'Data Transformations'
      firstContent = (
        <div>
          <img alt='' src={dataTransformationsImage} width='98' height='79' style={{ marginBottom: '20px' }} />
          <h2>{ translate('Unlock data transformations and attribution!') }</h2>
          <p>{ translate('The real value of the marketing data is obtained when it is cleaned, mapped, segmented and transformed into dimensions and metrics that make sense for your business.') }</p>
          <p>{ translate('Get in touch with our sales team to learn more.') }</p>
          <a href='https://meetings.hubspot.com/segmentstream/feature-unlock' target='_blank' rel='noopener noreferrer'>
            <ConfirmButton style={{ marginTop: '20px' }} onClick={this.onClose}>{ translate('Request Access') }</ConfirmButton>
          </a>
        </div>
      )
    } else if (feature === DATA_STREAMS) {
      featureName = 'Data Streams'
      firstContent = (
        <div>
          <img alt='' src={bigQueryImage} width='347' height='97' style={{ marginBottom: '20px' }} />
          <h2>Collect real-time events <br />into your Google BigQuery</h2>
          <p>Automatically collect real-time events or webhooks<br /> in your Google BigQuery data warehouse.<br /> Without any sampling, limits and delays.</p>
          <p>Get in touch with our product specialist to learn more:</p>
          <ConfirmButton style={{ marginTop: '20px' }} onClick={this.onRequestAccess}>{ translate('Request Access') }</ConfirmButton>
        </div>
      )
    }

    return (
      <InformationModal isOpen={isOpened} onClose={this.onClose}>
        {step === 1 ? firstContent : (
          <SendRequestForm onClose={this.onClose} feature={featureName} />
        )}
      </InformationModal>
    )
  }
}

export default connect(
  state => ({
    locale: state.locale,
    isOpened: state.blueHeader.modalWindow.component === RESTRICTED_FEATURE_MODAL,
    feature: state.blueHeader.modalWindow.feature
  }),
  { onCloseModal: closeModal }
)(RestrictedFeatureModal)
