import React from 'react'
import Modal from 'react-modal'
import { CloseButton, modalContentStyle, modalOverlayStyle } from './styles'

const style = {
  content: modalContentStyle,
  overlay: modalOverlayStyle
}

class InformationModal extends React.PureComponent {
  render () {
    const { isOpen, children, onClose, featureRequest } = this.props
    const informationModalStyle = featureRequest ? {
      content: {
        ...modalContentStyle,
        width: '787px'
      },
      overlay: modalOverlayStyle
    } : style
    return (
      <Modal shouldCloseOnOverlayClick={!!onClose}
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel='Information modal'
        style={informationModalStyle}
      >
        {!!onClose && <CloseButton onClick={onClose} />}
        <div>
          {children}
        </div>
      </Modal>
    )
  }
}

export default InformationModal
