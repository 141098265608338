import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  &:hover {
    cursor: ${props => (props.enableHover ? 'pointer' : 'auto')};
  }
`

export const Option = styled.div`
  font-size: 16px;
  user-select: none;
  display: flex;
  min-width: 140px;
  max-width: 240px;
`

export const OptionLabel = styled.div`
  min-width: 120px;
  max-width: 220px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const Triangle = styled.div`
  margin-left: 10px;
  margin-top: 8px;
  width: 2px;
  height: 2px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
  float: right;
  ${props =>
    props.rotated &&
    css`
      transform: rotate(225deg);
      margin-top: 9px;
    `}
`

export const Box = styled.div`
  cursor: pointer;
  max-height: 300px;
  border: solid 1px #e3e4e6;
  border-radius: 0 0 3px 3px;
  border-top: 0;
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 10px;
  z-index: 1;
  overflow: scroll;
  color: #1d2c4c;
`

export const OptionDropdown = styled.div`
  position: absolute;
  cursor: pointer;
  min-width: 300px;
  top: 30px;
  z-index: 1;
  & > div:first-child {
    border-top: 1px;
    border-radius: 3px;
  }
`

export const BoxLine = styled.div`
  color: #707479;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  white-space: nowrap;
  background-color: ${props => (props.selected ? '#fafafb' : 'none')};
  &:hover {
    background-color: #e8e8e8;
  }
`

export const Title = styled.div`
  font-size: 10px;
  line-height: 12px;
  color: rgba(255, 255, 255, 0.5);
`

export const SearchSelectWrapper = styled.div`
  padding: 0 20px;
  border-right: 1px solid rgba(255, 255, 255, 0.3);
  &:last-child {
    border: none;
  }  
`
