import { saveAll } from './saveAll'
import { close } from './close'
import uuid from 'uuid'

export const save = enrichment => async (dispatch, getState) => {
  const name = getState().page.integrations.integrationForm.name
  const eventEnrichments = getState().integrations[name].eventEnrichments || []
  let list = []
  if (!enrichment.id) {
    // create
    const id = uuid.v4()
    list = [
      ...eventEnrichments,
      {
        ...enrichment,
        id
      }
    ]
  } else {
    // update
    list = eventEnrichments.map(item => (item.id === enrichment.id ? enrichment : item))
  }
  await dispatch(saveAll(name, list))
  dispatch(close())
}
