import React from 'react'
import { connect } from 'react-redux'
import { create } from 'redux/modules/dataDestinations'
import IntegrationLogo from 'containers/IntegrationLogo'
import { DataDestination, Name, Signal, Plus, LogoContainer } from '../styles'

const DataDestinationItem = ({ dataDestination, onCreate }) => {
  const handleCreate = () => {
    onCreate({ type: dataDestination.type })
  }

  return (
    <DataDestination onClick={handleCreate}>
      <LogoContainer>
        <IntegrationLogo logo={dataDestination.logo} />
        <Signal>
          <Plus />
        </Signal>
      </LogoContainer>
      <Name>{dataDestination.type}</Name>
    </DataDestination>
  )
}

export default connect(
  null,
  { onCreate: create }
)(DataDestinationItem)
