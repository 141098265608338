import styled from 'styled-components'

export const Switch = styled.div`
  position: relative;
  width: 171px;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #e3e4e6;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
  user-select: none;
`

export const Box = styled.div`
  padding: 12px 0 12px;
  cursor: pointer;
  color: #1d2c4c;
  font-size: 16px;
`

export const Wrapper = styled.div`
  position: absolute;
  top: 16px;
  left: 13px;
`

export const Triangle = styled.div`
  position: absolute;
  top: 16px;
  right: 12px;
  border: solid #cad3dc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  transform: ${props => (props.rotated ? 'rotate(225deg)' : 'rotate(45deg)')};
`

export const Switcher = styled.div`
  position: absolute;
  width: 171px;
  bottom: -37px;
  left: -1px;
  padding: 13px 0 13px;
  border: 1px solid #e3e4e6;
  background-color: #fff;
  border-bottom-left-radius: 3px;
  border-bottom-right-radius: 3px;
  border-top: none;
  box-sizing: border-box;
  font-size: 16px;
  line-height: 18px;
  cursor: pointer;

  &:hover {
    background-color: #f5f5f5;
  }

  display: ${props => (props.open ? 'block' : 'none')};
`
