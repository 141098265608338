import styled from 'styled-components'
import help from './help.svg'

export const Help = styled.div`
  width: 48px;
  height: 18px;
  background: url(${help}) no-repeat center;
  background-size: 18px 18px;
  margin-left: -5px;
  margin-right: -5px;
`
