import logo from './logo.svg'

const config = {
  type: 'SQL Server',
  enabledForGoogleAnalyticsProjects: false,
  poweredByFivetran: true,
  betaFeature: true,
  logo,
  authForm: [
    {
      label: 'Click to connect SQL Server via Fivetran',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: logo
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My SQL Server',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    }
  ]
}

export default config
