import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import { open } from 'redux/modules/page/dataDestinations/createDataDestinationSide/open'
import { allDataDestinations, sandboxDataDestinations } from 'dataDestinations'
import getTranslate from 'utils/getTranslate'
import DataDestinationsForm from './DataDestinationsForm'
import CreateDataDestinationSide from './CreateDataDestinationSide'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import DataDestinationItem from './DataDestinationItem'
import DataDestinationOnboarding from './DataDestinationOnboarding'
import { ItemContainer, OnboardingContainer } from './styles'
import OnboardingPlaceholder from '../OnboardingPlaceholder'
import { open as integrationOpen } from 'redux/modules/page/integrations/integrationForm'
import IntegrationSide from '../Integrations/IntegrationSide'
import bq from './bq.png'
import ru from './ru'
import { createDatasetLink } from 'utils/bigQueryHelpers'
import LinkButton from 'uiKit/LinkButton'
import EnabledStatusButton from 'uiKit/EnabledStatusButton'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

const DDMANAGER_STREAMING_INTEGRATION = 'DDManager Streaming'

const DataDestinationsList = ({ dataDestinations, integrations, openCreateDataDestinationSide, locale, onIntegrationOpen, isSaleMode, features }) => {
  const streamingIntegration = integrations[DDMANAGER_STREAMING_INTEGRATION]

  const availableDataDestinations = (features || []).includes(ProjectFeature.BetaFeatures) ? allDataDestinations : allDataDestinations.filter(dd => !dd.betaFeature)
  const shownDataDestinations = isSaleMode ? [...availableDataDestinations, ...sandboxDataDestinations] : availableDataDestinations

  const translate = getTranslate(locale, { ru })
  const confirmButton = (
    <ConfirmButton onClick={openCreateDataDestinationSide}>Add Data Destination</ConfirmButton>
  )

  const streamingConfig = (
    <div style={{ display: 'flex' }}>
      {
        streamingIntegration && <LinkButton text='View BigQuery dataset' link={createDatasetLink(streamingIntegration)} />
      }
      <EnabledStatusButton text='BigQuery enabled' handleClick={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }} />
    </div>
  )

  return (
    <div>
      <IntegrationSide displayMode={'simple'} />
      {(!streamingIntegration || !streamingIntegration.isEnabled)
        ? (
          <OnboardingContainer>
            <OnboardingPlaceholder
              title={translate('To export data, please connect your BigQuery account first')}
              icon={bq}
              showArrow
              buttonTitle={translate('Enable BigQuery integration')}
              buttonAction={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }}
              linkTitle='Getting Started Tutorial ▸'
              linkUrl='https://docs.segmentstream.com/bigquery/overview'
            />
          </OnboardingContainer>
        ) : (
          <div>
            {Object.keys(dataDestinations).length ? (
              <SectionHeader leftContent={confirmButton} rightContent={streamingConfig} />
            ) : (
              <OnboardingContainer>
                <DataDestinationOnboarding onAddButtonClick={openCreateDataDestinationSide} />
              </OnboardingContainer>
            )}
            <ItemContainer>
              {Object.keys(dataDestinations).map(dataDestinationId => {
                const dataDestination = dataDestinations[dataDestinationId]
                const dataDestinationType = dataDestination.type
                const dataDestinationConfig = shownDataDestinations.find(
                  ds => ds.type === dataDestinationType
                )
                const dataDestinationTitle = (dataDestination.options || {}).title
                if (dataDestinationConfig) {
                  return (
                    <DataDestinationItem
                      title={dataDestinationTitle}
                      id={dataDestination.id}
                      key={dataDestination.id}
                      dataDestinationConfig={dataDestinationConfig}
                      isEnabled={dataDestination.isEnabled}
                    />
                  )
                } else {
                  return undefined
                }
              })}
              <DataDestinationsForm />
              <CreateDataDestinationSide />
            </ItemContainer>
          </div>
        )}
    </div>
  )
}

export default connect(
  state => ({
    dataDestinations: state.dataDestinations,
    integrations: state.integrations,
    locale: state.locale,
    isSaleMode: state.saleMode.status,
    features: state.project.features
  }),
  {
    openCreateDataDestinationSide: open,
    onIntegrationOpen: integrationOpen
  }
)(DataDestinationsList)
