import styled from 'styled-components'

export const HeaderWrapper = styled.div`
  margin-bottom: 23px;
  display: flex;
  justify-content: space-between;
`

export const HeaderLeft = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
`

export const HeaderRight = styled.div`
  width: ${props => (props.width ? props.width : 'auto')};
`
