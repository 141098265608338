import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'ML-based user scoring',
  icon,
  locale: { ru },
  description:
    'Segments all visitors on the website in real-time, according to the value they will bring in the future. Uses a machine learning model based on 20+ visitors behavior factors. The visitors segment is available in the digitalData.user.segment variable. It can be transmitted to any integration from the catalog.',
  form: [
    {
      label: 'Events array',
      type: 'string array',
      name: 'eventsArray'
    },
    {
      label: 'Context attributes array',
      type: 'string array',
      name: 'contextAttributesArray'
    }
  ]
}

export default config
