import path from './path'

const OPEN = `openModal@${path}`

export const openModal = component => ({
  type: OPEN,
  component
})

export default (state = {}, action) =>
  action.type === OPEN
    ? {
      ...state,
      component: action.component
    }
    : state
