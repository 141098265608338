import React, { useState } from 'react'

import {
  BillingOptions, BillingPeriod,
  BillingPeriodSwitcher, EnterprisePrice, Feature,
  FlexContainer,
  Label, PlanDescription, Price, PricingPlan, PricingPlanLabel
} from 'containers/App/Dashboard/BlueHeader/ProjectBilling/styles'
import Space from 'containers/Space'
import { PROJECT_TYPES } from 'redux/modules/project'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'

import billingPeriods, { ANNUAL } from '../billingPeriods'
import { billingAccountCurrency } from '../billingAccount'

const CALL_BOOKING_LINK = 'https://meetings.hubspot.com/segmentstream/discovery-call'

export function BillingPricingPlansList ({
  billingAccount,
  projectType,
  onPriceSelected,
  availablePricingPlans,
  availableProjects = []
}) {
  const [billingPeriod, setBillingPeriod] = useState(ANNUAL.value)
  const currency = billingAccountCurrency(billingAccount)

  const group = availableProjects.some(project => project.type === PROJECT_TYPES.BigQuery)
    ? 'BigQuery'
    : 'GoogleAnalytics'

  const projectPricingPlans = availablePricingPlans
    .filter(pricingPlan => pricingPlan.group === group)
    .map(pricingPlan => ({
      pricingPlan,
      price: pricingPlan.prices.find(price => price.billingPeriod === billingPeriod)
    }))

  const handlePriceSelected = value => {
    onPriceSelected(value)
  }

  return (
    <BillingOptions>
      <FlexContainer>
        <Label>Select pricing plan</Label>
        <BillingPeriodSwitcher>
          { billingPeriods.map(option => (
            <BillingPeriod
              key={option.value}
              onClick={() => setBillingPeriod(option.value)}
              selected={billingPeriod === option.value}>
              {option.label}
            </BillingPeriod>
          )) }
        </BillingPeriodSwitcher>
      </FlexContainer>
      <div>
        <Space />
        { projectPricingPlans.map(({ pricingPlan, price }) => (
          <PricingPlan key={pricingPlan.id}>
            <FlexContainer style={{ alignItems: 'center' }}>
              <PricingPlanLabel>{pricingPlan.name}</PricingPlanLabel>
              Monthly ad spend up to {currency.symbol}{pricingPlan.monthlySpendLimit.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            </FlexContainer>
            <FlexContainer style={{ alignItems: 'center', marginTop: '25px' }}>
              <FlexContainer style={{ alignItems: 'baseline' }}>
                <Price>{currency.symbol}{price.price}</Price>/per month
              </FlexContainer>
              <ConfirmButton onClick={() => handlePriceSelected({ ...price, pricingPlan })}>
                Select pricing plan
              </ConfirmButton>
            </FlexContainer>
          </PricingPlan>
        ))}

        <PricingPlan>
          <EnterprisePrice>Custom Pricing</EnterprisePrice>
          <FlexContainer style={{ alignItems: 'center' }}>
            <PricingPlanLabel>Custom</PricingPlanLabel>
            <EnterprisePrice>Custom Pricing</EnterprisePrice>
          </FlexContainer>
          <FlexContainer style={{ alignItems: 'flex-start', marginTop: '20px' }}>
            {
              projectType === PROJECT_TYPES.BigQuery ? (
                <div>
                  <Feature>Real-time event streaming from website to Google BigQuery</Feature>
                  <Feature>User scoring for accurate ad targeting</Feature>
                  <Feature>Multi-channel behavioral attribution based on ML</Feature>
                  <Feature>Individual marketing reports for efficient advertising budget allocation and ads
                    optimization</Feature>
                  <Feature>Dedicated account manager</Feature>
                  <Feature>Enterprise SLA</Feature>
                  <Feature>Enterprise onboarding</Feature>
                </div>
              ) : (
                <PlanDescription>
                  We provide custom pricing for agencies and enterprise teams.<br />Contact us to learn more.
                </PlanDescription>
              )
            }
            <a href={CALL_BOOKING_LINK} target='_blank' rel='noopener noreferrer'>
              <ConfirmButton>Contact us</ConfirmButton>
            </a>
          </FlexContainer>
        </PricingPlan>
      </div>
    </BillingOptions>
  )
}
