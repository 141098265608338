import path from './path'

const OPEN = `open@${path}`

export const open = name => ({
  type: OPEN,
  dataTransformationName: name
})

export default (state = {}, action) =>
  action.type === OPEN ? { ...state, isOpen: true, name: action.dataTransformationName } : state
