import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'

const config = {
  name: 'Segmento',
  color: '#ff2d00',
  category: [ADVERTISING],
  sideLogo,
  logo,
  requiresSetup: false
}

export default config
