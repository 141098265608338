import styled, { css } from 'styled-components'

import { Button } from './Button'

export const ButtonPrimary = styled(Button)`
  padding: 14px 20px;
  min-width: 140px;
  width: auto;
  background-color: ${props => (props.pending ? '#3059da' : '#4d6dd3')};
  ${props =>
    !props.disabled &&
  !props.pending &&
  css`
      &:hover {
        background-color: #6d8df2;
      }
    `}
  ${props => props.dangerZone &&
  css`
      background-color: ${props.pending ? '#FF8B88' : '#FF504B'};
      &:hover {
        background-color: #d25450;
      }
    `}
`
