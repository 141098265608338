import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Getintent', //
  color: '#1eb0fc',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Site ID',
      type: 'string',
      name: 'siteId',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    }
  ]
}

export default config
