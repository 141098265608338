import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchYandexMetricaCounters, fetchYandexMetricaGoals } from '../../api/dataDestination'

const config = {
  type: 'Yandex.Metrica',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datadestinations/yandex-metrica',
  authType: 'OAuth2',
  authIcon,
  authName: 'Yandex.Metrica Account',
  isPublished: true,
  authForm: [
    {
      label: 'Authenticate with Yandex',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Yandex.Metrica',
      validation: ['required']
    },
    {
      label: 'Counter Id',
      type: 'dynamic select',
      name: 'counterId',
      validation: ['required'],
      placeholder: 'Select counter ID',
      optionsFetcher: fetchYandexMetricaCounters,
      description: 'No counters were found for this user or access was not granted.',
      extendedView: true,
      tip: 'Do not forget to enable offline conversion in your <a href="https://yandex.com/dev/metrika/doc/api2/practice/conversion.html#offline">account</a>.',
      enable: ['goal']
    },
    {
      label: 'Goal',
      type: 'dynamic select',
      name: 'goal',
      validation: ['required'],
      placeholder: 'Select goal',
      optionsFetcher: fetchYandexMetricaGoals,
      description: 'No goals were found for this counter or access was not granted.',
      extendedView: true,
      dependsOn: ['counterId']
    },
    {
      label: 'BigQuery source table name',
      type: 'string',
      name: 'sourceTable',
      defaultValue: 'yandexMetricaScoreExport',
      validation: ['required']
    },
    {
      label: 'Schedule',
      type: 'select',
      name: 'schedule',
      defaultValue: 'daily',
      options: [
        {
          label: 'daily',
          value: 'daily'
        },
        {
          label: 'hourly',
          value: 'hourly'
        }
      ],
      validation: ['required']
    }
  ]
}

export default config
