import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import {
  INTEGRATIONS,
  PRIORITY,
  EVENTS,
  ENRICHMENTS,
  SCRIPTS,
  DATA_STREAMS,
  DATA_SOURCES,
  DATA_TRANSFORMATIONS,
  DATA_DESTINATIONS,
  CHECKOUT_SUCCESS,
  CHECKOUT_FAIL,
  CHARTS,
  CHARTS_SETTINGS
} from 'redux/modules/page/changePage'

import Integrations from './Integrations'
import Priority from './Priority'
import Events from './Events'
import Enrichments from './Enrichments'
import Scripts from './Scripts'
import DataSources from './DataSources'
import Reports from './Reports'
import DataTransformations from './DataTransformations'
import DataDestinations from './DataDestinations'
import Charts from './Charts'
import ChartsSettings from './ChartsSettings'
import RestrictedFeatureModal from './RestrictedFeatureModal'
import CheckoutNotificationModal from './CheckoutNotificationModal'
import { DataStreams } from './DataStreams'

import { Container, Content } from './styles'

const Body = ({ page, reports = [] }) => {
  const report = reports.filter(report => `${report.name}-report` === page)[0]

  return (
    <Container>
      <Content>
        {page === INTEGRATIONS && <Integrations />}
        {page === PRIORITY && <Priority />}
        {page === EVENTS && <Events />}
        {page === ENRICHMENTS && <Enrichments />}
        {page === SCRIPTS && <Scripts />}
        {page === DATA_SOURCES && <DataSources />}
        {page && report && page.includes('report') && <Reports reportUrl={report.url} />}
        {page === DATA_TRANSFORMATIONS && <DataTransformations />}
        {page === DATA_DESTINATIONS && <DataDestinations />}
        {page === DATA_STREAMS ? <DataStreams /> : null}
        {page === CHARTS && <Charts />}
        {page === CHARTS_SETTINGS && <ChartsSettings />}
        {(page === CHECKOUT_SUCCESS || page === CHECKOUT_FAIL) && <CheckoutNotificationModal />}
      </Content>
      <RestrictedFeatureModal />
    </Container>
  )
}

export default connect(state => ({
  page: state.page.name,
  reports: _.get(state, 'project.options.reports')
}))(Body)
