import React from 'react'
import { connect } from 'react-redux'
import dataTransformations, { mockDataTransformations } from 'dataTransformations'
import DataTransformationItem from './DataTransformationItem'
import { Block } from './styles'

const DataTransformationsList = ({ projectDataTransformations = [], isSaleMode }) => {
  const allDataTransformations = isSaleMode ? [...dataTransformations, ...mockDataTransformations] : dataTransformations

  const enabledDataTransformations = projectDataTransformations.filter(dt => !dt.isDisabled).map(dt => dt.jobName)
  return (
    <Block>
      {allDataTransformations.map(dataTransformation => (
        <DataTransformationItem
          key={dataTransformation.name}
          transformation={dataTransformation}
          isEnabled={enabledDataTransformations.indexOf(dataTransformation.jobName) > -1}
        />
      ))}
    </Block>
  )
}

export default connect(state => ({
  projectDataTransformations: state.project.dataTransformations,
  isSaleMode: state.saleMode.status
}))(DataTransformationsList)
