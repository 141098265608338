import path from './path'
import { createInvitation as apiCreateInvitation } from 'api/project'
import { success, pending } from 'redux/modules/blueHeader/modalWindow'

const INVITE_PROJECT_MEMBER = `inviteProjectMember@${path}`

export const inviteProjectMember = inviteEmail => async (dispatch, getState) => {
  dispatch(pending())
  const projectId = getState().project.id
  const createdInvitation = await dispatch(apiCreateInvitation(projectId, inviteEmail))
  dispatch({
    type: INVITE_PROJECT_MEMBER,
    invitation: createdInvitation
  })
  dispatch(success())
}

export default (state = {}, action) => (action.type === INVITE_PROJECT_MEMBER
  ? {
    ...state,
    invitations: [...state.invitations, action.invitation]
  }
  : state
)
