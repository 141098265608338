import React from 'react'
import { connect } from 'react-redux'
import { logout } from 'redux/modules/auth'
import { openModal, PROJECT_OPTIONS, PROJECT_MEMBERS } from 'redux/modules/blueHeader/modalWindow'
import getTranslate from 'utils/getTranslate'
import { TRIAL_PRICING_PLANS, SELF_SERVE_PRICING_PLANS, FREE } from 'utils/pricingPlans'
import { openModal as openProjectInfo } from 'redux/modules/blueHeader/projectInfo'
import { openModal as openProjectBilling } from 'redux/modules/blueHeader/projectBilling'
import {
  Container,
  HeaderWrapper,
  SegmentStreamLogo,
  MenuLink,
  FlexContainer,
  Logout
} from './styles'
import SearchSelect from './SearchSelect'
import ProjectOptions from './ProjectOptions'
import ProjectInfo from './ProjectInfo'
import ProjectMembers from './ProjectMembers'
import ru from './ru.json'
import { PROJECT_CREATION, changePage } from 'redux/modules/page/changePage'
import { Billing } from 'containers/App/Dashboard/BlueHeader/Billing'
import { selectProject } from 'redux/modules/project/selectProject'
import { selectBillingAccount } from 'redux/modules/user/selectBillingAccount'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'
import { PROJECT_TYPES } from 'redux/modules/project'

const BlueHeader = ({ locale, onLogout, onOpenProjectInfo, onOpenProjectBilling, allProjects, project, onOpenModal, isProjectOwner, onChangePage, trialExpiresAt, subscriptionExpiresAt, pricingPlan, user, onSelectBillingAccount, onSelectProject, isSuperAdmin }) => {
  const translate = getTranslate(locale, { ru })

  const { billingAccounts, billingAccount } = user || {}
  const { pricingPlan: userPricingPlan } = billingAccount || {}

  const isTrialPricingPlan = TRIAL_PRICING_PLANS.includes(userPricingPlan)
  const isSelfServePricingPlan = SELF_SERVE_PRICING_PLANS.includes(userPricingPlan)

  const projectOptionsClick = () => onOpenModal(PROJECT_OPTIONS)
  const projectInfoClick = () => onOpenProjectInfo()
  const projectMembersClick = () => onOpenModal(PROJECT_MEMBERS)
  const projectBillingClick = () => onOpenProjectBilling()
  const projectCreationClick = () => onChangePage(PROJECT_CREATION)

  const allBillingAccountOptions = billingAccounts.map(billingAccount => ({
    value: billingAccount.id,
    label: billingAccount.accountName
  }))
  const allProjectOptions = allProjects.map(project => ({
    value: project.id,
    label: project.name
  }))

  const handleSelectBillingAccount = (billingAccountId) => {
    onSelectBillingAccount(user, billingAccountId)
  }

  return (
    <Container>
      <HeaderWrapper>
        <FlexContainer>
          <SegmentStreamLogo />
          {allBillingAccountOptions.length > 1 && <SearchSelect title='Account' allOptions={allBillingAccountOptions} selectedOption={{ value: billingAccount.id, label: billingAccount.accountName }} onSelect={handleSelectBillingAccount} />}
          {!!allProjectOptions.length && project && project.id && <SearchSelect title='Project' allOptions={allProjectOptions} selectedOption={{ value: project.id, label: project.name }} onSelect={onSelectProject} />}
          { (project.type === PROJECT_TYPES.GoogleAnalytics || isSuperAdmin) && userPricingPlan && userPricingPlan !== FREE && !isTrialPricingPlan && isProjectOwner && <MenuLink position='left' onClick={projectCreationClick}>Add Project</MenuLink>}
        </FlexContainer>
        <FlexContainer>
          { isProjectOwner && isSelfServePricingPlan && <MenuLink onClick={projectBillingClick}>{translate('Billing')}</MenuLink>}
          { project.id && <MenuLink onClick={projectInfoClick}>{translate('Project Info')}</MenuLink>}
          { project.type === PROJECT_TYPES.BigQuery && (project.features || []).includes(ProjectFeature.Tracking) && <MenuLink onClick={projectOptionsClick}>{translate('Tracking Settings')}</MenuLink>}
          { project.id && isProjectOwner && <MenuLink onClick={projectMembersClick}>{translate('Project Members')}</MenuLink> }
          <MenuLink href='https://docs.segmentstream.com' target='_blank' rel='noopener noreferrer'>
            {translate('Documentation')}
          </MenuLink>
          <Logout onClick={onLogout} />
        </FlexContainer>
      </HeaderWrapper>
      <ProjectOptions />
      <ProjectInfo />
      <ProjectMembers />
      <Billing />
    </Container>
  )
}

export default connect(
  state => {
    const { billingAccount } = state.project || {}
    const { trialExpiresAt, subscriptionExpiresAt, pricingPlan } = billingAccount || {}

    return {
      trialExpiresAt,
      subscriptionExpiresAt,
      pricingPlan,
      locale: state.locale,
      allProjects: state.allProjects,
      project: state.project,
      isProjectOwner: (state.project.billingAccount || {}).userId === state.user.userId || state.user.role === 'super_admin',
      isSuperAdmin: state.user.role === 'super_admin',
      user: state.user
    }
  },
  {
    onLogout: logout,
    onOpenProjectInfo: openProjectInfo,
    onOpenProjectBilling: openProjectBilling,
    onOpenModal: openModal,
    onChangePage: changePage,
    onSelectProject: selectProject,
    onSelectBillingAccount: selectBillingAccount
  }
)(BlueHeader)
