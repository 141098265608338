import { parse } from 'query-string'

const SALE_MODE = 'sale_mode'

const getQuerySaleMode = query => parse(query)[SALE_MODE]

export const getSaleMode = cookies => !!cookies.get(SALE_MODE)

export const setSaleMode = (query, cookies) => {
  const cookieSaleMode = getSaleMode(cookies)
  const querySaleMode = getQuerySaleMode(query)
  if (querySaleMode === '1' && !cookieSaleMode) {
    cookies.set(SALE_MODE, '1')
  } else if (querySaleMode === '0' && cookieSaleMode) {
    cookies.remove(SALE_MODE)
  }
}
