import styled from 'styled-components'

export const Container = styled.div`
  padding: 30px 33px 24px;
`

export const Top = styled.div`
  width: 100%;
  height: 86px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 24px;
  line-height: 24px;
  padding-left: 33px;
  background-color: #fff;
`

export const Header = styled.span`
  max-width: 60%;
  min-width: 140px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`

export const HeaderButtonsWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
`

export const HeaderButton = styled.div`
  margin-right: 33px;
`
