import { closeForm } from './closeForm'
import { remove as removeScript } from 'redux/modules/scripts/remove'
import { remove as apiRemove } from 'api/script'
import { enablePublish } from 'redux/modules/project'

export const remove = (scriptId, scriptName) => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiRemove(projectId, scriptId))
  await dispatch(closeForm())
  dispatch(removeScript(scriptId, scriptName))
  dispatch(enablePublish())
}
