import React from 'react'
import SelectBox from 'containers/Form/SelectBox'
import Input from 'containers/Form/Input'
import InlineRow from 'containers/Form/InlineRow'
import getTranslate from 'utils/getTranslate'
import variableOptions from './options'
import ru from './ru.json'

const Variable = ({
  locale,
  typeOptions,
  placeholder,
  label,
  style = { width: '100%' },
  touched,
  hasFocus,
  validation,
  onBlur,
  onTouch,
  onFocus,
  onChange,
  value,
  theme
}) => {
  const translate = getTranslate(locale, { ru })

  const changeType = type => {
    onChange({
      type,
      value: value ? value.value : undefined
    })
  }

  const changeValue = val => {
    onChange({
      type: value ? value.type : typeOptions[0],
      value: val
    })
  }

  const options = typeOptions.map(option => ({
    label: translate(variableOptions[option]),
    value: option
  }))

  const inputStyle = { width: '50%', marginLeft: '17px' }
  if (style.height) inputStyle.height = style.height

  return (
    <div style={style}>
      {label && <label style={{ marginBottom: '16px' }}>{label}</label>}
      <InlineRow>
        <SelectBox
          options={options}
          value={value ? value.type : typeOptions[0]}
          onChange={changeType}
          onTouch={onTouch}
          style={style}
          theme={theme}
        />
        <Input
          placeholder={placeholder}
          value={value ? value.value : undefined}
          hasFocus={hasFocus}
          validation={validation}
          touched={touched}
          onChange={changeValue}
          onBlur={onBlur}
          onFocus={onFocus}
          onTouch={onTouch}
          style={inputStyle}
        />
      </InlineRow>
    </div>
  )
}

export default Variable
