import React from 'react'
import Mapping from 'containers/Form/Mapping'
import VariableMappingRow from './VariableMappingRow'
import { mappingRowProps } from 'containers/Form/Mapping/MappingRow'

const VariableMapping = ({
  locale,
  style,
  name,
  value,
  label,
  description,
  touched,
  typeOptions,
  keyOptions,
  isValid,
  placeholderKey,
  placeholderValue,
  onChange,
  onTouch,
  onValidate,
  inputStyle
}) => {
  const isValidRow = row =>
    row[0] && row[0].length > 0 && row[1] && row[1].value && row[1].value.length > 0
  const createRow = ({ row, index, focusedRow, onFocus, onChangeRow }) => (
    <VariableMappingRow
      inputStyle={inputStyle}
      typeOptions={typeOptions}
      keyOptions={keyOptions}
      onTouch={onTouch}
      {...mappingRowProps({
        locale,
        focusedRow,
        index,
        onFocus,
        placeholderKey,
        placeholderValue,
        onChangeRow,
        row,
        touched
      })}
    />
  )

  return (
    <Mapping
      locale={locale}
      description={description}
      label={label}
      onCreateRow={createRow}
      value={value}
      style={style}
      isValidRow={isValidRow}
      onChange={onChange}
      onTouch={onTouch}
      onValidate={onValidate}
      name={name}
      isValid={isValid}
    />
  )
}

export default VariableMapping
