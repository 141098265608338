import styled from 'styled-components'
import plus from './plus.png'
import LockBadge from 'containers/LockBadge'

export const Transformation = styled.div`
  position: relative;
  display: flex;
  vertical-align: top;
  width: 47%;
  height: 215px;
  margin: 0 23px 30px 0;
  padding: 35px 5% 35px 3.5%;
  box-sizing: border-box;
  border: ${props => (props.available ? '1px solid #d5dee6' : '1px solid #e9ebed')};
  border-radius: 8px;
  cursor: ${props => (props.available ? 'pointer' : 'default')};
  background: #fff;
  text-align: left;
  &:hover {
    box-shadow: ${props => (props.available ? '0 7px 10px 0 rgba(24, 88, 196, 0.12)' : 'none')};
  }
`

export const TransformationIcon = styled.div`
  width: 39px;
  height: 39px;
  margin: 0 auto;
  padding: 0;
  background: url(${props => props.icon}) center center no-repeat;
  background-size: 39px 39px;
  opacity: ${props => (props.available ? '1' : '0.5')};
`

export const Signal = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const Plus = styled.div`
  width: 15px;
  height: 15px;
  background: url(${plus}) no-repeat;
  background-size: 15px 15px;
`

export const IconContainer = styled.div`
  width: 39px;
  height: 39px;
  display: flex;
  justify-content: flex-start;
`

export const TransDetails = styled.div`
  margin-left: 8%;
  margin-top: 10px;
`

export const Name = styled.div`
  font-size: 18px;
  color: #0c1533;
  overflow: hidden;
  margin-bottom: 8px;
`

export const Description = styled.div`
  width: 100%;
  font-size: 14px;
  color: #7d8293;
`

export const Lock = styled(LockBadge)`
  position: absolute;
  top: 12px;
  right: 12px;
`
