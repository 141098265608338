import React, { Component } from 'react'
import { SearchBox } from './styles'

export default class Search extends Component {
  constructor (props) {
    super(props)
    this.handleChange = this.handleChange.bind(this)
  }

  handleChange (e) {
    this.props.onChange(e.target.value)
  }

  render () {
    const { value } = this.props
    return (
      <div>
        <SearchBox
          placeholder='Search'
          onChange={this.handleChange}
          type='text'
          value={value}
        />
      </div>
    )
  }
}
