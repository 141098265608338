import React from 'react'
import Input from 'containers/Form/Input'
import SelectBox from 'containers/Form/SelectBox'
import { THEME_FORM_ARRAY_ITEM } from 'containers/Form/SelectBox/styles'
import Divider from 'containers/Form/Mapping/Divider'
import Variable from 'containers/Form/Variable'
import InlineRow from 'containers/Form/InlineRow'

const VariableMappingRow = ({
  locale,
  hasFocusLeft,
  hasFocusRight,
  keyOptions,
  mappingKey,
  mappingValue,
  onChangeKey,
  onChangeValue,
  onTouch,
  onFocusLeft,
  onFocusRight,
  placeholderKey,
  placeholderValue,
  touched,
  typeOptions,
  inputStyle
}) => {
  const changed =
    (mappingKey !== undefined && mappingKey.length > 0) ||
    (mappingValue !== undefined && mappingValue.length > 0)
  const handleChangeKey = value => {
    onChangeKey(value)
  }
  const handleChangeValue = value => {
    const newValue =
      typeof value.value === 'string'
        ? { ...value, value: value.value.replace(/\s+/g, '') }
        : value
    onChangeValue(newValue)
  }

  const defaultInputStyle = { width: '180px' }
  const defaultVariableStyle = { width: '100%' }

  if (inputStyle.height) {
    defaultInputStyle.height = inputStyle.height
    defaultVariableStyle.height = inputStyle.height
  }

  return (
    <InlineRow>
      {keyOptions && (
        <SelectBox
          options={keyOptions}
          value={mappingKey}
          onChange={handleChangeKey}
          onTouch={onTouch}
          theme={THEME_FORM_ARRAY_ITEM}
        />
      )}
      {!keyOptions && (
        <Input
          style={defaultInputStyle}
          placeholder={placeholderKey}
          value={mappingKey}
          hasFocus={hasFocusLeft}
          validation={['required']}
          touched={touched && changed}
          onChange={handleChangeKey}
          onFocus={onFocusLeft}
          onTouch={onTouch}
        />
      )}
      <Divider />
      <Variable
        style={defaultVariableStyle}
        locale={locale}
        typeOptions={typeOptions}
        placeholder={placeholderValue}
        value={mappingValue}
        hasFocus={hasFocusRight}
        validation={['required']}
        touched={touched && changed}
        onChange={handleChangeValue}
        onFocus={onFocusRight}
        onTouch={onTouch}
        theme={THEME_FORM_ARRAY_ITEM}
      />
    </InlineRow>
  )
}

export default VariableMappingRow
