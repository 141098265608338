import React, { PureComponent } from 'react'
import {
  Wrapper,
  RadioButton
} from './styles'
import Tooltip from 'containers/Tooltip'

class RadioButtonGroup extends PureComponent {
  constructor (props) {
    super(props)
    this.handleSelect = this.handleSelect.bind(this)
  }

  handleSelect (value) {
    this.props.onChange(value === '' ? undefined : value)
  }

  render () {
    const { options, value, theme } = this.props
    const hasLabel = options[0] && typeof options[0].label === 'string'
    return (
      <Wrapper theme={theme}>
        {options.map(opt => {
          const val = hasLabel ? opt.value : opt
          const lab = hasLabel ? opt.label : opt
          return (
            <RadioButton selected={value === val} theme={theme} key={val} onClick={() => this.handleSelect(val)}>
              <div>{lab || val}</div>
              <div style={{ display: 'flex' }}>
                {opt.note}
                {opt.tip && <Tooltip tip={opt.tip} place='left' />}
              </div>
            </RadioButton>
          )
        })}
      </Wrapper>
    )
  }
}

export default RadioButtonGroup
