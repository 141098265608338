import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING, ANALYTICS } from 'integrations/categories'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const WEBVISOR_VERSION_1 = 1
const WEBVISOR_VERSION_2 = 2

const config = {
  name: 'Yandex Metrica',
  color: '#ff3f3f',
  category: [ADVERTISING, ANALYTICS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/yandex-metrica',
  form: [
    {
      label: 'Yandex Metrica counter ID',
      type: 'string',
      name: 'counterId',
      validation: ['required']
    },
    {
      label: 'Accurate bounce rate',
      type: 'boolean',
      name: 'accurateTrackBounce'
    },
    {
      label: 'Enable click map',
      type: 'boolean',
      name: 'clickmap'
    },
    {
      label: 'Enable webvisor',
      type: 'boolean',
      name: 'webvisor',
      enable: ['webvisorVersion']
    },
    {
      label: 'Webvisor version',
      type: 'select',
      name: 'webvisorVersion',
      options: [
        {
          label: '1.0',
          value: WEBVISOR_VERSION_1
        },
        {
          label: '2.0',
          value: WEBVISOR_VERSION_2
        }
      ],
      defaultValue: WEBVISOR_VERSION_1
    },
    {
      label: 'Enable links tracking',
      type: 'boolean',
      name: 'trackLinks',
      defaultValue: true
    },
    {
      label: 'Enable browser hash tracking',
      type: 'boolean',
      name: 'trackHash'
    },
    {
      label: 'Send User ID',
      type: 'boolean',
      name: 'sendUserId',
      defaultValue: true
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    },
    {
      label: 'Purchase Goal ID',
      type: 'number',
      name: 'purchaseGoalId'
    },
    {
      label: 'Goals mapping',
      type: 'string mapping',
      name: 'goals'
    },
    {
      label: 'Visit parameters',
      type: 'variable mapping',
      name: 'visitParamsVars',
      typeOptions: [DIGITAL_DATA, EVENT]
    },
    {
      label: 'User parameters',
      type: 'variable mapping',
      name: 'userParamsVars',
      typeOptions: [DIGITAL_DATA, EVENT]
    }
  ]
}

export default config
