import React from 'react'
import { Table, TableData, TableHead, TableRow, Wrapper } from './styles'

export default ({ isEventEnrichment = false, headers, data }) => {
  const size = headers.length
  const renderHeader = () =>
    headers.map((header, headerIndex) => (
      <TableHead key={headerIndex} size={size} isEventEnrichment={isEventEnrichment}>
        {header}
      </TableHead>
    ))
  const renderData = () =>
    data.map((row, rowIndex) => (
      <TableRow key={rowIndex}>
        {row.map((item, itemIndex) => (
          <TableData key={itemIndex} size={size} isEventEnrichment={isEventEnrichment}>
            {item}
          </TableData>
        ))}
      </TableRow>
    ))
  if (data.length === 0) return null
  return (
    <Wrapper>
      <Table>
        {renderHeader()}
        {renderData()}
      </Table>
    </Wrapper>
  )
}
