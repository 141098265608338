import React from 'react'
import { ButtonTitle, ButtonContainer, Link } from './styles'

const LinkButton = ({ text, link, target }) => (
  <a style={{ textDecoration: 'none' }} target={target || '_blank'} rel='noopener noreferrer' href={link} >
    <ButtonContainer>
      <ButtonTitle>{text}</ButtonTitle>
      <Link />
    </ButtonContainer>
  </a>
)

export default LinkButton
