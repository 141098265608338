import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

import { GET_DATA_STREAMS } from './getDataStreams'

export const CREATE_DATA_STREAM = gql`
  mutation CreateDataStream($dataStream:DataStreamInput!) {
    createDataStream(dataStream:$dataStream) {
      id
      projectId
      isEnabled
      displayName
      destinationTableName
      config {
        uaIntegrationType
      }
      anonymize
    }
  }
`

export const useCreateDataStreamMutation = (projectId) => useMutation(CREATE_DATA_STREAM, {
  // We can update cache directly instead of re-fetching it
  // https://www.apollographql.com/docs/react/data/mutations/#making-all-other-cache-updates
  refetchQueries: [{
    query: GET_DATA_STREAMS,
    variables: { projectId }
  }],
  awaitRefetchQueries: true
})
