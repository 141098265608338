import { saveOptions as apiSaveOptions, enable as apiEnable } from 'api/integration'
import { enablePublish, enableTip } from 'redux/modules/project'
import { saveIntegrationToIntegrations } from 'redux/modules/integrations'
import { close } from './close'
import { INTEGRATIONS } from '../../changePage'

export const saveIntegration = (name, data, status, touchedFields) => async (dispatch, getState) => {
  if (touchedFields) {
    const projectId = getState().project.id
    await dispatch(apiSaveOptions(projectId, name, data))
    if (!status) {
      dispatch(enableTip(name, INTEGRATIONS))
    }
    dispatch(saveIntegrationToIntegrations(name, data, status))
    await dispatch(apiEnable(projectId, name))
    dispatch(enablePublish())
  }
  dispatch(close())
}
