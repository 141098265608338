import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { closeModal, PROJECT_MEMBERS } from 'redux/modules/blueHeader/modalWindow'
import Space from 'containers/Space'
import { Line } from './styles'
import Member from './member'
import Invitation from './invitation'
import InviteForm from './inviteForm'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

class ProjectMembers extends Component {
  render () {
    const { locale, isOpen, onClose, members, invitations, isProjectOwner } = this.props
    const translate = getTranslate(locale, { ru })
    return (
      <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Project Members')}>
        {isProjectOwner && <InviteForm />}
        {members.length > 0 &&
          <div>
            <h2>{translate('Members')}</h2>
            <Line />
          </div>}
        <Space />
        {members.map(member => (
          <Member key={member.userId} email={member.userEmail} permissionId={member.id} showRemoveButton={isProjectOwner} />
        ))}

        {invitations.length > 0 &&
          <div>
            <h2>{translate('Invitations')}</h2>
            <Line />
          </div>
        }
        <Space />
        {invitations.map(invitation => (
          <Invitation key={invitation.id} email={invitation.email} permissionId={invitation.id} showRemoveButton={isProjectOwner} />
        ))}
      </ModalWindow>
    )
  }
}

const mapStateToProps = state => {
  const { component } = state.blueHeader.modalWindow
  return {
    locale: state.locale,
    members: state.project.members || [],
    invitations: state.project.invitations || [],
    isOpen: component === PROJECT_MEMBERS,
    isProjectOwner: (state.project.billingAccount || {}).userId === state.user.userId || state.user.role === 'super_admin'
  }
}

const mapDispatchToProps = () => ({
  onClose: closeModal
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ProjectMembers)
