import reduceReducers from 'reduce-reducers'
import changeTabReducer, { changeTab } from './changeTab'
import closeReducer, { close } from './close'
import openReducer, { open } from './open'
import { saveIntegration } from './saveIntegration'
import { saveOverrideFunctions } from './saveOverrideFunctions'
import eventEnrichments from './eventEnrichments'

export { changeTab, close, open, saveIntegration, saveOverrideFunctions }

export default reduceReducers(
  (state = {}, action) => ({
    ...state,
    eventEnrichments: eventEnrichments(state.eventEnrichments, action)
  }),
  (
    state = {
      isOpen: true,
      name: 'Criteo',
      activeTab: 'eventEnrichments'
    }
  ) => state,
  changeTabReducer,
  closeReducer,
  openReducer
)
