import styled from 'styled-components'
import deleteButton from './delete-button.svg'

export const Button = styled.div`
  width: ${props => props.full ? '100%' : 'calc(100% - 40px)'};
  display: inline-block;
  height: 60px;
  padding: 18px 0;
  border: 1px dashed #96a5b4;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 200;
  color: #96a5b4;
  text-align: center;
  cursor: pointer;
  outline: none;
  user-select: none;
`

export const DeleteButton = styled.div`
  background: url(${deleteButton}) no-repeat;
  cursor: pointer;
  outline: none;
  width: 20px;
  height: 13px;
  margin-left: 20px;
  display: inline-block;
`

export const Icon = styled.div`
  width: 17px;
  height: 17px;
  display: inline-block;
  margin: 0 auto;
  clear: left;
  padding: 0;
  background: url(${props => props.logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
