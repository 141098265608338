import { closeForm } from './closeForm'
import { remove as removeEnrichment } from 'redux/modules/enrichments/remove'
import { remove as apiRemove } from 'api/enrichment'
import { enablePublish } from 'redux/modules/project'

export const remove = (enrichmentId, enrichmentName) => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiRemove(projectId, enrichmentId))
  await dispatch(closeForm())
  dispatch(removeEnrichment(enrichmentId, enrichmentName))
  dispatch(enablePublish())
}
