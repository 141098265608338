import styled from 'styled-components'

export const Description = styled.div`
  display: block;
  margin-top: 15px;
  font-size: 14px;
  line-height: 14px;
  color: #727272;
`

export const UnitContainer = styled.div`
  width: 0;
  height: 0;
`

export const UnitsDisplay = styled.div`
  color: #93a2b1;
  width: 100px;
  text-align: right;
  font-size: 18px;
  margin: 20px -130px;
`

export const Label = styled.div`
  display: flex;
  margin-bottom: 16px;
`
