import React, { Component } from 'react'
import { Integration, IntegrationLogo } from './styles'
import { DragSource as dragSource, DropTarget as dropTarget } from 'react-dnd'
import flow from 'lodash/flow'
import { CARD } from './ItemType'
import allIntegrations, { sandboxIntegrations } from 'integrations'

class Card extends Component {
  render () {
    const {
      item,
      connectDragSource,
      connectDropTarget,
      isDragging,
      isSaleMode
    } = this.props

    const integrations = isSaleMode
      ? [...allIntegrations, ...sandboxIntegrations]
      : allIntegrations

    const index = integrations.findIndex(integration => integration.name === item)
    const integration = integrations[index]
    return connectDragSource(
      connectDropTarget(
        <div style={{ display: 'inline-block' }}>
          <Integration fake={isDragging}>
            {!isDragging && <IntegrationLogo logo={integration.logo} />}
          </Integration>
        </div>
      )
    )
  }
}

const cardSource = {
  beginDrag: props => ({
    item: props.item
  }),
  endDrag: (props, monitor) => {
    const dragItem = monitor.getItem().item
    props.dropCard(dragItem)
  }
}

const cardTarget = {
  hover: (props, monitor) => {
    const dragItem = monitor.getItem().item
    const hoverItem = props.item
    if (dragItem === hoverItem) {
      return
    }
    props.moveCard(dragItem, hoverItem)
  }
}

export default flow(
  dropTarget(CARD, cardTarget, connect => ({
    connectDropTarget: connect.dropTarget()
  })),
  dragSource(CARD, cardSource, (connect, monitor) => ({
    connectDragSource: connect.dragSource(),
    isDragging: monitor.isDragging()
  }))
)(Card)
