import path from './path'

const RESET = `reset@${path}`

export const authenticatePasswordReset = resetToken => async dispatch => {
  await dispatch({ type: RESET, resetToken })
  window.history.replaceState({}, null, '/')
}

export default (state = {}, action) => {
  if (action.type === RESET) {
    return { resetToken: action.resetToken }
  }
  return state
}
