import isValid from './isValid'
import getDisabledFields from './getDisabledFields'

export default (form, data) => {
  const disabledFields = getDisabledFields(form, data)
  const firstInvalidField = form.find(
    field => {
      if (
        !(disabledFields && disabledFields.includes(field.name)) &&
        field.type === 'group'
      ) {
        return field.items.find(f => !isValid(f, field.flattenOptions
          ? data[f.name] : data[field.name][f.name]) &&
          !(disabledFields && disabledFields.includes(f.name)))
      } else {
        return !isValid(field, data[field.name]) && !(disabledFields && disabledFields.includes(field.name))
      }
    }
  )
  return !firstInvalidField
}
