import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'

const MAX_CUSTOM_VARS = 200

const customVars = Array(MAX_CUSTOM_VARS).fill('')
  .map((_, index) => `u${index + 1}`)

const config = {
  name: 'DoubleClick Floodlight',
  color: '#10aa08',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Events',
      type: 'group array',
      name: 'events',
      addButtonLabel: 'Add event',
      validation: ['required'],
      items: [
        {
          label: 'Floodlight configuration ID',
          type: 'string',
          name: 'floodlightConfigID'
        },
        {
          label: 'Event name',
          type: 'string',
          name: 'event'
        },
        {
          label: 'Activity group tag string',
          type: 'string',
          name: 'activityGroupTagString'
        },
        {
          label: 'Activity tag string',
          type: 'string',
          name: 'activityTagString'
        },
        {
          label: 'Counting method',
          type: 'select',
          name: 'countingMethod',
          options: [
            {
              label: 'Standard',
              value: 'standard'
            },
            {
              label: 'Transactions',
              value: 'transactions'
            }
          ],
          defaultValue: null
        },
        {
          label: 'Custom variables',
          type: 'variable mapping',
          name: 'customVars',
          typeOptions: [DIGITAL_DATA, EVENT],
          keyOptions: customVars
        }
      ]
    }
  ]
}

export default config
