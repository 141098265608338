import reduceReducers from 'reduce-reducers'
import modalWindow from './modalWindow'
import projectInfo from './projectInfo'
import projectBilling from './projectBilling'

export default reduceReducers((state = {}, action) => ({
  ...state,
  projectInfo: projectInfo(state.projectInfo, action),
  projectBilling: projectBilling(state.projectBilling, action),
  modalWindow: modalWindow(state.modalWindow, action)
}))
