import React from 'react'
import { connect } from 'react-redux'
import { open } from 'redux/modules/page/integrations/integrationForm'
import { openModal, BIG_QUERY, RESTRICTED_FEATURE_MODAL } from 'redux/modules/blueHeader/modalWindow'
import IntegrationLogo from 'containers/IntegrationLogo'
import _ from 'lodash'
import EllipsisText from 'react-ellipsis-text'
import Status from 'containers/Status'
import { Category, Integration, LogoContainer, Name, Plus, Signal, Lock } from './styles'
import getTranslate from 'utils/getTranslate'
import { isAvailableForPricingPlan } from 'utils/pricingPlans'
import ru from 'integrations/ru.json'

const IntegrationItem = ({ locale, integration, isEnabled, onOpen, onOpenModal, pricingPlan, isForceAllow }) => {
  const translate = getTranslate(locale, { ru })

  const availableForCurrentPricingPlan = isAvailableForPricingPlan(integration, pricingPlan)

  const handleClick = () => {
    if (availableForCurrentPricingPlan || isForceAllow) onOpen(integration.name)
    else onOpenModal(RESTRICTED_FEATURE_MODAL, BIG_QUERY)
  }

  const category = integration.category.reduce(
    (acc, value, index) => (index !== 0 ? `${acc}, ${translate(value)}` : translate(value)),
    ''
  )

  return (
    <Integration onClick={handleClick}>
      <LogoContainer>
        <IntegrationLogo logo={integration.logo} />
        <Signal>
          {isEnabled && <Status isEnabled={isEnabled} />}
          {!isEnabled && <Plus />}
        </Signal>
        {!availableForCurrentPricingPlan && <Lock /> }
      </LogoContainer>
      <Name>
        <EllipsisText text={integration.displayName || integration.name} length={17} />
      </Name>
      <Category>
        <EllipsisText text={category} length={20} />
      </Category>
    </Integration>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    pricingPlan: _.get(state, 'project.billingAccount.pricingPlan'),
    // Book24 and super_admin
    isForceAllow: state.project.id === 'e57c48e2-cddd-4d22-9649-968547cd1307' || state.user.role === 'super_admin'
  }),
  {
    onOpen: open,
    onOpenModal: openModal
  }
)(IntegrationItem)
