import path from './path'
import { deletedEvent } from 'utils/digitalDataEvents'

const REMOVE = `remove@${path}`

export const remove = (id, name) => {
  deletedEvent(name, path)
  return {
    type: REMOVE,
    id
  }
}

export default (state = [], action) =>
  action.type === REMOVE
    ? state.reduce((acc, item) => (item.id !== action.id ? [...acc, item] : acc), [])
    : state
