import React, { useContext } from 'react'
import { dataStreamsContext } from '../Context'
import { CreateDataStreamSide } from '../Components/CreateDataStreamSide'

export function CreateContainer () {
  const {
    trackingBaseUri,

    create: {
      isLoading,
      isOpen,
      close,
      selectedDataStreamType,

      createDataStream,
      selectDataStreamType
    }
  } = useContext(dataStreamsContext)

  return (
    <CreateDataStreamSide
      isLoading={isLoading}
      isOpen={isOpen}
      close={close}
      selectedDataStreamType={selectedDataStreamType}
      selectDataStreamType={selectDataStreamType}
      createDataStream={createDataStream}
      trackingBaseUri={trackingBaseUri}
    />
  )
}
