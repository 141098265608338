import React from 'react'
import { connect } from 'react-redux'
import { save } from 'redux/modules/page/dataTransformations'
import Form from 'containers/Form'
import getTranslate from 'utils/getTranslate'
import translateFormItem from 'utils/translateFormItem'

const DataTransformationForm = ({ locale, dataTransformation, initialData = [], onSave }) => {
  const dataTransformationInitialData = initialData.filter(dt => dt.jobName === dataTransformation.jobName)[0]
  const data = (dataTransformationInitialData || {}).params

  const handleSave = data => {
    const dataTransformationData = {
      params: data,
      jobName: dataTransformation.jobName,
      mock: dataTransformation.mock
    }
    onSave(initialData, dataTransformationData)
  }

  const translate = getTranslate(locale, dataTransformation.locale)
  const form = dataTransformation.form.map(formItem => translateFormItem(formItem, translate))

  return <Form locale={locale} form={form} data={data} onSave={handleSave} />
}

export default connect(
  state => {
    return {
      locale: state.locale,
      initialData: JSON.parse(JSON.stringify(state.project.dataTransformations || []))
    }
  },
  {
    onSave: save
  }
)(DataTransformationForm)
