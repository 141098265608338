import React, { Component } from 'react'
import Search from './Search'
import { Box, BoxLine, Option, OptionDropdown, Triangle, Wrapper, Title, SearchSelectWrapper, OptionLabel } from './styles'

const KEY_UP = 38
const KEY_DOWN = 40
const KEY_ENTER = 13

class SearchSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      cursor: 0,
      searchInput: '',
      filteredOptionsList: this.getFilteredOptionsList(props.selectedOption.value)
    }
    this.handleSelect = this.handleSelect.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this)
  }

  componentDidUpdate (prevProps) {
    const { selectedOption: previousSelectedOption } = prevProps
    const { selectedOption } = this.props

    if (selectedOption.value !== previousSelectedOption.value) {
      this.setState({
        filteredOptionsList: this.getFilteredOptionsList(selectedOption.value)
      })
    }
  }

  getFilteredOptionsList (optionValue, searchInput) {
    const { allOptions } = this.props

    let filteredOptionsList = allOptions.filter(item => item.value !== optionValue)
    if (searchInput) {
      filteredOptionsList = filteredOptionsList.filter(item =>
        item.label.toLowerCase().includes(searchInput.toLowerCase())
      )
    }
    return filteredOptionsList
  }

  handleKeyDown (event) {
    const { cursor, filteredOptionsList } = this.state
    const { keyCode } = event
    if (keyCode === KEY_UP && cursor > 0) {
      this.setState({ cursor: cursor - 1 })
    } else if (keyCode === KEY_DOWN && cursor < filteredOptionsList.length - 1) {
      this.setState({ cursor: cursor + 1 })
    } else if (keyCode === KEY_ENTER && filteredOptionsList[cursor]) {
      this.handleSelect(filteredOptionsList[cursor].value)
    }
  }

  handleChangeSearchInput (searchInput) {
    const { optionValue } = this.props
    this.setState({
      searchInput,
      cursor: 0,
      filteredOptionsList: this.getFilteredOptionsList(optionValue, searchInput)
    })
  }

  handleSelect (optionValue) {
    this.props.onSelect(optionValue)
    this.setState({
      isOpen: false,
      cursor: 0,
      searchInput: '',
      filteredOptionsList: this.getFilteredOptionsList(optionValue)
    })
  }

  handleClick () {
    if (this.props.allOptions.length > 1) {
      this.setState({
        isOpen: !this.state.isOpen
      })
    }
  }

  handleBlur (event) {
    if (!event.currentTarget.contains(event.relatedTarget)) {
      this.setState({
        isOpen: false
      })
    }
  }

  render () {
    const { title, selectedOption, allOptions } = this.props
    const { isOpen, cursor, searchInput, filteredOptionsList } = this.state

    return (
      <SearchSelectWrapper>
        <Title>{ title }</Title>
        <Wrapper
          enableHover={allOptions.length > 1}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          tabIndex='0'
        >
          <Option onClick={this.handleClick}>
            <OptionLabel>{selectedOption.label}</OptionLabel>
            {allOptions.length > 1 && <Triangle rotated={isOpen} />}
          </Option>
          {isOpen && (
            <OptionDropdown>
              {allOptions.length > 9 && (
                <Search
                  value={searchInput}
                  onChange={this.handleChangeSearchInput}
                />
              )}
              <Box>
                {filteredOptionsList.map((item, i) => (
                  <BoxLine
                    key={item.value}
                    onClick={() => this.handleSelect(item.value)}
                    selected={cursor === i}
                  >
                    {item.label}
                  </BoxLine>
                ))}
                {!filteredOptionsList.length && <BoxLine>No results found</BoxLine>}
              </Box>
            </OptionDropdown>
          )}
        </Wrapper>
      </SearchSelectWrapper>
    )
  }
}

export default SearchSelect
