import styled from 'styled-components'

export const AuthButton = styled.div`
  width: 100%;
  border-radius: 8px;
  border: 1px #e1e7ed solid;
  background-color: #ffffff;
  cursor: pointer;
  transition: all 0.25s ease;
  padding: 26px 10px;
  text-align: center;

  &:hover {
    border-color: #ccc;
    background-color: #f1f1f1;
  }
`

export const AuthIcon = styled.img`
  height: 30px;
  width: auto;
  margin-top: -4px;
  margin-bottom: -5px;
  margin-right: 20px;
`

export const AuthText = styled.span`
  font-size: 22px;
  color: #0c1537;
`

export const AuthError = styled.div`
  color: #ff706b;
  margin: 10px auto;
  text-align: center;
`
