import styled from 'styled-components'

export const PRO_MODE_HEIGHT = 33

export const Menu = styled.div`
  height: ${PRO_MODE_HEIGHT}px;
  min-width: 1050px;
  background-color: #fafafb;
  border-bottom: 1px solid #dee0e1;
`

export const MenuWrapper = styled.div`
  min-width: 1050px;
  height: 100%;
  margin: 0 auto;
  height: 100%;
  padding: 0 55px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Tab = styled.div`
  padding-left: 30px;
  font-size: 14px;
  line-height: 16px;
  color: #76797e;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
`

export const Right = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;
`
