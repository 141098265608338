import path from './path'
import { enable, disable } from 'api/event'
import { enablePublish } from 'redux/modules/project'
import { changedStatusEvent } from 'utils/digitalDataEvents'

const CHANGE_STATUS = `changeStatus@${path}`

export const changeStatus = (id, name, isEnabled) => async (dispatch, getState) => {
  const projectId = getState().project.id
  if (isEnabled) {
    await dispatch(enable(projectId, id))
  } else {
    await dispatch(disable(projectId, id))
  }
  dispatch({
    type: CHANGE_STATUS,
    isEnabled,
    id
  })
  dispatch(enablePublish())
  changedStatusEvent(name, path, isEnabled)
}

export default (state = [], action) => {
  if (action.type === CHANGE_STATUS) {
    return state.map(item =>
      item.id === action.id
        ? {
          ...item,
          isEnabled: action.isEnabled
        }
        : item
    )
  }
  return state
}
