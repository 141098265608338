import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchAwinAccounts } from 'api/dataSource'

const config = {
  type: 'Awin',
  authType: 'Basic',
  authName: 'Awin API token',
  logo,
  authIcon,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/awin',
    google_analytics: 'https://docs.segmentstream.com/datasources/awin'
  },
  authForm: [
    {
      label: 'API token',
      type: 'string',
      name: 'apiToken',
      validation: ['required'],
      tip: 'You can find the API token in the <a href="https://ui.awin.com/awin-api" target="_blank" rel="noopener noreferrer">API credentials section</a> of your Awin control panel.'
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Awin',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Account settings',
      type: 'group array',
      name: 'accountSettings',
      addButtonLabel: 'Add account',
      items: [
        {
          label: 'Account',
          type: 'dynamic select',
          name: 'accountId',
          validation: ['required'],
          placeholder: 'Select Account',
          optionsFetcher: fetchAwinAccounts,
          description: 'No accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://wiki.awin.com/index.php/Account_Permissions" target="_blank" rel="noopener noreferrer">create a new one</a> and click "Refresh".',
          extendedView: true
        },
        {
          label: 'Region',
          type: 'select',
          name: 'region',
          placeholder: 'Account region',
          options: ['AT', 'AU', 'BE', 'BR', 'BU', 'CA', 'CH', 'DE', 'DK', 'ES', 'FI', 'FR', 'GB', 'IE', 'IT', 'NL', 'NO', 'PL', 'SE', 'US'],
          validation: ['required']
        },
        {
          label: 'Timezone',
          type: 'select',
          name: 'timezone',
          placeholder: 'Account timezone',
          options: ['America/Sao_Paulo', 'Australia/Sydney', 'Europe/Berlin', 'Europe/Dublin', 'Europe/Helsinki', 'Europe/London', 'Europe/Paris', 'Canada/Central', 'Canada/Eastern', 'Canada/Mountain', 'Canada/Pacific', 'US/Central', 'US/Eastern', 'US/Mountain', 'US/Pacific', 'UTC'],
          validation: ['required']
        },
        {
          label: 'AWIN commission',
          type: 'number',
          name: 'commission',
          units: '%',
          defaultValue: '0',
          validation: ['required']
        }
      ]
    },
    {
      label: 'Transactions',
      type: 'boolean',
      name: 'transactions',
      defaultValue: true,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Publisher performance report',
      type: 'boolean',
      name: 'publisherPerformanceReport',
      defaultValue: true,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Creative performance report',
      type: 'boolean',
      name: 'creativePerformanceReport',
      defaultValue: true,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'UTM source',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'AWIN',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'UTM medium mapping',
      type: 'select',
      name: 'utmMediumMapping',
      options: ['Manual', 'Publisher Type'],
      defaultValue: 'Manual',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'UTM medium value',
      type: 'string',
      name: 'utmMedium',
      showIf: (state) => state.utmMediumMapping === 'Manual',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'UTM campaign mapping',
      type: 'select',
      name: 'utmCampaignMapping',
      options: ['None', 'Publisher Type', 'Publisher Name', 'Publisher ID'],
      defaultValue: 'None',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'utmGroupedReport',
          enable: ['utmSource', 'utmMediumMapping', 'utmMedium', 'utmCampaignMapping'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'UTM source',
          type: 'string',
          name: 'utmSource',
          defaultValue: 'AWIN'
        },
        {
          label: 'UTM medium mapping',
          type: 'select',
          name: 'utmMediumMapping',
          options: ['Manual', 'Publisher Type'],
          defaultValue: 'Manual'
        },
        {
          label: 'UTM medium value',
          type: 'string',
          name: 'utmMedium',
          showIf: (state) => (state.additionalTransformationSettings || {}).utmMediumMapping === 'Manual'
        },
        {
          label: 'UTM campaign mapping',
          type: 'select',
          name: 'utmCampaignMapping',
          options: ['None', 'Publisher Type', 'Publisher Name', 'Publisher ID'],
          defaultValue: 'None'
        }
      ]
    }
  ]
}

export default config
