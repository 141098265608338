import React from 'react'
import { connect } from 'react-redux'
import { create } from 'redux/modules/dataSources'
import IntegrationLogo from 'containers/IntegrationLogo'

import { DataSource, Name, Signal, Plus, LogoContainer, Label } from '../styles'

const DataSourceItem = ({ dataSource, onCreate, onRequest }) => {
  const handleCreate = () => {
    onCreate({ type: dataSource.type })
  }

  const handleRequest = () => {
    onRequest({ dataSource })
  }

  return (
    <DataSource onClick={dataSource.byRequest ? handleRequest : handleCreate}>
      {dataSource.byRequest && <Label>By request</Label>}
      {dataSource.poweredByFivetran && <Label>Fivetran</Label>}
      <LogoContainer>
        <IntegrationLogo logo={dataSource.logo} />
        <Signal>
          <Plus />
        </Signal>
      </LogoContainer>
      <Name>{dataSource.displayName || dataSource.type}</Name>
    </DataSource>
  )
}

export default connect(
  null,
  { onCreate: create }
)(DataSourceItem)
