import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'Prepare Sessions',
  jobName: 'prepare-sessions-table',
  icon,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/data-transformations/prepare-sessions',
  description: 'Groups information about user actions into sessions.',
  form: [
    {
      label: 'New session timeout',
      type: 'number',
      name: 'lookbackWindow',
      units: 'days',
      defaultValue: 90
    },
    {
      label: 'Session timeout',
      type: 'number',
      name: 'sessionTimeout',
      units: 'minutes',
      defaultValue: 30
    },
    {
      label: 'Referral Exclusion List',
      type: 'string array',
      name: 'referralExclusionList'
    }
  ]
}

export default config
