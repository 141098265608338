import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Reddit',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/reddit',
    google_analytics: 'https://docs.segmentstream.com/datasources/reddit'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Reddit Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Reddit',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Reddit',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        }
      ]
    }
  ]
}

export default config
