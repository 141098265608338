import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Recreativ',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/recreativ',
  authType: 'Basic',
  authIcon,
  authName: 'Recreativ API token',
  isPublished: true,
  authForm: [
    {
      label: 'API token',
      type: 'string',
      name: 'apiToken',
      validation: ['required']
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Recreativ',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    }
  ]
}

export default config
