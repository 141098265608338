import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'First Click Attribution',
  icon,
  locale: { ru },
  description:
    'Forms a table with sessions attributed to the first click within the lookback window. 100% of the conversion value is assigned to the first channel through which the visitor came before the conversion.',
  form: [
    {
      label: 'Lookback window',
      type: 'number',
      name: 'lookbackWindow',
      units: 'days'
    }
  ]
}

export default config
