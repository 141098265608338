import logo from './logo.png'

const config = {
  type: 'Pinterest',
  enabledForGoogleAnalyticsProjects: true,
  docsUrl: 'https://docs.segmentstream.com/datasources/pinterest',
  poweredByFivetran: true,
  logo,
  authForm: [
    {
      label: 'Click to connect Pinterest via Fivetran',
      type: 'oauth2 button',
      name: 'isAuthenticated'
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Pinterest',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Shopping campaigns settings',
      type: 'group',
      name: 'shoppingCampaignsSettings',
      items: [
        {
          label: 'Landing page domain for Shopping campaigns',
          type: 'string',
          name: 'landingPageDomain',
          placeholder: 'www.example.com',
          tip: 'Read more about this setting <br>in the <a href="https://docs.segmentstream.com/datasources/pinterest#utm-matching-for-shopping-campaigns" target="_blank" rel="noopener noreferrer">documentation</a>.'
        },
        {
          label: 'UTM parameters for all Shopping campaigns',
          type: 'string',
          name: 'defaultUtmParams',
          placeholder: 'utm_source=pinterest&utm_medium=cpc&utm_campaign={campaignid}',
          tip: 'Read more about this setting <br>in the <a href="https://docs.segmentstream.com/datasources/pinterest#utm-matching-for-shopping-campaigns" target="_blank" rel="noopener noreferrer">documentation</a>.'
        },
        {
          label: 'UTM parameters for specific Shopping campaigns',
          type: 'string mapping',
          name: 'campaignUtmParamsMap',
          placeholderKey: 'Campaign ID',
          placeholderValue: 'utm_source=pinterest&utm_medium=cpc&utm_campaign={campaignid}',
          tip: 'Read more about this setting <br>in the <a href="https://docs.segmentstream.com/datasources/pinterest#utm-matching-for-shopping-campaigns" target="_blank" rel="noopener noreferrer">documentation</a>.'
        }
      ]
    }
  ]
}

export default config
