import path from './path'

const TYPE = `success@${path}`
export const SUCCESS = 'success'

export const success = () => ({
  type: TYPE
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      ...state,
      status: SUCCESS
    }
    : state
