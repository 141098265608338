export const fetchCountryCode = async () => {
  try {
    const response = await window.fetch('https://segmentstream.com/cdn-cgi/trace')

    if (response.status !== 200) {
      return
    }

    const responseBody = await response.text()

    const location = responseBody.match(/^loc=(.*)$/gm)
    if (!location.length) {
      return
    }

    return location[0].split('=').slice(-1)[0]
  } catch (e) {
  }
}
