import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { allDataDestinations, sandboxDataDestinations } from 'dataDestinations'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'
import DataDestinationItem from './DataDestinationItem'
import { close } from 'redux/modules/page/dataDestinations/createDataDestinationSide/close'
import { ItemContainer } from './styles'
import ru from './ru'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

const CreateDataDestinationSide = ({ isOpen, onClose, isSaleMode, locale, features }) => {
  const availableDataDestinations = (features || []).includes(ProjectFeature.BetaFeatures) ? allDataDestinations : allDataDestinations.filter(dd => !dd.betaFeature)

  const dataDestinations = isSaleMode ? [...sandboxDataDestinations, ...availableDataDestinations] : availableDataDestinations

  const translate = getTranslate(locale, { ru })

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Add Data Destination')}>
      <ItemContainer>
        {dataDestinations.map(dataDestination => (
          <DataDestinationItem key={dataDestination.id || dataDestination.type} dataDestination={dataDestination} />
        ))}
      </ItemContainer>
    </ModalWindow>
  )
}

const mapStateToProps = state => {
  const { isOpen } = state.page.dataDestinations.createDataDestinationSide
  return {
    isOpen,
    locale: state.locale,
    isSaleMode: state.saleMode.status,
    isProMode: isProMode(state.proMode.status, state.user.role),
    features: state.project.features
  }
}

const mapDispatchToProps = () => ({
  onClose: close
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(CreateDataDestinationSide)
