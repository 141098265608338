import path from './path'
import { create as apiCreate, update as apiUpdate } from 'api/enrichment'
import { enablePublish } from 'redux/modules/project'
import { savedEvent } from 'utils/digitalDataEvents'

const CREATE = `create@${path}`
const UPDATE = `update@${path}`

export const save = enrichment => async (dispatch, getState) => {
  const projectId = getState().project.id
  const eventLabel = enrichment.field
  if (enrichment.id === undefined) {
    const createdEnrichment = await dispatch(apiCreate(projectId, enrichment))
    dispatch({
      type: CREATE,
      enrichment: createdEnrichment
    })
  } else {
    const updatedEnrichment = await dispatch(apiUpdate(projectId, enrichment))
    dispatch({
      type: UPDATE,
      enrichment: updatedEnrichment
    })
  }
  dispatch(enablePublish())
  savedEvent(eventLabel, path)
}

export default (state = [], action) => {
  if (action.type === CREATE) {
    return [...state, { ...action.enrichment }]
  }
  if (action.type === UPDATE) {
    return state.map(item => (item.id === action.enrichment.id ? action.enrichment : item))
  }
  return state
}
