import reduceReducers from 'reduce-reducers'
import setStatusReducer, { setStatus } from './setStatus'

export { setStatus }

export default reduceReducers(
  (state = {}, action) => ({
    ...state
  }),
  setStatusReducer
)
