import logo from './logo.png'
import authIcon from './authIcon.png'
import {
  fetchDV360Partners,
  fetchDV360Advertisers
} from 'api/dataSource'

const config = {
  type: 'Google Display & Video 360',
  enabledForGoogleAnalyticsProjects: false,
  docsUrl: 'https://docs.segmentstream.com/datasources/google-display-and-video-360',
  authType: 'OAuth2',
  logo,
  authIcon,
  authName: 'Google Account',
  authForm: [
    {
      label: 'Sign in with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Display & Video 360',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Partner',
      type: 'dynamic select',
      name: 'partnerId',
      validation: ['required'],
      placeholder: 'Select partner',
      optionsFetcher: fetchDV360Partners,
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Advertisers',
      type: 'dynamic select array',
      name: 'advertisersIds',
      validation: ['required'],
      placeholder: 'Select advertiser',
      optionsFetcher: fetchDV360Advertisers,
      searchable: true,
      uniqueValues: true,
      dependsOn: ['partnerId']
    },
    {
      label: 'UTM source for YouTube & partners ads',
      type: 'string',
      placeholder: 'youtube',
      tip: 'Enter the utm_source parameter used in the URLs<br> of your Youtube & partners ads.<br>Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/google-display-and-video-360#utm-matching-for-youtube-&-partners-ads" target="_blank" rel="noopener noreferrer">documentation</a>.',
      name: 'youtubeAndPartnersUTMSource'
    },
    {
      label: 'UTM medium for YouTube & partners ads',
      type: 'string',
      placeholder: 'cpm',
      tip: 'Enter the utm_medium parameter used in the URLs<br> of your Youtube & partners ads.<br>Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/google-display-and-video-360#utm-matching-for-youtube-&-partners-ads" target="_blank" rel="noopener noreferrer">documentation</a>.',
      name: 'youtubeAndPartnersUTMMedium'
    }
  ]
}

export default config
