import React from 'react'
import getValidationError from 'containers/Form/getValidationError'
import { StyledInput } from './styles'

const Input = ({
  disabled,
  hasFocus,
  isNumber,
  name,
  onBlur,
  onChange,
  onFocus,
  onTouch,
  placeholder,
  style,
  touched,
  type = 'text',
  value = '',
  validation,
  maxLength,
  autocomplete,
  theme,
  fieldError
}) => {
  const handleChange = event => {
    const inputValue = event.target.value
    if (inputValue === '') {
      onChange(undefined)
      return
    }

    if (isNumber && !isNaN(inputValue)) {
      onChange(Number(inputValue))
      return
    }

    if (!isNumber) {
      onChange(inputValue)
    }
  }

  const handleFocus = () => {
    if (!hasFocus && onFocus) {
      onFocus()
    }
    if (!touched && onTouch) {
      onTouch()
    }
  }

  const handleBlur = event => {
    if (!isNumber && event.target.value !== '') {
      const trimmedValue = event.target.value.trim()
      if (trimmedValue !== value) {
        onChange(trimmedValue)
      }
    }

    if (hasFocus && onBlur) {
      onBlur()
    }
  }

  const error =
    touched && !disabled && !hasFocus && validation
      ? getValidationError(validation, value)
      : undefined

  return (
    <StyledInput
      style={style}
      error={error || fieldError}
      type={type}
      name={name}
      value={String(value)}
      placeholder={!hasFocus ? placeholder : ''}
      disabled={disabled}
      onChange={handleChange}
      onClick={handleFocus}
      onBlur={handleBlur}
      autoFocus={hasFocus}
      maxLength={maxLength}
      autoComplete={autocomplete}
      theme={theme}
    />
  )
}

export default Input
