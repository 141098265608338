import React, { Component } from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import { DragDropContext as dragDropContext } from 'react-dnd'
import HTML5Backend from 'react-dnd-html5-backend'
import CardBlock from './CardBlock'
import { save } from 'redux/modules/priority/save'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'
import { PriorityLabel } from './styles'

const moveItem = (array, dragItem, hoverItem) => {
  const itemIndex = array.indexOf(dragItem)
  return array.reduce((acc, val, index) => {
    if (val === dragItem) {
      return acc
    }
    if (val === hoverItem) {
      return index > itemIndex ? [...acc, val, dragItem] : [...acc, dragItem, val]
    }
    return [...acc, val]
  }, [])
}

const dropItem = (array, item) =>
  array.reduce((acc, val) => {
    if (val === item) return acc
    if (val === null) return [...acc, item]
    return [...acc, val]
  }, [])

const moveFakeItem = (array, dragItem, hoverItem) => {
  if (array.includes(dragItem)) {
    return moveItem(array, dragItem, hoverItem)
  }
  if (array.length === 0 && hoverItem === undefined) {
    return [null]
  }
  const nullIndex = array.indexOf(null)
  return array.reduce((acc, val, index) => {
    if (val === null) {
      return acc
    }
    if (val === hoverItem) {
      return nullIndex > index ? [...acc, null, val] : [...acc, val, null]
    }
    return [...acc, val]
  }, [])
}

class Priority extends Component {
  constructor (props) {
    super(props)
    this.moveCard = this.moveCard.bind(this)
    this.dropNewCard = this.dropNewCard.bind(this)
    this.dropCard = this.dropCard.bind(this)
  }

  UNSAFE_componentWillMount () { // eslint-disable-line
    const { before, after } = this.props.priority
    this.setState({
      beforeArray: before,
      afterArray: after
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    const { before, after } = nextProps.priority || {}
    this.setState({
      beforeArray: before,
      afterArray: after
    })
  }

  moveCard (dragItem, hoverItem) {
    const { beforeArray, afterArray } = this.state
    this.setState({
      beforeArray: moveFakeItem(beforeArray, dragItem, hoverItem),
      afterArray: moveFakeItem(afterArray, dragItem, hoverItem)
    })
  }

  dropNewCard (dragItem) {
    const { beforeArray, afterArray } = this.state
    const before = dropItem(beforeArray, dragItem)
    const after = dropItem(afterArray, dragItem)
    this.setState({
      beforeArray: before,
      afterArray: after
    })
    this.props.onSave({
      before,
      after
    })
  }

  dropCard () {
    this.props.onSave({
      before: this.state.beforeArray,
      after: this.state.afterArray
    })
  }

  render () {
    const { beforeArray, afterArray } = this.state
    const { locale, isSaleMode } = this.props
    const translate = getTranslate(locale, { ru })
    const priorityLabelBefore = (
      <PriorityLabel priority='before'>{translate('During page loading')}</PriorityLabel>
    )
    const priorityLabelAfter = (
      <PriorityLabel priority='after'>{translate('After page loaded')}</PriorityLabel>
    )
    return (
      <div>
        <SectionHeader
          leftContent={priorityLabelBefore} leftWidth='50%'
          rightContent={priorityLabelAfter} rightWidth='50%'
        />
        <div style={{ display: 'flex' }}>
          <CardBlock
            priority='before'
            array={beforeArray}
            moveCard={this.moveCard}
            dropCard={this.dropCard}
            dropNewCard={this.dropNewCard}
            isSaleMode={isSaleMode}
          />

          <CardBlock
            priority='after'
            array={afterArray}
            moveCard={this.moveCard}
            dropCard={this.dropCard}
            dropNewCard={this.dropNewCard}
            isSaleMode={isSaleMode}
          />
        </div>
      </div>
    )
  }
}

export default dragDropContext(HTML5Backend)(
  connect(
    state => ({
      locale: state.locale,
      priority: state.priority,
      isSaleMode: state.saleMode.status
    }),
    {
      onSave: save
    }
  )(Priority)
)
