import { remove as apiRemove } from 'api/dataSource'
import path from './path'
import { deletedEvent } from 'utils/digitalDataEvents'

const REMOVE = `remove@${path}`

export const remove = (id, type) => async (dispatch, getState) => {
  const projectId = getState().project.id
  deletedEvent(type, path)

  await dispatch(apiRemove(projectId, id))
  dispatch({
    type: REMOVE,
    id
  })
}

export default (state = {}, action) => {
  if (action.type === REMOVE) {
    return Object.keys(state).reduce((acc, dataSourceId) => {
      const dataSource = state[dataSourceId]
      if (action.id !== dataSource.id) {
        acc[dataSource.id] = dataSource
      }
      return acc
    }, {})
  }
  return state
}
