import styled from 'styled-components'

export default styled.div`
  margin-left: 14px;
  margin-right: 17px;
  margin-top: 2px;
  width: 4px;
  height: 4px;
  border: solid #93a2b1;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  -webkit-transform: rotate(-45deg);
  -ms-transform: rotate(-45deg);
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
`
