export const setProjectVariable = ({ id, name, hasUnpublishedChanges, type }) => {
  const project = {
    name,
    id,
    hasUnpublishedChanges,
    type
  }
  window.digitalData.changes.push(['project', project])
}

export const setLanguageVariable = locale => {
  const language = locale === 'en' ? 'en-GB' : 'ru'
  window.digitalData.changes.push(['website.language', language])
}

export const setHasUnpublishedChangesVariable = hasUnpublishedChanges => {
  window.digitalData.changes.push(['project.hasUnpublishedChanges', hasUnpublishedChanges])
}

export const setUserVariable = ({ userId, email, role }) => {
  window.digitalData.changes.push([
    'user',
    {
      ...window.digitalData.user,
      userId,
      email,
      role
    }
  ])
}

export const setBillingAccountVariable = ({ billingAccountId, id, accountName, pricingPlan }) => {
  window.digitalData.changes.push([
    'billingAccount',
    {
      id: id || billingAccountId,
      pricingPlan,
      name: accountName
    }
  ])
}
