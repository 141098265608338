import path from './path'

const FETCH = `fetch@${path}`

export const fetch = events => ({
  type: FETCH,
  events
})

export default (state = [], action) => (action.type === FETCH ? action.events : state)
