import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import reducer from './modules'
import { composeWithDevTools } from 'redux-devtools-extension'
import { createCookieMiddleware } from 'redux-cookie'
import apiCall from './apiCall'
import crashReporter from './crashReporter'

export default cookies => {
  const store = createStore(
    reducer,
    undefined,
    composeWithDevTools(
      applyMiddleware(apiCall, thunk, createCookieMiddleware(cookies), crashReporter)
    )
  )

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./modules', () => {
      store.replaceReducer(require('./modules'))
    })
  }

  return store
}
