import { apiCall } from '../api/apiCall'
import { AuthenticationError } from '../api/api-errors'
import { logout } from './modules/auth'

const call = async (options, dispatch) => {
  let res
  try {
    res = await apiCall(options)
  } catch (error) {
    if (error instanceof AuthenticationError) {
      return dispatch(logout())
    }
  }
  const body = await res.text()

  try {
    return JSON.parse(body)
  } catch (error) {
    return body
  }
}

export default store => next => action => {
  if (typeof action !== 'object' || action.type !== undefined || action.url === undefined) {
    return next(action)
  }
  return call(action, store.dispatch, store.getState)
}
