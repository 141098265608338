import { apiCall } from './apiCall'
// import { billingAccountCurrency } from '../containers/App/Dashboard/BlueHeader/Billing/billingAccount'

export const update = (projectId, id, dataDestination) => ({
  url: `/projects/${projectId}/data_destinations/${id}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(dataDestination)
})

export const create = (projectId, dataDestination) => ({
  url: `/projects/${projectId}/data_destinations`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(dataDestination)
})

export const remove = (projectId, id) => ({
  url: `/projects/${projectId}/data_destinations/${id}`,
  method: 'DELETE'
})

export const removeAuth = (projectId, id) => ({
  url: `/projects/${projectId}/data_destinations/${id}/auth_options`,
  method: 'DELETE'
})

export const enable = (projectId, id) => ({
  url: `/projects/${projectId}/data_destinations/${id}/enable`,
  method: 'POST'
})

export const disable = (projectId, id) => ({
  url: `/projects/${projectId}/data_destinations/${id}/disable`,
  method: 'POST'
})

const fetchAndParse = async (url, throwOriginalError = false) => {
  const resp = await apiCall({
    url,
    method: 'GET'
  })

  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }

  return resp.json()
}

export const fetchGoogleAnalyticsAccounts = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/accountId/values`)
export const fetchGoogleAnalyticsProperties = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/propertyId/values?accountId=${context.dependentFieldValues.accountId}`)
export const fetchGoogleAnalyticsDataSets = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/dataSetId/values?accountId=${context.dependentFieldValues.accountId}&propertyId=${context.dependentFieldValues.propertyId}&dataSetType=${context.dependentFieldValues.dataSetType}`)
export const checkAuthOptions = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/checkAuthOptions`).catch(e => console.error(e))
export const fetchGoogleAdsCustomers = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/customerId/values`)

export const fetchGoogleAdsConversions = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/conversion/values?customerId=${context.dependentFieldValues.customerId}`)

export const fetchYandexMetricaCounters = (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/counters/values`)
export const fetchYandexMetricaGoals = async (context) => fetchAndParse(`/projects/${context.projectId}/data_destinations/${context.id}/options/goals/values?counterId=${context.dependentFieldValues.counterId}`)
