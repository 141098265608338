import path from './path'
import { viewedEvent } from 'utils/digitalDataEvents'
import { dismissTip } from 'redux/modules/project/dismissTip'

const OPEN = `open@${path}`

export const open = (id, type) => dispatch => {
  viewedEvent(type, path)
  dispatch(dismissTip())
  dispatch({
    type: OPEN,
    id
  })
}

export default (state = {}, action) =>
  action.type === OPEN ? { ...state, isOpen: true, id: action.id } : state
