import { CURRENCY_SYMBOLS, getCurrencyCode, EU_COUNTRIES } from 'containers/App/SignUp/countries'

export const isSubscriptionActive = (billingAccount) =>
  billingAccount.subscriptionStatus &&
  billingAccount.subscriptionStatus.expiresAt &&
  new Date(billingAccount.subscriptionStatus.expiresAt) > new Date()

export const isSubscriptionExpired = (billingAccount) =>
  billingAccount.subscriptionStatus &&
  billingAccount.subscriptionStatus.expiresAt &&
  new Date(billingAccount.subscriptionStatus.expiresAt) < new Date()

export const isTrialActive = (billingAccount) =>
  !billingAccount.subscriptionStatus &&
  billingAccount.trialStatus &&
  billingAccount.trialStatus.expiresAt &&
  new Date(billingAccount.trialStatus.expiresAt) > new Date()

export const isTrialExpired = (billingAccount) =>
  !billingAccount.subscriptionStatus &&
  billingAccount.trialStatus &&
  billingAccount.trialStatus.expiresAt &&
  new Date(billingAccount.trialStatus.expiresAt) < new Date()

export const isBillingAccountActive = (billingAccount) =>
  isTrialActive(billingAccount) || isSubscriptionActive(billingAccount)

export const billingAccountCurrency = (billingAccount) => {
  const code = getCurrencyCode(billingAccount.countryCode)
  const symbol = CURRENCY_SYMBOLS[code] || code

  return { code, symbol }
}

export const shouldValidateTaxIdForCountry = (countryCode) => {
  return EU_COUNTRIES.includes(countryCode) ||
    countryCode === 'GB' ||
    countryCode === 'AU' ||
    countryCode === 'NO' ||
    countryCode === 'CH'
}

// TODO: tax id validation on backend
export const validateVatId = (taxId, countryCode) => {
  if (!shouldValidateTaxIdForCountry(countryCode)) {
    return { valid: true }
  }

  const type =
    EU_COUNTRIES.includes(countryCode) ? 'eu_vat'
      : countryCode === 'GB' ? 'eu_vat'
        : countryCode === 'AU' ? 'au_gst'
          : countryCode === 'NO' ? 'no_vat'
            : countryCode === 'CH' ? 'ch_vat'
              : undefined

  const params = {
    type,
    vat_id: taxId
  }

  return window
    .fetch('https://api.vatstack.com/v1/validations', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json; charset=utf-8',
        Authorization: 'Credential c1c70837e6347de1e72c236cc1267d4d'
      },
      body: JSON.stringify(params)
    })
    .then(r => r.json())
}

export const normalizeTaxId = (taxId, countryCode) => {
  if (!shouldValidateTaxIdForCountry(countryCode)) {
    return taxId
  }

  if (taxId.indexOf(countryCode) === 0) {
    return taxId
  }

  switch (true) {
    case countryCode === 'GR':
      return 'EL' + taxId
    default:
      return countryCode + taxId
  }
}
