import { getProject as apiGetProject } from 'api/project'
import { fetchIntegrations } from 'redux/modules/integrations'
import { fetch as fetchEnrichments } from 'redux/modules/enrichments/fetch'
import { fetch as fetchEvents } from 'redux/modules/events/fetch'
import { fetch as fetchScripts } from 'redux/modules/scripts/fetch'
import { fetch as fetchDataSources } from 'redux/modules/dataSources/fetch'
import { fetch as fetchDataDestinations } from 'redux/modules/dataDestinations/fetch'
import { fetch as fetchPriority } from 'redux/modules/priority/fetch'
import { setProjectVariable, setBillingAccountVariable } from 'utils/digitalDataVariables'
import { getReportName } from 'utils/getReportName'
import { changePage, INTEGRATIONS, REPORTS, DATA_SOURCES, DATA_DESTINATIONS, DATA_TRANSFORMATIONS, STANDARD_PAGES, GENERAL_PAGES, PROJECT_CREATION } from 'redux/modules/page/changePage'
import path from './path'
import {
  isAvailableForPricingPlan,
  DATA_SOURCES_PRICING_PLANS,
  TRIAL_PRICING_PLANS
} from 'utils/pricingPlans'
import { PROJECT_TYPES } from './index'

const FETCH_PROJECT = `fetchProject@${path}`

export const NO_REDIRECT = 'noRedirect'

export const fetchProject = (projectId, page, userRole) => async dispatch => {
  if (!projectId) {
    dispatch(changePage(PROJECT_CREATION))
    return
  }
  const project = await dispatch(apiGetProject(projectId))
  const { options = {}, billingAccount = {} } = project
  const forceAllow = userRole === 'super_admin'
  const dataSourcesAvailable = forceAllow || (project.type === PROJECT_TYPES.GoogleAnalytics && isAvailableForPricingPlan(undefined, billingAccount.pricingPlan, DATA_SOURCES_PRICING_PLANS))
  const dataDestinationsAvailable = forceAllow
  const dataTransformationsAvailable = forceAllow
  const isTrialPricingPlan = TRIAL_PRICING_PLANS.includes(billingAccount.pricingPlan)
  setProjectVariable({
    id: project.projectId,
    name: project.projectName,
    hasUnpublishedChanges: !project.isPublished,
    type: project.type
  })
  setBillingAccountVariable(billingAccount)
  await dispatch({
    type: FETCH_PROJECT,
    project
  })
  dispatch(fetchIntegrations(project.integrations))
  dispatch(fetchEnrichments(project.enrichments))
  dispatch(fetchEvents(project.events))
  dispatch(fetchScripts(project.scriptsList))
  dispatch(fetchPriority(project.integrationsPriority))
  dispatch(fetchDataSources(project.dataSources))
  dispatch(fetchDataDestinations(project.dataDestinations))

  if (page !== NO_REDIRECT) {
    if (project.type === PROJECT_TYPES.GoogleAnalytics) {
      if (page === PROJECT_CREATION && !isTrialPricingPlan) {
        dispatch(changePage(PROJECT_CREATION))
      } else if (!page || !GENERAL_PAGES.includes(page)) {
        dispatch(changePage(DATA_SOURCES))
      } else if (page === DATA_DESTINATIONS || GENERAL_PAGES.includes(page)) {
        dispatch(changePage(page))
      }
    } else {
      if (page === DATA_SOURCES && dataSourcesAvailable) {
        await dispatch(changePage(DATA_SOURCES))
      } else if (page === DATA_DESTINATIONS && dataDestinationsAvailable) {
        await dispatch(changePage(DATA_DESTINATIONS))
      } else if (page === DATA_TRANSFORMATIONS && dataTransformationsAvailable) {
        await dispatch(changePage(DATA_TRANSFORMATIONS))
      } else if (page === PROJECT_CREATION && !isTrialPricingPlan) {
        dispatch(changePage(PROJECT_CREATION))
      } else if (GENERAL_PAGES.includes(page)) {
        await dispatch(changePage(page))
      } else if (STANDARD_PAGES.includes(page)) {
        await dispatch(changePage(page))
      } else {
        if (getReportName(options)) {
          await dispatch(changePage(REPORTS(getReportName(options))))
        } else if (dataSourcesAvailable) {
          await dispatch(changePage(DATA_SOURCES))
        } else if (page === DATA_DESTINATIONS && dataDestinationsAvailable) {
          await dispatch(changePage(DATA_DESTINATIONS))
        } else if (page === DATA_TRANSFORMATIONS && dataTransformationsAvailable) {
          await dispatch(changePage(DATA_TRANSFORMATIONS))
        } else {
          await dispatch(changePage(INTEGRATIONS))
        }
      }
    }
  }
}

export default (state = {}, action) => {
  if (action.type === FETCH_PROJECT) {
    const {
      projectName,
      projectId,
      customSdkUrl,
      isPublished,
      customIntegrations,
      dataTransformations,
      advancedLevelOfSecurity,
      options,
      scripts,
      snippet,
      members,
      invitations,
      apiToken,
      userId,
      billingAccount,
      type,
      createdAt,
      ProjectFeatures,
      currency,
      timezone
    } = action.project
    return {
      ...state.project,
      name: projectName,
      id: projectId,
      customSDKUrl: customSdkUrl,
      isPublished,
      customIntegrations,
      dataTransformations,
      advancedLevelOfSecurity,
      options,
      scripts,
      snippet,
      apiToken,
      members,
      invitations,
      userId,
      billingAccount,
      enabledTips: [],
      type,
      createdAt,
      features: (ProjectFeatures || []).map(f => f.feature),
      currency,
      timezone
    }
  }
  return state
}
