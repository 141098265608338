import logo from './logo.png'

export const webhookConfig = {
  type: 'Webhook',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datastreams/webhook',
  form: dataStream => [
    {
      label: 'Display name',
      type: 'string',
      name: 'displayName',
      defaultValue: 'My Webhook',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data stream in SegmentStream.'
    },
    {
      label: 'Destination table name',
      type: 'string',
      name: 'destinationTableName',
      defaultValue: 'webhookStream',
      validation: ['required'],
      tip: 'BigQuery table name'
    }
  ]
}
