import path from './path'
import { enablePublish } from 'redux/modules/project'
import { enable as apiEnable, disable as apiDisable, saveOptions as apiSaveOptions } from 'api/integration'
import { add as addPriority } from 'redux/modules/priority/add'
import { remove as removePriority } from 'redux/modules/priority/remove'
import { changedStatusEvent } from 'utils/digitalDataEvents'

const CHANGE_STATUS = `changeStatus@${path}`

export const changeStatus = (name, isEnabled, firstEnable) => async (dispatch, getState) => {
  const projectId = getState().project.id
  if (firstEnable) {
    await dispatch(apiSaveOptions(projectId, name))
  }
  if (isEnabled) {
    await dispatch(apiEnable(projectId, name))
    await dispatch(addPriority(name))
  } else {
    await dispatch(apiDisable(projectId, name))
    await dispatch(removePriority(name))
  }
  dispatch({
    type: CHANGE_STATUS,
    name,
    isEnabled
  })
  dispatch(enablePublish())
  changedStatusEvent(name, path, isEnabled)
}

export default (state = {}, action) =>
  action.type === CHANGE_STATUS
    ? {
      ...state,
      [action.name]: {
        ...state[action.name],
        isEnabled: action.isEnabled
      }
    }
    : state
