import path from './path'

const FETCH_DATA_DESTINATIONS = `fetch@${path}`

export const fetch = dataDestinations => ({
  type: FETCH_DATA_DESTINATIONS,
  dataDestinations
})

export default (state = {}, action) =>
  action.type === FETCH_DATA_DESTINATIONS
    ? action.dataDestinations.reduce(
      (acc, dataDestination) => ({
        ...acc,
        [dataDestination.id]: dataDestination
      }),
      {}
    )
    : state
