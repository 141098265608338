import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { Iframe } from './styles'
import { HEADER_HEIGHT } from 'containers/App/Dashboard/BlueHeader/styles'
import { PRO_MODE_HEIGHT } from 'containers/App/Dashboard/ProMode/Header/styles'
import { isProMode } from 'utils/proMode'

const MIN_APP_WIDTH = 1050
const MAX_APP_WIDTH = 1200
const SIDE_MENU_OFFSET = 250
const SIDE_MENU_OFFSET_SMALL = 222
const SMALL_SCREEN_WIDTH = 1440
const ZENDESK_ELEMENT_ID = 'launcher'

class Reports extends PureComponent {
  constructor (props) {
    super(props)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  UNSAFE_componentWillMount () { // eslint-disable-line
    this.updateDimensions()
  }

  componentDidMount () {
    if (document.getElementById(ZENDESK_ELEMENT_ID)) {
      document.getElementById(ZENDESK_ELEMENT_ID).style.right = '9999px'
    }
    window.addEventListener('resize', this.updateDimensions)
  }

  componentWillUnmount () {
    if (document.getElementById(ZENDESK_ELEMENT_ID)) {
      document.getElementById(ZENDESK_ELEMENT_ID).style.right = '0px'
    }
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions () {
    const clientWidth = window.document.documentElement.clientWidth
    const sideOffset = clientWidth <= SMALL_SCREEN_WIDTH ? SIDE_MENU_OFFSET_SMALL : SIDE_MENU_OFFSET
    const appWidth =
      clientWidth > MIN_APP_WIDTH
        ? clientWidth
        : MIN_APP_WIDTH
    const iframeWidth = appWidth - sideOffset < MAX_APP_WIDTH ? appWidth - sideOffset : MAX_APP_WIDTH
    const iframeLeftOffset = (appWidth - sideOffset - iframeWidth) / 2 + sideOffset
    const topOffset = this.props.isProMode ? HEADER_HEIGHT + PRO_MODE_HEIGHT : HEADER_HEIGHT
    this.setState({
      width: iframeWidth,
      height: window.document.documentElement.clientHeight - topOffset,
      left: iframeLeftOffset
    })
  }

  render () {
    const { reportUrl } = this.props
    const { width, height, left } = this.state
    return <Iframe src={reportUrl} width={width} height={height} left={left} />
  }
}

export default connect(
  state => ({
    isProMode: isProMode(state.proMode.status, state.user.role)
  }))(Reports)
