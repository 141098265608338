import { saveAll } from './saveAll'
import { close } from './close'

export const remove = id => async (dispatch, getState) => {
  const name = getState().page.integrations.integrationForm.name
  const eventEnrichments = getState().integrations[name].eventEnrichments || []
  const list = eventEnrichments.reduce((acc, item) => (item.id !== id ? [...acc, item] : acc), [])
  await dispatch(saveAll(name, list))
  dispatch(close())
}
