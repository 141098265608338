import PrepareSessions from './PrepareSessions'
// import AggregateCosts from './AggregateCosts'
import MLbasedUserScoring from './MLbasedUserScoring'
import FirstClickAttribution from './FirstClickAttribution'
import AffiliateFraudDetection from './AffiliateFraudDetection'
import FunnelBasedAttribution from './FunnelBasedAttribution'
import LastNonDirectClickAttribution from './LastNonDirectClickAttribution'
import MLbasedAttribution from './MLbasedAttribution'
import InternalPromo from './InternalPromo'

const NORMALIZED_COSTS_DEPENDENCY = 'normalizedCostsDependency'
const FIXER_CURRENCY_DEPENDENCY = 'fixerCurrencyDependency'

const dataTransformations = [
  PrepareSessions
  // AggregateCosts
]

const mockDataTransformations = [
  MLbasedUserScoring,
  FirstClickAttribution,
  AffiliateFraudDetection,
  FunnelBasedAttribution,
  LastNonDirectClickAttribution,
  MLbasedAttribution,
  InternalPromo
]

mockDataTransformations.forEach(dt => {
  dt.mock = true
})

export {
  NORMALIZED_COSTS_DEPENDENCY,
  FIXER_CURRENCY_DEPENDENCY,
  mockDataTransformations
}

export default dataTransformations
