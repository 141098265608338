import logo from './logo.png'
import sideLogo from './logo-side.png'
import { EMAIL } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Ofsys',
  color: '#4486c8',
  category: [EMAIL],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/ofsys',
  form: [
    {
      label: 'Account key',
      type: 'string',
      name: 'accountKey',
      validation: ['required']
    }
  ]
}

export default config
