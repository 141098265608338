import React, { Component } from 'react'
import {
  NotificationTab,
  NotificationContent,
  NotificationIcon,
  NotificationText,
  CloseButton
} from './styles'

export default class Notification extends Component {
  constructor (props) {
    super(props)
    this.setWrapperRef = this.setWrapperRef.bind(this)
    this.handleClickOutside = this.handleClickOutside.bind(this)
  }

  componentDidMount () {
    document.addEventListener('click', this.handleClickOutside)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClickOutside)
  }

  setWrapperRef (node) {
    this.wrapperRef = node
  }

  handleClickOutside (event) {
    if (this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.props.dismissTip()
    }
  }

  render () {
    const { text, dismissTip } = this.props
    const createMarkup = () => ({ __html: text })
    return (
      <div ref={this.setWrapperRef}>
        <NotificationTab>
          <NotificationContent>
            <NotificationIcon />
            <NotificationText dangerouslySetInnerHTML={createMarkup()} />
          </NotificationContent>
          <CloseButton onClick={dismissTip} />
        </NotificationTab>
      </div>
    )
  }
}
