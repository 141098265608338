import logo from './logo.png'
import sideLogo from './logo-side.png'
import { EMAIL, PUSH_NOTIFICATIONS } from 'integrations/categories'
import ru from './ru.json'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'

const API_V2 = 'V2'
const API_V3 = 'V3'

const config = {
  name: 'Mindbox',
  color: '#62be1f',
  category: [EMAIL, PUSH_NOTIFICATIONS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/mindbox',
  form: [
    {
      label: 'API version',
      type: 'select',
      name: 'apiVersion',
      options: [
        {
          label: API_V2,
          value: API_V2,
          enable: [
            'userIdProvider',
            'brandSystemName',
            'pointOfContactSystemName',
            'projectSystemName',
            'projectDomain',
            'setCartOperation'
          ]
        },
        {
          label: API_V3,
          value: API_V3,
          enable: [
            'productIdsMapping',
            'productSkuIdsMapping',
            'productCategoryIdsMapping',
            'customerIdsMapping',
            'areaIdsMapping',
            'endpointId'
          ]
        }
      ],
      defaultValue: API_V2
    },
    {
      label: 'Endpoint ID',
      type: 'string',
      name: 'endpointId'
    },
    {
      label: 'Web push',
      type: 'boolean',
      name: 'webpush',
      defaultValue: false,
      enable: [
        'firebaseMessagingSenderId',
        'pushSubscriptionTriggerEvent',
        'useCustomServiceWorkerPath',
        'serviceWorkerPath',
        'serviceWorkerScope',
        'includeManifest'
      ]
    },
    {
      label: 'Include default manifest.json',
      type: 'boolean',
      name: 'includeManifest',
      defaultValue: true
    },
    {
      label: 'Custom ServiceWorker path',
      type: 'boolean',
      name: 'useCustomServiceWorkerPath',
      defaultValue: false,
      enable: [
        'serviceWorkerPath',
        'serviceWorkerScope'
      ]
    },
    {
      label: 'ServiceWorker path',
      type: 'string',
      name: 'serviceWorkerPath',
      placeholder: '/nested/mindbox-services-worker.js'
    },
    {
      label: 'ServiceWorker scope',
      type: 'string',
      name: 'serviceWorkerScope'
    },
    {
      label: 'Push subscription trigger event',
      type: 'string',
      name: 'pushSubscriptionTriggerEvent',
      defaultValue: 'Agreed to Receive Push Notifications',
      placeholder: 'Agreed to Receive Push Notifications'
    },
    {
      label: 'Webpush sender ID',
      type: 'string',
      name: 'firebaseMessagingSenderId'
    },
    {
      label: 'Brand',
      type: 'string',
      name: 'brandSystemName'
    },
    {
      label: 'Point of contact system name',
      type: 'string',
      name: 'pointOfContactSystemName'
    },
    {
      label: 'Project system name',
      type: 'string',
      name: 'projectSystemName'
    },
    {
      label: 'Domain',
      type: 'string',
      name: 'projectDomain'
    },
    {
      label: 'User ID provider',
      type: 'string',
      name: 'userIdProvider'
    },
    {
      label: 'User variables',
      type: 'variable mapping',
      name: 'userVars',
      typeOptions: [DIGITAL_DATA, EVENT],
      placeholderKey: 'Mindbox user variable',
      placeholderValue: 'DigitalData variable'
    },
    {
      label: 'Order custom variables',
      type: 'variable mapping',
      name: 'orderVars',
      typeOptions: [DIGITAL_DATA, EVENT],
      placeholderKey: 'Mindbox order variable',
      placeholderValue: 'DigitalData variable'
    },
    {
      label: 'Product custom variables',
      type: 'string mapping',
      name: 'productVars',
      placeholderKey: 'Mindbox product variable',
      placeholderValue: 'DigitalData product variable'
    },
    {
      label: 'Set cart operation',
      type: 'string',
      name: 'setCartOperation'
    },
    {
      label: 'Operation mapping',
      type: 'string mapping',
      name: 'operationMapping',
      placeholderKey: 'DigitalData event',
      placeholderValue: 'Mindbox operation'
    },
    {
      label: 'Customer IDs mapping',
      type: 'variable mapping',
      name: 'customerIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData variable',
      typeOptions: [DIGITAL_DATA, EVENT]
    },
    {
      label: 'Product IDs mapping',
      type: 'string mapping',
      name: 'productIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData product variable'
    },
    {
      label: 'Product SKU IDs mapping',
      type: 'string mapping',
      name: 'productSkuIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData product variable'
    },
    {
      label: 'Product category IDs mapping',
      type: 'variable mapping',
      name: 'productCategoryIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData variable',
      typeOptions: [DIGITAL_DATA, EVENT]
    },
    {
      label: 'Area IDs mapping',
      type: 'variable mapping',
      name: 'areaIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData variable',
      typeOptions: [DIGITAL_DATA, EVENT]
    },
    {
      label: 'Order IDs mapping',
      type: 'variable mapping',
      name: 'orderIdsMapping',
      placeholderKey: 'Mindbox ID',
      placeholderValue: 'DigitalData variable',
      typeOptions: [DIGITAL_DATA, EVENT]
    }
  ]
}

export default config
