import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { close } from 'redux/modules/page/dataTransformations/dataTransformationsForm'
import dataTransformations, { mockDataTransformations } from 'dataTransformations'
import getTranslate from 'utils/getTranslate'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import { changeStatusDataTransformations } from 'redux/modules/project'
import DataTransformationForm from './DataTransformationForm'
import ru from './ru.json'

const DataTransformationsSide = ({
  isOpen,
  dataTransformationName,
  onClose,
  locale,
  projectName,
  projectDataTransformations = [],
  onChangeStatus
}) => {
  const allDataTransformations = [...dataTransformations, ...mockDataTransformations]
  const index = allDataTransformations.findIndex(item => item.name === dataTransformationName)
  const dataTransformation = allDataTransformations[index] || {}
  const enabledDataTransformations = projectDataTransformations.filter(dt => !dt.isDisabled).map(dt => dt.jobName)
  const isDisabled = enabledDataTransformations.indexOf(dataTransformation.jobName) === -1
  const translate = getTranslate(locale, { ru })
  const translateDataTransName = getTranslate(locale, dataTransformation.locale)
  const messageTemplate =
    'Are you sure that you want to <b>{{ACTION}}</b> the "{{DATA_TRANS_NAME}}" transformation in the <b>{{PROJECT_NAME}}</b> project?'
  const disableMessage = translate(messageTemplate, {
    DATA_TRANS_NAME: translateDataTransName(dataTransformationName),
    PROJECT_NAME: projectName,
    ACTION: translate('DISABLE')
  })

  const docsLink = dataTransformation.docsUrl && (
    <DocsLink
      locale={locale}
      url={dataTransformation.docsUrl}
    />
  )

  const handleStatusChange = () => {
    onChangeStatus(dataTransformation.jobName, !isDisabled)
  }

  const statusBox = (
    <StatusBox
      locale={locale}
      isEnabled={!isDisabled}
      onChange={handleStatusChange}
      disableMessage={disableMessage}
    />
  )

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={onClose}
      header={translateDataTransName(dataTransformationName)}
      docsLink={docsLink}
      statusBox={statusBox}
    >
      <DataTransformationForm dataTransformation={dataTransformation} />
    </ModalWindow>
  )
}

export default connect(
  state => {
    const { name, isOpen } = state.page.dataTransformations.dataTransformationsForm
    return {
      isOpen,
      dataTransformationName: name,
      locale: state.locale,
      projectName: state.project.name,
      projectDataTransformations: state.project.dataTransformations
    }
  },
  {
    onClose: close,
    onChangeStatus: changeStatusDataTransformations
  }
)(DataTransformationsSide)
