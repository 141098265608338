import { ANALYTICS } from 'integrations/categories'
import { DIGITAL_DATA, EVENT, PRODUCT } from 'containers/Form/Variable/options'
import { LEGACY_2_TRAFFIC_BASED, BUSINESS, ENTERPRISE, FREE } from 'utils/pricingPlans'
import logo from './logo.png'
import sideLogo from './logo-side.png'
import authIcon from './authIcon.png'
import ru from './ru.json'
import { fetchBigQueryProjects, fetchBigQueryDataSets } from 'api/integration'

const config = {
  name: 'DDManager Streaming',
  displayName: 'Google BigQuery',
  color: '#5bb3e9',
  category: [ANALYTICS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/bigquery/connecting-bigquery',

  configurations: {
    availableForPricingPlans: [LEGACY_2_TRAFFIC_BASED, BUSINESS, ENTERPRISE, FREE]
  },

  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  authForm: [
    {
      label: 'Authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'BigQuery was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Google Cloud project',
      type: 'dynamic select',
      name: 'projectId',
      validation: ['required'],
      placeholder: 'Select Project',
      optionsFetcher: fetchBigQueryProjects,
      description: 'There are no Google Cloud Platform projects in this Google Account. To collect data into Google BigQuery, you first need to create a Google Cloud Platform project and then click the field refresh button. Go to <a class="description" href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer">cloud.google.com</a> and click the “Try It Free” button. Enter your personal information and payment card details.',
      enable: ['datasetName', 'dashboardsDatasetName'],
      tip: 'Select a project to choose the dataset where you want to collect data'
    },
    {
      label: 'BigQuery dataset',
      type: 'dynamic select',
      name: 'datasetName',
      validation: ['required'],
      placeholder: 'Select Dataset',
      optionsFetcher: fetchBigQueryDataSets,
      description: 'There are no datasets in this project. Please create a dataset and then click the field refresh button.',
      dependsOn: ['projectId'],
      tip: 'Choose the dataset where you want to collect data'
    },
    {
      label: 'BigQuery dashboards dataset',
      type: 'dynamic select',
      name: 'dashboardsDatasetName',
      placeholder: 'Select Dataset',
      optionsFetcher: fetchBigQueryDataSets,
      description: 'There are no datasets in this project. Please create a dataset and then click the field refresh button.',
      dependsOn: ['projectId'],
      tip: 'Choose the dataset where you store data for dashboards'
    },
    // {
    //   label: 'Service account ID',
    //   type: 'string',
    //   name: 'serviceAccountId',
    // },
    // {
    //   label: 'Service account private key',
    //   type: 'string',
    //   name: 'serviceAccountPrivateKey',
    // },
    {
      label: 'UTC offset',
      type: 'select',
      name: 'utcOffset',
      options: [
        '-12:00',
        '-11:00',
        '-10:00',
        '-09:30',
        '-09:00',
        '-08:00',
        '-07:00',
        '-06:00',
        '-05:00',
        '-04:00',
        '-03:30',
        '-03:00',
        '-02:00',
        '-01:00',
        '+00:00',
        '+01:00',
        '+02:00',
        '+03:00',
        '+03:30',
        '+04:00',
        '+04:30',
        '+05:00',
        '+05:30',
        '+05:45',
        '+06:00',
        '+06:30',
        '+07:00',
        '+08:00',
        '+08:45',
        '+09:00',
        '+09:30',
        '+10:00',
        '+10:30',
        '+11:00',
        '+12:00',
        '+12:45',
        '+13:00',
        '+14:00'
      ],
      defaultValue: '+00:00',
      configurations: {
        simple: {
          isVisible: false
        }
      }
    },
    {
      label: 'Custom dimensions',
      type: 'variable mapping',
      name: 'dimensions',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT],
      configurations: {
        simple: {
          isVisible: false
        }
      }
    },
    {
      label: 'Custom metrics',
      type: 'variable mapping',
      name: 'metrics',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT],
      configurations: {
        simple: {
          isVisible: false
        }
      }
    }
  ]
}

export default config
