import styled from 'styled-components'

export const TextareaFieldInput = styled.textarea`
  width: 100%;
  height: 450px;
  padding: 12px;
  border: 1px solid #e3e4e6;
  border-radius: 3px;
  font-size: 16px;
  outline: none;
  appearance: none;
  &:disabled {
    background-color: #fafafb;
    color: #fafafb;
  }
  &:focus {
    border-color: #3bc569;
  }  
  border-color: ${props => (!props.error ? '#e1e7ed' : '#ff706b')};
`
