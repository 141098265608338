import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthField from 'containers/Form/AuthField'
import ErrorMessage from 'containers/ErrorMessage'
import { login, PENDING, FAILED } from 'redux/modules/auth/login'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import {
  FormWrapper,
  SegmentStreamLogo,
  Form,
  InputLabel,
  ConfirmButton,
  SimpleLinkWithArrow
} from './styles'
import getValidationError from 'containers/Form/getValidationError'
import { viewedPage } from 'utils/digitalDataEvents'
import { changePage, FORGOT_PASSWORD, SIGN_UP } from 'redux/modules/page/changePage'
import Cookies from 'js-cookie'
import { BILLING_ACCOUNT_ID } from 'redux/modules/auth/constants'

const ZENDESK_ELEMENT_ID = 'launcher'

class Login extends Component {
  constructor (props) {
    super(props)
    this.isSegmentStreamDomain = /\.segmentstream\.com$/.test(window.location.hostname)
    this.state = {}
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleEmailFocus = this.handleEmailFocus.bind(this)
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this)
    this.handleEmailTouch = this.handleEmailTouch.bind(this)
    this.handlePasswordTouch = this.handlePasswordTouch.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleForgotPassword = this.handleForgotPassword.bind(this)
    this.handleSignUp = this.handleSignUp.bind(this)
  }

  componentDidMount () {
    viewedPage('authorization')
    if (document.getElementById(ZENDESK_ELEMENT_ID)) {
      document.getElementById(ZENDESK_ELEMENT_ID).style.right = '9999px'
    }
  }

  componentWillUnmount () {
    if (document.getElementById(ZENDESK_ELEMENT_ID)) {
      document.getElementById(ZENDESK_ELEMENT_ID).style.right = '0px'
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (this.state.status !== nextProps.status) {
      this.setState({ status: nextProps.status })
    }
  }

  handleSubmit (event) {
    const { email, password } = this.state
    const { locale, onLogin } = this.props
    const billingAccountId = Cookies.get(BILLING_ACCOUNT_ID)
    event.preventDefault()
    if (email && password && !getValidationError(['email'], email, locale)) {
      onLogin(email, password, billingAccountId)
    } else {
      this.setState({
        touchedEmail: true,
        touchedPassword: true
      })
    }
  }

  handleEmailChange (value) {
    this.setState({
      email: value
    })
  }

  handlePasswordChange (value) {
    this.setState({
      password: value
    })
  }

  handleEmailFocus () {
    this.setState({
      focus: 'email',
      status: undefined
    })
  }

  handlePasswordFocus () {
    this.setState({
      focus: 'password',
      status: undefined
    })
  }

  handleEmailTouch () {
    this.setState({ touchedEmail: true })
  }

  handlePasswordTouch () {
    this.setState({ touchedPassword: true })
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  handleForgotPassword () {
    const { onChangePage } = this.props
    onChangePage(FORGOT_PASSWORD)
  }

  handleSignUp () {
    const { onChangePage } = this.props
    onChangePage(SIGN_UP)
  }

  render () {
    const { email, password, focus, touchedEmail, touchedPassword, status } = this.state
    const { locale } = this.props
    const translate = getTranslate(locale, { ru })
    return (
      <FormWrapper>
        <SegmentStreamLogo />
        <Form onSubmit={this.handleSubmit}>
          {status === FAILED && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>{translate('Sorry, but login and password do not match')}</ErrorMessage>
            </div>
          )}
          <InputLabel>Email</InputLabel>
          <AuthField
            value={email}
            onChange={this.handleEmailChange}
            onFocus={this.handleEmailFocus}
            onTouch={this.handleEmailTouch}
            onBlur={this.handleBlur}
            hasFocus={focus === 'email'}
            touched={touchedEmail}
            validation={['required', 'email']}
            name='email'
            placeholder='Email'
            autocomplete='username'
          />
          <InputLabel>{translate('Password')}</InputLabel>
          <AuthField
            value={password}
            onChange={this.handlePasswordChange}
            onFocus={this.handlePasswordFocus}
            onTouch={this.handlePasswordTouch}
            onBlur={this.handleBlur}
            hasFocus={focus === 'password'}
            touched={touchedPassword}
            validation={['required']}
            type='password'
            name='password'
            placeholder={translate('Password')}
            autocomplete='current-password'
          />
          <ConfirmButton type='submit' pending={status === PENDING}>
            {translate('Log in')}
          </ConfirmButton>

          <div style={{ textAlign: 'center', marginTop: '20px' }}>

            <div style={{ display: 'inline-flex' }}>
              <span>{translate('Forgot your password?')}&nbsp;</span>
              <SimpleLinkWithArrow onClick={this.handleForgotPassword}>
                {translate('Reset your password')}
              </SimpleLinkWithArrow>
            </div>

            {/* <div style={{ display: 'inline-flex', marginTop: '5px' }}> */}
            {/*  <span>{translate('Don’t have an account?')}&nbsp;</span> */}
            {/*  <SimpleLinkWithArrow onClick={this.handleSignUp}> */}
            {/*    {translate('Sign up')} */}
            {/*  </SimpleLinkWithArrow> */}
            {/* </div> */}
          </div>

        </Form>
      </FormWrapper>
    )
  }
}

export default connect(
  state => ({
    status: state.auth && state.auth.status,
    locale: state.locale
  }),
  {
    onLogin: login,
    onChangePage: changePage
  }
)(Login)
