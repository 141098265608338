import _ from 'lodash'
import { viewedPage } from 'utils/digitalDataEvents'
import path from './path'
import { dismissTip } from 'redux/modules/project/dismissTip'

export const INTEGRATIONS = 'integrations'
export const PRIORITY = 'priority'
export const EVENTS = 'events'
export const ENRICHMENTS = 'enrichments'
export const SCRIPTS = 'scripts'
export const DATA_SOURCES = 'dataSources'
export const DATA_DESTINATIONS = 'dataDestinations'
export const DATA_STREAMS = 'dataStreams'
export const CHARTS = 'charts'
export const CHARTS_SETTINGS = 'charts-settings'
export const REPORTS = (name) => `${name}-report`
export const DATA_TRANSFORMATIONS = 'dataTransformations'
export const FORGOT_PASSWORD = 'forgotPassword'
export const RESET_PASSWORD = 'resetPassword'
export const LOGIN = 'login'
export const SIGN_UP = 'signUp'
export const INVITATION = 'invitation'
export const CHECKOUT_SUCCESS = 'checkoutSuccess'
export const CHECKOUT_FAIL = 'checkoutFail'
export const PROJECT_CREATION = 'projectCreation'
export const CONFIRM_EMAIL_SUCCESS = 'confirmEmailSuccess'
export const CONFIRM_EMAIL_FAIL = 'confirmEmailFail'
export const CREATE_BILLING_ACCOUNT = 'createBillingAccount'
export const CONVERSIONS = 'conversions'

export const STANDARD_PAGES = [INTEGRATIONS, PRIORITY, EVENTS, ENRICHMENTS, SCRIPTS]
export const GENERAL_PAGES = [CHECKOUT_SUCCESS, CHECKOUT_FAIL, CONFIRM_EMAIL_SUCCESS, CONFIRM_EMAIL_FAIL]

const CHANGE_PAGE = `changePage@${path}`

const pageToPath = {
  [INTEGRATIONS]: '/integrations',
  [PRIORITY]: '/priority',
  [EVENTS]: '/events',
  [ENRICHMENTS]: '/enrichments',
  [SCRIPTS]: '/scripts',
  [DATA_STREAMS]: '/data-streams',
  [DATA_SOURCES]: '/data-sources',
  [DATA_DESTINATIONS]: '/data-destinations',
  [CONVERSIONS]: '/conversions',
  [CHARTS]: '/charts',
  [CHARTS_SETTINGS]: '/charts-settings',
  [DATA_TRANSFORMATIONS]: '/data-transformations',
  [FORGOT_PASSWORD]: '/forgot-password',
  [LOGIN]: '/login',
  [SIGN_UP]: '/sign-up',
  [CHECKOUT_SUCCESS]: '/checkout-success',
  [CHECKOUT_FAIL]: '/checkout-fail',
  [PROJECT_CREATION]: '/project-creation',
  [CONFIRM_EMAIL_SUCCESS]: '/confirm-email/success',
  [CONFIRM_EMAIL_FAIL]: '/confirm-email/fail',
  [CREATE_BILLING_ACCOUNT]: '/create-billing-account'
}

export const getPageByPath = (path) => _.invert(pageToPath)[path]

export const getPathByPage = (page) => pageToPath[page] || '/'

export const changePage = (page) => dispatch => {
  dispatch(dismissTip())
  viewedPage(page)
  dispatch({
    type: CHANGE_PAGE,
    page
  })

  const path = getPathByPage(page)
  if (window.history && path) {
    window.history.replaceState(null, null, path)
  }
}

export default (state = {}, action) =>
  action.type === CHANGE_PAGE
    ? {
      ...state,
      name: action.page
    }
    : state
