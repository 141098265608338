import path from './path'
import { saveCustomSDKUrl as apiSaveCustomSDKUrl } from 'api/project'
import { success, pending } from 'redux/modules/proMode/modalWindow'

const SAVE_CUSTOM_SDK_URL = `saveCustomSDKUrl@${path}`

export const saveCustomSDKUrl = (customSDKUrl, touchedFields) => async (dispatch, getState) => {
  if (touchedFields) {
    dispatch(pending())
    const projectId = getState().project.id
    await dispatch(apiSaveCustomSDKUrl(projectId, customSDKUrl))
    dispatch({
      type: SAVE_CUSTOM_SDK_URL,
      customSDKUrl
    })
    dispatch(success())
  }
}

export default (state = {}, action) =>
  action.type === SAVE_CUSTOM_SDK_URL
    ? {
      ...state,
      customSDKUrl: action.customSDKUrl
    }
    : state
