import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

const CREATE_BILLING_ACCOUNT = gql`
  mutation CreateBillingAccount($company:String, $countryCode:String) {
    createBillingAccount(company:$company, countryCode:$countryCode) {
      id
      userId
      accountName
      countryCode
    }
  }
`

export const useCreateBillingAccountMutation = () => useMutation(CREATE_BILLING_ACCOUNT)
