import { ACCESS_TOKEN, REFRESH_TOKEN, ACCESS_TOKEN_APP_V1, REFRESH_TOKEN_APP_V1 } from './constants'

const isProdV1 = document.location.hostname.includes('-v1')
const isDev = process.env.NODE_ENV === 'development'

export const getDomain = () => {
  if (document.location.hostname.includes('localhost')) {
    return '.localhost'
  }

  if (isDev) {
    return '.segmentstream.dev'
  }

  return '.segmentstream.com'
}

export const getAccessTokenName = () => (isProdV1 || isDev) ? ACCESS_TOKEN_APP_V1 : ACCESS_TOKEN

export const getRefreshTokenName = () => (isProdV1 || isDev) ? REFRESH_TOKEN_APP_V1 : REFRESH_TOKEN
