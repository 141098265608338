import { update as apiUpdate } from 'api/dataSource'
import path from './path'
import { changedEvent } from 'utils/digitalDataEvents'

const SAVE = `save@${path}`

export const save = (options, type, enableOnSave) => async (dispatch, getState) => {
  const projectId = getState().project.id
  const { id } = getState().page.dataSources.dataSourcesForm

  const apiResponse = await dispatch(apiUpdate(projectId, id, { options }))

  if (!apiResponse.errors && apiResponse !== 'Bad Request') {
    dispatch({
      type: SAVE,
      id,
      options,
      enableOnSave
    })
    changedEvent(type, path)
  } else {
    const error = apiResponse.errors ? apiResponse.errors[0].message : apiResponse
    window.alert(error)
  }
}

export default (state = {}, action) => {
  const savedOptions = {
    ...state[action.id],
    options: action.options
  }

  if (action.enableOnSave) {
    savedOptions.isEnabled = true
  }

  return action.type === SAVE
    ? {
      ...state,
      [action.id]: savedOptions
    }
    : state
}
