import styled, { css } from 'styled-components'
import arrow from './arrow.png'

export const OnboardingWindow = styled.div`
  width: 699px;
  border-radius: 10px;
  border: solid 1px #e7e7e7;
  margin: 30px auto 0;
  padding-top: 75px;
  padding-bottom: 75px;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const OnboardingIcon = styled.div`
  width: 60px;
  height: 60px;
  background: url(${props => props.icon}) no-repeat;
  background-size: 60px 60px;
  margin: 0 auto;
  ${props =>
    props.large &&
  css`
    width: 131px;
    height: 131px;
    background-size: 131px 131px;
  `}  
`

export const OnboardingTitle = styled.p`
  width: 500px;
  font: 600 22px/1.41 ProxmiaNovaLight;
  text-align: center;
  color: #0c1533;
  margin: 25px auto 0;
`

export const OnboardingBody = styled.p`
  width: 550px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #0c1533;
  margin: 25px auto 0;
`

export const OnboardingArrow = styled.div`
  width: 18px;
  height: 8px;
  background: url(${arrow}) no-repeat;
  background-size: 18px 8px;
  margin: 25px auto 28px;
`

export const OnboardingLink = styled.a`
  font-size: 16px;
  color: #4d6dd3;
  text-decoration: underline;
  margin: 25px auto;
`
