import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Weborama',
  color: '#90141F',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/weborama',
  form: [
    {
      label: 'Website ID',
      type: 'string',
      name: 'siteId'
    },
    {
      label: 'Event mapping',
      type: 'string mapping',
      name: 'eventPixels'
    }
  ]
}

export default config
