import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchClientLogins } from 'api/dataSource'

const config = {
  type: 'Yandex.Direct',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/yandex-direct',
  authType: 'OAuth2',
  authIcon,
  authName: 'Yandex Account',
  authForm: [
    {
      label: 'Click to authenticate with Yandex',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Yandex.Direct',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import campaign performance report',
      type: 'boolean',
      name: 'campaignPerformanceReport',
      defaultValue: false,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Import account balance report',
      type: 'boolean',
      name: 'accountsInfoReport',
      defaultValue: false,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Include VAT',
      type: 'boolean',
      name: 'includeVAT',
      configurations: {
        google_analytics: {
          visibleBefore: '2020-07-15'
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Agency',
      type: 'boolean',
      name: 'isAgency',
      enable: ['clientLogins', 'useOperatorUnits']
    },
    {
      label: 'Spend agency points',
      type: 'boolean',
      name: 'useOperatorUnits'
    },
    {
      label: 'Client logins (for agencies)',
      type: 'dynamic select array',
      name: 'clientLogins',
      placeholder: 'Select client login',
      validation: ['required'],
      optionsFetcher: fetchClientLogins,
      description: 'No client logins were found for this account or access was not granted. Please, make sure you have access to the required client logins or <a class="description" href="https://yandex.com/adv/partners" target="_blank" rel="noopener noreferrer">add a new client</a> and click “Refresh”.',
      errorDescription: 'This account doesn\'t have the rights to access the agency service. Please, add this account as an <a class="description" href="https://yandex.com/support/direct/campaigns/agency-representatives.html" target="_blank" rel="noopener noreferrer">agency representative</a> and click “Refresh“.',
      searchable: true
    },
    {
      label: 'Default URL parameters',
      type: 'string',
      defaultValue: 'utm_source=yandex&utm_medium=cpc&utm_campaign={campaign_name}',
      name: 'defaultUrlParameters',
      placeholder: 'URL parameters',
      tip: 'Default URL parameters will be applied to all campaigns that weren\'t <br /> matched automatically, or matched through the Search banners configuration.'
    },
    {
      label: 'URL parameters for all Search banners',
      type: 'string',
      name: 'defaultMcBannerUrlParams',
      placeholder: 'URL parameters',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      },
      tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/yandex-direct#utm-matching-for-search-banners" target="_blank" rel="noopener noreferrer">documentation</a>.'
    },
    {
      label: 'URL parameters for specific Search banners',
      type: 'string mapping',
      name: 'mcBannerUrlParams',
      placeholderKey: 'Campaign ID',
      placeholderValue: 'URL parameters',
      keyInputWidth: 35,
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      },
      tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/yandex-direct#utm-matching-for-search-banners" target="_blank" rel="noopener noreferrer">documentation</a>.'
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          enable: ['defaultMcBannerUrlParams', 'mcBannerUrlParams', 'costMultiplier'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default URL parameters',
          type: 'string',
          defaultValue: 'utm_source=yandex&utm_medium=cpc&utm_campaign={campaign_name}',
          name: 'defaultUrlParameters',
          placeholder: 'URL parameters',
          tip: 'Default URL parameters will be applied to all campaigns that weren\'t matched automatically, or matched through the Search banners configuration.'
        },
        {
          label: 'URL parameters for all Search banners',
          type: 'string',
          name: 'defaultMcBannerUrlParams',
          placeholder: 'URL parameters',
          tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/yandex-direct#utm-matching-for-search-banners" target="_blank" rel="noopener noreferrer">documentation</a>.'
        },
        {
          label: 'URL parameters for specific Search banners',
          type: 'string mapping',
          name: 'mcBannerUrlParams',
          placeholderKey: 'Campaign ID',
          placeholderValue: 'URL parameters',
          keyInputWidth: 35,
          tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/yandex-direct#utm-matching-for-search-banners" target="_blank" rel="noopener noreferrer">documentation</a>.'
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        }
      ]
    }
  ]
}

export default config
