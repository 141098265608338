import React from 'react'
import { connect } from 'react-redux'
import Form from 'containers/Form'
import Space from 'containers/Space'
import { Link } from './styles'
import { save, close, remove } from 'redux/modules/page/integrations/integrationForm/eventEnrichments'
import types, { EVENT, PRODUCT } from './types'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'

const EnrichmentForm = ({ locale, projectName, enrichment, onSave, onRemove, onClose }) => {
  const translate = getTranslate(locale, { ru })
  const messageTemplate = translate(
    'Are you sure that you want to <b>{{ACTION}}</b> the "{{VARIABLE_NAME}}" variable in the <b>{{PROJECT_NAME}}</b> project?'
  )
  const deleteMessage = translate(messageTemplate, {
    VARIABLE_NAME: enrichment ? enrichment.prop : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DELETE')
  })
  const handleRemove = () => {
    onRemove(enrichment.id)
  }
  const handleSave = data => {
    onSave(data)
  }
  const form = [
    {
      label: translate('Scope'),
      type: 'select',
      name: 'scope',
      options: [
        {
          label: translate(types[EVENT]),
          value: EVENT,
          enable: ['event']
        },
        {
          label: translate(types[PRODUCT]),
          value: PRODUCT,
          enable: ['selector']
        }
      ],
      defaultValue: EVENT
    },
    {
      label: translate('Variable name'),
      type: 'string',
      name: 'prop',
      validation: ['required', 'no-double-quotes']
    },
    {
      label: translate('Event name'),
      type: 'string',
      name: 'event',
      placeholder: translate('All events'),
      validation: ['no-double-quotes']
    },
    {
      label: translate('Handler function'),
      type: 'javascript',
      name: 'handler',
      validation: ['required']
    }
  ]

  const header = enrichment !== undefined ? enrichment.prop : translate('Add event variable')

  return (
    <div>
      <label>
        <Link onClick={onClose}>{translate('All variables')}</Link>
        &nbsp;/ {header}
      </label>
      <Space />
      <Form
        locale={locale}
        form={form}
        data={enrichment}
        onSave={handleSave}
        onRemove={enrichment !== undefined ? handleRemove : undefined}
        deleteMessage={deleteMessage}
      />
    </div>
  )
}

export default connect(
  state => {
    const { name, eventEnrichments } = state.page.integrations.integrationForm
    const id = eventEnrichments && eventEnrichments.id
    const list = state.integrations[name].eventEnrichments
    const enrichment = id ? list.find(item => item.id === id) : undefined
    return {
      locale: state.locale,
      projectName: state.project.name,
      enrichment
    }
  },
  {
    onSave: save,
    onClose: close,
    onRemove: remove
  }
)(EnrichmentForm)
