import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'TradeTracker',
  color: '#4890cd',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Campaign ID',
      type: 'string',
      name: 'campaignId',
      validation: ['required']
    },
    {
      label: 'TradeTracker cookie name',
      type: 'string',
      name: 'cookieName',
      defaultValue: 'tradetracker'
    },
    {
      label: 'Cookie tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: ['cookieTtl', 'cookieDomain']
    },
    {
      label: 'Cookie time to live',
      type: 'number',
      name: 'cookieTtl',
      defaultValue: 90,
      validation: ['required']
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain',
      validation: ['required']
    },
    {
      label: 'Enable deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which require deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    },
    {
      label: 'utm_source from TradeTracker',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'tradetracker'
    }
  ]
}

export default config
