import styled from 'styled-components'
import close from './close.svg'
import back from './back.svg'

export const Step = styled.div`
  display: ${props => props.visible ? 'flex' : 'none'};
  justify-content: center;
  align-items: center;
  height: 100vh;
`

export const CloseButton = styled.div`
  height: 30px;
  width: 30px;
  background: url(${close}) no-repeat;
  position: absolute;
  top: 40px;
  left: 40px;  
  cursor: pointer;
`

export const BackButton = styled.div`
  height: 30px;
  width: 80px;
  padding-left: 35px;
  font-size: 20px;
  line-height: 30px;
  color: #1D2C4C;  
  background: url(${back}) no-repeat left center;
  background-size: 14px 30px;
  position: absolute;
  top: 50px;
  left: 50px;  
  cursor: pointer;
`

export const Heading = styled.div`
  font-size: 29px;
  line-height: 35px;
  color: #1D2C4C;
  text-align: center;
`

export const ProjectTypes = styled.div`
  width: 914px;
  height: 500px;
  margin-top: 60px;
  display: flex;
`

export const ProjectType = styled.div`
  border: 1px solid #CBCBCB;
  border-radius: 20px;
  padding: 67px 44px 40px 43px;
  width: 50%;
  &:first-child {
    margin-right: 32px;
  }  
`

export const Icon = styled.img`
  width: 80px;
  height: 80px;
`

export const Title = styled.div`
  font-size: 29px;
  line-height: 35px;
  display: flex;
  align-items: center;
  color: #1D2C4C;
  margin-top: 30px;
`

export const Feature = styled.div`
  font-size: 18px;
  line-height: 25px;
  color: #1D2C4C;
  margin: 30px 0 40px;
`

export const Button = styled.button`
  border: 1px solid #4D6DD3;
  background: #fff;
  padding: 19px 0;
  border-radius: 5px;
  font-size: 20px;
  line-height: 24px;
  text-align: center;
  color: #4D6DD3;  
  width: 100%;
  cursor: pointer;
  outline: none;
`

export const Loader = styled.div`
  margin: 20px 0;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4D6DD3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`
