import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthField from 'containers/Form/AuthField'
import ErrorMessage from 'containers/ErrorMessage'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'
import { FormWrapper, SegmentStreamLogo, ResetPasswordForm, InputLabel, ConfirmButton, Header, Description, SimpleBlueLink } from '../Login/styles'
import { changePage, LOGIN } from 'redux/modules/page/changePage'
import { sendResetPasswordToken } from 'redux/modules/user/resetPassword'
import getValidationError from 'containers/Form/getValidationError'
import SuccessMessage from 'containers/SuccessMessage/index'

const PENDING = 'pending'
const FAILED = 'failed'
const SUCCESS = 'success'

class ForgotPassword extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: null
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleEmailFocus = this.handleEmailFocus.bind(this)
    this.handleEmailTouch = this.handleEmailTouch.bind(this)

    this.handleLoginPage = this.handleLoginPage.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (event) {
    const { email } = this.state
    const { locale, onSendResetPasswordToken } = this.props
    event.preventDefault()
    if (email && !getValidationError(['email'], email, locale)) {
      try {
        this.setState({ status: PENDING })
        await onSendResetPasswordToken(email)
        this.setState({ status: SUCCESS, email: '', touchedEmail: false })
      } catch (e) {
        this.setState({ status: FAILED })
      }
    } else {
      this.setState({
        touchedEmail: true
      })
    }
  }

  handleEmailChange (value) {
    this.setState({
      email: value
    })
  }

  handleEmailFocus () {
    this.setState({
      focus: 'email',
      status: null
    })
  }

  handleEmailTouch () {
    this.setState({ touchedEmail: true })
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  handleLoginPage () {
    const { onChangePage } = this.props
    onChangePage(LOGIN)
  }

  render () {
    const { email, focus, touchedEmail, status } = this.state
    const { locale } = this.props
    const translate = getTranslate(locale, { ru })
    return (
      <FormWrapper>
        <SegmentStreamLogo />
        <ResetPasswordForm onSubmit={this.handleSubmit}>
          <Header>{translate('Account recovery')}</Header>
          {status === FAILED && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>{translate('Sorry, we couldn\'t find a matching email')}</ErrorMessage>
            </div>
          )}
          {status === SUCCESS && (
            <div style={{ textAlign: 'center' }}>
              <SuccessMessage>{translate('Please check your email!')}</SuccessMessage>
              <SimpleBlueLink onClick={this.handleLoginPage}>
                {translate('Back to Log in')}
              </SimpleBlueLink>
            </div>
          )}
          {status === null && (
            <Description>
              {translate('We\'ll send you instructions for resetting your password by email')}
            </Description>
          )}
          {status !== SUCCESS && (
            <div>
              <InputLabel>Email</InputLabel>
              <AuthField
                value={email}
                onChange={this.handleEmailChange}
                onFocus={this.handleEmailFocus}
                onTouch={this.handleEmailTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'email'}
                touched={touchedEmail}
                validation={['required', 'email']}
                name='email'
                placeholder='Email'
              />
              <ConfirmButton type='submit' pending={status === PENDING}>
                {translate('Reset password')}
              </ConfirmButton>
            </div>
          )}
        </ResetPasswordForm>
      </FormWrapper>
    )
  }
}

export default connect(
  state => ({ locale: state.locale }),
  {
    onSendResetPasswordToken: sendResetPasswordToken,
    onChangePage: changePage
  }
)(ForgotPassword)
