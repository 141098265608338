import { create as apiCreate } from 'api/dataSource'
import { close } from '../page/dataSources/createDataSourceSide'
import { open } from '../page/dataSources/dataSourcesForm'
import path from './path'

const CREATE = `create@${path}`

export const create = dataSource => async (dispatch, getState) => {
  const projectId = getState().project.id
  const apiResponse = await dispatch(apiCreate(projectId, dataSource))
  await dispatch({
    type: CREATE,
    dataSource: apiResponse
  })
  const { id, type } = apiResponse
  dispatch(close())
  dispatch(open(id, type))
}

export default (state = {}, action) =>
  action.type === CREATE
    ? {
      ...state,
      [action.dataSource.id]: action.dataSource
    }
    : state
