import reduceReducers from 'reduce-reducers'

import closeFormReducer from './closeForm'
import openFormReducer from './openForm'
import { save } from './save'
import { remove } from './remove'

export { save, remove }

export default reduceReducers(closeFormReducer, openFormReducer)
