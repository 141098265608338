import { gql } from 'apollo-boost'
import { useQuery } from '@apollo/react-hooks'

export const GET_DATA_STREAMS = gql`
  query GetDataStreams($projectId:ID!) {
    project(id:$projectId) {
      dataStreams {
        id
        projectId
        displayName
        destinationTableName
        type
        isEnabled
        config {
          uaIntegrationType
        }
        anonymize
      }
    }
  }
`

export const useGetDataStreamsQuery = (projectId) => useQuery(GET_DATA_STREAMS, {
  variables: { projectId }
})
