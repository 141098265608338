import React from 'react'

import {
  Container,
  RowContainer,
  MessageContainer,
  Logo,
  HeaderText,
  Text,
  ButtonContainer,
  Button, BoldText, TextWider, SecondaryText
} from './styles'
import { BackButton } from '../../ProjectCreation/styles'

export function EmailConfirmationPage ({ user, onResend, resendInProgress, resendDisabled, resendSuccess, logout }) {
  return (
    <Container>
      <BackButton onClick={() => logout({ pageName: 'signUp' })}>Back</BackButton>
      <RowContainer>
        <Logo />
        <MessageContainer>
          <HeaderText>Please verify your email</HeaderText>

          <TextWider>We sent email to <BoldText>{user.email}</BoldText></TextWider>
          <TextWider>Just click on the button in the email to complete your signup. If you don’t see it, you may need to check your spam folder. </TextWider>
          <TextWider>Still can’t find the email?</TextWider>
          <ButtonContainer>
            <Button
              onClick={onResend}
              pending={resendInProgress}
              disabled={resendDisabled}
            >
              Resend Email
            </Button>
            { resendSuccess
              ? <SecondaryText>A new confirmation email was sent.</SecondaryText>
              : <SecondaryText />
            }
          </ButtonContainer>
          <Text>Need help? <a style={{ color: '#4D6DD3' }} href='mailto:support@segmentstream.com' rel='noopener noreferrer' target='_blank'>Contact us.</a></Text>
        </MessageContainer>
      </RowContainer>
    </Container>
  )
}
