import path from './path'

const CHANGE_STATUS_FILTER = `changeStatusFilter@${path}`

export const changeStatusFilter = status => ({
  type: CHANGE_STATUS_FILTER,
  status
})

export default (state = {}, action) =>
  action.type === CHANGE_STATUS_FILTER
    ? {
      ...state,
      filter: {
        ...state.filter,
        status: action.status
      }
    }
    : state
