import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import App from 'containers/App'
import createStore from './redux'
import Cookies from 'js-cookie'
import { setStatus as setProModeStatus } from 'redux/modules/proMode'
import { setStatus as setSaleModeStatus } from 'redux/modules/saleMode'
import { PROJECT_ID, BILLING_ACCOUNT_ID } from 'redux/modules/auth/constants'
import { getAccessTokenName, getRefreshTokenName } from 'redux/modules/auth/utils'
import { setProMode, getProMode } from 'utils/proMode'
import { setSaleMode, getSaleMode } from 'utils/saleMode'
import { authenticate, logout } from 'redux/modules/auth'
import { getUser as apiGetUser } from 'api/user'
import { getApplicationSettings as apiGetApplicationSettings } from 'api/application-settings'
import { fetch as fetchLocale } from 'redux/modules/locale'
import { fetch as fetchApplicationSettings } from 'redux/modules/applicationSettings/fetch'
import { fetch as fetchUser } from 'redux/modules/user/fetch'
import { authenticatePasswordReset } from 'redux/modules/auth/reset'
import { authenticateUserRegister } from 'redux/modules/auth/register'
import { getUrlParam } from 'utils/getUrlParam'
import {
  changePage,
  getPageByPath,
  RESET_PASSWORD,
  INVITATION
} from 'redux/modules/page/changePage'
import Modal from 'react-modal'

import { ApolloProvider } from '@apollo/react-hooks'

import './index.css'
import { createApolloClient } from 'create-apollo-client'
import { selectBillingAccount } from 'redux/modules/user/selectBillingAccount'

const renderApp = (store, apolloClient) => {
  ReactDOM.render(
    <Provider store={store} >
      <ApolloProvider client={apolloClient}>
        <App />
      </ApolloProvider>
    </Provider>,
    window.document.getElementById('root')
  )
}

const load = async () => {
  setProMode(window.location.search, Cookies)
  const proMode = getProMode(Cookies)
  setSaleMode(window.location.search, Cookies)
  const saleMode = getSaleMode(Cookies)
  const locale = 'en'
  const accessToken = Cookies.get(getAccessTokenName())
  const refreshToken = Cookies.get(getRefreshTokenName())
  const billingAccountId = Cookies.get(BILLING_ACCOUNT_ID)
  const store = createStore(Cookies)
  await store.dispatch(setSaleModeStatus(saleMode))
  await store.dispatch(fetchLocale(locale))
  const applicationSettings = await store.dispatch(apiGetApplicationSettings())
  await store.dispatch(fetchApplicationSettings(applicationSettings))
  const page = getPageByPath(window.location.pathname)

  const apolloClient = createApolloClient(store)

  if (!refreshToken && !accessToken) {
    await store.dispatch(logout())
    Cookies.remove(PROJECT_ID)
  } else {
    await store.dispatch(apiGetUser())

    if (Cookies.get(getAccessTokenName())) {
      const user = await store.dispatch(apiGetUser())
      await store.dispatch(authenticate(Cookies.get(getAccessTokenName()), refreshToken, false))

      if (user) {
        await store.dispatch(fetchUser(user))
        await store.dispatch(selectBillingAccount(user, billingAccountId))

        if (user?.role === 'super_admin') {
          await store.dispatch(setProModeStatus(proMode))
        }
      }
    }
  }

  // Set initial page
  const resetToken = getUrlParam('resetToken')
  const invitationToken = getUrlParam('invitationToken')
  if (resetToken) {
    await store.dispatch(authenticatePasswordReset(resetToken))
    await store.dispatch(changePage(RESET_PASSWORD))
  } else if (invitationToken) {
    await store.dispatch(authenticateUserRegister(invitationToken))
    await store.dispatch(changePage(INVITATION))
  } else if (page) {
    await store.dispatch(changePage(page))
  }

  renderApp(store, apolloClient)

  Modal.setAppElement(window.document.getElementById('root'))

  if (module.hot) {
    module.hot.accept('containers/App', () => renderApp(store, apolloClient))
  }
}

load()
