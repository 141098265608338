import styled from 'styled-components'

export const Title = styled.div`
  font-size: 22px;
  line-height: 38px;
  color: #0c1533;
`

export const IntegrationFiltersContainer = styled.div`
  color: #0c1537;
  font-size: 14px;
  line-height: 42px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-end;
  user-select: none;
`
