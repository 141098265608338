import path from './path'
import apiEndpoint from '../../../../apiEndpoint'

const AUTHORIZE = `authorize@${path}`

export const GOOGLE = 'google'

export const authorize = provider => async (dispatch, getState) => {
  const projectId = getState().project.id
  const accessToken = getState().auth.accessToken

  window.open(`${apiEndpoint}/projects/${projectId}/streaming/authorize/${provider}?access_token=${accessToken}`)
  dispatch({ type: AUTHORIZE })
}
