import styled from 'styled-components'

export const Button = styled.div`
  width: 100%;
  border-bottom: solid 1px #e1e7ed;
  text-align: right;
  padding: 0 33px;
`

export const RightContainer = styled.div`
  cursor: pointer;
  padding: 17px 0;
  display: inline-block;
`

export const Text = styled.span`
  font-size: 18px;
  color: #1d2c4c;
  margin-right: 10px;
`

export const Icon = styled.img`
  width: 15px;
  height: 15px;
  vertical-align: middle;
`

export const LeftContainer = styled.div`
  float: left;
  padding-top: 17px;
`

export const AuthIcon = styled.img`
  height: 25px;
  width: auto;
  margin-right: 20px;
`

export const AuthName = styled.span`
  font-size: 18px;
  color: #1d2c4c;
  vertical-align: super;
`
