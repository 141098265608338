import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthField from 'containers/Form/AuthField'
import ErrorMessage from 'containers/ErrorMessage'
import getTranslate from 'utils/getTranslate'
import { registerUser } from 'redux/modules/project/registerUser'
import ru from './ru.json'
import { FormWrapper, SegmentStreamLogo, ResetPasswordForm, InputLabel, ConfirmButton, Description, SimpleBlueLink } from '../Login/styles'
import { changePage, LOGIN } from 'redux/modules/page/changePage'
import SuccessMessage from 'containers/SuccessMessage/index'

const PENDING = 'pending'
const FAILED = 'failed'
const SUCCESS = 'success'
const PASSWORDS_MISMATCH = 'passwords mismatch'
const FAILED_PASSWORD_LENGTH = 'failed password length'

class Invitation extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: null,
      touchedFields: {}
    }

    this.createFieldFocusHandler = this.createFieldFocusHandler.bind(this)
    this.createFieldTouchHandler = this.createFieldTouchHandler.bind(this)
    this.createFieldChangeHandler = this.createFieldChangeHandler.bind(this)

    this.handleLoginPage = this.handleLoginPage.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  createFieldFocusHandler (field) {
    return () => this.setState({
      focus: field,
      status: null
    })
  }

  createFieldTouchHandler (field) {
    return () => this.markFieldAsTouched(field)
  }

  createFieldChangeHandler (field) {
    return (value) => this.setState({
      [field]: value
    })
  }

  markFieldAsTouched (field) {
    this.setState({
      touchedFields: {
        ...this.state.touchedFields,
        [field]: true
      }
    })
  }

  async handleSubmit (event) {
    const { onRegisterUser, locale } = this.props
    const { name, lastName, password, confirmPassword } = this.state

    const translate = getTranslate(locale, { ru })

    event.preventDefault()
    if (password !== confirmPassword) {
      this.setState({
        status: PASSWORDS_MISMATCH
      })
    } else if ((password || '').length < 6) {
      this.setState({
        status: FAILED_PASSWORD_LENGTH
      })
    } else {
      try {
        this.setState({ status: PENDING })
        const response = await onRegisterUser(name, lastName, password)
        if (response === '') {
          this.setState({ status: SUCCESS })
        } else {
          window.alert(translate('The invitation link is no longer valid'))
        }
      } catch (e) {
        this.setState({ status: FAILED })
      }
    }
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  handleLoginPage () {
    const { onChangePage } = this.props
    onChangePage(LOGIN)
  }

  render () {
    const {
      name,
      lastName,
      password,
      confirmPassword,
      focus,
      touchedFields,
      status
    } = this.state

    return (
      <FormWrapper>
        <SegmentStreamLogo />
        <ResetPasswordForm onSubmit={this.handleSubmit}>
          {status === PASSWORDS_MISMATCH && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>Passwords must match!</ErrorMessage>
            </div>
          )}
          {status === FAILED_PASSWORD_LENGTH && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>
                Password length must be at least 6 characters
              </ErrorMessage>
            </div>
          )}
          {status === SUCCESS && (
            <div style={{ textAlign: 'center' }}>
              <SuccessMessage>
                User has been registered <br />
              </SuccessMessage>
              <SimpleBlueLink onClick={this.handleLoginPage}>
                Back to Log in
              </SimpleBlueLink>
            </div>
          )}
          {status !== SUCCESS && (
            <div>
              <Description>
                Enter registration details
              </Description>

              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <InputLabel>First&nbsp;Name</InputLabel>
                <AuthField
                  value={name}
                  onChange={this.createFieldChangeHandler('name')}
                  onFocus={this.createFieldFocusHandler('name')}
                  onTouch={this.createFieldTouchHandler('name')}
                  onBlur={this.handleBlur}
                  hasFocus={focus === 'name'}
                  touched={touchedFields.name}
                  validation={['required']}
                  type='text'
                  name='name'
                  placeholder='First Name'
                  autocomplete='name'
                  style={{ marginRight: '17px' }}
                />

                <InputLabel>Last&nbsp;Name</InputLabel>
                <AuthField
                  value={lastName}
                  onChange={this.createFieldChangeHandler('lastName')}
                  onFocus={this.createFieldFocusHandler('lastName')}
                  onTouch={this.createFieldTouchHandler('lastName')}
                  onBlur={this.handleBlur}
                  hasFocus={focus === 'lastName'}
                  touched={touchedFields.lastName}
                  validation={['required']}
                  type='text'
                  name='lastName'
                  placeholder='Last Name'
                  autocomplete='lastName'
                />
              </div>

              <InputLabel>Password</InputLabel>
              <AuthField
                value={password}
                onChange={this.createFieldChangeHandler('password')}
                onFocus={this.createFieldFocusHandler('password')}
                onTouch={this.createFieldTouchHandler('password')}
                onBlur={this.handleBlur}
                hasFocus={focus === 'password'}
                touched={touchedFields.password}
                validation={['required']}
                type='password'
                name='password'
                placeholder='Password'
                autocomplete='new-password'
              />
              <InputLabel>Confirm&nbsp;password</InputLabel>
              <AuthField
                value={confirmPassword}
                onChange={this.createFieldChangeHandler('confirmPassword')}
                onFocus={this.createFieldFocusHandler('confirmPassword')}
                onTouch={this.createFieldTouchHandler('confirmPassword')}
                onBlur={this.handleBlur}
                hasFocus={focus === 'confirmPassword'}
                touched={touchedFields.confirmPassword}
                validation={['required']}
                type='password'
                name='confirmPassword'
                placeholder='Confirm password'
                autocomplete='new-password'
              />
              <ConfirmButton type='submit' pending={status === PENDING}>
                Register
              </ConfirmButton>
            </div>
          )}
        </ResetPasswordForm>
      </FormWrapper>
    )
  }
}

export default connect(
  state => ({ locale: state.locale }),
  {
    onRegisterUser: registerUser,
    onChangePage: changePage
  }
)(Invitation)
