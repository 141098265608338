import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'Internal promo',
  icon,
  locale: { ru },
  description:
    'Generates a table with information about impressions, clicks and transactions attributed to the last click on the banner within the session. Allows you to build reports on the effectiveness of internal campaigns.',
  form: []
}

export default config
