import { useContext, useEffect, useState } from 'react'
import useDeepCompareMemoize from './deepCompareMemoize'
import CubeContext from './CubeContext'

export function useCubeQuery (query) {
  const [isLoading, setLoading] = useState(false)
  const [resultSet, setResultSet] = useState(null)
  const [error, setError] = useState(null)
  const context = useContext(CubeContext)

  async function fetch () {
    const cubejsApi = context?.cubejsApi

    if (!cubejsApi) {
      throw new Error('Cube.js API client is not provided')
    }

    setError(null)
    setResultSet(null)
    setLoading(true)

    try {
      const response = await cubejsApi.query(query)
      setResultSet(response)
    } catch (error) {
      console.log(error)
      setError(error)
      setResultSet(null)
    }

    setLoading(false)
  }

  useEffect(() => {
    const cubejsApi = context?.cubejsApi

    if (!cubejsApi) {
      throw new Error('Cube.js API client is not provided')
    }

    async function loadQuery () {
      setError(null)
      setLoading(true)

      try {
        await fetch()
      } catch (e) {
        setError(e)
        setResultSet(null)
        setLoading(false)
      }
    }

    loadQuery()
  // eslint-disable-next-line
  }, useDeepCompareMemoize([query.queries]))

  return {
    isLoading,
    error,
    resultSet
  }
}
