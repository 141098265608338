import { apiCall } from './apiCall'
import { sleep } from '../utils/sleep'

const RETRY_LIMIT = 5

export const CheckoutSessionStatuses = {
  Initializing: 'Initializing',
  Ready: 'Ready',
  Error: 'Error'
}

const checkoutSessionPost = (projectId, checkoutData) => apiCall({
  url: `/projects/${projectId}/billing-account/checkout-sessions`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(checkoutData)
}).then(resp => resp.text())
  .then(resp => JSON.parse(resp))
  .catch(r => ({ status: CheckoutSessionStatuses.Error }))

const checkoutSessionGet = (projectId, checkoutSessionId) => apiCall({
  url: `/projects/${projectId}/billing-account/checkout-sessions/${checkoutSessionId}`
}).then(resp => resp.text())
  .then(resp => JSON.parse(resp))
  .catch(r => ({ status: CheckoutSessionStatuses.Error }))

const billingAccountGet = (projectId) => apiCall({
  url: `/projects/${projectId}`
}).then(resp => resp.text())
  .then(resp => JSON.parse(resp))
  .catch(r => ({ status: CheckoutSessionStatuses.Error }))

export const createCheckoutSession = async (projectId, checkoutData) => {
  const createResponse = await checkoutSessionPost(projectId, checkoutData)

  const checkoutSessionId = createResponse.id

  let response
  let counter = 0
  do {
    await sleep(2000)
    response = await checkoutSessionGet(projectId, checkoutSessionId)
    counter++
  } while (response.status === CheckoutSessionStatuses.Initializing && counter < RETRY_LIMIT)

  if (response.status === CheckoutSessionStatuses.Initializing) {
    return {
      status: CheckoutSessionStatuses.Error
    }
  }

  return response
}

export const waitForSubscriptionUpdate = async (projectId) => {
  let subscriptionExpiresAt
  let response
  let counter = 0
  do {
    await sleep(2000)
    response = await billingAccountGet(projectId)
    subscriptionExpiresAt = response.billingAccount.subscriptionExpiresAt
    counter++
  } while ((!subscriptionExpiresAt || new Date(subscriptionExpiresAt) <= new Date()) && counter < RETRY_LIMIT)
  return subscriptionExpiresAt && new Date(subscriptionExpiresAt) > new Date()
}
