import React, { Component } from 'react'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import ConfirmationWindow from 'containers/ConfirmationWindow'
import getTranslate from 'utils/getTranslate'
import { Triangle } from './styles'
import ru from './ru.json'

class PublishButton extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.handlePublishClick = this.handlePublishClick.bind(this)
    this.closeConfirmation = this.closeConfirmation.bind(this)
    this.handlePublishConfirmation = this.handlePublishConfirmation.bind(this)
  }

  handlePublishClick () {
    this.setState({
      isConfirmationOpen: true
    })
  }

  handlePublishConfirmation () {
    this.props.onPublish()
    this.setState({
      isConfirmationOpen: false
    })
  }

  closeConfirmation () {
    this.setState({
      isConfirmationOpen: false
    })
  }

  render () {
    const { locale, pending, isPublished, compact } = this.props
    const { isConfirmationOpen } = this.state
    const translate = getTranslate(locale, { ru })
    return (
      <div>
        <ConfirmButton
          style={{
            height: '45px',
            width: compact ? '168px' : '196px',
            fontSize: '14px',
            lineHeight: '14px',
            marginBottom: '14px'
          }}
          pending={pending}
          disabled={isPublished}
          onClick={this.handlePublishClick}
        >
          {translate('Publish')}
          <Triangle />
        </ConfirmButton>
        <ConfirmationWindow
          messageText={translate('Publish changes?')}
          confirmButtonText={translate('Yes')}
          cancelButtonText={translate('Cancel')}
          isOpen={isConfirmationOpen}
          onClose={this.closeConfirmation}
          onConfirm={this.handlePublishConfirmation}
        />
      </div>
    )
  }
}

export default PublishButton
