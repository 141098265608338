import path from './path'
import { setCookie } from 'redux-cookie'
import { logout } from './logout'
import { getDomain, getAccessTokenName, getRefreshTokenName } from 'redux/modules/auth/utils'

const AUTH = `auth@${path}`

export const authenticate = (accessToken, refreshToken, needToUpdateTokens = true) => async dispatch => {
  let domain = getDomain()
  let accessTokenName = getAccessTokenName()
  let refreshTokenName = getRefreshTokenName()

  if (needToUpdateTokens) {
    await dispatch(setCookie(accessTokenName, accessToken, { domain, expires: 1 / 24 })) // 1 hour
    await dispatch(setCookie(refreshTokenName, refreshToken, { domain, expires: 90 })) // 3 months
  }

  if (!accessToken || !refreshToken) {
    dispatch(logout())
  }

  await dispatch({
    type: AUTH,
    accessToken,
    refreshToken
  })
}

export default (state = {}, action) => {
  if (action.type === AUTH) {
    return {
      ...state,
      isAuthenticated: true
    }
  }
  return state
}
