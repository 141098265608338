import React from 'react'
import styled from 'styled-components'

const Success = styled.div`
  padding-top: 5px;
  text-align: right;
  color: #3bc569;
`

export default ({ message, width }) => <Success style={{ width: width || '100%' }}>{message}</Success>
