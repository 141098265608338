import reduceReducers from 'reduce-reducers'
import closeModalReducer, { closeModal } from './closeModal'
import errorReducer, { error, ERROR } from './error'
import openModalReducer, { openModal } from './openModal'
import pendingReducer, { pending, PENDING } from './pending'
import successReducer, { success, SUCCESS } from './success'

export { closeModal, error, openModal, pending, success, ERROR, PENDING, SUCCESS }

export const CUSTOM_SDK_URL = 'custom sdk url'
export const CUSTOM_INTEGRATIONS = 'custom integrations'
export const DATA_TRANSFORMATIONS = 'data transformations'
export const PROJECT_OPTIONS = 'project options'
export const SCRIPTS = 'scripts'

export default reduceReducers(
  closeModalReducer,
  errorReducer,
  openModalReducer,
  pendingReducer,
  successReducer
)
