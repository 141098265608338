import React, { useEffect, useState } from 'react'
import MomentUtils from '@date-io/moment'
import * as moment from 'moment'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import Checkbox from '@material-ui/core/Checkbox'
import InputLabel from '@material-ui/core/InputLabel'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import { makeStyles } from '@material-ui/core/styles'

import { queries, defaultDateRange, defaultPreviousDateRange } from '@segmentstream/cubejs-client'

import { useCubeQuery } from '../../../../../../utils/cubejs/cubeQuery'

import ChartRenderer from '../components/ChartRenderer'
import DashboardItem from '../components/DashboardItem'

import { sourceMediumTable } from '../metrics/tables/sourceMedium'

const getStyles = makeStyles(() => ({
  formControl: {
    width: '100%'
  },
  indeterminateColor: {
    color: '#f50057'
  },
  selectAllText: {
    fontWeight: 500
  },
  selectedAll: {
    backgroundColor: 'rgba(0, 0, 0, 0.08)',
    '&:hover': {
      backgroundColor: 'rgba(0, 0, 0, 0.08)'
    }
  }
}))

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  },
  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: 'bottom',
    horizontal: 'center'
  },
  transformOrigin: {
    vertical: 'top',
    horizontal: 'center'
  },
  variant: 'menu'
}

function select ({ resultSet, isLoading }, title, selected, setSelected) {
  const classes = getStyles()

  const loaded = !isLoading && resultSet
  const options = !loaded ? [] : resultSet

  const isAllSelected =
    options.length > 0 && selected && selected.value?.length === options.length

  if (selected === null && loaded) {
    setSelected({
      value: options,
      isAllSelected: true
    })
  }

  const handleChange = (event) => {
    const value = event.target.value
    if (value[value.length - 1] === 'all') {
      setSelected({
        value: selected.value?.length === options.length ? [] : options,
        isAllSelected: selected.value?.length === options.length
      })
      return
    }
    setSelected({
      value,
      isAllSelected: options.length > 0 && value.length === options.length
    })
  }

  return (
    <Grid item xs={3} key={title}>
      <FormControl className={classes.formControl}>
        <InputLabel id={title}>{title}</InputLabel>
        <Select
          labelId={title}
          multiple
          value={selected?.value || []}
          onChange={handleChange}
          renderValue={(selected) => selected.join(', ')}
          MenuProps={MenuProps}
        >
          <MenuItem
            value='all'
            classes={{
              root: isAllSelected ? classes.selectedAll : ''
            }}
          >
            <ListItemIcon>
              <Checkbox
                classes={{ indeterminate: classes.indeterminateColor }}
                checked={isAllSelected}
                indeterminate={
                  selected?.value && selected?.value.length > 0 && selected?.value.length < options.length
                }
              />
            </ListItemIcon>
            <ListItemText
              classes={{ primary: classes.selectAllText }}
              primary='Select All'
            />
          </MenuItem>
          {options.map((option) => (
            <MenuItem key={option} value={option}>
              <ListItemIcon>
                <Checkbox checked={selected?.value && selected?.value.indexOf(option) > -1} />
              </ListItemIcon>
              <ListItemText primary={option} />
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Grid>
  )
}

export const DashboardPage = () => {
  const [source, setSource] = useState(null)
  const [medium, setMedium] = useState(null)
  const [campaign, setCampaign] = useState(null)
  const [platform, setPlatform] = useState(null)
  const [device, setDevice] = useState(null)
  const [country, setCountry] = useState(null)
  const [domain, setDomain] = useState(null)
  const [account, setAccount] = useState(null)

  const [filters, setFiltersRaw] = useState([])

  const [dateFrom, setDateFrom] = useState(moment(defaultDateRange()[0]))
  const [dateTo, setDateTo] = useState(moment(defaultDateRange()[1]))
  const [dateRange, setDateRange] = useState([dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')])
  const [previousDateRange, setPreviousDateRange] = useState([
    moment(defaultPreviousDateRange()[0]).format('YYYY-MM-DD'),
    moment(defaultPreviousDateRange()[0]).format('YYYY-MM-DD')
  ])

  useEffect(() => {
    setDateRange([dateFrom.format('YYYY-MM-DD'), dateTo.format('YYYY-MM-DD')])
    setPreviousDateRange([
      dateFrom.clone().subtract(dateTo.diff(dateFrom)).subtract(1, 'day').format('YYYY-MM-DD'),
      dateTo.clone().subtract(dateTo.diff(dateFrom)).subtract(1, 'day').format('YYYY-MM-DD')
    ])
  }, [dateFrom, dateTo])

  useEffect(() => {
    setSource(null)
    setMedium(null)
    setCampaign(null)
    setPlatform(null)
    setDevice(null)
    setCountry(null)
    setDomain(null)
    setAccount(null)
  }, [dateFrom, dateTo])

  const selectSource = select(
    useCubeQuery(queries.legacy.dimension.source.query(dateRange)),
    queries.legacy.dimension.source.name, source, setSource
  )

  const selectMedium = select(
    useCubeQuery(queries.legacy.dimension.medium.query(dateRange)),
    queries.legacy.dimension.medium.name, medium, setMedium
  )

  const selectCampaign = select(
    useCubeQuery(queries.legacy.dimension.campaign.query(dateRange)),
    queries.legacy.dimension.campaign.name, campaign, setCampaign
  )

  const selectPlatform = select(
    useCubeQuery(queries.legacy.dimension.platform.query(dateRange)),
    queries.legacy.dimension.platform.name, platform, setPlatform
  )

  const selectDevice = select(
    useCubeQuery(queries.legacy.dimension.device.query(dateRange)),
    queries.legacy.dimension.device.name, device, setDevice
  )

  const selectCountry = select(
    useCubeQuery(queries.legacy.dimension.country.query(dateRange)),
    queries.legacy.dimension.country.name, country, setCountry
  )

  const selectDomain = select(
    useCubeQuery(queries.legacy.dimension.domain.query(dateRange)),
    queries.legacy.dimension.domain.name, domain, setDomain
  )

  const selectAccount = select(
    useCubeQuery(queries.legacy.dimension.account.query(dateRange)),
    queries.legacy.dimension.account.name, account, setAccount
  )

  const filtersAreReady = source && medium && campaign &&
    platform && device && country &&
    domain && account

  const setFilters = (newFilters) => {
    if (newFilters.length === 0 && filters.length === 0) {
      return
    }
    setFiltersRaw(newFilters)
  }
  useEffect(() => {
    if (!filtersAreReady) {
      return
    }

    const newFilters = filtersAreReady ? [
      { ...(source && source?.value.length > 0 && !source?.isAllSelected ? {
        member: 'Attribution.source',
        operator: 'set',
        values: source?.value
      } : {}) },
      { ...(medium && medium?.value.length > 0 && !medium?.isAllSelected ? {
        member: 'Attribution.medium',
        operator: 'set',
        values: medium?.value
      } : {}) },
      { ...(campaign && campaign?.value.length > 0 && !campaign?.isAllSelected ? {
        member: 'Attribution.campaign',
        operator: 'set',
        values: campaign?.value
      } : {}) },
      { ...(platform && platform?.value.length > 0 && !platform?.isAllSelected ? {
        member: 'Attribution.adPlatform',
        operator: 'set',
        values: platform?.value
      } : {}) },
      { ...(device && device?.value.length > 0 && !device?.isAllSelected ? {
        member: 'Attribution.device',
        operator: 'set',
        values: device?.value
      } : {}) },
      { ...(country && country?.value.length > 0 && !country?.isAllSelected ? {
        member: 'Attribution.country',
        operator: 'set',
        values: country?.value
      } : {}) },
      { ...(domain && domain?.value.length > 0 && !domain?.isAllSelected ? {
        member: 'Attribution.domain',
        operator: 'set',
        values: domain?.value
      } : {}) },
      { ...(account && account?.value.length > 0 && !account?.isAllSelected ? {
        member: 'Attribution.adAccount',
        operator: 'set',
        values: account?.value
      } : {}) }
    ].filter(filter => Object.keys(filter).length > 0) : []

    setFilters(newFilters)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    source, medium, campaign,
    platform, device, country,
    domain, account, filtersAreReady
  ])

  const [metricsList, setMetricsList] = useState([])
  useEffect(() => {
    const newMetricsList = Object.entries(queries.legacy.measures).map(([id, query]) => ({
      id,
      name: query.name,
      vizState: {
        indifferentChanges: false,
        inscreseIsPositive: false,
        current: {
          query: query.query(dateRange, filters),
          size: 3,
          chartType: 'number'
        },
        previous: {
          query: query.query(previousDateRange, filters),
          size: 3,
          chartType: 'number'
        }
      }
    }))

    setMetricsList(newMetricsList)
  }, [dateRange, previousDateRange, filters])

  const [dashboardItems, setDashboardItems] = useState([])

  useEffect(() => {
    setDashboardItems([
      {
        id: 'charts_sessions',
        name: queries.legacy.charts.chartsSessions.name,
        vizState: {
          query: queries.legacy.charts.chartsSessions.query(dateRange, filters),
          size: 12,
          chartType: 'area'
        }
      },
      {
        id: 'revenue_chart',
        name: queries.legacy.charts.revenueChart.name,
        vizState: {
          query: queries.legacy.charts.revenueChart.query(dateRange, filters),
          size: 6,
          chartType: 'area'
        }
      },
      {
        id: 'charts_roas',
        name: queries.legacy.charts.chartsROAS.name,
        vizState: {
          query: queries.legacy.charts.chartsROAS.query(dateRange, filters),
          size: 6,
          chartType: 'area'
        }
      },
      sourceMediumTable(dateRange, filters)
    ])
  }, [dateRange, previousDateRange, filters])

  const renderDashboardItem = ({ id, name, vizState }) => (
    <Grid style={{ 'textAlign': 'center' }} item xs={vizState.size || 2} key={id}>
      <DashboardItem title={name}>
        <ChartRenderer vizState={vizState} />
      </DashboardItem>
    </Grid>
  )

  const Empty = () => (
    <div
      style={{
        textAlign: 'center',
        padding: 12
      }}
    >
      <Typography variant='h5' color='inherit'>
        There are no charts on this dashboard. Use Playground Build to add one.
      </Typography>
    </div>
  )

  if (!dashboardItems.length) {
    return (
      <Empty />
    )
  }

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Grid
          container
          spacing={3}
          alignItems='flex-start'
          style={{ marginBottom: '32px' }}
        >
          <Grid item xs={3}>
            <KeyboardDatePicker
              value={dateFrom.format('YYYY-MM-DD')}
              onChange={date => setDateFrom(moment(date))}
              label='From'
              format='YYYY-MM-DD'
            />
          </Grid>
          <Grid item xs={3}>
            <KeyboardDatePicker
              value={dateTo}
              onChange={date => setDateTo(moment(date))}
              label='To'
              format='YYYY-MM-DD'
            />
          </Grid>
        </Grid>
      </MuiPickersUtilsProvider>

      <Grid
        container
        spacing={3}
        justifyContent='flex-start'
        alignItems='flex-start'
      >
        {selectSource}
        {selectMedium}
        {selectCampaign}
        {selectPlatform}
        {selectDevice}
        {selectCountry}
        {selectDomain}
        {selectAccount}
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent='flex-start'
        alignItems='flex-start'
        style={{ marginTop: '32px' }}
      >
        {metricsList.map(renderDashboardItem)}
      </Grid>

      <Grid
        container
        spacing={3}
        justifyContent='space-around'
        alignItems='flex-start'
        style={{ marginTop: '32px' }}
      >
        {dashboardItems.map(renderDashboardItem)}
      </Grid>
    </>
  )
}
