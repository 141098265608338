import { queries } from '@segmentstream/cubejs-client'
import { adTermTable } from './adTerm'

export const campaignNameTable = (dateRange, filters) => {
  const adTermTableInstance = adTermTable(dateRange, filters)
  return {
    id: `tables_campaign_name`,
    name: queries.legacy.attributionsTables.campaignNameTable.name,
    vizState: {
      child: {
        name: adTermTableInstance.name,
        data: (campaign) => {
          const adTermTableChildInstance = adTermTable(dateRange, [
            ...filters,
            {
              member: 'Attribution.campaign',
              operator: 'equals',
              values: [campaign]
            }
          ])
          return adTermTableChildInstance.vizState
        }
      },
      query: queries.legacy.attributionsTables.campaignNameTable.query(dateRange, filters),
      size: 12,
      chartType: 'table'
    }
  }
}
