import { ApolloClient } from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink } from 'apollo-link'

import { getFreshAccessToken } from './api/apiCall'

function createOmitTypenameLink () {
  return new ApolloLink((operation, forward) => {
    if (operation.variables) {
      operation.variables = JSON.parse(JSON.stringify(operation.variables), omitTypename)
    }

    return forward(operation)
  })
}

function omitTypename (key, value) {
  return key === '__typename' ? undefined : value
}

export function createApolloClient () {
  const httpLink = createHttpLink({
    uri: '/services/gateway/graphql'
  })

  const authLink = setContext((_, { headers }) =>
    getFreshAccessToken()
      .then(token => ({
        headers: {
          ...headers,
          authorization: token ? `Bearer ${token}` : ''
        }
      }))
      .catch(e => ({ headers }))
  )

  return new ApolloClient({
    link: ApolloLink.from([
      createOmitTypenameLink(),
      authLink,
      httpLink
    ]),
    cache: new InMemoryCache({ addTypename: true })
  })
}
