import path from './path'
import { deletedEvent } from 'utils/digitalDataEvents'

const REMOVE = `remove@${path}`

export const remove = (id, name) => {
  deletedEvent(name, path)
  return {
    type: REMOVE,
    id
  }
}

export default (state = [], action) => {
  if (action.type === REMOVE) {
    return state.reduce((acc, item) => (item.id !== action.id ? [...acc, item] : acc), [])
  }
  return state
}
