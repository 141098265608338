import path from './path'

const REGISTER = `register@${path}`

export const authenticateUserRegister = invitationToken => async dispatch => {
  await dispatch({ type: REGISTER, invitationToken })
  window.history.replaceState({}, null, '/')
}

export default (state = {}, action) => {
  if (action.type === REGISTER) {
    return { invitationToken: action.invitationToken }
  }
  return state
}
