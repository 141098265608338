import styled from 'styled-components'

export const Name = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  &:hover {
    text-decoration: underline;
    cursor: pointer;
  }
`
