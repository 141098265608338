import path from './path'

const CHANGE_CATEGORY = `changeCategory@${path}`

export const changeCategory = category => ({
  type: CHANGE_CATEGORY,
  category
})

export default (state = {}, action) =>
  action.type === CHANGE_CATEGORY
    ? {
      ...state,
      filter: {
        ...state.filter,
        category: action.category
      }
    }
    : state
