export const ALL = 'All Categories'
export const ADVERTISING = 'Advertising'
export const PERSONALIZATION = 'Personalization'
export const ANALYTICS = 'Analytics'
export const TAG_MANAGEMENT = 'Tag management'
export const AFFILIATE_NETWORKS = 'Affiliate networks'
export const PUSH_NOTIFICATIONS = 'Push notifications'
export const ATTRIBUTION = 'Attribution'
export const EMAIL = 'Email'
export const PRODUCT_RECOMMENDATION = 'Product recommendation'
export const LIVE_CHAT = 'Live chat'
export const DMP = 'DMP'

export default [
  ALL,
  ADVERTISING,
  PERSONALIZATION,
  ANALYTICS,
  TAG_MANAGEMENT,
  AFFILIATE_NETWORKS,
  PUSH_NOTIFICATIONS,
  ATTRIBUTION,
  EMAIL,
  PRODUCT_RECOMMENDATION,
  LIVE_CHAT,
  DMP
]
