import React from 'react'
import { connect } from 'react-redux'
import CubeProvider from '../../../../../utils/cubejs/CubeProvider'
import { CubeJSClient } from '@segmentstream/cubejs-client'
import Grid from '@material-ui/core/Grid'
import Typography from '@material-ui/core/Typography'

import { apiCall } from '../../../../../api/apiCall'
import { DashboardPage } from './pages/DashboardPage'

const API_URL = process.env.REACT_APP_DEV ? 'https://strong-dycusburg.gcp-us-central1.cubecloudapp.dev' : 'https://agreeable-haleyville.gcp-us-central1.cubecloudapp.dev'

const Charts = ({ projectId, dashboardsDatasetName }) => {
  if (!dashboardsDatasetName) {
    return (
      <Grid
        container
        spacing={3}
        alignItems='flex-start'
        style={{ marginBottom: '32px' }}
      >
        <Grid item style={{ margin: '30% 0 0 30%' }} xs={12}>
          <Typography variant='h5'>
          Please select BigQuery dashboards dataset
          </Typography>
        </Grid>
      </Grid>
    )
  }
  const cubejsApi = new CubeJSClient(API_URL, async () => {
    const resp = await apiCall({
      url: `/projects/${projectId}/cubejsJWT`,
      method: 'post'
    })

    if (resp.status !== 200) {
      throw new Error(resp.statusText)
    }

    return (await resp.json()).jwt
  })

  return (
    <CubeProvider cubejsApi={cubejsApi}>
      <DashboardPage cubejsApi={cubejsApi} />
    </CubeProvider>
  )
}

export default connect(
  state => {
    const bq = Object.entries(state.integrations).find(integration => integration[0] === 'DDManager Streaming')?.[1]
    return {
      projectId: state.project.id,
      dashboardsDatasetName: bq?.options?.dashboardsDatasetName
    }
  })(Charts)
