import { save as saveDataSource } from 'redux/modules/dataSources/save'
import { close as closeForm } from './dataSourcesForm/close'
import { enableTip } from 'redux/modules/project/enableTip'
import { DATA_SOURCES } from '../changePage'

export const save = (dataSource, type, isEnabled) => async dispatch => {
  let enableOnSave

  if (!isEnabled) {
    enableOnSave = true
    dispatch(enableTip(type, DATA_SOURCES))
  }

  await dispatch(saveDataSource(dataSource, type, enableOnSave))
  dispatch(closeForm())
}
