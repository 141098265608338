import Button from './Button'
import styled, { css } from 'styled-components'

export default styled(Button)`
  background-color: ${props => (props.pending ? '#FF8B88' : '#FF504B')};
  ${props =>
    !props.disabled &&
    !props.pending &&
    css`
      &:hover {
        background-color: #d25450;
      }
    `}
`
