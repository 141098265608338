import path from './path'

const FETCH = `fetch@${path}`

export const fetch = scripts => ({
  type: FETCH,
  scripts
})

export default (state = [], action) => (action.type === FETCH ? action.scripts : state)
