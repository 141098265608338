import styled from 'styled-components'
import checkMark from './checkMark.svg'
import unknownIcon from './unknownIcon.svg'
import userIcon from './userIcon.svg'

export const Title = styled.div`
  font-size: 18px;
  color: #0c1533;
`
export const SubmitBox = styled.div`
  display: flex;
  justify-content: space-between;
`

export const SuccessMessage = styled.div`
  color: #3bc569;
  &:before {
    content: ' ';
    background: url(${checkMark}) center center no-repeat;
    padding-right: 30px;
  }
`

export const ErrorMessage = styled.div`
  color: #ff706b;
`

export const Line = styled.div`
  height: 0px;
  width: 100%;
  border-top: 1px solid #E7ECF1;
`

export const MemberBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
`

export const MemberDetails = styled.div`
  display: flex;
`

export const MemberContacts = styled.div`
  display: flex;
  margin-left: 15px;
  flex-direction: column;
  justify-content: space-around;
`

export const UnknownSign = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #DEE4F5 url(${unknownIcon}) center center no-repeat;
`

export const MemberSign = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  background: #4D6DD3 url(${userIcon}) center center no-repeat;
`

export const MemberName = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #000;
`

export const MemberEmail = styled.div`
  font-size: 14px;
  line-height: 16px;
  color: #7D8293;
`

export const DeleteMember = styled.div`
  font-size: 14px;
  text-decoration: underline;
  color: #DC5C5C;
  cursor: pointer;
`
