import React from 'react'
import { connect } from 'react-redux'
import OnboardingPlaceholder from '../OnboardingPlaceholder'
import lamp from './lamp.png'

const DataDestinationOnboarding = ({ onAddButtonClick }) => {
  return (
    <OnboardingPlaceholder
      title='Add your first data destination<br> by clicking the "Add Data Destination" button below'
      icon={lamp}
      showArrow
      buttonTitle='Add Data Destination'
      buttonAction={onAddButtonClick}
      linkTitle='Read more about data destinations'
      linkUrl='https://docs.segmentstream.com/datadestinations/index'
    />
  )
}

export default connect(state => ({
  locale: state.locale
}))(DataDestinationOnboarding)
