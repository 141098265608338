import React from 'react'
import { connect } from 'react-redux'
import { open } from 'redux/modules/page/dataSources/dataSourcesForm/open'
import IntegrationLogo from 'containers/IntegrationLogo'
import EllipsisText from 'react-ellipsis-text'
import Status from 'containers/Status'
import { isProMode } from 'utils/proMode'
import { DataSource, Title, Name, Signal, Plus, LogoContainer } from './styles'

const DataSourceItem = ({ dataSourceConfig, isEnabled, onClick, id, title, isProMode }) => {
  const handleClick = () => {
    onClick(id, dataSourceConfig.type)
  }

  const {
    type,
    logo,
    displayName
  } = dataSourceConfig

  return (
    <DataSource onClick={handleClick}>
      <LogoContainer>
        <IntegrationLogo logo={logo} />
        <Signal>{isEnabled ? <Status isEnabled={isEnabled} /> : <Plus />}</Signal>
      </LogoContainer>
      <Title>
        <EllipsisText text={title || `My ${type}`} length={16} />
      </Title>
      <Name>{displayName || type}{isProMode ? ` (#${id})` : ''}</Name>
    </DataSource>
  )
}

export default connect(
  state => ({
    isProMode: isProMode(state.proMode.status, state.user.role)
  }),
  { onClick: open }
)(DataSourceItem)
