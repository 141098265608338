import styled, { css } from 'styled-components'
import { HEADER_HEIGHT } from 'containers/App/Dashboard/BlueHeader/styles'
import { PRO_MODE_HEIGHT } from 'containers/App/Dashboard/ProMode/Header/styles'
import tabSelectIcon from './tabSelectIcon.svg'
import LockBadge from 'containers/LockBadge'

export const Container = styled.div`
  padding: ${props => (props.compact ? '32px 15px 32px 27px;' : '32px 27px;')}
  text-align: left;
  user-select: none;
  background: #fafbfe;
  min-height: calc(100vh - ${HEADER_HEIGHT}px);
  ${props =>
    props.proMode &&
  css`
    min-height: calc(100vh - ${HEADER_HEIGHT}px - ${PRO_MODE_HEIGHT}px);
    `};
`

export const MenuGroupLabel = styled.div`
  font-size: 14px;
  font-weight: 600;
  line-height: 1.4;
  color: #0c1533;
  text-transform: uppercase;
  margin-bottom: 20px;
  padding-left: 37px;
  background: url(${props => props.thumbnail}) no-repeat left top;
  background-size: 19px 19px;
`

export const MenuItem = styled.div`
  position: relative;
  font-size: 16px;
  line-height: 1;
  color: #0c1533;
  cursor: pointer;
  margin-left: ${props => (props.compact ? '15px;' : '37px;')}
  margin-bottom: 21px;
  &:last-child {
    margin-bottom: 0px;
  }
  ${props =>
    props.active &&
  css`
      color: #4d6dd3;
      margin-left: -27px;
      padding-left: ${props => (props.compact ? '42px;' : '64px;')}
      background: url(${tabSelectIcon}) no-repeat;
      background-size: contain;
      cursor: auto;
    `};

  ${props =>
    props.beta &&
  css`
    *::after {
      content: "123";
      opacity: 0.5;
    }
    `}
`

export const Menu = styled.div`
  position: sticky;
  top: 20px;
`

export const MenuGroup = styled.div`
  width: ${props => (props.compact ? '180px;' : '200px;')}
  margin-top: 45px;
  &:first-child {
    margin-top: 0px;
  }
  display: flex;
  flex-direction: column;
`
export const Lock = styled(LockBadge)`
  position: absolute;
  top: -1px;
  left: -30px;
`

export const Beta = styled.span`
  opacity: 0.5;
`
