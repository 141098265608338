import React from 'react'
import Mapping from 'containers/Form/Mapping'
import StringMappingRow from './StringMappingRow'
import { mappingRowProps } from 'containers/Form/Mapping/MappingRow'

const StringMapping = ({
  locale,
  style,
  name,
  value,
  label,
  description,
  touched,
  isValid,
  placeholderKey,
  placeholderValue,
  onChange,
  onTouch,
  onValidate,
  inputStyle,
  valueOptions,
  defaultValueOption,
  tip,
  keyInputWidth
}) => {
  const isValidRow = row => row[0] && row[0].length > 0 && row[1] && row[1].length > 0

  const createRow = ({ row, index, focusedRow, onFocus, onChangeRow }) => (
    <StringMappingRow
      inputStyle={inputStyle}
      keyInputWidth={keyInputWidth}
      onTouch={onTouch}
      valueOptions={valueOptions}
      defaultValueOption={defaultValueOption}
      {...mappingRowProps({
        locale,
        focusedRow,
        index,
        onFocus,
        placeholderKey,
        placeholderValue,
        onChangeRow,
        row: [row[0], row[1] || defaultValueOption],
        touched
      })}
    />
  )

  return (
    <Mapping
      locale={locale}
      description={description}
      label={label}
      onCreateRow={createRow}
      value={value}
      style={style}
      isValidRow={isValidRow}
      onChange={onChange}
      onTouch={onTouch}
      onValidate={onValidate}
      name={name}
      isValid={isValid}
      tip={tip}
    />
  )
}

export default StringMapping
