import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ANALYTICS, ATTRIBUTION } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'OWOX BI Streaming',
  color: '#0087cf',
  category: [ANALYTICS, ATTRIBUTION],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/owox',
  form: [
    {
      label: 'Namespace',
      type: 'string',
      name: 'namespace'
    },
    {
      label: 'Enable session streaming',
      type: 'boolean',
      name: 'sessionStreaming',
      enable: ['sessionIdDimension']
    },
    {
      label: 'Session ID dimension',
      type: 'string',
      name: 'sessionIdDimension',
      validation: ['required']
    }
  ]
}

export default config
