import styled from 'styled-components'

export const Message = styled.div`
  margin-top: 5px;
`

export const Link = styled.a`
  color: #4d6dd3;
  cursor: pointer;
  text-decoration: underline;
`
