import reduceReducers from 'reduce-reducers'
import allProjects from './allProjects'
import auth from './auth'
import enrichments from './enrichments'
import events from './events'
import integrations from './integrations'
import locale from './locale'
import page from './page'
import priority from './priority'
import project from './project'
import proMode from './proMode'
import saleMode from './saleMode'
import blueHeader from './blueHeader'
import scripts from './scripts'
import dataSources from './dataSources'
import dataDestinations from './dataDestinations'
import user from './user'
import applicationSettings from './applicationSettings'

import logoutReducer from './logout'

export default reduceReducers(
  (state = {}, action) => ({
    ...state,
    allProjects: allProjects(state.allProjects, action),
    auth: auth(state.auth, action),
    enrichments: enrichments(state.enrichments, action),
    events: events(state.events, action),
    locale: locale(state.locale, action),
    page: page(state.page, action),
    priority: priority(state.priority, action),
    project: project(state.project, action),
    integrations: integrations(state.integrations, action),
    proMode: proMode(state.proMode, action),
    saleMode: saleMode(state.saleMode, action),
    blueHeader: blueHeader(state.blueHeader, action),
    scripts: scripts(state.scripts, action),
    dataSources: dataSources(state.dataSources, action),
    dataDestinations: dataDestinations(state.dataDestinations, action),
    user: user(state.user, action),
    applicationSettings: applicationSettings(state.applicationSettings, action)
  }),
  logoutReducer
)
