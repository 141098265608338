import path from './path'
import { saveCustomIntegrations as apiSaveCustomIntegrations } from 'api/project'
import { success, pending } from 'redux/modules/proMode/modalWindow'

const SAVE_CUSTOM_INTEGRATIONS = `saveCustomIntegration@${path}`

export const saveCustomIntegrations = (customIntegrations, touchedFields) => async (dispatch, getState) => {
  if (touchedFields) {
    dispatch(pending())
    const projectId = getState().project.id
    await dispatch(apiSaveCustomIntegrations(projectId, customIntegrations))
    dispatch({
      type: SAVE_CUSTOM_INTEGRATIONS,
      customIntegrations
    })
    dispatch(success())
  }
}

export default (state = {}, action) =>
  action.type === SAVE_CUSTOM_INTEGRATIONS
    ? {
      ...state,
      customIntegrations: action.customIntegrations,
      isPublished: false
    }
    : state
