import React from 'react'
import styled from 'styled-components'

import * as moment from 'moment'

import {
  billingAccountCurrency,
  isSubscriptionActive,
  isSubscriptionExpired,
  isTrialActive,
  isTrialExpired
} from '../billingAccount'
import {
  Description,
  SubscriptionEmphasis,
  SubscriptionStatusMessage
} from './styles'
import { BillingPricingPlansList } from './billingPricingPlansList'
import { ANNUAL } from '../billingPeriods'
import { ButtonPrimary } from '../../../../../../uiKit'

const BillingPortalButtonContainer = styled.div`
  margin-top: 25px;
  flex-grow: 1;
  display: flex;
  justify-content: center;
`

export function BillingStatusStep ({ billingAccount, projectType, onPriceSelected, availablePricingPlans, onOpenBillingPortal, availableProjects }) {
  const currency = billingAccountCurrency(billingAccount)
  const subscriptionExpiresAt = billingAccount.subscriptionStatus?.expiresAt
  const nextCharge = moment(subscriptionExpiresAt).format('MMMM Do, YYYY')

  const currentPrice = billingAccount?.subscriptionStatus?.price

  const period = (currentPrice) =>
    currentPrice.billingPeriod === ANNUAL.value ? 'year' : 'month'

  return (
    <React.Fragment>
      <BillingStatusMessage billingAccount={billingAccount} />
      { isSubscriptionActive(billingAccount) && currentPrice
        ? (
          <div>
            <Description>
              This billing account's plan is set to {currency.symbol}{currentPrice.totalPrice.toLocaleString()} per {period(currentPrice)} and will renew on {nextCharge}. Adjusted for your account balance, you will be charged {currency.symbol}{currentPrice.totalPrice.toLocaleString()}.
              <br /><br />
              If you want to change the pricing plan, please contact support at <a style={{ color: '#4D6DD3' }} href='mailto:support@segmentstream.com' rel='noopener noreferrer' target='_blank'>support@segmentstream.com</a>.
              <br /><br />
              If you want to download invoices, change your payment method or cancel the subscription, please visit the customer billing management portal.
              <br />
            </Description>
            <BillingPortalButtonContainer>
              <ButtonPrimary onClick={onOpenBillingPortal}>
                Open billing management portal
              </ButtonPrimary>
            </BillingPortalButtonContainer>
          </div>
        )
        : null
      }
      {
        isSubscriptionExpired(billingAccount) || isTrialActive(billingAccount) || isTrialExpired(billingAccount)
          ? (
            <BillingPricingPlansList
              availablePricingPlans={availablePricingPlans}
              billingAccount={billingAccount}
              projectType={projectType}
              onPriceSelected={onPriceSelected}
              availableProjects={availableProjects}
            />
          )
          : null
      }
    </React.Fragment>
  )
}

function BillingStatusMessage ({ billingAccount }) {
  if (isTrialActive(billingAccount) || isTrialExpired(billingAccount)) {
    const currentDate = new Date()
    const trialExpiresAt = billingAccount.trialStatus?.expiresAt
    const trialExpirationDate = new Date(trialExpiresAt)
    const daysLeft = Math.ceil(moment(trialExpirationDate).diff(moment(currentDate), 'days', true))

    return (
      <SubscriptionStatusMessage>
        Trial period - <SubscriptionEmphasis>{ trialExpirationDate > currentDate ? `${daysLeft} days left` : 'expired' }</SubscriptionEmphasis>
      </SubscriptionStatusMessage>
    )
  } else if (billingAccount.subscriptionStatus) {
    const price = billingAccount.subscriptionStatus.price
    const pricingPlan = price ? price.pricingPlan : undefined

    return (
      <SubscriptionStatusMessage>
        Pricing plan: <SubscriptionEmphasis>{ pricingPlan.name || billingAccount.pricingPlan }{isSubscriptionExpired(billingAccount) && ' (expired)' }</SubscriptionEmphasis>
      </SubscriptionStatusMessage>
    )
  } else {
    return null
  }
}
