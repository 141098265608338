export const ANNUAL = {
  value: 'annual',
  label: 'Annual payment'
}

export const MONTHLY = {
  value: 'monthly',
  label: 'Monthly payment'
}

export default [
  ANNUAL,
  MONTHLY
]
