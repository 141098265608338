import reduceReducers from 'reduce-reducers'
import integrationForm from './integrationForm'
import changeCategoryReducer, { changeCategory } from './changeCategory'
import changeNameFilterReducer, { changeNameFilter } from './changeNameFilter'
import changeStatusFilterReducer, { changeStatusFilter } from './changeStatusFilter'

export { changeCategory, changeNameFilter, changeStatusFilter }

const initialState = {
  filter: {}
}

export default reduceReducers(
  (state = initialState) => state,
  (state = {}, action) => ({
    ...state,
    integrationForm: integrationForm(state.integrationForm, action)
  }),
  changeCategoryReducer,
  changeNameFilterReducer,
  changeStatusFilterReducer
)
