import styled from 'styled-components'
import LockBadge from 'containers/LockBadge'
import plus from './plus.png'

export const Integration = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
  width: 177px;
  height: 177px;
  margin: 0 23px 23px 0;
  padding: 0 10px 22px;
  box-sizing: border-box;
  border: 1px solid #e1e7ed;
  border-radius: 8px;
  cursor: pointer;
  background: #fff;
  background-size: contain;
  text-align: left;
  &:hover {
    box-shadow: 0 7px 10px 0 rgba(24, 88, 196, 0.12);
  }
`

export const Signal = styled.div`
  position: absolute;
  top: 12px;
  right: 12px;
`

export const Lock = styled(LockBadge)`
  position: absolute;
  top: 12px;
  left: 12px;
`

export const Plus = styled.div`
  width: 15px;
  height: 15px;
  background: url(${plus}) no-repeat;
  background-size: 15px 15px;
`

export const LogoContainer = styled.div`
  width: 100%;
  height: 109px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-top: 6px;
`

export const Name = styled.div`
  margin-bottom: 2px;
  font-size: 16px;
  text-align: center;
  color: #0c1533;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
`

export const Category = styled.div`
  width: 100%;
  font-size: 14px;
  line-height: normal;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  color: #7d8293;
`
