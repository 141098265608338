import React from 'react'
import { connect } from 'react-redux'
import { open } from 'redux/modules/page/dataTransformations/dataTransformationsForm/open'
import { changePage, DATA_SOURCES } from 'redux/modules/page/changePage'
import getTranslate from 'utils/getTranslate'
import Status from 'containers/Status'
import EllipsisText from 'react-ellipsis-text'
import { checkDataTransformationDependencies } from 'utils/checkDataTransformationDependencies'
import DataTransformationDependencyMessage from './DataTransformationDependencyMessage'

import {
  Transformation,
  TransformationIcon,
  IconContainer,
  Name,
  Plus,
  Lock,
  Signal,
  Description,
  TransDetails
} from './styles'

const DataTransformationItem = ({ locale, dataSources, transformation, onOpen, onChangePage, isEnabled }) => {
  const missingDependency = checkDataTransformationDependencies(dataSources, transformation.dependencies)
  const available = !missingDependency
  const handleClick = () => {
    onOpen(transformation.name)
  }
  const handleMissingDependencyClick = () => {
    onChangePage(DATA_SOURCES)
  }
  const translate = getTranslate(locale, transformation.locale)
  return (
    <Transformation onClick={available ? handleClick : undefined} available={available}>
      { available
        ? <Signal>{isEnabled ? <Status isEnabled={isEnabled} /> : <Plus />}</Signal> : <Lock />
      }
      <IconContainer>
        <TransformationIcon icon={transformation.icon} available={available} />
      </IconContainer>
      <TransDetails>
        <Name>{translate(transformation.name)}</Name>
        <Description>
          <EllipsisText text={translate(transformation.description)} length={250} />
          {
            !available
              ? <DataTransformationDependencyMessage
                missingDependency={missingDependency}
                onClick={handleMissingDependencyClick}
                locale={locale}
              /> : null
          }
        </Description>
      </TransDetails>
    </Transformation>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    dataSources: state.dataSources
  }),
  {
    onOpen: open,
    onChangePage: changePage
  }
)(DataTransformationItem)
