import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Google Drive',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datadestinations/google-drive',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Drive',
      validation: ['required']
    },
    {
      label: 'BigQuery source table name',
      type: 'string',
      name: 'sourceTable',
      validation: ['required']
    },
    {
      label: 'Format',
      type: 'select',
      name: 'format',
      options: [
        'CSV'
      ],
      defaultValue: 'CSV'
    },
    {
      label: 'Filename',
      type: 'string',
      name: 'filename',
      validation: ['required']
    },
    {
      label: 'Folder',
      type: 'google drive file picker',
      name: 'folder'
    },
    {
      label: 'Schedule',
      type: 'select',
      name: 'schedule',
      defaultValue: 'daily',
      options: [
        {
          label: 'daily',
          value: 'daily'
        },
        {
          label: 'hourly',
          value: 'hourly'
        }
      ],
      validation: ['required']
    }
  ]
}

export default config
