import path from './path'

const TYPE = `fetch@${path}`

export const fetch = priority => ({
  type: TYPE,
  priority: priority || {
    before: [],
    after: []
  }
})

export default (state = {}, action) => (action.type === TYPE ? action.priority : state)
