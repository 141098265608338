import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ANALYTICS } from 'integrations/categories'
import { DIGITAL_DATA, EVENT, PRODUCT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const metrics = ['']
for (let index = 1; index <= 200; index += 1) {
  metrics.push(`metric${index}`)
}

const dimensions = ['']
for (let index = 1; index <= 200; index += 1) {
  dimensions.push(`dimension${index}`)
}

const customDimensions = [{ label: 'none', value: undefined }]
for (let index = 1; index <= 200; index += 1) {
  const v = `dimension${index}`
  customDimensions.push({ label: v, value: v })
}

const contentGroups = ['']
for (let index = 1; index <= 200; index += 1) {
  contentGroups.push(`contentGroup${index}`)
}

const config = {
  name: 'Google Analytics',
  color: '#f9ae42',
  category: [ANALYTICS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/google-analytics',
  form: [
    {
      label: 'Tracking ID',
      type: 'string',
      name: 'trackingId',
      validation: ['required']
    },
    {
      label: 'Enhanced Ecommerce',
      type: 'boolean',
      name: 'enhancedEcommerce'
    },
    {
      label: 'Send User ID to GA',
      type: 'boolean',
      name: 'sendUserId'
    },
    {
      label: 'Enhanced link attribution',
      type: 'boolean',
      name: 'enhancedLinkAttribution'
    },
    {
      label: 'Anonymize IP',
      type: 'boolean',
      name: 'anonymizeIp'
    },
    {
      label: 'Transliterate cyrillic symbols',
      type: 'boolean',
      name: 'transliterate'
    },
    {
      label: 'Enable cross-domain tracking',
      type: 'boolean',
      name: 'crossDomainTracking',
      defaultValue: false,
      enable: ['linkedDomains']
    },
    {
      label: 'Linked domains',
      type: 'string array',
      name: 'linkedDomains',
      placeholder: 'example.com'
    },
    {
      label: 'Default currency',
      type: 'string',
      name: 'defaultCurrency',
      defaultValue: 'RUB'
    },
    {
      label: 'Site speed sample rate',
      type: 'number',
      name: 'siteSpeedSampleRate',
      defaultValue: 1
    },
    {
      label: 'Website domain',
      type: 'string',
      name: 'domain',
      defaultValue: 'auto'
    },
    {
      label: 'Optimize Container ID',
      type: 'string',
      name: 'optimizeContainerId'
    },
    {
      label: 'ClientId custom dimension',
      type: 'select',
      name: 'clientIdCustomDimension',
      options: customDimensions,
      defaultValue: undefined
    },
    {
      label: 'Dimensions',
      type: 'variable mapping',
      name: 'dimensions',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT],
      keyOptions: dimensions
    },
    {
      label: 'Metrics',
      type: 'variable mapping',
      name: 'metrics',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT],
      keyOptions: metrics
    },
    {
      label: 'Content groups',
      type: 'variable mapping',
      name: 'contentGroups',
      typeOptions: [DIGITAL_DATA, EVENT],
      keyOptions: contentGroups
    },
    {
      label: 'Namespace',
      type: 'string',
      name: 'namespace'
    },
    {
      label: 'Checkout options',
      type: 'string array',
      name: 'checkoutOptions'
    }
  ],
  compatibilityMap: {
    noConflict: () => ({
      noConflict: undefined
    })
  }
}

export default config
