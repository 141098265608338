import React from 'react'
import getTranslate from 'utils/getTranslate'
import { BookIcon, DocsLinkButton, Text } from './styles'
import ru from './ru'

const DocsLink = ({ locale, url }) => {
  const translate = getTranslate(locale, { ru })

  return (
    <DocsLinkButton href={url} target='_blank' rel='noopener noreferrer'>
      <BookIcon />
      <Text>{translate('Documentation')}</Text>
    </DocsLinkButton>
  )
}

export default DocsLink
