import React, { Component } from 'react'
import {
  Checkbox,
  Label,
  Circle,
  Wrapper,
  Description
} from './styles'
import Tooltip from 'containers/Tooltip'

class BooleanField extends Component {
  constructor (props) {
    super(props)
    this.state = { options: [] }

    this.loadOptions = this.loadOptions.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  componentDidMount () {
    this.loadOptions('initialLoad')
  }

  loadOptions (initialLoad) {
    const {
      optionsFetcher,
      context,
      disableFormSave,
      onChange
    } = this.props
    if (optionsFetcher) {
      disableFormSave(true)
      optionsFetcher(context)
        .then(res => {
          this.optionsLoadStateHandler(res, initialLoad)
          disableFormSave(!res.length)
          if (!res.length) onChange(undefined)
        })
        .catch(() => {
          this.optionsLoadStateHandler([], initialLoad)
          this.setState({ fetchError: true })
          onChange(undefined)
        })
    }
  }

  optionsLoadStateHandler (res, initialLoad) {
    const {
      context,
      dependsOn = []
    } = this.props
    this.setState({
      options: res
    })
    if (initialLoad) {
      const dependentFieldValues = {}
      dependsOn.forEach(dependency => {
        dependentFieldValues[dependency] = (context.dependentFieldValues || {})[dependency]
      })
      this.setState({
        initialLoad: true,
        dependentFieldValues
      })
    }
  }

  handleChange () {
    const { value, onChange, onTouch, optionsFetcher } = this.props
    const { options, fetchError } = this.state
    if (!optionsFetcher || (!fetchError && options && options.length)) {
      onChange(!value)
      if (onTouch) onTouch()
    }
  }

  render () {
    const {
      label,
      value = false,
      tip,
      tipPlacement,
      description,
      errorDescription
    } = this.props
    const { options, initialLoad, fetchError } = this.state

    const descriptionHTML = () => ({ __html: description })
    const errorDescriptionHTML = () => ({ __html: errorDescription })

    return (
      <div>
        <Wrapper>
          <Label
            style={{
              marginLeft: '0',
              color: '#1d2c4c',
              fontSize: '18px',
              fontWeight: 'normal'
            }}
          >
            <div style={{ display: 'flex' }}>
              {label}
              {tip && <Tooltip tip={tip} place={tipPlacement || 'right'} />}
            </div>
          </Label>
          <Checkbox checked={value} onClick={this.handleChange}>
            <Circle checked={value} />
          </Checkbox>
        </Wrapper>
        {
          (initialLoad && !options.length) &&
          <Description dangerouslySetInnerHTML={(fetchError && errorDescription) ? errorDescriptionHTML() : descriptionHTML()} />
        }
      </div>
    )
  }
}

export default BooleanField
