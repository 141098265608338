import reduceReducers from 'reduce-reducers'
import modalWindow from './modalWindow'
import setStatusReducer, { setStatus } from './setStatus'

export { setStatus }

export default reduceReducers(
  (state = {}, action) => ({
    ...state,
    modalWindow: modalWindow(state.modalWindow, action)
  }),
  setStatusReducer
)
