import path from './path'

const OPEN = `open@${path}`

export const open = id => ({
  type: OPEN,
  id
})

export default (state = {}, action) =>
  action.type === OPEN
    ? {
      ...state,
      isOpen: true,
      id: action.id
    }
    : state
