import path from './path'
import { dismissTip } from 'redux/modules/project/dismissTip'

const OPEN = `open@${path}`

export const open = () => dispatch => {
  dispatch(dismissTip())
  dispatch({
    type: OPEN
  })
}

export default (state = {}, action) => (action.type === OPEN ? { ...state, isOpen: true } : state)
