import logo from './logo.png'
import sideLogo from './logo-side.png'
import {
  ADVERTISING,
  PERSONALIZATION,
  AFFILIATE_NETWORKS,
  PUSH_NOTIFICATIONS
} from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Flocktory',
  color: '#64aa2f',
  category: [ADVERTISING, PERSONALIZATION, AFFILIATE_NETWORKS, PUSH_NOTIFICATIONS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/flocktory',
  form: [
    {
      label: 'Website ID',
      type: 'string',
      name: 'siteId',
      validation: ['required']
    },
    {
      label: 'Enable Postcheckout',
      type: 'boolean',
      name: 'postCheckout',
      defaultValue: true
    },
    {
      label: 'Enable Precheckout',
      type: 'boolean',
      name: 'preCheckout'
    },
    {
      label: 'Enable Exchange',
      type: 'boolean',
      name: 'exchange',
      enable: ['exchangeTriggerEvent']
    },
    {
      label: 'Exchange trigger events',
      type: 'string array',
      name: 'exchangeTriggerEvent',
      defaultValue: ['Completed Transaction'],
      validation: ['required']
    }
  ],
  compatibilityMap: {
    exchangeTriggerEvent: ({ exchangeTriggerEvent }) => {
      if (typeof exchangeTriggerEvent === 'string') {
        return {
          exchangeTriggerEvent: [exchangeTriggerEvent]
        }
      }
    }
  }
}

export default config
