import React from 'react'
import ArrayBox from 'containers/Form/ArrayBox'
import DigitalDataConditionsRow from './DigitalDataConditionsRow'

const DigitalDataConditions = ({ locale, description, label, value, onChange }) => {
  const handleChange = newRows => {
    onChange(newRows.filter(row => row.prop && row.condition && row.value !== undefined))
  }
  const defaultRow = { prop: undefined, condition: '=', value: undefined }
  const rows = value && value.length ? value : [defaultRow]
  const createRow = ({ row, index, onChangeRow }) => {
    const handleChangeProp = prop => onChangeRow(index, { ...row, prop })
    const handleChangeCondition = condition => onChangeRow(index, { ...row, condition })
    const handleChangeValue = val => onChangeRow(index, { ...row, value: val })
    return (
      <DigitalDataConditionsRow
        locale={locale}
        prop={row.prop}
        condition={row.condition}
        value={row.value}
        onChangeProp={handleChangeProp}
        onChangeCondition={handleChangeCondition}
        onChangeValue={handleChangeValue}
      />
    )
  }
  const style = { display: 'none ' } // temporary hide
  return (
    <ArrayBox
      locale={locale}
      defaultRow={defaultRow}
      description={description}
      label={label}
      onCreateRow={createRow}
      rows={rows}
      style={style}
      onChange={handleChange}
    />
  )
}

export default DigitalDataConditions
