import React from 'react'
import { connect } from 'react-redux'
import allIntegrations, { sandboxIntegrations, topIntegrationsName } from 'integrations'
import { ALL } from 'integrations/categories'
import { STATUS_ALL, STATUS_ENABLED, STATUS_DISABLED } from 'integrations/statuses'
import IntegrationItem from './IntegrationItem'
import { Block } from './styles'

const ADSPIRE_PROJECTS_LIST = [
  '7605e85f-77fd-4e37-96d2-d084122c18a0',
  'fab0cdf8-33f9-4b4e-979b-8879c8f9409f',
  'a7cffd19-c4cf-4c4f-82b1-f325827ec7c7',
  '7573b6b6-3116-4087-bbe6-b6063f9e7a6d',
  '2319d910-59f5-4937-9c2a-8ca3dc9523f4',
  'c2ddfc20-f1d2-43f3-9dae-e66fd6253857',
  '12132574-b1b7-45a4-a355-ea9d7f7688fd'
]

const IntegrationsList = ({ filter, allIntegrationData, isSaleMode, projectId }) => {
  let integrations = []

  if (isSaleMode) {
    integrations = [...sandboxIntegrations, ...allIntegrations]

    const topIntegrations = integrations.filter((item, index) => {
      if (topIntegrationsName.includes(item.name)) {
        integrations.splice(index, 1)
        return true
      }
      return false
    })

    integrations = [...topIntegrations, ...integrations]

    integrations.sort((first, second) => {
      const firstIndex = topIntegrationsName.indexOf(first.name)
      const secondIndex = topIntegrationsName.indexOf(second.name)
      return secondIndex - firstIndex
    })
  } else {
    integrations = allIntegrations
    if (!ADSPIRE_PROJECTS_LIST.includes(projectId)) {
      integrations = allIntegrations.filter((integration) => {
        return integration.name !== 'AdSpire'
      })
    }
  }

  const integrationCategoryMatch = integration =>
    filter.category && filter.category !== ALL
      ? integration.category.includes(filter.category)
      : true

  const integrationNameSearchMatch = integration =>
    filter.name ? integration.name.toLowerCase().includes(filter.name.toLowerCase()) : true

  const enabledIntegrations = Object.keys(allIntegrationData).filter(
    item => allIntegrationData[item].isEnabled
  )

  const integrationStatusMatch = integration => {
    if (filter.status === STATUS_ENABLED) {
      return enabledIntegrations.includes(integration.name)
    }
    if (filter.status === STATUS_DISABLED) {
      return !enabledIntegrations.includes(integration.name)
    }
    if (filter.status === STATUS_ALL) {
      return true
    }
    return true
  }

  const filteredIntegrations = integrations.filter(integration => {
    return (
      integrationCategoryMatch(integration) &&
      integrationNameSearchMatch(integration) &&
      integrationStatusMatch(integration)
    )
  })

  return (
    <Block>
      {filteredIntegrations.map(integration => (
        <IntegrationItem
          key={integration.name}
          integration={integration}
          isEnabled={enabledIntegrations.includes(integration.name)}
        />
      ))}
    </Block>
  )
}

export default connect(state => ({
  locale: state.locale,
  filter: state.page.integrations.filter,
  allIntegrationData: state.integrations,
  isSaleMode: state.saleMode.status,
  projectId: state.project.id
}))(IntegrationsList)
