import { costsDataSources } from 'dataSources'

const costsDataSourceTypes = costsDataSources.map(ds => ds.type)

export const getNormalizedCostsDataSources = (dataSources) => {
  const normalizedCostsDataSources = []
  Object.keys(dataSources).forEach(key => {
    const options = dataSources[key].options
    if (options && dataSources[key].isEnabled && costsDataSourceTypes.includes(dataSources[key].type)) {
      if (options.normalizedCostsReport === undefined) {
        normalizedCostsDataSources.push(dataSources[key].type)
      } else if (options.normalizedCostsReport) {
        normalizedCostsDataSources.push(dataSources[key].type)
      }
    }
  })
  return normalizedCostsDataSources
}
