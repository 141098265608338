import logo from './logo.png'
import sideLogo from './logo-side.png'
import ru from './ru.json'
import { ANALYTICS } from 'integrations/categories'
import { DIGITAL_DATA, CONSTANT } from 'containers/Form/Variable/options'

export default {
  name: 'K50',
  color: '#D60001',
  category: [ANALYTICS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/k50',
  form: [
    {
      label: 'Website ID',
      type: 'string',
      name: 'siteId',
      validation: ['required']
    },
    {
      label: 'Label variable',
      type: 'variable',
      name: 'labelVar',
      typeOptions: [DIGITAL_DATA, CONSTANT]
    }
  ]
}
