import reduceReducers from 'reduce-reducers'
import closeModalReducer, { closeModal } from './closeModal'
import openModalReducer, { openModal } from './openModal'
import pendingReducer, { pending, PENDING } from './pending'
import successReducer, { success, SUCCESS } from './success'
import { save } from './save'

export { closeModal, openModal, save, pending, success, PENDING, SUCCESS }

export const PROJECT_OPTIONS = 'project options'
export const PROJECT_MEMBERS = 'project members'
export const RESTRICTED_FEATURE_MODAL = 'restricted feature modal'
export const DATA_DESTINATIONS = 'data destinations'
export const DATA_TRANSFORMATIONS = 'data transformations'
export const DATA_SOURCES = 'data sources'
export const BIG_QUERY = 'big query'
export const TRACKING = 'tracking'
export const DATA_STREAMS = 'data streams'

export default reduceReducers(
  closeModalReducer,
  openModalReducer,
  pendingReducer,
  successReducer
)
