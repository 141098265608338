import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import {
  fetchGoogleAnalytics4BQProjects,
  fetchGoogleAnalytics4BQDatasets
} from 'api/dataSource'

const config = {
  type: 'Google Analytics 4',
  logo,
  hideEnabledButtom: true,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/google-analytics-4',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  authForm: [
    {
      label: 'Authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'BigQuery was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Analytics 4',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Google Cloud project',
      type: 'dynamic select',
      name: 'projectId',
      validation: ['required'],
      placeholder: 'Select Project',
      optionsFetcher: fetchGoogleAnalytics4BQProjects,
      description: 'There are no Google Cloud Platform projects in this Google Account. To collect data into Google BigQuery, you first need to create a Google Cloud Platform project and then click the field refresh button. Go to <a class="description" href="https://cloud.google.com/" target="_blank" rel="noopener noreferrer">cloud.google.com</a> and click the “Try It Free” button. Enter your personal information and payment card details.',
      enable: ['datasetName'],
      tip: 'Select the Google Cloud project used for Google Analytics 4 data export'
    },
    {
      label: 'BigQuery dataset',
      type: 'dynamic select',
      name: 'datasetName',
      validation: ['required'],
      placeholder: 'Select Dataset',
      optionsFetcher: fetchGoogleAnalytics4BQDatasets,
      description: 'There are no Google Analytics 4 datasets in this project. Please, make sure you\'ve selected a correct Google Cloud project or the authentificated user has sufficient permissions.',
      dependsOn: ['projectId'],
      enable: ['dailyTableName', 'interdayTableName'],
      tip: 'Select the dataset used for Google Analytics 4 data export'
    },
    {
      label: 'Daily table full name',
      type: 'string',
      name: 'dailyTableName',
      dependsOn: ['projectId', 'datasetName'],
      valueFetcher: async (context) => {
        return `${context.formData.projectId}.${context.formData.datasetName}.events_*`
      },
      disabled: true
    },
    {
      label: 'Interday table full name',
      type: 'string',
      name: 'interdayTableName',
      dependsOn: ['projectId', 'datasetName'],
      valueFetcher: async (context) => {
        return `${context.formData.projectId}.${context.formData.datasetName}.events_intraday_*`
      },
      disabled: true
    }
  ]
}
export default config
