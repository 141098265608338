import styled from 'styled-components'

export const Title = styled.div`
  font-size: 22px;
  line-height: 45px;
  color: #0c1533;
`

export const ButtonTitle = styled.div`
  padding-left: 30px;
  padding-right: 15px;
`

export const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #e3e4e6;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
  user-select: none;
  height: 44px;
`

export const OnboardingContainer = styled.div`
  min-height: calc(100vh - 164px);
  display: flex;
  align-items: center;
  justify-content: center;
`
