import { removeAuth as apiRemoveAuth } from 'api/integration'
import { fetchProject } from 'redux/modules/project'
import { changeStatus } from './changeStatus'
import path from './path'

const SET_AUTH_STATUS = `setAuthStatus@${path}`

export const setAuthStatus = (integrationName, isAuthenticated) => async (dispatch, getState) => {
  const projectId = getState().project.id
  const page = getState().page.name
  const userRole = getState().user.role
  await dispatch(fetchProject(projectId, page, userRole))
  dispatch({
    type: SET_AUTH_STATUS,
    isAuthenticated,
    integrationName
  })
}

export const removeAuth = integrationName => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(apiRemoveAuth(projectId, integrationName))
  dispatch(setAuthStatus(integrationName, false))
  dispatch(changeStatus(integrationName, false))
}

export default (state = {}, action) =>
  action.type === SET_AUTH_STATUS
    ? {
      ...state,
      [action.integrationName]: {
        ...state[action.integrationName],
        isAuthenticated: action.isAuthenticated
      }
    }
    : state
