import styled, { css } from 'styled-components'

export const Integration = styled.div`
  cursor: move;
  background-color: white;
  border: 1px solid #d5dee6;
  width: 120px;
  height: 120px;
  margin-right: 20px;
  margin-bottom: 20px;
  padding-top: 30px;
  display: inline-block;
  border-radius: 15px;
  ${props =>
    props.fake &&
    css`
      background-color: #fafafb;
      border-style: dashed;
    `}
  @media (max-width: 1300px) {
    margin-right: 5px;
    margin-bottom: 5px;
  }
`

export const Block = styled.div`
  width: 50%;
  height: 100%;
  display: inline-block;
  min-height: 400px;
`

export const DropArea = styled.div`
  margin-bottom: 20px;
  width: 120px;
  height: 120px;
  display: inline-block;
  @media (max-width: 1300px) {
    margin-bottom: 5px;
  }
`

export const PriorityLabel = styled.div`
  font-size: 26px;
  display: inline-block;
  margin-bottom: 48px;
  color: #0c1533;
  text-align: left;
`

export const IntegrationLogo = styled.div`
  width: 105px;
  height: 60px;
  display: block;
  margin: 0 auto;
  padding: 0;
  background: url(${props => props.logo});
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
`
