const translateFormItem = (item, translate) => {
  const translatedItem = {
    ...item,
    label: translate(item.label),
    description: translate(item.description),
    placeholder: translate(item.placeholder),
    placeholderKey: translate(item.placeholderKey),
    placeholderValue: translate(item.placeholderValue),
    units: translate(item.units),
    addButtonLabel: translate(item.addButtonLabel),
    tip: translate(item.tip)
  }
  if (item.items) {
    translatedItem.items = item.items.map(groupItem => translateFormItem(groupItem, translate))
  }
  if (item.options) {
    translatedItem.options = item.options.map(option => {
      if (typeof option === 'string') return option
      return {
        ...option,
        label: translate(option.label)
      }
    })
  }
  return translatedItem
}

export default translateFormItem
