/* eslint-disable react-hooks/rules-of-hooks */
import React from 'react'
import { useCubeQuery } from '../../../../../../utils/cubejs/cubeQuery'
import {
  CartesianGrid,
  PieChart,
  Pie,
  Cell,
  AreaChart,
  Area,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  BarChart,
  Bar,
  LineChart,
  Line
} from 'recharts'
import formatNumber from '@segmentstream/format-number'
import { withStyles, useTheme } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import Typography from '@material-ui/core/Typography'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import Collapse from '@material-ui/core/Collapse'
import TableFooter from '@material-ui/core/TableFooter'
import TablePagination from '@material-ui/core/TablePagination'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import IconButton from '@material-ui/core/IconButton'
import Box from '@material-ui/core/Box'
import FirstPageIcon from '@material-ui/icons/FirstPage'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import KeyboardArrowUp from '@material-ui/icons/KeyboardArrowUp'
import KeyboardArrowDown from '@material-ui/icons/KeyboardArrowDown'
import LastPageIcon from '@material-ui/icons/LastPage'

const convertResultByType = (value, type) => {
  switch (type) {
    case 'number':
      return value
    case 'percent':
      return `${formatNumber(value, 2)} %`
    default:
      return value
  }
}
const TableCellStyled = withStyles((theme) => ({
  head: {
    backgroundColor: '#4d6dd3',
    color: theme.palette.common.white,
    padding: 8
  },
  body: {
    fontSize: 14,
    padding: 8
  }
}))(TableCell)

const colors = ['#1565c0', '#388e3c', '#00acc1']

const CartesianChart = ({ resultSet, children, singleY, ChartComponent }) => (
  <ResponsiveContainer width='100%' height={350}>
    <ChartComponent data={resultSet.chartPivot()}>
      <defs>
        <linearGradient id='color0' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='5%' stopColor={colors[0]} stopOpacity={1} />
          <stop offset='95%' stopColor={colors[0]} stopOpacity={0} />
        </linearGradient>
        <linearGradient id='color1' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='5%' stopColor={colors[1]} stopOpacity={1} />
          <stop offset='95%' stopColor={colors[1]} stopOpacity={0} />
        </linearGradient>
        <linearGradient id='color2' x1='0' y1='0' x2='0' y2='1'>
          <stop offset='5%' stopColor={colors[2]} stopOpacity={1} />
          <stop offset='95%' stopColor={colors[2]} stopOpacity={0} />
        </linearGradient>
      </defs>
      <XAxis dataKey='x' />
      {singleY ? (<YAxis yAxisId={1} />) : resultSet.seriesNames().map((series, i) => (
        <YAxis orientation={i === 1 ? 'right' : 'left'} type='number' yAxisId={i >= 1 ? 1 : i} />
      ))}
      <CartesianGrid />
      {children}
      <Legend />
      <Tooltip />
    </ChartComponent>
  </ResponsiveContainer>
)

function TablePaginationActions (props) {
  const theme = useTheme()
  const { count, page, rowsPerPage, onPageChange } = props

  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0)
  }

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1)
  }

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1)
  }

  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1))
  }

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label='first page'
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label='previous page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='next page'
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label='last page'
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  )
}

const TypeToChartComponent = {
  line: ({ resultSet, singleY }) => {
    return (
      <CartesianChart resultSet={resultSet} singleY={singleY} ChartComponent={LineChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Line
            type='monotoneX'
            strokeWidth={3}
            yAxisId={singleY || i >= 1 ? 1 : i}
            key={series.key}
            stackId='a'
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
          />
        ))}
      </CartesianChart>
    )
  },
  bar: ({ resultSet }) => {
    return (
      <CartesianChart resultSet={resultSet} ChartComponent={BarChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Bar
            key={series.key}
            stackId='a'
            dataKey={series.key}
            name={series.title}
            fill={colors[i]}
          />
        ))}
      </CartesianChart>
    )
  },
  area: ({ resultSet, singleY }) => {
    return (
      <CartesianChart resultSet={resultSet} singleY={singleY} ChartComponent={AreaChart}>
        {resultSet.seriesNames().map((series, i) => (
          <Area
            type='monotoneX'
            strokeWidth={3}
            yAxisId={singleY || i >= 1 ? 1 : i}
            key={series.key}
            stackId='a'
            dataKey={series.key}
            name={series.title}
            stroke={colors[i]}
            fill={`url(#color${i})`}
            fillOpacity={1}
          />
        ))}
      </CartesianChart>
    )
  },
  pie: ({ resultSet }) => {
    return (
      <ResponsiveContainer width='100%' height={350}>
        <PieChart>
          <Pie
            isAnimationActive={false}
            data={resultSet.chartPivot()}
            nameKey='x'
            dataKey={resultSet.seriesNames()[0].key}
            fill='#8884d8'
          >
            {resultSet.chartPivot().map((e, index) => (
              <Cell key={index} fill={colors[index % colors.length]} />
            ))}
          </Pie>
          <Legend />
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    )
  },
  number: ({ resultSet, current, previous, indifferentChanges, inscreseIsPositive }) => {
    if (resultSet) {
      return (
        <Typography variant='h5'>
          {convertResultByType(formatNumber(resultSet.value, 2), current.type)}
        </Typography>
      )
    }

    const currentData = current.value
    const previousData = previous.value

    const precentDiff = currentData > previousData
      ? (currentData / previousData - 1) * 100
      : (1 - currentData / previousData) * -100

    const isGrey = indifferentChanges
    const isGreen = (inscreseIsPositive && Number(currentData) > Number(previousData)) || (!inscreseIsPositive && Number(currentData) < Number(previousData))

    return (
      <Typography>
        <Typography variant='h5'> {convertResultByType(formatNumber(currentData, 2), current.type)}</Typography>
        <Typography style={{ 'margin': '0' }}> {convertResultByType(formatNumber(previousData, 2), previous.type)}</Typography>
        {Number.isFinite(precentDiff) && <Typography style={{ color: isGrey ? '' : (isGreen ? '#0f9d58' : '#f45a4e'), 'margin': '0' }} >({precentDiff > 0 ? '+' : ''}{formatNumber(precentDiff, 2)} %)</Typography>}
      </Typography>
    )
  },
  table: ({ resultSet, child, formatString = (row, key) => formatNumber(row[key]) }) => {
    const [page, setPage] = React.useState(0)
    const [rowsPerPage, setRowsPerPage] = React.useState(10)

    const rows = resultSet.tablePivot()

    const handleChangePage = (event, newPage) => {
      setPage(newPage)
    }

    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10))
      setPage(0)
    }

    const [selected, setSelected] = React.useState()
    return (
      <Table aria-label='custom pagination table'>
        <TableHead>
          <TableRow>
            <TableCellStyled key='expand' />
            {resultSet.tableColumns().map((c) => (
              <TableCellStyled key={c.key}>{c.title}</TableCellStyled>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {(rowsPerPage > 0
            ? rows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
            : rows
          ).map((row, index) => (
            <React.Fragment>
              <TableRow key={index}>
                <TableCell>
                  {child && <IconButton aria-label='expand row' size='small' onClick={() => setSelected(selected === row[resultSet.tableColumns()[0].key] ? undefined : row[resultSet.tableColumns()[0].key])}>
                    {selected === row[resultSet.tableColumns()[0].key] ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
                  </IconButton>}
                </TableCell>
                {resultSet.tableColumns().map((c) => (
                  <TableCellStyled key={c.key}>{formatString(row, c.key)}</TableCellStyled>
                ))}
              </TableRow>
              {child && <TableRow>
                <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11}>
                  <Collapse in={selected === row[resultSet.tableColumns()[0].key]} timeout='auto' unmountOnExit>
                    <Box margin={1}>
                      <Typography style={{ 'textAlign': 'center' }} color='textSecondary' gutterBottom>
                        {child.name}
                      </Typography>
                      <ChartRenderer vizState={child.data(selected)} />
                    </Box>
                  </Collapse>
                </TableCell>
              </TableRow>}
            </React.Fragment>
          ))}
        </TableBody>
        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 30, 50, { label: 'All', value: -1 }]}
              colSpan={10}
              count={rows.length}
              rowsPerPage={rowsPerPage}
              page={page}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page'
                },
                native: true
              }}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
              ActionsComponent={TablePaginationActions}
            />
          </TableRow>
        </TableFooter>
      </Table>
    )
  }
}

const TypeToMemoChartComponent = Object.keys(TypeToChartComponent)
  .map((key) => ({
    [key]: React.memo(TypeToChartComponent[key])
  }))
  .reduce((a, b) => ({ ...a, ...b }))

const renderChart = (Component) => ({ resultSet, current, previous, error, ...props }) => {
  if (resultSet || (current?.resultSet && previous?.resultSet)) {
    return (
      <Component resultSet={resultSet} current={current?.resultSet} previous={previous?.resultSet} {...props} />
    )
  }

  if (error || current?.error || previous?.error) {
    return (
      <>{(error || current?.error || previous?.error).toString()}</>
    )
  }

  return (
    <CircularProgress />
  )
}

const ChartRenderer = ({ vizState = {} }) => {
  const { query, chartType, current, previous, ...options } = vizState

  const component = TypeToMemoChartComponent[current || previous ? 'number' : chartType]
  if (!component) {
    return null
  }

  if (query) {
    const renderProps = useCubeQuery(query, {
      resetResultSetOnChange: true
    })

    return renderChart(component)({ ...options, ...renderProps })
  }

  const renderCurrentProps = useCubeQuery(current.query, {
    resetResultSetOnChange: true
  })
  const renderPreviousProps = useCubeQuery(previous.query, {
    resetResultSetOnChange: true
  })

  return renderChart(component)({ ...options, current: renderCurrentProps, previous: renderPreviousProps })
}

export default ChartRenderer
