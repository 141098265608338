import React from 'react'
import getValidationError from 'containers/Form/getValidationError'
import TextArea from 'containers/Form/TextArea'
import FieldError from 'containers/Form/FieldError'

const TextAreaField = ({
  locale,
  disabled,
  hasFocus,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onTouch,
  placeholder,
  style = { marginBottom: '17px', fontSize: '16px' },
  touched,
  validation,
  value = '',
  autocomplete = false
}) => {
  const error =
    touched && !disabled && !hasFocus && validation
      ? getValidationError(validation, value, locale)
      : undefined

  return (
    <div style={style}>
      {label && <label>{label}</label>}
      <TextArea
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onTouch={onTouch}
        onBlur={onBlur}
        hasFocus={hasFocus}
        touched={touched}
        validation={validation}
        autocomplete={autocomplete}
      />
      {error && <FieldError message={error} />}
    </div>
  )
}

export default TextAreaField
