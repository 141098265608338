import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'AdvCake',
  color: '#424242',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Automatic cookie tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: [
        'trackIdCookieName',
        'urlCookieName',
        'cookieTtl',
        'cookieDomain',
        'deduplication',
        'deduplicationUtmMedium',
        'utmSource'
      ]
    },
    {
      label: 'Cookie, which stores Track ID',
      type: 'string',
      name: 'trackIdCookieName',
      defaultValue: 'advcake_trackid'
    },
    {
      label: 'Cookie, which stores landing URL',
      type: 'string',
      name: 'urlCookieName',
      defaultValue: 'advcake_url'
    },
    {
      label: 'Cookie time to live',
      type: 'number',
      name: 'cookieTtl',
      defaultValue: 90
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain'
    },
    {
      label: 'Activate deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which require deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    },
    {
      label: 'utm_source from AdvCake',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'advcake'
    }
  ]
}

export default config
