import React from 'react'
import { connect } from 'react-redux'
import { saveOverrideFunctions } from 'redux/modules/page/integrations/integrationForm'
import Form from 'containers/Form'

const OverrideFunctions = ({ locale, name, overrideFunctions, onSave }) => {
  const form = [
    {
      type: 'text',
      name: 'overrideFunctions'
    }
  ]
  const initialData = { overrideFunctions }
  const handleSave = data => {
    onSave(name, data.overrideFunctions)
  }

  return <Form locale={locale} form={form} data={initialData} onSave={handleSave} />
}

export default connect(
  state => {
    const { name } = state.page.integrations.integrationForm
    return {
      locale: state.locale,
      overrideFunctions: state.integrations[name] && state.integrations[name].overrideFunctions,
      name
    }
  },
  {
    onSave: saveOverrideFunctions
  }
)(OverrideFunctions)
