import React from 'react'
import { AuthButton, AuthIcon, AuthText } from './styles'
import apiEndpoint from '../../../apiEndpoint'

class OAuth2Button extends React.PureComponent {
  constructor (props) {
    super(props)
    this.win = null
    this.onMessage = this.onMessage.bind(this)
    this.handleClick = this.handleClick.bind(this)
  }

  componentDidMount () {
    window.addEventListener('message', this.onMessage)
  }

  componentWillUnmount () {
    window.removeEventListener('message', this.onMessage)
    if (this.win) this.win.close()
  }

  onMessage (message) {
    const { onChange } = this.props
    const { data } = message
    if (data.type === 'oauth2_callback') {
      onChange(true)
      if (this.win) this.win.close()
    }
  }

  handleClick () {
    const {
      context: { getFreshAccessToken, authorizeEndpoint }
    } = this.props

    const winOptions = 'width=1200, height=900, top=0, left=0, resizable=yes, scrollbars=yes, status=yes'
    this.win = window.open('about:blank', '_blank', winOptions)

    getFreshAccessToken().then(accessToken => {
      const authorizeURL = `${apiEndpoint}${authorizeEndpoint}?access_token=${accessToken}`
      if (this.win) {
        this.win.location = authorizeURL
        this.win.focus()
      }
    })
  }

  render () {
    const {
      label,
      icon
    } = this.props

    return (
      <AuthButton onClick={this.handleClick}>
        <AuthIcon src={icon} />
        <AuthText>{label}</AuthText>
      </AuthButton>
    )
  }
}

export default OAuth2Button
