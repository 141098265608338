import React, { Component } from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import {
  closeModal,
  PENDING,
  SUCCESS,
  CUSTOM_INTEGRATIONS
} from 'redux/modules/proMode/modalWindow'
import { saveCustomIntegrations } from 'redux/modules/project'
import AceEditor from 'containers/AceEditor'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import SuccessMessage from 'containers/SuccessMessage'
import Space from 'containers/Space'

class CustomIntegrations extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: JSON.stringify(this.props.value, 0, 2),
      touched: false
    }
    this.handleChange = this.handleChange.bind(this)
    this.handleTouch = this.handleTouch.bind(this)
    this.handleSave = this.handleSave.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    if (this.props.value !== nextProps.value) {
      this.setState({
        value: JSON.stringify(nextProps.value, 0, 2)
      })
    }
    if (this.props.isOpen !== nextProps.isOpen && nextProps.isOpen === false) {
      this.setState({
        value: JSON.stringify(nextProps.value, 0, 2),
        touched: false
      })
    }
  }

  handleChange (value) {
    this.setState({ value })
  }

  handleTouch () {
    this.setState({ touched: true })
  }

  handleSave () {
    const touchedFields = this.state.touched
    const value = this.state.value && JSON.parse(this.state.value)
    this.props.onSave(value, touchedFields)
    this.setState({ touched: false })
  }

  render () {
    const { touched, value } = this.state
    const { isOpen, onClose, status } = this.props
    return (
      <ModalWindow isOpen={isOpen} onClose={onClose} header='Custom integrations'>
        {status === SUCCESS && !touched && (
          <SuccessMessage>Custom integrations was saved</SuccessMessage>
        )}
        <AceEditor
          mode='json'
          style={{ marginBottom: '20px' }}
          value={value}
          onChange={this.handleChange}
          onFocus={this.handleTouch}
        />
        <Space />
        <ConfirmButton onClick={this.handleSave} pending={status === PENDING}>
          Save
        </ConfirmButton>
      </ModalWindow>
    )
  }
}

export default connect(
  state => {
    const { component, status } = state.proMode.modalWindow
    return {
      isOpen: component === CUSTOM_INTEGRATIONS,
      value: state.project.customIntegrations || {},
      status
    }
  },
  {
    onClose: closeModal,
    onSave: saveCustomIntegrations
  }
)(CustomIntegrations)
