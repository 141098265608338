import _ from 'lodash'

export const LEGACY_1_FIXED_PRICE = 'legacy_1_fixed_price'
export const LEGACY_1_FREE = 'legacy_1_free'
export const LEGACY_2_TRAFFIC_BASED = 'legacy_2_traffic_based'
export const LIGHT = 'light'
export const BUSINESS = 'business'
export const ENTERPRISE = 'enterprise'
export const FREE = 'free'
export const CONNECT_TRIAL = 'connect_trial'
export const CONNECT_SMALL = 'connect_small'
export const CONNECT_MEDIUM = 'connect_medium'
export const CONNECT_LARGE = 'connect_large'
export const STARTER_GA = 'starter_ga'
export const SMALL_GA = 'small_ga'
export const MEDIUM_GA = 'medium_ga'
export const LARGE_GA = 'large_ga'
export const DEMO = 'demo'

export const CONNECT_PRICING_PLANS = [
  CONNECT_TRIAL,
  CONNECT_SMALL,
  CONNECT_MEDIUM,
  CONNECT_LARGE
]

export const DATA_STREAMS_PRICING_PLANS = [
  BUSINESS,
  ENTERPRISE,
  FREE,
  DEMO
]

export const DATA_SOURCES_PRICING_PLANS = [
  BUSINESS,
  ENTERPRISE,
  FREE,
  DEMO,
  ...CONNECT_PRICING_PLANS
]

export const TRIAL_PRICING_PLANS = [
  CONNECT_TRIAL
]

export const GOOGLE_ANALYTICS_PRICING_PLANS = [
  STARTER_GA,
  SMALL_GA,
  MEDIUM_GA,
  LARGE_GA
]

export const SELF_SERVE_PRICING_PLANS = [
  ...TRIAL_PRICING_PLANS,
  ...CONNECT_PRICING_PLANS,
  ...GOOGLE_ANALYTICS_PRICING_PLANS
]

export const DATA_TRANSFORMATIONS_PRICING_PLANS = [BUSINESS, ENTERPRISE, FREE, DEMO]
export const DATA_DESTINATIONS_PRICING_PLANS = [BUSINESS, ENTERPRISE, FREE, DEMO]
export const DEFAULT_PRICING_PLANS = [
  BUSINESS,
  ENTERPRISE,
  FREE,
  LIGHT,
  LEGACY_2_TRAFFIC_BASED,
  LEGACY_1_FREE,
  LEGACY_1_FIXED_PRICE,
  DEMO
]

export const isAvailableForPricingPlan = (item, pricingPlan, pricingPlanArray) => {
  const availableForPricingPlans = _.get(item, 'configurations.availableForPricingPlans') || pricingPlanArray
  return availableForPricingPlans ? availableForPricingPlans.includes(pricingPlan) : true
}

export const getFieldsUnavailableForPricingPlan = (form, pricingPlan) => {
  const unavailableFields = []
  form.forEach(field => {
    const availableForPricingPlan = field.availableForPricingPlans
      ? field.availableForPricingPlans.includes(pricingPlan)
      : true
    if (!availableForPricingPlan) {
      unavailableFields.push(field.name)
    }
  })
  return unavailableFields
}
