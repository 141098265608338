import path from './path'

const SET_AUTH_CHECK_ERROR = `setAuthCheckError@${path}`

export const setAuthCheckError = (integrationName, authenticationFailMessage) => async dispatch => {
  dispatch({
    type: SET_AUTH_CHECK_ERROR,
    integrationName,
    authenticationFailMessage
  })
}

export default (state = {}, action) =>
  action.type === SET_AUTH_CHECK_ERROR
    ? {
      ...state,
      [action.integrationName]: {
        ...state[action.integrationName],
        authenticationFailMessage: action.authenticationFailMessage,
        isAuthenticated: false
      }
    }
    : state
