import React from 'react'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'

import OnboardingPlaceholder from '../../OnboardingPlaceholder'
import bq from '../../DataSources/bq.png'
import SectionHeader from '../../../../../SectionHeader'
import IntegrationSide from '../../Integrations/IntegrationSide'
import { DataStreamsOnboarding } from './DataStreamsOnboarding'
import { Spinner } from '../../../../../../uiKit'
import { DataStreamItem } from './DataStreamItem'
import { ItemContainer, OnboardingContainer } from './styles'
import EnabledStatusButton from '../../../../../../uiKit/EnabledStatusButton'
import LinkButton from '../../../../../../uiKit/LinkButton'
import { createDatasetLink } from '../../../../../../utils/bigQueryHelpers'

const DDMANAGER_STREAMING_INTEGRATION = 'DDManager Streaming'

export function DataStreamsList ({ onAddClicked, list, integrations, onIntegrationOpen }) {
  const streamingIntegration = integrations[DDMANAGER_STREAMING_INTEGRATION]

  if (list.isLoading) {
    return <Spinner />
  }

  if (!streamingIntegration || !streamingIntegration.isEnabled) {
    return (
      <div>
        <IntegrationSide displayMode={'simple'} />
        <OnboardingContainer>
          <OnboardingPlaceholder
            title={'To stream data to Google BigQuery, please connect your BigQuery account first'}
            icon={bq}
            showArrow
            buttonTitle={'Enable BigQuery integration'}
            buttonAction={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }}
            linkTitle='Getting Started Tutorial ▸'
            linkUrl='https://docs.segmentstream.com/bigquery/overview'
          />
        </OnboardingContainer>
      </div>
    )
  }

  const streamingConfig = (
    <div style={{ display: 'flex' }}>
      {
        streamingIntegration && <LinkButton text='View BigQuery dataset' link={createDatasetLink(streamingIntegration)} />
      }
      <EnabledStatusButton text='BigQuery enabled' handleClick={() => { onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION) }} />
    </div>
  )

  return (
    <div>
      <SectionHeader
        leftContent={
          list.dataStreams && list.dataStreams.length > 0
            ? <ConfirmButton onClick={onAddClicked}>Add Data Stream</ConfirmButton>
            : null
        }
        rightContent={streamingConfig}
      />

      { !list.dataStreams || list.dataStreams.length < 1
        ? <DataStreamsOnboarding onAddButtonClick={onAddClicked} />
        : (
          <ItemContainer>
            {
              list.dataStreams.map(dataStream => (
                <DataStreamItem
                  key={dataStream.id}
                  dataStream={dataStream}
                  onClick={() => list.selectDataStreamToUpdate(dataStream)}
                />
              ))
            }
          </ItemContainer>
        )
      }
    </div>
  )
}
