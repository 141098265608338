import React, { useState } from 'react'
import { ButtonDanger } from './ButtonDanger'

import ConfirmationWindow from 'containers/ConfirmationWindow'

export function ButtonWithConfirmation ({ label, dangerZone, confirmMessage, onConfirm, onCancel, confirmButtonText, cancelButtonText }) {
  const [confirmOpen, setConfirmOpen] = useState(false)

  const handleClick = () => {
    setConfirmOpen(true)
  }

  const handleConfirm = async () => {
    setConfirmOpen(false)

    if (onConfirm) {
      await onConfirm()
    }
  }

  const handleCancel = async () => {
    setConfirmOpen(false)

    if (onCancel) {
      await onCancel()
    }
  }

  return (
    <React.Fragment>
      <ConfirmationWindow
        dangerZone={dangerZone}
        isOpen={confirmOpen}
        messageText={confirmMessage}
        onConfirm={handleConfirm}
        onClose={handleCancel}
        confirmButtonText={confirmButtonText || label}
        cancelButtonText={cancelButtonText || 'Cancel'}
      />
      <ButtonDanger onClick={handleClick} label={label || confirmButtonText}>
        { label || confirmButtonText }
      </ButtonDanger>
    </React.Fragment>
  )
}
