const getDisabledFields = (options, selectedOption) => {
  const disabledFields = []
  if (selectedOption && selectedOption.disable) {
    disabledFields.push(...selectedOption.disable)
  }
  const allEnabledFields = options.reduce((acc, item) => acc.concat(item.enable), [])
  if (!selectedOption) {
    return disabledFields.concat(allEnabledFields)
  }
  return disabledFields.concat(
    allEnabledFields.filter(
      item => !selectedOption.enable || !selectedOption.enable.includes(item)
    )
  )
}

export default (form, state) => form.reduce((acc, field) => {
  if (state && state[field.name] && field.disable) {
    return acc.concat(field.disable)
  }
  if (state && !state[field.name] && field.enable) {
    return acc.concat(field.enable)
  }
  if (state && field.showIf && !field.showIf(state)) {
    return acc.concat(field.name)
  }
  if (state && field.type === 'group') {
    let fieldDisabledFields = []
    field.items.forEach(i => {
      if (state[field.name]) {
        if (!state[field.name][i.name] && i.enable) {
          fieldDisabledFields = [...fieldDisabledFields, i.enable]
        }
        if (i.showIf && !i.showIf(state)) {
          fieldDisabledFields = [...fieldDisabledFields, i.name]
        }
      } else if (field.flattenOptions) {
        if (!state[i.name] && i.enable) {
          fieldDisabledFields = [...fieldDisabledFields, i.enable]
        }
        if (i.showIf && !i.showIf(state)) {
          fieldDisabledFields = [...fieldDisabledFields, i.name]
        }
      }
    })
    return acc.concat(...fieldDisabledFields)
  }
  if (field.type === 'select' && field.options && field.options[0] instanceof Object) {
    const currentValue = state[field.name]
    const selectedOption = field.options.find(item => item.value === currentValue)
    return acc.concat(getDisabledFields(field.options, selectedOption))
  }
  return acc
}, [])
