import React from 'react'
import { connect } from 'react-redux'
import _ from 'lodash'
import ModalWindow from 'containers/ModalWindow'
import Form from 'containers/Form'
import { save, closeModal } from 'redux/modules/blueHeader/projectInfo'
import getTranslate from 'utils/getTranslate'
import { DEFAULT_PRICING_PLANS } from 'utils/pricingPlans'
import { isProMode } from 'utils/proMode'
import ru from './ru.json'
import currencies from 'dataSources/Fixer/currencies'
import { PROJECT_TYPES } from '../../../../../redux/modules/project'
import { validForGoNodeJSAndBrowsersTimeZones } from 'timezones'

const ProjectInfo = ({ locale, projectInfo, isOpen, onClose, onSave, isProMode, projectType, isUserSuperAdmin }) => {
  const translate = getTranslate(locale, { ru })
  let form = [
    {
      label: translate('Project name'),
      type: 'string',
      name: 'projectName',
      validation: ['required']
    },
    {
      label: translate('Project ID'),
      type: 'string',
      name: 'projectId',
      disabled: true,
      availableForPricingPlans: DEFAULT_PRICING_PLANS
    },
    {
      label: 'Timezone',
      type: 'select',
      options: validForGoNodeJSAndBrowsersTimeZones,
      name: 'timezone',
      disabled: !isUserSuperAdmin
    }
  ]

  if (projectType === PROJECT_TYPES.BigQuery) {
    form.push({
      label: 'Project currency',
      type: 'select',
      name: 'currency',
      options: currencies,
      disabled: !isUserSuperAdmin
    })
  }

  if (new Date('2020-08-01') > new Date(projectInfo.createdAt)) {
    form = [
      ...form,
      {
        label: translate('HTTP API token'),
        type: 'string',
        name: 'apiToken',
        disabled: true,
        availableForPricingPlans: DEFAULT_PRICING_PLANS
      },
      {
        label: translate('Website snippet code'),
        type: 'html',
        name: 'snippet',
        disabled: true,
        availableForPricingPlans: DEFAULT_PRICING_PLANS
      }
    ]
  }

  if (isProMode) {
    form.push({
      label: translate('Pricing plan'),
      type: 'string',
      name: 'pricingPlan',
      disabled: true
    })
  }

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Project Info')}>
      <Form locale={locale} form={form} data={projectInfo} onSave={onSave} />
    </ModalWindow>
  )
}

export default connect(
  state => {
    const { isOpen } = state.blueHeader.projectInfo
    const { id, name, apiToken, snippet, billingAccount, createdAt, currency, type, timezone } = state.project || {}

    const projectInfo = {
      projectId: id,
      projectName: name,
      apiToken,
      snippet,
      pricingPlan: _.get(billingAccount, 'pricingPlan'),
      createdAt,
      currency,
      timezone
    }

    return {
      isOpen,
      projectInfo,
      locale: state.locale,
      isProMode: isProMode(state.proMode.status, state.user.role),
      projectType: type,
      isUserSuperAdmin: state.user.role === 'super_admin'
    }
  },
  {
    onClose: closeModal,
    onSave: save
  }
)(ProjectInfo)
