import { gql } from 'apollo-boost'
import { useMutation } from '@apollo/react-hooks'

export const UPDATE_DATA_STREAM = gql`
  mutation UpdateDataStream($dataStream:DataStreamInput!) {
    updateDataStream(dataStream:$dataStream) {
      id
      projectId
      isEnabled
      displayName
      destinationTableName
      config {
        uaIntegrationType
      }
      anonymize
    }
  }
`

export const useUpdateDataStreamMutation = () => useMutation(UPDATE_DATA_STREAM)
