import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import Form from 'containers/Form'
import { save, closeModal, PROJECT_OPTIONS } from 'redux/modules/blueHeader/modalWindow'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'
import ru from './ru.json'

const ProjectOptions = ({
  locale,
  projectOptions,
  isOpen,
  onClose,
  onSave,
  isProMode
}) => {
  const handleSave = (data, touchedFields) => {
    onSave(data, touchedFields)
  }
  const translate = getTranslate(locale, { ru })
  const form = [
    {
      label: translate('Automatically send "Viewed Page" event'),
      type: 'boolean',
      name: 'sendViewedPageEvent',
      validation: ['required'],
      defaultValue: true
    },
    {
      label: translate('Use cookie storage instead of localStorage'),
      type: 'boolean',
      name: 'useCookieStorage',
      defaultValue: false
    },
    {
      label: translate('Cookie domain name'),
      type: 'string',
      name: 'domain'
    },
    {
      label: translate('Session length in seconds'),
      type: 'number',
      name: 'sessionLength',
      defaultValue: 3600
    },
    {
      label: translate('Website maximal width'),
      type: 'number',
      name: 'websiteMaxWidth',
      defaultValue: undefined
    },
    {
      label: translate('Page load timeout in milliseconds'),
      type: 'number',
      name: 'pageLoadTimeout',
      defaultValue: 3000
    },
    {
      label: translate('Track validation errors'),
      type: 'boolean',
      name: 'trackValidationErrors',
      defaultValue: false
    },
    {
      label: translate('Track all JavaScript errors'),
      type: 'boolean',
      name: 'trackJsErrors',
      defaultValue: false
    },
    {
      label: translate('Cookie consent'),
      type: 'select',
      name: 'cookieConsent',
      options: [
        {
          label: 'None',
          value: 'none'
        },
        {
          label: 'Info',
          value: 'info'
        },
        {
          label: 'Opt-in',
          value: 'optin'
        },
        {
          label: 'Opt-out',
          value: 'optout'
        }
      ],
      defaultValue: 'none'
    },
    {
      label: translate('Clear context.campaign on "Completed Transaction"'),
      type: 'boolean',
      name: 'unpersistContextCampaignOnTransaction',
      defaultValue: true
    }
  ]

  if (isProMode) {
    form.push({
      label: translate('Combine SDK and initialization script in one file'),
      type: 'boolean',
      name: 'combineSdkAndInit',
      defaultValue: false
    })
  }

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Tracking Settings')}>
      <Form locale={locale} form={form} data={projectOptions} onSave={handleSave} />
    </ModalWindow>
  )
}

const mapStateToProps = state => {
  const { component } = state.blueHeader.modalWindow
  return {
    projectOptions: state.project.options || {},
    locale: state.locale,
    isOpen: component === PROJECT_OPTIONS,
    isProMode: isProMode(state.proMode.status, state.user.role)
  }
}

const mapDispatchToProps = () => ({
  onClose: closeModal,
  onSave: save
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(ProjectOptions)
