import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'
import {
  INTEGRATIONS,
  PRIORITY,
  EVENTS,
  ENRICHMENTS,
  SCRIPTS,
  DATA_SOURCES,
  REPORTS,
  DATA_TRANSFORMATIONS,
  DATA_DESTINATIONS,
  DATA_STREAMS,
  CHARTS,
  CHARTS_SETTINGS,
  CONVERSIONS
} from 'redux/modules/page/changePage'
import dataSchemaIcon from './data-schema.svg'
import dataImportIcon from './data-import.svg'
import reportsIcon from './reports.svg'
import settingsIcon from './reports-settings.svg'

export default (advancedLevelOfSecurity, proMode, salesMode, isUserSuperAdmin, reports = [], changePage, project) => {
  const integrationsClick = () => changePage(INTEGRATIONS)
  const priorityClick = () => changePage(PRIORITY)
  const eventsClick = () => changePage(EVENTS)
  const enrichmentsClick = () => changePage(ENRICHMENTS)
  const scriptsClick = () => changePage(SCRIPTS)
  const dataStreamsClick = () => changePage(DATA_STREAMS)
  const dataSourcesClick = () => changePage(DATA_SOURCES)
  const dataDestinationsClick = () => changePage(DATA_DESTINATIONS)
  const dataTransformationsClick = () => changePage(DATA_TRANSFORMATIONS)
  const baseDashboards = () => changePage(CHARTS)
  const reportsSettings = () => changePage(CHARTS_SETTINGS)

  const isDev = window.location.hostname.includes('.dev')
  const isV1 = window.location.hostname.includes('-v1')
  const domain = isV1 ? 'https://app.segmentstream.com' : 'https://app-v0.segmentstream.com'

  const dataTransformationsBetaClick = () => {
    let url = ['/data-transformations', 'projects', `${project.id}?fromPanel=old`].join('/')

    if (!isDev) {
      url = domain + url
    }

    window.location.replace(url)
  }

  const conversionsClick = () => {
    let url = [
      '/data-transformations',
      'projects',
      project.id,
      'conversions?fromPanel=old'
    ].join('/')

    if (!isDev) {
      url = domain + url
    }

    window.location.replace(url)
  }

  const dataMonitoringClick = () => {
    let url = [
      '/data-transformations',
      'projects',
      project.id,
      'monitoring?fromPanel=old'
    ].join('/')

    if (!isDev) {
      url = domain + url
    }

    window.location.replace(url)
  }

  const userAccessibleReports = reports.filter(report => report.superuser === true ? isUserSuperAdmin : true)

  const userBaseReports = []
  if (project?.options?.baseDashboards) {
    if (project.options.baseDashboards.includes('base')) {
      userBaseReports.push({
        label: 'Base dashboards',
        onClick: baseDashboards,
        page: CHARTS
      })
    }
  }

  const isTrackingFeatureDisabled = !(project.features || []).includes(ProjectFeature.Tracking)

  return {
    items: [
      {
        label: 'Reports settings',
        hidden: !isUserSuperAdmin,
        thumbnail: settingsIcon,
        items:
          [
            ...(isUserSuperAdmin ? [{
              label: 'Settings',
              onClick: reportsSettings,
              page: CHARTS_SETTINGS
            }] : [])
          ]
      },
      {
        label: 'Reports',
        hidden: !isUserSuperAdmin || (!userAccessibleReports.length && !userBaseReports.length),
        thumbnail: reportsIcon,
        items:
          [
            ...userBaseReports,
            ...userAccessibleReports.map(({ name }) => {
              return {
                label: name,
                onClick: () => changePage(REPORTS(name)),
                page: REPORTS(name)
              }
            })
          ]
      },
      {
        label: 'Google BigQuery',
        hidden: !isUserSuperAdmin,
        thumbnail: dataImportIcon,
        items: [
          {
            label: 'Data Sources',
            onClick: dataSourcesClick,
            page: DATA_SOURCES
          },
          {
            label: 'Data Streams',
            onClick: dataStreamsClick,
            page: DATA_STREAMS
          },
          {
            label: 'Data Destinations',
            onClick: dataDestinationsClick,
            page: DATA_DESTINATIONS
          },
          {
            label: 'Data Transformations',
            onClick: dataTransformationsBetaClick,
            page: DATA_TRANSFORMATIONS,
            beta: true
          },
          {
            label: 'Data Monitoring',
            onClick: dataMonitoringClick,
            page: DATA_SOURCES,
            beta: true,
            preventActiveState: true
          },
          ...proMode ? [
            {
              label: 'Conversions',
              onClick: conversionsClick,
              page: CONVERSIONS,
              beta: true
            },
            {
              label: 'Data Apps (Legacy)',
              onClick: dataTransformationsClick
            }
          ] : []
        ]
      },
      {
        label: 'Menu',
        thumbnail: dataSchemaIcon,
        items: [
          {
            label: 'Integrations',
            onClick: integrationsClick,
            page: INTEGRATIONS
          },
          {
            label: 'Priorities',
            hidden: isUserSuperAdmin ? false : isTrackingFeatureDisabled,
            onClick: priorityClick,
            page: PRIORITY
          },
          {
            label: 'Events',
            hidden: isUserSuperAdmin ? false : isTrackingFeatureDisabled || advancedLevelOfSecurity,
            onClick: eventsClick,
            page: EVENTS
          },
          {
            label: 'Variables',
            hidden: isUserSuperAdmin ? false : isTrackingFeatureDisabled || advancedLevelOfSecurity,
            onClick: enrichmentsClick,
            page: ENRICHMENTS
          },
          {
            label: 'Scripts',
            hidden: isUserSuperAdmin ? false : isTrackingFeatureDisabled || advancedLevelOfSecurity,
            onClick: scriptsClick,
            page: SCRIPTS
          }
        ]
      }
    ]
  }
}
