import path from './path'
import { openedEvent } from 'utils/digitalDataEvents'

const OPEN = `openModal@${path}`

export const openModal = component => {
  openedEvent(undefined, path, 'Project')
  return {
    type: OPEN,
    component
  }
}

export default (state = {}, action) =>
  action.type === OPEN
    ? {
      ...state,
      isOpen: true
    }
    : state
