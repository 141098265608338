import path from './path'

const TYPE = `pending@${path}`
export const PENDING = 'pending'

export const pending = () => ({
  type: TYPE
})

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      ...state,
      status: PENDING
    }
    : state
