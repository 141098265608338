import reduceReducers from 'reduce-reducers'
import integrations from './integrations'
import enrichments from './enrichments'
import events from './events'
import scripts from './scripts'
import dataSources from './dataSources'
import dataDestinations from './dataDestinations'
import dataTransformations from './dataTransformations'
import changePageReducer from './changePage'
import publishReducer from './publish'

export default reduceReducers(
  (state = {}, action) => ({
    ...state,
    integrations: integrations(state.integrations, action),
    enrichments: enrichments(state.enrichments, action),
    events: events(state.events, action),
    scripts: scripts(state.scripts, action),
    dataSources: dataSources(state.dataSources, action),
    dataDestinations: dataDestinations(state.dataDestinations, action),
    dataTransformations: dataTransformations(state.dataTransformations, action)
  }),
  changePageReducer,
  publishReducer,
  (state = {}) => state
)
