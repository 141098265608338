import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'retailCRM',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/retailcrm',
  authType: 'Basic',
  authIcon,
  authName: 'API key',
  isPublished: true,
  authForm: [
    {
      label: 'API key',
      type: 'string',
      name: 'apiKey',
      validation: ['required']
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My retailCRM',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'retailCRM endpoint',
      type: 'string',
      name: 'endpoint',
      validation: ['required', /^[a-zA-Z0-9\-.]+$/]
    },
    {
      label: 'Orders import',
      type: 'boolean',
      name: 'importOrders',
      defaultValue: true,
      enable: ['orderCustomFields']
    },
    {
      label: 'Order custom fields (code)',
      type: 'string array',
      name: 'orderCustomFields'

    }
  ]
}

export default config
