import { ADVERTISING } from 'integrations/categories'
import { DIGITAL_DATA, CONSTANT, EVENT } from 'containers/Form/Variable/options'
import logo from './logo.png'
import sideLogo from './logo-side.png'
import ru from './ru.json'

const config = {
  name: 'Vkontakte',
  color: '#507299',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/vkontakte',
  form: [
    {
      label: 'Vkontakte pixel settings',
      type: 'group array',
      name: 'pixels',
      addButtonLabel: 'Add Vkontakte pixel',
      items: [
        {
          label: 'Vkontakte pixel ID',
          type: 'string',
          name: 'pixelId'
        },
        {
          label: 'Product feed has grouped products',
          type: 'boolean',
          name: 'feedWithGroupedProducts',
          defaultValue: false
        },
        {
          label: 'Pricelist ID for dynamic retargeting',
          type: 'variable',
          name: 'priceListId',
          defaultValue: {
            type: CONSTANT,
            value: '1'
          },
          typeOptions: [DIGITAL_DATA, CONSTANT, EVENT],
          validation: ['required']
        }
      ]
    },
    {
      label: 'Custom events',
      type: 'string mapping',
      name: 'customEvents',
      placeholderKey: 'DigitalData event',
      placeholderValue: 'Vkontakte event'
    },
    {
      label: 'Custom events (old version)',
      type: 'string mapping',
      name: 'eventPixels',
      placeholderKey: 'DigitalData event',
      placeholderValue: 'Vkontakte pixel URL'
    }
  ]
}

export default config
