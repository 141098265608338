import path from './path'

const SET_STATUS = `setStatus@${path}`

export const setStatus = status => ({
  type: SET_STATUS,
  status
})

export default (state = {}, action) =>
  action.type === SET_STATUS
    ? {
      ...state,
      status: action.status
    }
    : state
