import React from 'react'
import { connect } from 'react-redux'
import {
  openModal,
  CUSTOM_SDK_URL,
  CUSTOM_INTEGRATIONS,
  DATA_TRANSFORMATIONS,
  PROJECT_OPTIONS,
  SCRIPTS
} from 'redux/modules/proMode/modalWindow'
import {
  authorize as authorizeEcomScore,
  GOOGLE as ECOMSCORE_GOOGLE_PROVIDER,
  YANDEX as ECOMSCORE_YANDEX_PROVIDER
} from 'redux/modules/proMode/ecomScore/authorize'
import { authorize as authorizeStreaming } from 'redux/modules/proMode/streaming/authorize'
import { Menu, MenuWrapper, Tab, Right } from './styles'

const Header = ({ onOpenModal, onAuthorizeEcomScore }) => {
  const customSdkUrlClick = () => onOpenModal(CUSTOM_SDK_URL)
  const customIntegrationsClick = () => onOpenModal(CUSTOM_INTEGRATIONS)
  const dataTransformationsClick = () => onOpenModal(DATA_TRANSFORMATIONS)
  const projectOptionsClick = () => onOpenModal(PROJECT_OPTIONS)
  const scriptsClick = () => onOpenModal(SCRIPTS)
  const ecomScoreGoogleAuthClick = () => onAuthorizeEcomScore(ECOMSCORE_GOOGLE_PROVIDER)
  const ecomScoreYandexAuthClick = () => onAuthorizeEcomScore(ECOMSCORE_YANDEX_PROVIDER)
  return (
    <Menu>
      <MenuWrapper>
        <Right>
          <Tab onClick={ecomScoreGoogleAuthClick}>EcomScore Google Auth</Tab>
          <Tab onClick={ecomScoreYandexAuthClick}>EcomScore Yandex Auth</Tab>
          <Tab onClick={customSdkUrlClick}>Custom SDK URL</Tab>
          <Tab onClick={customIntegrationsClick}>Custom integrations</Tab>
          <Tab onClick={dataTransformationsClick}>Data transformations</Tab>
          <Tab onClick={projectOptionsClick}>Project options</Tab>
          <Tab onClick={scriptsClick}>Scripts</Tab>
        </Right>
      </MenuWrapper>
    </Menu>
  )
}

const mapStateToProps = state => ({
  projectId: state.project.id,
  accessToken: state.auth.accessToken
})

const mapDispatchToProps = () => ({
  onOpenModal: openModal,
  onAuthorizeEcomScore: authorizeEcomScore,
  onAuthorizeStreaming: authorizeStreaming
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(Header)
