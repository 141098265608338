import path from './path'
import { openedEvent } from 'utils/digitalDataEvents'

const TYPE = `openForm@${path}`

export const openForm = (id, name) => {
  openedEvent(name, path)
  return {
    type: TYPE,
    id
  }
}

export default (state = {}, action) =>
  action.type === TYPE
    ? {
      ...state,
      isOpen: true,
      id: action.id
    }
    : state
