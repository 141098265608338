import styled from 'styled-components'

export const THEME_FORM_FIELD = 'form_field'
export const THEME_BILLING_FIELD = 'form_billing_field'
export const THEME_PROJECT_CREATION = 'form_project_creation'

export const Wrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  outline: none;
  &:hover {
    cursor: ${props => (props.enableHover ? 'pointer' : 'auto')};
  }
  opacity: ${props => props.disabled ? '0.5' : '1'};
`

export const SelectBoxWrapper = styled.div`
  cursor: pointer;
  border: solid 1px #3bc569;
  border-radius: 0 0 8px 8px;
  border-top: 0;
  background-color: #fff;
  padding: 8px 0;
  z-index: 1;
  color: #1d2c4c;
  overflow: none;
  text-align: left;
`

export const SelectBox = styled.div`
  max-height: 284px;
  background-color: #fff;
  overflow-y: scroll;
`

export const Dropdown = styled.div`
  position: absolute;
  cursor: pointer;
  width: 100%;
  top: ${props => (props.theme === THEME_FORM_FIELD ? '50' : '54')}px;
  z-index: 1;
`

export const BoxLine = styled.div`
  color: #707479;
  padding-left: 15px;
  padding-right: 15px;
  font-size: 18px;
  line-height: 30px;
  height: 30px;
  white-space: nowrap;
  background-color: ${props => (props.selected ? '#fafafb' : 'none')};
  &:hover {
    background-color: #e8e8e8;
  }
`

export const Select = styled.div`
  position: absolute;
  margin-top: -1px;
  border: solid 1px #e3e4e6;
  border-top: none;
  min-width: 100%;
  overflow-y: scroll;
  max-height: 360px;
  background-color: #fff;
  z-index: 10;
  border-radius: 0 0 8px 8px;
`

export const Label = styled.div`
  display: flex;
  margin-bottom: 16px;
`

export const Triangle = styled.div`
  margin-left: auto;
  position: absolute;
  right: 24px;  
  width: 4px;
  height: 4px;
  border: solid #cad3dc;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 4px;
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
`
