import React from 'react'
import InlineRow from 'containers/Form/InlineRow'
import { Name } from './styles'

export default ({ name, isEnabled, onClick }) => {
  const withStatus = (
    <InlineRow>
      <Name onClick={onClick}>{name}</Name>
    </InlineRow>
  )
  const withoutStatus = <Name onClick={onClick}>{name}</Name>
  return isEnabled !== undefined ? withStatus : withoutStatus
}
