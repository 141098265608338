import logo from './logo.png'

const config = {
  type: 'Taboola',
  enabledForGoogleAnalyticsProjects: true,
  byRequest: true,
  logo
}

export default config
