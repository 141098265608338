import React from 'react'
import styled from 'styled-components'

import {
  Container,
  HeaderText,
  Logo,
  MessageContainer,
  Button,
  ButtonContainer,
  RowContainer,
  Text, SecondaryText
} from './styles'

import failIcon from './fail-icon.svg'

const Message = styled(MessageContainer)`
  justify-content: space-around;
  align-items: center;
`

const FailIcon = styled.div`
  background: url(${failIcon}) no-repeat;
  width: 42px;
  height: 42px;
  background-size: contain;
`

export function EmailConfirmationFailPage ({ onResend, resendInProgress, resendSuccess, resendError }) {
  return (
    <Container>
      <RowContainer>
        <Logo />
        <Message>
          <FailIcon />
          <HeaderText>Confirmation link is no longer valid</HeaderText>

          <Text>Looks like your confirmation link is expired or invalid.</Text>
          <Text>Resend confirmation email and try one more time with the new link.</Text>

          <ButtonContainer>
            <Button
              onClick={onResend}
              pending={resendInProgress}
              disabled={resendSuccess || resendInProgress}
            >
              Resend Email
            </Button>
            { resendSuccess
              ? <SecondaryText>A new confirmation email was sent.</SecondaryText>
              : resendError
                ? <SecondaryText>Failed to resend email. Please try again later</SecondaryText>
                : <SecondaryText />
            }
          </ButtonContainer>
        </Message>
      </RowContainer>
    </Container>
  )
}
