import styled from 'styled-components'
import search from './search.png'

export const IntegrationSearchBox = styled.input`
  width: 216px;
  height: 40px;
  padding-left: 16px;
  border: 1px solid #e1e7ed;
  border-radius: 4px;
  line-height: 20px;
  font-size: 14px;
  color: #1d2c4c;
  background-color: #fff;
  outline: none;
  cursor: text;
  background: url(${search}) no-repeat;
  background-position: 180px;
  background-size: 18px;
  margin-left: 18px;
  &:focus {
    border-color: #3bc569;
  }
  ::placeholder {
    color: #778fa9;
  }
`
