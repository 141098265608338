import { saveOverrideFunctions as apiSaveOverrideFunctions } from 'api/integration'
import { saveOverrideFunctions as saveOverrideFunctionsToIntegration } from 'redux/modules/integrations'
import { close } from './close'

export const saveOverrideFunctions = (integrationName, overrideFunctions) => async (
  dispatch,
  getState
) => {
  const projectId = getState().project.id
  await dispatch(apiSaveOverrideFunctions(projectId, integrationName, overrideFunctions))
  dispatch(saveOverrideFunctionsToIntegration(integrationName, overrideFunctions))
  dispatch(close())
}
