import { PUSH_NOTIFICATIONS } from 'integrations/categories'
import { DIGITAL_DATA, EVENT } from 'containers/Form/Variable/options'
import logo from './logo.png'
import sideLogo from './logo-side.png'
import ru from './ru.json'

const config = {
  name: 'OneSignal',
  color: '#e54b4d',
  category: [PUSH_NOTIFICATIONS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/onesignal',
  form: [
    {
      label: 'OneSignal App ID',
      type: 'string',
      name: 'appId',
      validation: ['required']
    },
    {
      label: 'Auto registration',
      type: 'boolean',
      name: 'autoRegister',
      description:
        'When user enters website, automatically suggest subscription to push notifications',
      disable: ['pushSubscriptionTriggerEvent']
    },
    {
      label: 'Push subscription trigger event',
      type: 'string',
      name: 'pushSubscriptionTriggerEvent',
      defaultValue: 'Agreed to Receive Push Notifications'
    },
    {
      label: 'Use Slide Prompt',
      type: 'boolean',
      name: 'isSlidePrompt',
      description: 'Use Slide Prompt',
      enable: 'promptOptions'
    },
    {
      label: 'Slide Prompt options',
      type: 'group',
      name: 'promptOptions',
      items: [
        {
          label: 'Slide Prompt action message',
          type: 'string',
          name: 'actionMessage',
          maxLength: 90,
          placeholder: "We'd like to show you notifications for the latest news and updates."
        },
        {
          label: 'Slide Prompt accept button text',
          type: 'string',
          name: 'acceptButtonText',
          maxLength: 15,
          placeholder: 'Allow'
        },
        {
          label: 'Slide Prompt cancel button text',
          type: 'string',
          name: 'cancelButtonText',
          maxLength: 15,
          placeholder: 'No thanks'
        }
      ]
    },
    {
      label: 'Tags settings',
      type: 'variable mapping',
      name: 'tagVars',
      typeOptions: [DIGITAL_DATA, EVENT]
    }
  ]
}

export default config
