import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import { open } from 'redux/modules/page/dataSources/createDataSourceSide/open'
import { allDataSources, sandboxDataSources } from 'dataSources'
import getTranslate from 'utils/getTranslate'
import DataSourcesForm from './DataSourcesForm'
import CreateDataSourceSide from './CreateDataSourceSide'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import DataSourceItem from './DataSourceItem'
import DataSourceOnboarding from './DataSourceOnboarding'
import { ItemContainer, Container, OnboardingContainer } from './styles'
import OnboardingPlaceholder from '../OnboardingPlaceholder'
import Tip from '../Tip'
import Notification from '../Notification'
import { open as integrationOpen } from 'redux/modules/page/integrations/integrationForm'
import IntegrationSide from '../Integrations/IntegrationSide'
import bq from './bq.png'
import ga from './ga.png'
import warning from './warning.svg'
import ru from './ru'
import { dismissTip } from 'redux/modules/project/dismissTip'
import { openModal as openProjectBilling } from 'redux/modules/blueHeader/projectBilling'
import { create } from 'redux/modules/dataDestinations'
import { open as dataDestinationOpen } from 'redux/modules/page/dataDestinations/dataDestinationsForm/open'
import { PROJECT_TYPES, DDMANAGER_STREAMING_INTEGRATION, GA_DATA_DESTINATION } from 'redux/modules/project'
import DataDestinationsForm from '../DataDestinations/DataDestinationsForm'
import { DATA_SOURCES, INTEGRATIONS, DATA_DESTINATIONS } from 'redux/modules/page/changePage'
import { createDatasetLink } from 'utils/bigQueryHelpers'
import LinkButton from 'uiKit/LinkButton'
import EnabledStatusButton from 'uiKit/EnabledStatusButton'
import { isProMode } from '../../../../../utils/proMode'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

const DataSourcesList = ({
  dataSources,
  dataDestinations,
  integrations,
  openCreateDataSourceSide,
  locale,
  onIntegrationOpen,
  isSaleMode,
  trialExpiresAt,
  subscriptionExpiresAt,
  enabledTips,
  onDismissTip,
  onOpenProjectBilling,
  projectType,
  projectId,
  projectName,
  onCreateDataDestination,
  onOpenDataDestination,
  isProMode,
  countryCode,
  accountName,
  userFirstName,
  userLastName,
  userEmail,
  isProjectOwner,
  features
}) => {
  const bqProjectType = projectType === PROJECT_TYPES.BigQuery
  const gaProjectType = projectType === PROJECT_TYPES.GoogleAnalytics

  const googleAnalyticsIndex = Object.keys(dataDestinations || {}).find(dataDestinationId => {
    return dataDestinations[dataDestinationId].type === GA_DATA_DESTINATION
  })
  const mainIntegration = bqProjectType
    ? integrations[DDMANAGER_STREAMING_INTEGRATION]
    : dataDestinations[googleAnalyticsIndex]

  const availableDataSources = (features || []).includes(ProjectFeature.BetaFeatures) ? allDataSources : allDataSources.filter(ds => !ds.betaFeature)

  const shownDataSources = isSaleMode ? [...availableDataSources, ...sandboxDataSources] : availableDataSources
  const translate = getTranslate(locale, { ru })
  const confirmButton = (
    <ConfirmButton onClick={openCreateDataSourceSide}>Add Data Source</ConfirmButton>
  )
  const dataSourceTip = enabledTips.find(tip => tip.type === DATA_SOURCES)
  const dataSourceTipMessage = `You have enabled data collection from <b>${dataSourceTip && dataSourceTip.name}</b>. Data will appear in ${bqProjectType ? 'Google BigQuery' : 'Google Analytics'} the next day. You can change the settings in the data source card.`

  const isV1 = window.location.hostname.includes('-v1')
  const domain = isV1 ? 'https://app.segmentstream.com' : 'https://app-v0.segmentstream.com'

  const streamingConfig = (
    <div>
      <div style={{ display: 'flex' }}>
        { gaProjectType
          ? <LinkButton
            text='Data Monitoring'
            target='_self'
            link={`${domain}/data-transformations/projects/${projectId}/monitoring?fromPanel=old`}
          /> : null
        }
        {
          bqProjectType && mainIntegration && <LinkButton text='View BigQuery dataset' link={createDatasetLink(mainIntegration)} />
        }
        <EnabledStatusButton
          text={bqProjectType ? 'BigQuery enabled' : 'Google Analytics enabled'}
          handleClick={() => {
            bqProjectType
              ? onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION)
              : onOpenDataDestination(googleAnalyticsIndex, GA_DATA_DESTINATION)
          }}
        />
      </div>
      {
        bqProjectType && enabledTips.find(tip => tip.type === INTEGRATIONS && tip.name === DDMANAGER_STREAMING_INTEGRATION) &&
        <Tip
          text={'Google BigQuery is now connected. You can update the settings in this menu.'}
          left='235px'
          dismissTip={() => { onDismissTip(DDMANAGER_STREAMING_INTEGRATION, INTEGRATIONS) }}
        />
      }
      {
        !bqProjectType && enabledTips.find(tip => tip.type === DATA_DESTINATIONS && tip.name === GA_DATA_DESTINATION) &&
        <Tip
          text={'Google Analytics is now connected. You can update the settings in this menu.'}
          left='205px'
          dismissTip={() => { onDismissTip(GA_DATA_DESTINATION, DATA_DESTINATIONS) }}
        />
      }
    </div>
  )

  const isSubscriptionExpired = subscriptionExpiresAt ? new Date(subscriptionExpiresAt) < new Date() : null
  const isTrialExpired = trialExpiresAt ? trialExpiresAt && new Date(trialExpiresAt) < new Date() : null

  if (isSubscriptionExpired) {
    return (
      <OnboardingContainer>
        <OnboardingPlaceholder
          title={translate('Your subscription is currently inactive, <br> data collection has been suspended.')}
          body={`Likely reasons: you cancelled your subscription yourself or the subscription was canceled automatically (insufficient funds on the card, the card has expired, problems with the payment system). <br><br> For more information, contact support. To resume data collection, ${isProjectOwner ? 'go to the Billing section and subscribe to the service' : 'ask the account admin to subscribe to the service'}.`}
          icon={warning}
          showArrow={isProjectOwner}
          buttonTitle={isProjectOwner && translate('Go to Billing')}
          buttonAction={() => { onOpenProjectBilling() }}
        />
      </OnboardingContainer>
    )
  } else if (isSubscriptionExpired === null && isTrialExpired) {
    return (
      <OnboardingContainer>
        <OnboardingPlaceholder
          title={translate('The trial period is over, data collection is suspended.')}
          body={`To resume data collection, ${isProjectOwner ? 'go to the Billing section and subscribe to the service' : 'ask the account admin to subscribe to the service'}.`}
          icon={warning}
          showArrow={isProjectOwner}
          buttonTitle={isProjectOwner && translate('Go to Billing')}
          buttonAction={() => { onOpenProjectBilling() }}
        />
      </OnboardingContainer>
    )
  } else {
    const onboardingMessage = bqProjectType
      ? 'To import data to Google BigQuery, please connect your BigQuery account first'
      : 'To import data to Google Analytics, please connect your Google Analytics account first'
    const onboardingTitle = bqProjectType
      ? 'Enable BigQuery integration'
      : 'Connect your Google Analytics'
    const googleAnalyticsAction = () => googleAnalyticsIndex
      ? onOpenDataDestination(googleAnalyticsIndex, GA_DATA_DESTINATION)
      : onCreateDataDestination({ type: GA_DATA_DESTINATION })
    const onboardingAction = () => {
      bqProjectType
        ? onIntegrationOpen(DDMANAGER_STREAMING_INTEGRATION)
        : googleAnalyticsAction()
    }

    return (
      <Container narrow={!Object.keys(dataSources).length}>
        <IntegrationSide displayMode={'simple'} />
        <DataDestinationsForm />
        {(!mainIntegration || !mainIntegration.isEnabled)
          ? (
            <OnboardingContainer>
              <div>
                <OnboardingPlaceholder
                  title={onboardingMessage}
                  icon={projectType === PROJECT_TYPES.BigQuery ? bq : ga}
                  showArrow
                  buttonTitle={onboardingTitle}
                  buttonAction={onboardingAction}
                  linkTitle='Getting Started Tutorial ▸'
                  linkUrl={projectType === PROJECT_TYPES.BigQuery ? 'https://docs.segmentstream.com/bigquery/overview' : 'https://docs.segmentstream.com/google-analytics/overview'}
                />
              </div>
            </OnboardingContainer>
          ) : (
            <div>
              {Object.keys(dataSources).length ? (
                <SectionHeader leftContent={confirmButton} rightContent={streamingConfig} />
              ) : (
                <div>
                  <SectionHeader rightContent={streamingConfig} />
                  <OnboardingContainer streamingEnabled>
                    <DataSourceOnboarding onAddButtonClick={openCreateDataSourceSide} projectType={projectType} />
                  </OnboardingContainer>
                </div>
              )}
              <ItemContainer>
                {Object.keys(dataSources).map(dataSourceId => {
                  const dataSource = dataSources[dataSourceId]
                  const dataSourceType = dataSource.type
                  const dataSourceConfig = shownDataSources.find(
                    ds => ds.type === dataSourceType
                  )
                  const dataSourceTitle = (dataSource.options || {}).title
                  if (dataSourceConfig) {
                    return (
                      <DataSourceItem
                        title={dataSourceTitle}
                        id={dataSource.id}
                        key={dataSource.id}
                        dataSourceConfig={dataSourceConfig}
                        isEnabled={dataSource.isEnabled}
                      />
                    )
                  } else {
                    return undefined
                  }
                })}
                <DataSourcesForm />
                <CreateDataSourceSide />
              </ItemContainer>
              {
                dataSourceTip &&
                <Notification
                  tip={dataSourceTip.name}
                  text={dataSourceTipMessage}
                  theme='side'
                  dismissTip={() => {
                    onDismissTip(dataSourceTip.name, dataSourceTip.type)
                  }}
                />
              }
            </div>
          )}
      </Container>
    )
  }
}

export default connect(
  state => {
    const { billingAccount, type, id: projectId, name: projectName, features } = state.project || {}
    const { trialExpiresAt, subscriptionExpiresAt, countryCode, accountName } = billingAccount || {}
    const { name: userFirstName, lastName: userLastName, email: userEmail } = state.user || {}
    return {
      dataSources: state.dataSources,
      dataDestinations: state.dataDestinations,
      integrations: state.integrations,
      locale: state.locale,
      isSaleMode: state.saleMode.status,
      isProMode: isProMode(state.proMode.status, state.user.role),
      enabledTips: state.project.enabledTips,
      trialExpiresAt,
      subscriptionExpiresAt,
      projectType: type,
      projectId,
      projectName,
      countryCode,
      accountName,
      userFirstName,
      userLastName,
      userEmail,
      isProjectOwner: (state.project.billingAccount || {}).userId === state.user.userId || state.user.role === 'super_admin',
      features
    }
  },
  {
    openCreateDataSourceSide: open,
    onIntegrationOpen: integrationOpen,
    onDismissTip: dismissTip,
    onOpenProjectBilling: openProjectBilling,
    onCreateDataDestination: create,
    onOpenDataDestination: dataDestinationOpen
  }
)(DataSourcesList)
