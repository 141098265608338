import styled from 'styled-components'
import close from './close.svg'

export default styled.div`
  position: fixed;
  top: 46px;
  right: 752px;
  width: 50px;
  height: 50px;
  background: rgba(255, 255, 255, 0.55) url(${close}) no-repeat center;
  background-size: 29px 29px;
  cursor: pointer;
  border-radius: 10px;
  z-index: 1;
  display: ${props => (props.isOpen ? 'block' : 'none')};
  transition: all 0.15s linear;
`
