import React from 'react'
import { connect } from 'react-redux'
import styled from 'styled-components'
import Login from './Login'
import SignUp from './SignUp'
import ForgotPassword from './ForgotPassword'
import ResetPassword from './ResetPassword'
import Invitation from './Invitation'
import Dashboard from './Dashboard'
import { fetchCountryCode } from 'api/utils'
import CreateBillingAccount from './CreateBillingAccount/index'
import ProjectCreation from './ProjectCreation'
import {
  FORGOT_PASSWORD,
  RESET_PASSWORD,
  INVITATION,
  SIGN_UP,
  CONFIRM_EMAIL_SUCCESS,
  CONFIRM_EMAIL_FAIL,
  PROJECT_CREATION
} from 'redux/modules/page/changePage'
import { EmailConfirmationFailPage, EmailConfirmationPage, EmailConfirmationSuccessPage } from './EmailConfirmation'
import { PENDING } from '../../redux/modules/auth/login'
import { logout } from '../../redux/modules/auth'
import { Spinner } from '../../uiKit'

const Container = styled.div`
  height: 100%;
`

const SpinnerContainer = styled.div`
 width: 100%;
 height: 100vh;
 align-items: center;
 justify-content: center;
 display: flex;
`

const App = ({ isAuthenticated, authStatus, page, user, allProjects, onLogout, billingAccounts = [] }) => {
  const renderContent = () => {
    if (authStatus === PENDING) {
      return (
        <SpinnerContainer><Spinner /></SpinnerContainer>
      )
    }

    if (page === CONFIRM_EMAIL_SUCCESS) {
      return <EmailConfirmationSuccessPage />
    }

    if (page === CONFIRM_EMAIL_FAIL) {
      return <EmailConfirmationFailPage />
    }

    if (!isAuthenticated) {
      if (page === FORGOT_PASSWORD) {
        return <ForgotPassword />
      }
      if (page === RESET_PASSWORD) {
        return <ResetPassword />
      }
      if (page === INVITATION) {
        return <Invitation />
      }
      if (page === SIGN_UP) {
        return <SignUp countryCodeFetcher={fetchCountryCode} />
      }
      return <Login />
    }

    if (page === SIGN_UP) {
      onLogout({ pageName: 'signUp' })
    }

    if (user.emailConfirmed === false) {
      return <EmailConfirmationPage />
    }

    if (!allProjects.length && !user.billingAccount) {
      return <CreateBillingAccount countryCodeFetcher={fetchCountryCode} />
    }

    if ((billingAccounts.length === 1 && !allProjects.length) || page === PROJECT_CREATION) {
      return <ProjectCreation />
    }

    return <Dashboard />
  }

  return <Container>{renderContent()}</Container>
}

export default connect(
  state => ({
    isAuthenticated: state.auth && state.auth.isAuthenticated,
    authStatus: state.auth && state.auth.status,
    auth: state.auth,
    page: state.page.name,
    user: state.user,
    allProjects: state.allProjects,
    login: state.login,
    billingAccounts: (state.user || {}).billingAccounts
  }),
  {
    onLogout: logout
  }
)(App)
