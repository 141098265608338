import { useField } from 'formik'
import SearchableSelect from 'containers/Form/SearchableSelect'
import { THEME_BILLING_FIELD } from 'containers/Form/SelectBox/styles'
import React from 'react'

export function FormikSearchableSelect ({ options, ...props }) {
  const [field, meta, helpers] = useField(props)

  return (
    <SearchableSelect
      value={field.value}
      onChange={value => field.onChange({ target: { name: field.name, value } })}
      onTouch={() => helpers.setTouched(true)}
      onBlur={field.onBlur}
      touched={meta.touched}
      placeholder={props.placeholder}
      name={field.name}
      options={options}
      validation={['required']}
      theme={THEME_BILLING_FIELD}
    />
  )
}
