import path from './path'
import { revokePermission } from 'api/project'

const REMOVE_PROJECT_MEMBER = `removeProjectMember@${path}`

export const removeProjectMember = id => async (dispatch, getState) => {
  const projectId = getState().project.id
  await dispatch(await revokePermission(projectId, id))
  dispatch({
    type: REMOVE_PROJECT_MEMBER,
    permissionId: id
  })
}

export default (state = {}, action) =>
  action.type === REMOVE_PROJECT_MEMBER
    ? {
      ...state,
      members: state.members.filter(({ id }) => id !== action.permissionId)
    }
    : state
