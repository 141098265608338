import {
  sendResetPasswordToken as apiSendResetPasswordToken,
  resetPassword as apiResetPassword
} from 'api/user'

export const sendResetPasswordToken = email => async dispatch => {
  await dispatch(apiSendResetPasswordToken(email))
}

export const resetPassword = password => async (dispatch, getState) => {
  const resetToken = getState().auth.resetToken
  return dispatch(apiResetPassword(password, resetToken))
}
