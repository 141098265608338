import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'AppsFlyer',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datasources/appsflyer',
  authType: 'Basic',
  authIcon,
  authName: 'AppsFlyer API token',
  isPublished: true,
  authForm: [
    {
      label: 'API token',
      type: 'string',
      name: 'apiToken',
      validation: ['required']
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My AppsFlyer',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'App ID',
      type: 'string',
      name: 'appId',
      validation: ['required']
    },
    {
      label: 'Raw reports',
      type: 'group',
      flattenOptions: true,
      name: 'rawReports',
      items: [
        {
          label: 'Installs',
          type: 'boolean',
          name: 'installs'
        },
        {
          label: 'Retargeting conversions',
          type: 'boolean',
          name: 'retargetingConversions'
        },
        {
          label: 'In-app events',
          type: 'boolean',
          name: 'inAppEvents'
        },
        {
          label: 'Retargeting in-app events',
          type: 'boolean',
          name: 'retargetingInAppEvents'
        },
        {
          label: 'Uninstalls',
          type: 'boolean',
          name: 'uninstalls'
        },
        {
          label: 'Organic installs',
          type: 'boolean',
          name: 'organicInstalls'
        },
        {
          label: 'Organic in-app events',
          type: 'boolean',
          name: 'organicInAppEvents'
        }
      ]
    },
    {
      label: 'Ad revenue raw data reports',
      type: 'group',
      flattenOptions: true,
      name: 'adRevenueRawDataReports',
      items: [
        {
          label: 'Attributed ad revenue',
          type: 'boolean',
          name: 'attributedAdRevenue'
        },
        {
          label: 'Retargeting ad revenue',
          type: 'boolean',
          name: 'retargetingAdRevenue'
        },
        {
          label: 'Organic ad revenue',
          type: 'boolean',
          name: 'organicAdRevenue'
        }
      ]
    },
    {
      label: 'Performance reports (user acquisition)',
      type: 'group',
      flattenOptions: true,
      name: 'performanceReportsUserAcquisition',
      items: [
        {
          label: 'Partners report',
          type: 'boolean',
          name: 'partnersReport'
        },
        {
          label: 'Partners daily report',
          type: 'boolean',
          name: 'partnersDailyReport'
        },
        {
          label: 'Daily report',
          type: 'boolean',
          name: 'dailyReport'
        },
        {
          label: 'Geo report',
          type: 'boolean',
          name: 'geoReport'
        },
        {
          label: 'Geo daily report',
          type: 'boolean',
          name: 'geoDailyReport'
        }
      ]
    },
    {
      label: 'Performance reports (retargeting)',
      type: 'group',
      flattenOptions: true,
      name: 'performanceReportsRetargeting',
      items: [
        {
          label: 'Partners report',
          type: 'boolean',
          name: 'partnersReportRetargeting'
        },
        {
          label: 'Partners daily report',
          type: 'boolean',
          name: 'partnersDailyReportRetargeting'
        },
        {
          label: 'Daily report',
          type: 'boolean',
          name: 'dailyReportRetargeting'
        },
        {
          label: 'Geo report',
          type: 'boolean',
          name: 'geoReportRetargeting'
        },
        {
          label: 'Geo daily report',
          type: 'boolean',
          name: 'geoDailyReportRetargeting'
        }
      ]
    }
  ]
}

export default config
