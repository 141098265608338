import path from './path'
import { saveDataTransformations as apiSaveDataTransformations } from 'api/project'
import { success, pending } from 'redux/modules/proMode/modalWindow'
import { savedEvent } from 'utils/digitalDataEvents'

const SAVE_DATA_TRANSFORMATIONS = `saveDataTransformations@${path}`

export const saveDataTransformations = (dataTransformations, dataTransformation) => async (dispatch, getState) => {
  dispatch(pending())
  if (dataTransformation) {
    if (dataTransformations.length) {
      dataTransformations = dataTransformations.filter(dt => dt.jobName !== dataTransformation.jobName)
    }
    dataTransformations.push(dataTransformation)
    savedEvent(dataTransformation.jobName, path)
  }
  const projectId = getState().project.id
  await dispatch(apiSaveDataTransformations(projectId, dataTransformations))
  dispatch({
    type: SAVE_DATA_TRANSFORMATIONS,
    dataTransformations
  })
  dispatch(success())
}

export default (state = {}, action) =>
  action.type === SAVE_DATA_TRANSFORMATIONS
    ? {
      ...state,
      dataTransformations: action.dataTransformations
    }
    : state
