import icon from './Icon.svg'
import ru from './ru.json'

const config = {
  name: 'Funnel-based attribution',
  icon,
  locale: { ru },
  description:
    'Forms a table with sessions in which the value of a future order is attributed proportionaly to the degree of advancement of the visitor in the checkout funnel. The more funnel levels a visitor passes during a session, the more value is attributed to the source of this session.',
  form: [
    {
      label: 'Lookback window',
      type: 'number',
      name: 'lookbackWindow',
      units: 'days'
    }
  ]
}

export default config
