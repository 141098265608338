import React from 'react'

import { BillingCheckoutForm } from './billingCheckoutForm'
import {
  SubscriptionEmphasis,
  SubscriptionStatusMessage
} from './styles'
import { billingAccountCurrency } from '../billingAccount'

export function BillingCheckoutStep ({ billingAccount, selectedPrice, onSubmit, onCancel }) {
  const pricingPlan = selectedPrice.pricingPlan

  return (
    <div>
      <SubscriptionStatusMessage>
        Pricing plan: <SubscriptionEmphasis>{pricingPlan.name}</SubscriptionEmphasis>
        (Monthly ad spend up to {billingAccountCurrency(billingAccount).symbol}{pricingPlan.monthlySpendLimit.replace(/\B(?=(\d{3})+(?!\d))/g, ',')})
      </SubscriptionStatusMessage>

      <BillingCheckoutForm
        billingAccount={billingAccount}
        onSubmit={onSubmit}
        onCancel={onCancel}
      />
    </div>
  )
}
