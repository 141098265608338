import { useField } from 'formik'
import BooleanField from 'containers/Form/BooleanField'
import React from 'react'

export function FormikBooleanField ({ description, ...props }) {
  const [field,, helpers] = useField(props)

  return (
  // Possible props:
  //
  // optionsFetcher
  // disableFormSave
  // onChange
  // context
  // dependsOn
  // onChange
  // onTouch
  // label
  // value
  // tip
  // tipPlacement
  // description
  // errorDescription
    <BooleanField
      value={field.value}
      onChange={value => field.onChange({ target: { name: field.name, value } })}
      onTouch={() => helpers.setTouched(true)}
      label={description}
    />
  )
}
