import styled from 'styled-components'

export default styled.div`
  width: 245px;
  height: 59px;
  display: block;
  margin: 0 auto;
  padding: 0;
  background: url(${props => props.logo});
  background-size: 245px 59px;
  background-repeat: no-repeat;
  background-position: center;
`
