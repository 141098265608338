import React from 'react'

import uuid from 'uuid'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components'

import { StyledInput } from 'containers/Form/Input/styles'
import { FormikFieldContainer } from 'uiKit/formik'
import cleanObject from 'utils/cleanObject'
// import { utcOffsets } from 'utils/utcOffsets'
// import { FormikSelectBox } from 'uiKit/formik/formikSelectBox'
import { ButtonPrimary } from 'uiKit'
import { ButtonWithConfirmation } from 'uiKit/ButtonWithConfirmation'
import { FormikBooleanField } from 'uiKit/formik/formikBooleanField'

const FormContainer = styled.div`
  flex: 1;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 25px;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 7px;
  }
`

export function WebhookForm ({ trackingBaseUri, onSubmit, onRemove, dataStream }) {
  const initialValues = {
    id: uuid.v4(),
    displayName: 'My Webhook',
    destinationTableName: 'hitsWebhook',
    anonymize: false,
    config: null,
    ...cleanObject(dataStream || {})
  }

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(),
    destinationTableName: Yup
      .string()
      .required('Destination table name is required')
      .matches(/^[a-zA-Z0-9_]+$/, 'BigQuery table name can only contain letters (upper or lower case), numbers, and underscores')
  })

  const onFormSubmit = async (values) => {
    await onSubmit(values)
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        { ({ values, handleSubmit, submitForm, isSubmitting, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <FormikFieldContainer
                  name='displayName'
                  label='Display name'
                  type='string'
                  tip='Pick a name to help you identify this data stream in SegmentStream.'
                >
                  <StyledInput type='string' />
                </FormikFieldContainer>

                <FormikFieldContainer
                  name='destinationTableName'
                  label='Destination table name'
                  type='string'
                  tip='BigQuery table name'
                >
                  <StyledInput type='string' />
                </FormikFieldContainer>

                <FormikFieldContainer
                  name='anonymize'
                  type='boolean'
                >
                  <FormikBooleanField description='Anonymize IP' />
                </FormikFieldContainer>

                {values.id ? (
                  <div>
                    <FormikFieldContainer
                      name='endpoint'
                      label='Webhook endpoint'
                      tip='<a href="https://docs.segmentstream.com/datastreams/webhook" target="_blank">Read more about the implementation ▸</a>'
                    />
                    <StyledInput type='string' value={`${trackingBaseUri}/ds/${values.id}`} />
                  </div>
                ) : null}

                <ButtonsContainer>
                  <ButtonPrimary
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                  >
                    Save
                  </ButtonPrimary>
                  { onRemove ? (
                    <ButtonWithConfirmation
                      dangerZone
                      onConfirm={onRemove}
                      confirmButtonText='Remove'
                      cancelButtonText='Cancel'
                      confirmMessage={`Are you sure that you want to delete the "${dataStream.displayName}" data stream?`}
                    />
                  ) : null }

                </ButtonsContainer>
              </FormContainer>
            </form>
          )
        }}
      </Formik>
    </React.Fragment>
  )
}
