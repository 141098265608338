import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import { allDataSources, sandboxDataSources, byRequestDataSources } from 'dataSources'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'
import DataSourceItem from './DataSourceItem'
import { close } from 'redux/modules/page/dataSources/createDataSourceSide/close'
import { ItemContainer, MessageIcon, RequestText, CallToActionText } from './styles'
import { DataSource } from '../styles'
import ru from './ru'
import InformationModal from 'containers/InformationModal'
import SendRequestForm from '../../SendRequestForm'
import { PROJECT_TYPES } from 'redux/modules/project'
import { ProjectFeature } from '@segmentstream/segmentstream-projects-abac'

class CreateDataSourceSide extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
    this.onOpenRequestForm = this.onOpenRequestForm.bind(this)
    this.onCloseRequestForm = this.onCloseRequestForm.bind(this)
  }

  onOpenRequestForm ({ dataSource }) {
    this.setState({
      requestFormOpen: true,
      requestedDataSource: dataSource
    })
  }

  onCloseRequestForm () {
    this.setState({
      requestFormOpen: false
    })
  }

  render () {
    const { isOpen, onClose, isSaleMode, locale, projectType, features } = this.props
    const { requestFormOpen, requestedDataSource } = this.state

    const availableDataSources = (features || []).includes(ProjectFeature.BetaFeatures) ? allDataSources : allDataSources.filter(ds => !ds.betaFeature)

    let dataSources = isSaleMode ? [...sandboxDataSources, ...availableDataSources, ...byRequestDataSources] : [...availableDataSources, ...byRequestDataSources]
    if (projectType === PROJECT_TYPES.GoogleAnalytics) {
      dataSources = dataSources.filter(ds => ds.enabledForGoogleAnalyticsProjects)
    }
    const translate = getTranslate(locale, { ru })

    return (
      <ModalWindow isOpen={isOpen} onClose={onClose} header={translate('Add Data Source')}>
        <ItemContainer>
          {dataSources.map(dataSource => (
            <DataSourceItem key={dataSource.id || dataSource.type} dataSource={dataSource} onRequest={this.onOpenRequestForm} />
          ))}
          <DataSource onClick={this.onOpenRequestForm}>
            <MessageIcon />
            <RequestText>
              {translate('Can\'t find a data source?')}
              <CallToActionText>{translate('Contact us!')}</CallToActionText>
            </RequestText>
          </DataSource>
        </ItemContainer>
        <InformationModal isOpen={requestFormOpen} onClose={this.onCloseRequestForm} featureRequest={'Data Source'}>
          <SendRequestForm
            onClose={this.onCloseRequestForm}
            featureRequest='Data Source'
            featureRequestHeader='Request a data source below and we will build it for you.'
            featureRequestLabel='Data&nbsp;source&nbsp;name'
            requestedDataSource={requestedDataSource}
          />
        </InformationModal>
      </ModalWindow>
    )
  }
}

const mapStateToProps = state => {
  const { isOpen } = state.page.dataSources.createDataSourceSide
  return {
    isOpen,
    locale: state.locale,
    isSaleMode: state.saleMode.status,
    isProMode: isProMode(state.proMode.status, state.user.role),
    projectType: state.project.type,
    features: state.project.features
  }
}

const mapDispatchToProps = () => ({
  onClose: close
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(CreateDataSourceSide)
