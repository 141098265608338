import React from 'react'
import { connect } from 'react-redux'
import {
  changeTab,
  SETTINGS,
  OVERRIDE_FUNCTIONS,
  EVENT_ENRICHMENTS
} from 'redux/modules/page/integrations/integrationForm/changeTab'
import { Tab, Wrapper } from './styles'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'

import ru from './ru.json'

const SettingsTab = ({
  locale,
  activeTab,
  hasSettings,
  onChangeTab,
  isProMode,
  advancedLevelOfSecurity
}) => {
  const translate = getTranslate(locale, { ru })
  const settingsClick = () => onChangeTab(SETTINGS)
  const eventEnrichmentsClick = () => onChangeTab(EVENT_ENRICHMENTS)
  const overrideFunctionsClick = () => onChangeTab(OVERRIDE_FUNCTIONS)
  return (
    <Wrapper>
      {hasSettings && (
        <Tab active={activeTab === SETTINGS} onClick={settingsClick}>
          {translate('Settings')}
        </Tab>
      )}
      {!advancedLevelOfSecurity && (
        <Tab active={activeTab === EVENT_ENRICHMENTS} onClick={eventEnrichmentsClick}>
          {translate('Event variables')}
        </Tab>
      )}
      {isProMode && !advancedLevelOfSecurity && (
        <Tab active={activeTab === OVERRIDE_FUNCTIONS} onClick={overrideFunctionsClick}>
          Override functions
        </Tab>
      )}
    </Wrapper>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    activeTab: state.page.integrations.integrationForm.activeTab,
    isProMode: isProMode(state.proMode.status, state.user.role),
    advancedLevelOfSecurity: state.project.advancedLevelOfSecurity
  }),
  {
    onChangeTab: changeTab
  }
)(SettingsTab)
