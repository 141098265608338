import styled, { css } from 'styled-components'

export const Wrapper = styled.div`
  width: 100%;
  height: 46px;
  margin: 0;
  padding: 0 33px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  background-color: #eef0f6;
  user-select: none;
`

export const Tab = styled.div`
  margin-right: 30px;
  padding: 10px 0;
  font-size: 16px;
  line-height: 16px;
  color: #93a2b1;
  font-weight: 600;
  list-style: none;
  outline: none;
  cursor: pointer;
  ${props =>
    props.active &&
    css`
      box-shadow: 0 5px 0 0 #bfbfbf;
    `}
`
