import React from 'react'
import { DeleteButton, Item } from './styles'

const ArrayRow = ({ children, onRemoveRow, nestedStyle }) => (
  <Item>
    <div>{children}</div>
    <DeleteButton onClick={onRemoveRow} nestedStyle={nestedStyle} />
  </Item>
)

export default ArrayRow
