import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import { allDataDestinations, sandboxDataDestinations } from 'dataDestinations'
import Form from 'containers/Form'
import { close } from 'redux/modules/page/dataDestinations/dataDestinationsForm/close'
import { changeStatus } from 'redux/modules/dataDestinations/changeStatus'
import getTranslate from 'utils/getTranslate'
import { save } from 'redux/modules/page/dataDestinations'
import { saveAuth, removeAuth, setAuthStatus, remove, setAuthCheckError } from 'redux/modules/dataDestinations'
import { fetchProject, PROJECT_TYPES } from 'redux/modules/project'
import ru from './ru.json'
import AuthenticationStatusBox from '../AuthenticationStatusBox/index'
import { getFreshAccessToken } from '../../../../../api/apiCall'
import _ from 'lodash'
import merge from 'deepmerge'
import { checkAuthOptions } from 'api/dataDestination'

class DataDestinationsForm extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.handleAuthorizationCheck = this.handleAuthorizationCheck.bind(this)
  }

  async handleAuthorizationCheck () {
    const {
      onAuthCheckError,
      project,
      initialData: { id },
      dataDestination: { authError }
    } = this.props

    if (authError) {
      this.setState({
        loading: true
      })

      const authOptions = await checkAuthOptions({
        projectId: project.id,
        id
      })
      if (authOptions && authOptions.error && authOptions.error.code === 'UNAUTHORIZED') {
        await onAuthCheckError(id, authError)
      }

      this.setState({
        loading: false
      })
    }
  }

  render () {
    const {
      initialData,
      onChangeStatus,
      onSave,
      dataDestination,
      isDataDestinationAuthenticated,
      locale,
      project,
      isOpen,
      onClose,
      projectName,
      projectType,
      onUpdateAuth,
      onRemoveAuth,
      onRemove,
      onFetchProject,
      page,
      userRole
    } = this.props
    const { loading } = this.state
    if (!dataDestination) {
      return null
    }

    const handleChangeStatus = () => {
      if (initialData) {
        onChangeStatus(initialData.id, !initialData.isEnabled, dataDestination.type)
      }
    }

    const handleSave = async (options) => {
      onSave(options, dataDestination.type, !!initialData.options)
    }

    const handleAuthentication = (authOptions) => {
      if (authOptions) {
        onUpdateAuth(initialData.id, authOptions)
      } else {
        onRemoveAuth(initialData.id)
      }
    }

    const handleFormDataChange = (data) => {
      if (data && data.isAuthenticated === true) {
        onFetchProject(project.id, page, userRole)
      }
      context.formData = { ...initialData.options, ...data }
    }

    const handleRemove = () => {
      onRemove(initialData.id, dataDestination.type)
    }

    const form = !isDataDestinationAuthenticated ? dataDestination.authForm : dataDestination.form

    const defaultValues = {}

    form.forEach((formItem) => {
      const defaultValue = _.get(formItem, `configurations.${projectType}.defaultValue`)
      if (defaultValue) {
        defaultValues[formItem.name] = defaultValue
      }
      if (formItem.type === 'group') {
        formItem.items.forEach(item => {
          const defaultValue = _.get(item, `configurations.${projectType}.defaultValue`)
          if (defaultValue) {
            if (formItem.flattenOptions) {
              defaultValues[item.name] = defaultValue
            } else {
              defaultValues[formItem.name] = defaultValues[formItem.name] || {}
              defaultValues[formItem.name][item.name] = defaultValue
            }
          }
        })
      }
    })

    const initData = !isDataDestinationAuthenticated ? {} : merge(initialData.options, defaultValues)
    const filteredForm = form.filter((formItem) => {
      const isVisible = _.get(formItem, `configurations.${projectType}.isVisible`, true)
      if (isVisible && formItem.type === 'group') {
        formItem.items = formItem.items.filter(item => {
          return _.get(item, `configurations.${projectType}.isVisible`, true)
        })
      }
      return isVisible
    })

    const enabledStatusBox = initialData ? initialData.isEnabled : false
    const translate = getTranslate(locale, { ru })
    const messageTemplate =
      'Are you sure that you want to <b>{{ACTION}}</b> the {{DESTINATION_NAME}} destination in the <b>{{PROJECT_NAME}}</b> project?'
    const disableMessage = translate(messageTemplate, {
      DESTINATION_NAME: dataDestination ? dataDestination.type : '',
      PROJECT_NAME: projectName,
      ACTION: translate('DISABLE')
    })
    const deleteMessage = translate(messageTemplate, {
      DESTINATION_NAME: dataDestination ? dataDestination.type : '',
      PROJECT_NAME: projectName,
      ACTION: translate('DELETE')
    })

    const link = typeof dataDestination.docsUrl === 'object' ? dataDestination.docsUrl[project.type] : dataDestination.docsUrl
    const docsLink = link && (
      <DocsLink url={link} />
    )

    const statusBox = isDataDestinationAuthenticated && initialData.options && (
      <StatusBox
        locale={locale}
        isEnabled={enabledStatusBox}
        onChange={handleChangeStatus}
        disableMessage={disableMessage}
      />
    )

    const authenticationStatusBox = isDataDestinationAuthenticated && (
      <AuthenticationStatusBox
        locale={locale}
        authIcon={dataDestination.authIcon}
        authName={dataDestination.authName}
        onChange={handleAuthentication}
        authMeta={initialData.authMeta}
      />
    )

    const handleAuthenticationType =
      dataDestination.authType !== 'OAuth2' ? handleAuthentication : undefined

    const context = {
      authorizeEndpoint: `/projects/${project.id}/data_destinations/${initialData.id}/authorize`,
      getFreshAccessToken,
      id: initialData.id,
      formData: initialData.options || {},
      projectId: project.id,
      authenticationFailMessage: initialData.authenticationFailMessage
    }

    return (
      <ModalWindow
        isOpen={isOpen}
        onClose={onClose}
        header={dataDestination.type}
        middle={authenticationStatusBox}
        docsLink={docsLink}
        statusBox={statusBox}
      >
        <Form
          context={context}
          locale={locale}
          form={filteredForm}
          data={initData}
          onSave={isDataDestinationAuthenticated ? handleSave : handleAuthenticationType}
          onChange={handleFormDataChange}
          onRemove={projectType === PROJECT_TYPES.BigQuery ? handleRemove : null}
          deleteMessage={deleteMessage}
          handleAuthorizationCheck={isDataDestinationAuthenticated ? this.handleAuthorizationCheck : null}
          loading={loading}
        />
      </ModalWindow>
    )
  }
}

const mapStateToProps = state => {
  const { isOpen, id } = state.page.dataDestinations.dataDestinationsForm
  const initialData = state.dataDestinations[id] || undefined
  const dataDestination = initialData
    ? [...allDataDestinations, ...sandboxDataDestinations].filter(ds => initialData.type === ds.type)[0]
    : undefined
  const isDataDestinationAuthenticated = (initialData || {}).isAuthenticated === true

  return {
    isOpen,
    dataDestination,
    initialData,
    locale: state.locale,
    projectName: state.project.name,
    isDataDestinationAuthenticated,
    project: state.project,
    page: state.page.name,
    userRole: state.user.role,
    projectType: state.project.type
  }
}

const mapDispatchToProps = () => ({
  onClose: close,
  onSave: save,
  onUpdateAuth: saveAuth,
  onSetAuthStatus: setAuthStatus,
  onFetchProject: fetchProject,
  onRemoveAuth: removeAuth,
  onChangeStatus: changeStatus,
  onRemove: remove,
  onAuthCheckError: setAuthCheckError
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(DataDestinationsForm)
