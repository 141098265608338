import React, { Component } from 'react'
import {
  Wrapper,
  Option,
  Select,
  SelectedValue,
  Triangle,
  Input,
  THEME_FORM_ITEM,
  Icon,
  DynamicLabel,
  Description,
  Reload,
  ExtendedViewValue,
  Placeholder,
  OptionLabel,
  ValueWrapper
} from './styles'
import refresh from './refresh.svg'
import Tooltip from 'containers/Tooltip'
import getValidationError from 'containers/Form/getValidationError'
import FieldError from 'containers/Form/FieldError'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

class DynamicSelectBox extends Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false, options: [] }
    this.handleToggle = this.handleToggle.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSelect = this.handleSelect.bind(this)
  }

  componentDidMount () {
    this.loadOptions('initialLoad')
  }

  UNSAFE_componentWillReceiveProps (nextProps) { // eslint-disable-line
    const { context, dependsOn = [] } = this.props

    dependsOn.forEach(dependency => {
      const current = (this.state.dependentFieldValues || {})[dependency] || ''
      const next = (nextProps.context.dependentFieldValues || {})[dependency] || ''

      if (
        this.state.dependentFieldValues &&
        current.toString() !== next.toString()
      ) {
        this.setState({
          dependentFieldValues: {
            ...this.state.dependentFieldValues,
            [dependency]: (context.dependentFieldValues || {})[dependency]
          }
        })
        this.loadOptions()

        // prevent select value reset when dependent field is an array
        if (!Array.isArray(current) && !Array.isArray(next)) {
          this.handleSelect('')
        }
      }
    })
  }

  optionsLoadStateHandler (res, initialLoad) {
    const {
      context,
      dependsOn = []
    } = this.props

    const optionsWithLabels = res && res.length && res.some(option => option.label)
    const normalizedOptions = optionsWithLabels ? res.map(({ value, label, ...rest }) => ({ value, label: label || value, ...rest })) : res

    this.setState({
      loading: false,
      options: normalizedOptions
    })
    if (initialLoad) {
      const dependentFieldValues = {}
      dependsOn.forEach(dependency => {
        dependentFieldValues[dependency] = (context.dependentFieldValues || {})[dependency]
      })
      this.setState({
        initialLoad: true,
        dependentFieldValues
      })
    }
  }

  loadOptions (initialLoad) {
    const {
      optionsFetcher,
      context
    } = this.props
    this.setState({ loading: true })

    optionsFetcher(context)
      .then(res => this.optionsLoadStateHandler(res, initialLoad))
      .catch(() => this.optionsLoadStateHandler([], initialLoad))
  }

  handleToggle () {
    const { onTouch, onFocus, onBlur } = this.props
    if (onTouch) onTouch()
    if (this.state.isOpen) {
      if (onBlur) onBlur()
    } else if (!this.state.isOpen) {
      if (onFocus) onFocus()
    }
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleBlur () {
    const { onBlur } = this.props
    this.setState({
      isOpen: false
    })
    if (onBlur) onBlur()
  }

  handleSelect (val) {
    const {
      onChange,
      secondaryValue
    } = this.props
    const value = val
    if (secondaryValue) {
      const { options } = this.state
      val = {
        value: val,
        [secondaryValue]: options.find(o => o.value === val)[secondaryValue]
      }
    }
    onChange(value === '' ? undefined : val, secondaryValue)
  }

  render () {
    const {
      label,
      value,
      touched,
      hasFocus,
      validation,
      locale,
      theme = { THEME_FORM_ITEM },
      styles = {},
      disabled,
      description,
      tip,
      placeholder,
      extendedView,
      dependsOn = []
    } = this.props
    const { isOpen, options, loading, initialLoad, dependentFieldValues } = this.state
    const translate = getTranslate(locale, { ru })
    const componentStyle = { width: '100%', display: 'inline-block' }
    const disable = disabled || !options.length
    const hasLabel = options[0] && typeof options[0].label === 'string'
    const valueLabel = hasLabel ? (options.find(opt => opt.value === value) || {}).label || value : value
    const extendedViewValue = hasLabel ? (options.find(opt => opt.value === value) || {}).extendedLabel : value
    const error =
      touched && !disabled && !hasFocus && validation
        ? getValidationError(validation, value, locale)
        : undefined
    const descriptionHTML = () => ({ __html: description })
    const dependenciesFilled = dependsOn.length ? !dependsOn.some(key => (dependentFieldValues || {})[key] === undefined) : true
    return (
      <div style={{ ...componentStyle, ...styles }}>
        <DynamicLabel>
          {label && <label>{label}</label>}
          {tip && <Tooltip tip={tip} place='right' />}
          <Reload onClick={() => this.loadOptions()}>
            <Icon isLoading={!!loading} src={refresh} />
            {translate('Refresh')}
          </Reload>
        </DynamicLabel>
        { (initialLoad && !options.length && !loading && dependenciesFilled) && <Description dangerouslySetInnerHTML={descriptionHTML()} /> }
        <Wrapper tabIndex='0'
          onBlur={this.handleBlur}
          onClick={(!disable && !loading) ? this.handleToggle : undefined}
          theme={theme}
          disabled={disable || loading}>
          <Input isOpen={isOpen} theme={theme} error={error} extendedView={extendedView}>
            <SelectedValue extendedView={extendedView}>
              {
                loading ? 'Loading...' : (
                  (!disable && dependenciesFilled)
                    ? <ValueWrapper>
                      {valueLabel ? <OptionLabel>{valueLabel}</OptionLabel> : <Placeholder>{placeholder}</Placeholder>}
                      {extendedView && valueLabel && <ExtendedViewValue>{extendedViewValue}</ExtendedViewValue>}
                    </ValueWrapper>
                    : <ValueWrapper>
                      {value || <Placeholder>{placeholder}</Placeholder>}
                    </ValueWrapper>
                )
              }
            </SelectedValue>
            <Triangle theme={theme} extendedView={extendedView} />
          </Input>
          {isOpen && (
            <Select theme={theme}>
              {options.map(opt => {
                const val = hasLabel ? opt.value : opt
                const lab = hasLabel ? opt.label : opt
                return (
                  <Option key={val} onClick={() => this.handleSelect(val)} theme={theme}>
                    <OptionLabel>{lab || val}</OptionLabel>
                    {extendedView && <ExtendedViewValue>{opt.extendedLabel || val}</ExtendedViewValue>}
                  </Option>
                )
              })}
            </Select>
          )}
        </Wrapper>
        {error && <FieldError message={error} />}
      </div>
    )
  }
}

export default DynamicSelectBox
