import logo from './logo.png'
import sideLogo from './logo-side.png'
import { PERSONALIZATION } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Driveback',
  color: '#000',
  category: [PERSONALIZATION],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/driveback',
  form: [
    {
      label: 'Driveback ID',
      type: 'string',
      name: 'websiteToken',
      validation: ['required']
    }
  ]
}

export default config
