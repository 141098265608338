import logo from './logo.png'
import authIcon from './authIcon.png'

import { fetchGoogleAds2Reports, fetchGoogleAds2Customers } from 'api/dataSource'

const config = {
  type: 'Google Ads 2',
  betaFeature: true,
  color: '#1c84c6',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datasources/google-ads-2',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Ads 2',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Advertising accounts',
      type: 'dynamic select array',
      name: 'customerIds',
      validation: ['required'],
      placeholder: 'Select advertising account',
      optionsFetcher: fetchGoogleAds2Customers,
      description: 'No advertising accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/google-ads/answer/6366720?hl=en-GB" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      addButtonLabel: 'Add advertising account',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Reports',
      type: 'group array',
      name: 'reports',
      addButtonLabel: 'Add report',
      items: [
        {
          label: 'Report',
          type: 'dynamic select',
          name: 'report',
          validation: ['required'],
          placeholder: 'Select report',
          optionsFetcher: fetchGoogleAds2Reports
        },
        {
          label: 'Destination table name',
          type: 'string',
          name: 'tableName',
          validation: ['required']
        }
      ]
    }
  ]
}

export default config
