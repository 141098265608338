import React from 'react'
import { connect } from 'react-redux'
import { useMutation } from '@apollo/react-hooks'
import { gql } from 'apollo-boost'
import { logout } from 'redux/modules/auth'

import { EmailConfirmationPage } from '../Components/email-confirmation-page'

function EmailConfirmationPageContainer ({ user, onLogout }) {
  const [sendEmailConfirmationLetter, { loading, called, error }] = useMutation(gql`
    mutation SendEmailConfirmationLetter { sendEmailConfirmationLetter }
  `)

  const handleResend = async () => {
    await sendEmailConfirmationLetter()
  }

  return (
    <EmailConfirmationPage
      user={user}
      onResend={handleResend}
      resendInProgress={loading}
      resendDisabled={called}
      resendSuccess={called && !error && !loading}
      logout={onLogout}
    />
  )
}

export default connect(
  state => ({
    user: state.user
  }),
  {
    onLogout: logout
  }
)(EmailConfirmationPageContainer)
