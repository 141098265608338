import React from 'react'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import {
  OnboardingWindow,
  OnboardingIcon,
  OnboardingTitle,
  OnboardingBody,
  OnboardingArrow,
  OnboardingLink
} from './styles'
import onboardingLogo from './onboarding-logo.svg'

const OnboardingPlaceholder = ({ icon, title, body, buttonTitle, buttonAction, linkTitle, linkUrl, showArrow, tutorialLink }) => {
  const createTitleMarkup = () => ({ __html: title })
  const createBodyMarkup = () => ({ __html: body })
  return (
    <OnboardingWindow>
      { icon ? <OnboardingIcon icon={icon} /> : <OnboardingIcon large icon={onboardingLogo} /> }
      { title && <OnboardingTitle dangerouslySetInnerHTML={createTitleMarkup()} /> }
      { body && <OnboardingBody dangerouslySetInnerHTML={createBodyMarkup()} /> }
      { showArrow && <OnboardingArrow /> }
      { buttonTitle && buttonAction && <ConfirmButton onClick={buttonAction}>{buttonTitle}</ConfirmButton> }
      { linkTitle && linkUrl && <OnboardingLink href={linkUrl} target='_blank' rel='noopener noreferrer'>{linkTitle}</OnboardingLink> }
    </OnboardingWindow>
  )
}

export default OnboardingPlaceholder
