export const notDeprecated = (column) => column.attributes.status !== 'DEPRECATED'

export const getType = (column, type) => column.attributes.type === type

export const isTemplatized = (column) => {
  return !!column.attributes.minTemplateIndex ||
    !!column.attributes.maxTemplateIndex ||
    !!column.attributes.premiumMaxTemplateIndex
}

export const getTemplatizedIdList = (column) => {
  const columnIdList = []
  const columnId = column.id

  if (isTemplatized(column)) {
    const minIndex = parseInt(column.attributes.minTemplateIndex)
    const maxIndex = parseInt(column.attributes.premiumMaxTemplateIndex || column.attributes.maxTemplateIndex)

    for (var i = minIndex; i <= maxIndex; i++) {
      columnIdList.push({ id: columnId.replace('XX', i) })
    }
  }
  return columnIdList
}

export const handleTemplatizedIds = (acc, i) => {
  if (!isTemplatized(i)) {
    acc.push(i)
  } else {
    acc = [...acc, ...getTemplatizedIdList(i)]
  }
  return acc
}
