import React from 'react'
import Input from 'containers/Form/Input'
import SelectBox from 'containers/Form/SelectBox'
import { THEME_FORM_ARRAY_ITEM } from 'containers/Form/SelectBox/styles'
import InlineRow from 'containers/Form/InlineRow'
import getTranslate from 'utils/getTranslate'
import ru from './ru.json'

const DigitalDataConditionsRow = ({
  locale,
  prop,
  condition,
  value,
  onChangeProp,
  onChangeCondition,
  onChangeValue
}) => {
  const translate = getTranslate(locale, { ru })
  const conditionOptions = [
    {
      label: translate('equals'),
      value: '='
    },
    {
      label: translate('contains'),
      value: 'contains'
    },
    {
      label: translate('starts with'),
      value: 'startsWith'
    },
    {
      label: translate('ends with'),
      value: 'endsWith'
    },
    {
      label: translate('does not equal'),
      value: '!='
    },
    {
      label: translate('does not contain'),
      value: 'notConains'
    },
    {
      label: translate('does not start with'),
      value: 'notStartsWith'
    },
    {
      label: translate('does not end with'),
      value: 'notEndsWith'
    },
    {
      label: translate('less than'),
      value: '<'
    },
    {
      label: translate('less than or equals to'),
      value: '<='
    },
    {
      label: translate('greater than'),
      value: '>'
    },
    {
      label: translate('greater than or equals to'),
      value: '>='
    }
  ]

  return (
    <InlineRow>
      <Input value={prop} onChange={onChangeProp} placeholder={translate('DigitalData variable')} />
      <SelectBox
        theme={THEME_FORM_ARRAY_ITEM}
        options={conditionOptions}
        value={condition}
        onChange={onChangeCondition}
      />
      <Input
        value={value}
        onChange={onChangeValue}
        placeholder={translate('value to compare with')}
      />
    </InlineRow>
  )
}

export default DigitalDataConditionsRow
