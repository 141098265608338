import path from './path'

const DISMISS_TIP = `dismissTip@${path}`

export const dismissTip = (name, tipType) => {
  return {
    type: DISMISS_TIP,
    name,
    tipType
  }
}

export default (state = {}, action) =>
  action.type === DISMISS_TIP
    ? {
      ...state,
      enabledTips: action.name ? state.enabledTips.filter(tip => tip.type !== action.tipType || tip.name !== action.name) : []
    }
    : state
