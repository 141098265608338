import React from 'react'
import ArrayBox from 'containers/Form/ArrayBox'
import Input from 'containers/Form/Input'
import FieldError from 'containers/Form/FieldError'
import getValidationError from 'containers/Form/getValidationError'

const StringArray = ({
  locale,
  description,
  label,
  onChange,
  onTouch,
  style,
  value,
  placeholder,
  inputStyle,
  limit,
  validation,
  touched,
  tip
}) => {
  const error = touched && getValidationError(validation, value, locale)

  const defaultRow = ''

  const getRowsFromValue = () => {
    if (!value || value.length === 0) {
      return [defaultRow]
    }
    return value
  }

  const change = newRows => {
    const nextValue = newRows.filter(row => row !== defaultRow)
    onChange(nextValue)
    onTouch()
  }

  const createRow = ({ row, index, onChangeRow }) => (
    <Input
      style={inputStyle}
      value={row}
      placeholder={placeholder}
      onChange={val => onChangeRow(index, val)}
    />
  )

  return (
    <div>
      <ArrayBox
        locale={locale}
        defaultRow={defaultRow}
        description={description}
        label={label}
        onChange={change}
        onCreateRow={createRow}
        rows={getRowsFromValue()}
        style={style}
        limit={limit}
        error={error}
        tip={tip}
      />
      {error && <FieldError message={error} />}
    </div>
  )
}

export default StringArray
