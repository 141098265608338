import React, { Component } from 'react'
import AuthField from 'containers/Form/AuthField'
import Input from 'containers/Form/Input'
import { SelectBoxWrapper, SelectBox, BoxLine, Dropdown, Wrapper, Triangle, THEME_FORM_FIELD, THEME_BILLING_FIELD, THEME_PROJECT_CREATION } from './styles'

import { THEME_FORM_SINGLE_ARRAY_ITEM } from 'containers/Form/DynamicSelectArray'
import { Label } from 'containers/Form/SelectBox/styles'

const KEY_UP = 38
const KEY_DOWN = 40
const KEY_ENTER = 13

class SearchableSelect extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      cursor: 0,
      searchInput: '',
      filteredValuesList: this.getFilteredValuesList()
    }

    this.handleSelect = this.handleSelect.bind(this)
    this.handleClick = this.handleClick.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleChangeSearchInput = this.handleChangeSearchInput.bind(this)
  }

  getFilteredValuesList (searchInput) {
    const { value, options, uniqueValues, arrayValues = [] } = this.props
    const selectOptions = uniqueValues ? options.filter(option => (!arrayValues.includes(option) || option === value)) : options
    return searchInput ? selectOptions.filter(item => (item.label || item).toLowerCase().includes(searchInput.toLowerCase().trim())) : selectOptions
  }

  handleKeyDown (event) {
    const { cursor, filteredValuesList } = this.state
    const { keyCode } = event
    if (keyCode === KEY_UP && cursor > 0) {
      this.setState({ cursor: cursor - 1 })
    } else if (keyCode === KEY_DOWN && cursor < filteredValuesList.length - 1) {
      this.setState({ cursor: cursor + 1 })
    } else if (keyCode === KEY_ENTER && filteredValuesList[cursor]) {
      this.handleSelect((filteredValuesList[cursor].value || filteredValuesList[cursor]))
    }
  }

  handleChangeSearchInput (searchInput) {
    this.setState({
      searchTouched: true,
      isOpen: true,
      searchInput,
      cursor: 0,
      filteredValuesList: this.getFilteredValuesList(searchInput)
    })
  }

  handleSelect (value) {
    this.setState({
      searchTouched: false,
      isOpen: false,
      cursor: 0,
      searchInput: '',
      filteredValuesList: this.getFilteredValuesList()
    })
    this.props.onChange(value)
  }

  handleClick () {
    if (this.props.options.length > 1) {
      this.setState({
        isOpen: !this.state.isOpen,
        filteredValuesList: this.getFilteredValuesList()
      })
    }
  }

  handleBlur (event) {
    const { options, onChange } = this.props
    const { searchInput, searchTouched } = this.state

    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (searchTouched) {
        const { value } = (searchInput && options.find(opt => (opt.label || opt).toLowerCase() === searchInput.toLowerCase())) || {}
        onChange(value || '')
        this.setState({
          searchTouched: false,
          searchInput: ''
        })
      }

      this.setState({
        isOpen: false
      })
    }
  }

  render () {
    const { value, options, theme, placeholder, disabled, style, label } = this.props
    const { isOpen, cursor, searchInput, searchTouched, filteredValuesList } = this.state
    let componentStyle =
      { width: '50%', display: 'inline-block', ...style }
    if (theme === THEME_FORM_FIELD || theme === THEME_BILLING_FIELD) {
      componentStyle = { width: '100%', marginBottom: '17px' }
    } else if (theme === THEME_FORM_SINGLE_ARRAY_ITEM) {
      componentStyle = { width: '100%', display: 'inline-block' }
    } else if (theme === THEME_PROJECT_CREATION) {
      componentStyle = { width: '100%', marginTop: '17px' }
    }

    const hasLabel = options[0] && options[0].label
    const valueLabel = hasLabel ? (options.find(opt => opt.value === value) || {}).label : value
    const input = searchTouched ? searchInput : valueLabel

    return (
      <div style={componentStyle}>
        {
          label &&
          <Label>
            {<label>{label}</label>}
          </Label>
        }
        <Wrapper
          enableHover={options.length > 1}
          onKeyDown={this.handleKeyDown}
          onBlur={this.handleBlur}
          tabIndex='0'
          disabled={disabled}
          onClick={this.handleClick}
        >
          {
            theme === THEME_FORM_FIELD
              ? <AuthField
                theme={theme}
                onChange={this.handleChangeSearchInput}
                value={input}
                placeholder={placeholder}
                disabled={disabled}
              />
              : <Input
                theme={theme}
                onChange={this.handleChangeSearchInput}
                value={input}
                placeholder={placeholder}
                disabled={disabled}
              />
          }
          <Triangle />
          {isOpen && (
            <Dropdown theme={theme}>
              <SelectBoxWrapper>
                <SelectBox>
                  {filteredValuesList.map((item, i) => {
                    const val = item.value || item
                    const label = hasLabel ? item.label : item
                    return <BoxLine
                      key={val}
                      onClick={() => this.handleSelect(val)}
                      selected={cursor === i}
                    >
                      {label}
                    </BoxLine>
                  })}
                  {!filteredValuesList.length && <BoxLine>No results found</BoxLine>}
                </SelectBox>
              </SelectBoxWrapper>
            </Dropdown>
          )}
        </Wrapper>
      </div>
    )
  }
}

export default SearchableSelect
