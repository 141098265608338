import React, { useContext } from 'react'

import { dataStreamsContext } from '../Context'
import { UpdateDataStreamSide } from '../Components/UpdateDataStreamSide'

export function UpdateContainer () {
  const {
    trackingBaseUri,

    update: {
      isLoading,
      isOpen,
      selectedDataStream,

      close,
      updateDataStream,
      deleteDataStream
    }
  } = useContext(dataStreamsContext)

  const handleUpdate = (dataStreamOptions) =>
    updateDataStream({ ...selectedDataStream, ...dataStreamOptions })

  const handleDelete = () => deleteDataStream(selectedDataStream)

  return (
    <UpdateDataStreamSide
      trackingBaseUri={trackingBaseUri}
      isLoading={isLoading}
      isOpen={isOpen}
      selectedDataStream={selectedDataStream}
      close={close}
      updateDataStream={handleUpdate}
      deleteDataStream={handleDelete}
    />
  )
}
