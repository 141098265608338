import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchRtbHouseCampaigns } from 'api/dataSource'

const config = {
  type: 'RTBHouse',
  color: '#ee4037',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/rtb-house',
  authType: 'Basic',
  authIcon,
  authName: 'RTB House Account',
  isPublished: true,
  authForm: [
    {
      label: 'Username',
      type: 'string',
      name: 'login',
      validation: ['required']
    },
    {
      label: 'Password',
      type: 'password',
      name: 'password',
      validation: ['required']
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My RTB House',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import Advertisers Statistics report',
      type: 'boolean',
      name: 'advertisersStatsReport',
      defaultValue: false,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'UTM source',
      type: 'string',
      name: 'utmSource',
      validation: ['required'],
      defaultValue: 'rtbhouse',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'UTM medium',
      type: 'string',
      name: 'utmMedium',
      validation: ['required'],
      defaultValue: 'cpc',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Manual UTM mapping',
      type: 'boolean',
      name: 'manualMapping',
      defaultValue: false,
      enable: ['defaultUtmCampaign', 'urlParamsMap'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM campaign',
      type: 'string',
      name: 'defaultUtmCampaign',
      validation: ['required'],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Campaign specific URL parameters',
      type: 'group array',
      name: 'urlParamsMap',
      addButtonLabel: 'Add campaign',
      tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/rtb-house#utm-matching" target="_blank" rel="noopener noreferrer">documentation</a>.',
      items: [
        {
          label: 'Campaign',
          type: 'dynamic select',
          name: 'campaignId',
          validation: ['required'],
          placeholder: 'Select campaign',
          optionsFetcher: fetchRtbHouseCampaigns,
          description: 'No campaigns were found or access was not granted. Please, make sure you have access to the required campaigns or create a new one and click “Refresh”.'
        },
        {
          label: 'UTM campaign',
          type: 'string',
          name: 'utmCampaign',
          validation: ['required'],
          placeholder: 'Insert UTM campaign for the selected campaign'
        },
        {
          label: 'Override UTM source (optional)',
          type: 'string',
          name: 'utmSource',
          placeholder: 'Insert UTM source for the selected campaign'
        },
        {
          label: 'Override UTM medium (optional)',
          type: 'string',
          name: 'utmMedium',
          placeholder: 'Insert UTM medium for the selected campaign'
        }
      ],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          enable: ['utmSource', 'utmMedium'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'UTM source',
          type: 'string',
          name: 'utmSource',
          validation: ['required'],
          defaultValue: 'rtbhouse'
        },
        {
          label: 'UTM medium',
          type: 'string',
          name: 'utmMedium',
          validation: ['required'],
          defaultValue: 'cpc'
        },
        {
          label: 'Manual UTM mapping',
          type: 'boolean',
          name: 'manualMapping',
          defaultValue: false,
          enable: ['defaultUtmCampaign', 'urlParamsMap']
        },
        {
          label: 'Default UTM campaign',
          type: 'string',
          name: 'defaultUtmCampaign',
          validation: ['required']
        },
        {
          label: 'Campaign specific URL parameters',
          type: 'group array',
          name: 'urlParamsMap',
          addButtonLabel: 'Add campaign',
          tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/rtb-house#utm-matching" target="_blank" rel="noopener noreferrer">documentation</a>.',
          items: [
            {
              label: 'Campaign',
              type: 'dynamic select',
              name: 'campaignId',
              validation: ['required'],
              placeholder: 'Select campaign',
              optionsFetcher: fetchRtbHouseCampaigns,
              description: 'No campaigns were found or access was not granted. Please, make sure you have access to the required campaigns or create a new one and click “Refresh”.'
            },
            {
              label: 'UTM campaign',
              type: 'string',
              name: 'utmCampaign',
              validation: ['required'],
              placeholder: 'Insert UTM campaign for the selected campaign'
            },
            {
              label: 'Override UTM source (optional)',
              type: 'string',
              name: 'utmSource',
              placeholder: 'Insert UTM source for the selected campaign'
            },
            {
              label: 'Override UTM medium (optional)',
              type: 'string',
              name: 'utmMedium',
              placeholder: 'Insert UTM medium for the selected campaign'
            }
          ]
        }
      ]
    }
  ]
}

export default config
