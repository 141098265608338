import React from 'react'
import { connect } from 'react-redux'
import ModalWindow from 'containers/ModalWindow'
import Form from 'containers/Form'
import { closeForm } from 'redux/modules/page/events/closeForm'
import { changeStatus } from 'redux/modules/events/changeStatus'

import { save, remove } from 'redux/modules/page/events'
import StatusBox from 'containers/StatusBox'
import DocsLink from 'containers/DocsLink'
import types, { EVENT, CLICK, IMPRESSION, SCROLL, ACTIVE_TIME_ON_PAGE, ACTIVE_TIME_ON_SITE, TIME_ON_PAGE, TIME_ON_SITE, CHANGE } from './types'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'
import { isProMode } from 'utils/proMode'

const EventsForm = ({
  locale,
  event,
  isOpen,
  isProMode,
  onClose,
  onSave,
  onRemove,
  onChangeStatus,
  projectName
}) => {
  const translate = getTranslate(locale, { ru })
  const handleSave = (newEvent, touchedFields) => {
    onSave(newEvent, touchedFields)
  }
  const handleRemove = () => {
    if (event) {
      onRemove(event.id, event.name)
    }
  }
  const form = [
    {
      label: translate('Title'),
      type: 'string',
      name: 'name',
      validation: ['required', 'no-double-quotes']
    },
    {
      label: translate('Trigger'),
      type: 'select',
      name: 'type',
      options: [
        {
          label: translate(types[EVENT]),
          value: EVENT,
          enable: ['event']
        },
        {
          label: translate(types[CLICK]),
          value: CLICK,
          enable: ['selector', 'followLink']
        },
        {
          label: translate(types[IMPRESSION]),
          value: IMPRESSION,
          enable: ['selector']
        },
        {
          label: translate(types[SCROLL]),
          value: SCROLL,
          enable: ['scrollDepth']
        },
        {
          label: types[CHANGE],
          value: CHANGE,
          enable: ['selector']
        },
        {
          label: translate(types[ACTIVE_TIME_ON_PAGE]),
          value: ACTIVE_TIME_ON_PAGE,
          enable: ['seconds']
        },
        {
          label: translate(types[ACTIVE_TIME_ON_SITE]),
          value: ACTIVE_TIME_ON_SITE,
          enable: ['seconds']
        },
        {
          label: translate(types[TIME_ON_PAGE]),
          value: TIME_ON_PAGE,
          enable: ['seconds']
        },
        {
          label: translate(types[TIME_ON_SITE]),
          value: TIME_ON_SITE,
          enable: ['seconds']
        }
      ],
      defaultValue: EVENT
    },
    {
      label: translate('CSS selector'),
      type: 'string',
      name: 'selector',
      validation: ['required', 'no-double-quotes']
    },
    {
      label: translate('Scroll depth (%), comma separated values allowed'),
      type: 'string',
      name: 'scrollDepth',
      validation: ['required']
    },
    {
      label: translate('Time (seconds), comma separated values allowed'),
      type: 'string',
      name: 'seconds',
      validation: ['required']
    },
    {
      label: translate('Triggering event name'),
      type: 'string',
      name: 'event',
      validation: ['required', 'no-double-quotes'],
      defaultValue: 'Viewed Page'
    },
    {
      label: translate('Event handler'),
      type: 'javascript',
      name: 'handler',
      validation: ['required']
    },
    {
      label: translate('Follow link defined in "href" after event'),
      type: 'boolean',
      name: 'followLink',
      defaultValue: true
    },
    {
      type: 'digital data conditions',
      name: 'preConditions',
      label: translate('Preconditions')
    }
  ]

  if (isProMode) {
    form.push({
      label: 'Pro mode only',
      type: 'boolean',
      name: 'proModeOnly',
      defaultValue: false
    })
  }

  const header = event !== undefined ? event.name : translate('Add event')

  const handleChangeStatus = () => {
    if (event) {
      onChangeStatus(event.id, event.name, !event.isEnabled)
    }
  }

  const messageTemplate = translate(
    'Are you sure that you want to <b>{{ACTION}}</b> the "{{EVENT_NAME}}" event in the <b>{{PROJECT_NAME}}</b> project?'
  )
  const disableMessage = translate(messageTemplate, {
    EVENT_NAME: event ? event.name : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DISABLE')
  })
  const deleteMessage = translate(messageTemplate, {
    EVENT_NAME: event ? event.name : '',
    PROJECT_NAME: projectName,
    ACTION: translate('DELETE')
  })

  const statusBox =
    event !== undefined ? (
      <StatusBox
        locale={locale}
        isEnabled={event.isEnabled}
        onChange={handleChangeStatus}
        disableMessage={disableMessage}
      />
    ) : null

  const docsLink = <DocsLink locale={locale} url='https://docs.segmentstream.com/for-analyst/events' />

  return (
    <ModalWindow isOpen={isOpen} onClose={onClose} header={header} statusBox={statusBox} docsLink={docsLink}>
      <Form
        locale={locale}
        form={form}
        data={event}
        onSave={handleSave}
        onRemove={event !== undefined ? handleRemove : undefined}
        deleteMessage={deleteMessage}
      />
    </ModalWindow>
  )
}

export default connect(
  state => {
    const { isOpen, id } = state.page.events
    return {
      locale: state.locale,
      projectName: state.project.name,
      isOpen,
      isProMode: isProMode(state.proMode.status, state.user.role),
      event: id !== undefined ? state.events.find(item => item.id === id) : undefined
    }
  },
  {
    onClose: closeForm,
    onSave: save,
    onRemove: remove,
    onChangeStatus: changeStatus
  }
)(EventsForm)
