import React from 'react'
import { HeaderWrapper, HeaderLeft, HeaderRight } from './styles'

const SectionHeader = (
  {
    leftContent,
    leftWidth,
    rightContent,
    rightWidth
  }
) => {
  return (
    <HeaderWrapper>
      <HeaderLeft width={leftWidth}>
        { leftContent }
      </HeaderLeft>
      <HeaderRight width={rightWidth}>
        { rightContent }
      </HeaderRight>
    </HeaderWrapper>
  )
}

export default SectionHeader
