import path from './path'

const TYPE = `add@${path}`

export const add = integrationName => ({
  type: TYPE,
  integrationName
})

export default (state = {}, action) => {
  if (
    action.type === TYPE &&
    !state.before.includes(action.integrationName) &&
    !state.after.includes(action.integrationName)
  ) {
    return {
      before: [...state.before, action.integrationName],
      after: state.after
    }
  }
  return state
}
