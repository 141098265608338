import path from './path'

const SET_AUTH_CHECK_ERROR = `setAuthCheckError@${path}`

export const setAuthCheckError = (id, authenticationFailMessage) => async dispatch => {
  dispatch({
    type: SET_AUTH_CHECK_ERROR,
    id,
    authenticationFailMessage
  })
}

export default (state = {}, action) =>
  action.type === SET_AUTH_CHECK_ERROR
    ? {
      ...state,
      [action.id]: {
        ...state[action.id],
        authenticationFailMessage: action.authenticationFailMessage,
        isAuthenticated: false
      }
    }
    : state
