import { fetchCriteoAccounts, fetchCriteoCampaigns } from 'api/dataSource'
import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Criteo',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/criteo',
    google_analytics: 'https://docs.segmentstream.com/datasources/criteo'
  },
  authType: 'Basic',
  authIcon,
  authName: 'Criteo Account',
  authForm: [
    {
      label: 'Client ID',
      type: 'string',
      name: 'clientId',
      validation: ['required']
    },
    {
      label: 'Client Secret',
      type: 'string',
      name: 'clientSecret',
      validation: ['required']
    }
  ],
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Criteo',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Accounts',
      type: 'dynamic select array',
      name: 'accountIds',
      validation: ['required'],
      placeholder: 'Select Account',
      optionsFetcher: fetchCriteoAccounts,
      description: 'No accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or create a new one and click “Refresh”.',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Reporting currency',
      type: 'select',
      name: 'currency',
      options: ['RUB', 'USD', 'EUR'],
      defaultValue: 'USD'
    },
    {
      label: 'Import "Campaign Performance" report',
      type: 'boolean',
      name: 'campaignPerformanceReport',
      defaultValue: false,
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Default UTM source',
      type: 'string',
      name: 'utmSource',
      validation: ['required'],
      defaultValue: 'criteo',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium',
      type: 'string',
      name: 'utmMedium',
      validation: ['required'],
      defaultValue: 'cpc',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'URL parameters',
      type: 'group array',
      name: 'urlParamsMap',
      addButtonLabel: 'Add campaign',
      dependsOn: ['accountIds'],
      tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/criteo#utm-matching" target="_blank" rel="noopener noreferrer">documentation</a>.',
      items: [
        {
          label: 'Campaign ID',
          type: 'dynamic select',
          name: 'campaignId',
          validation: ['required'],
          placeholder: 'Select campaign',
          optionsFetcher: fetchCriteoCampaigns,
          dependsOn: ['accountIds'],
          description: 'No campaigns were found for this account or access was not granted. Please, make sure you have access to the required campaigns or create a new one and click “Refresh”.',
          extendedView: true
        },
        {
          label: 'UTM campaign',
          type: 'string',
          name: 'utmCampaign',
          validation: ['required'],
          placeholder: 'Insert UTM campaign for the selected campaign'
        },
        {
          label: 'Override UTM source (optional)',
          type: 'string',
          name: 'utmSource',
          placeholder: 'Insert UTM source for the selected campaign'
        },
        {
          label: 'Override UTM medium (optional)',
          type: 'string',
          name: 'utmMedium',
          placeholder: 'Insert UTM medium for the selected campaign'
        }
      ],
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      dependsOn: ['accountIds'],
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedCostsReport',
          defaultValue: true,
          enable: ['costMultiplier', 'utmSource', 'utmMedium', 'urlParamsMap'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source',
          type: 'string',
          name: 'utmSource',
          validation: ['required'],
          defaultValue: 'criteo'
        },
        {
          label: 'Default UTM medium',
          type: 'string',
          name: 'utmMedium',
          validation: ['required'],
          defaultValue: 'cpc'
        },
        {
          label: 'URL parameters',
          type: 'group array',
          name: 'urlParamsMap',
          addButtonLabel: 'Add campaign',
          tip: 'Read more about this setting in the <a href="https://docs.segmentstream.com/datasources/criteo#utm-matching" target="_blank" rel="noopener noreferrer">documentation</a>.',
          items: [
            {
              label: 'Campaign',
              type: 'dynamic select',
              name: 'campaignId',
              validation: ['required'],
              placeholder: 'Select campaign',
              optionsFetcher: fetchCriteoCampaigns,
              dependsOn: ['accountIds'],
              description: 'No campaigns were found for this account or access was not granted. Please, make sure you have access to the required campaigns or create a new one and click “Refresh”.',
              extendedView: true
            },
            {
              label: 'UTM campaign',
              type: 'string',
              name: 'utmCampaign',
              validation: ['required'],
              placeholder: 'Insert UTM campaign for the selected campaign'
            },
            {
              label: 'Override UTM source (optional)',
              type: 'string',
              name: 'utmSource',
              placeholder: 'Insert UTM source for the selected campaign'
            },
            {
              label: 'Override UTM medium (optional)',
              type: 'string',
              name: 'utmMedium',
              placeholder: 'Insert UTM medium for the selected campaign'
            }
          ]
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        }
      ]
    }
  ]
}

export default config
