import logo from './logo.png'
import sideLogo from './logo-side.png'
import { DMP } from 'integrations/categories'

const config = {
  name: '1DMC',
  color: '#50BE6A',
  category: [DMP],
  sideLogo,
  logo,
  form: [
    {
      label: 'Client ID',
      type: 'string',
      name: 'clientId',
      validation: ['required']
    },
    {
      label: 'Brand ID',
      type: 'string',
      name: 'brandId',
      validation: ['required']
    },
    {
      label: 'Enable SuperSync',
      type: 'boolean',
      name: 'superSync'
    },
    {
      label: 'DigitalData variable with anonymous user ID',
      type: 'string',
      name: 'anonymousIdVar',
      placeholder: 'Example: user.googleClientId'
    },
    {
      label: 'Enable DigitalData enrichment',
      type: 'boolean',
      name: 'profileEnrichment'
    },
    {
      label: 'Token',
      type: 'string',
      name: 'token'
    },
    {
      label: 'Attributes mapping',
      type: 'string mapping',
      name: 'attributesMapping',
      placeholderKey: '1DMC attribute ID',
      placeholderValue: 'DigitalData variable'
    }
  ]
}

export default config
