import React from 'react'
import InputField from 'containers/Form/InputField'
import BooleanField from 'containers/Form/BooleanField'
import StringMapping from 'containers/Form/StringMapping'
import StringArray from 'containers/Form/StringArray'
import VariableMapping from 'containers/Form/VariableMapping'
import Group from 'containers/Form/Group'
import GroupArray from 'containers/Form/GroupArray'
import Variable from 'containers/Form/Variable'
import SelectBox from 'containers/Form/SelectBox'
import { THEME_FORM_ITEM } from 'containers/Form/SelectBox/styles'
import InputText from 'containers/Form/InputText'
import AceEditor from 'containers/AceEditor'
import DigitalDataConditions from 'containers/Form/DigitalDataConditions'
import OAuth2Button from './OAuth2Button'
import GoogleDriveFilePicker from './GoogleDriveFilePicker'
import DynamicSelectBox from './DynamicSelectBox'
import DynamicSelectArray from './DynamicSelectArray'

const DEFAULT_EDITOR_FORM_OFFSET = 760

const FieldGenerator = (props) => {
  const {
    locale,
    field,
    value,
    hasFocus,
    touched,
    isValid,
    context,
    onChange,
    onFocus,
    onTouch,
    onValidate,
    disabledFields,
    unavailableFields,
    disableFormSave
  } = props

  const {
    name,
    type,
    label,
    icon,
    validation,
    description,
    errorDescription,
    placeholder,
    units,
    maxLength,
    autocomplete = 'off',
    disabled = false,
    limit,
    optionsFetcher,
    valueFetcher,
    tip,
    tipPlacement,
    extendedView,
    dependsOn,
    secondaryValue
  } = field

  const handleChange = (val, secondaryVal) => {
    onChange(name, val, secondaryVal)
  }

  const handleFocus = () => {
    onFocus(name)
  }

  const handleBlur = () => {
    onFocus(undefined)
  }

  const handleTouch = () => {
    onTouch(name)
  }

  const handleValidate = val => {
    onValidate(name, val)
  }

  const commonProps = {
    locale,
    description,
    label,
    context,
    onChange: handleChange,
    touched,
    value,
    disableFormSave
  }

  if (type === 'string' || type === 'number' || type === 'password') {
    return (
      <InputField
        {...commonProps}
        isNumber={type === 'number'}
        type={type === 'password' ? 'password' : 'text'}
        placeholder={placeholder}
        units={units}
        validation={validation}
        valueFetcher={valueFetcher}
        hasFocus={hasFocus}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onTouch={handleTouch}
        maxLength={maxLength}
        autocomplete={autocomplete}
        disabled={disabled}
        tip={tip}
        dependsOn={dependsOn}
      />
    )
  }

  if (type === 'boolean') {
    return <BooleanField
      {...commonProps}
      onTouch={handleTouch}
      tip={tip}
      tipPlacement={tipPlacement}
      optionsFetcher={optionsFetcher}
      errorDescription={errorDescription}
    />
  }

  if (type === 'string mapping') {
    const { placeholderKey, placeholderValue, valueOptions, defaultValueOption, keyInputWidth } = field
    return (
      <StringMapping
        {...commonProps}
        inputStyle={{ height: '51px' }}
        isValid={isValid}
        hasFocus={hasFocus}
        placeholderKey={placeholderKey}
        placeholderValue={placeholderValue}
        onValidate={handleValidate}
        onTouch={handleTouch}
        valueOptions={valueOptions}
        defaultValueOption={defaultValueOption}
        tip={tip}
        keyInputWidth={keyInputWidth}
      />
    )
  }
  if (type === 'variable mapping') {
    const { typeOptions, keyOptions, placeholderKey, placeholderValue } = field
    return (
      <VariableMapping
        {...commonProps}
        inputStyle={{ height: '51px' }}
        isValid={isValid}
        typeOptions={typeOptions}
        keyOptions={keyOptions && [...keyOptions]}
        placeholderKey={placeholderKey}
        placeholderValue={placeholderValue}
        onValidate={handleValidate}
        onTouch={handleTouch}
      />
    )
  }
  if (type === 'group') {
    const { items } = field
    return (
      <Group
        {...commonProps}
        items={items}
        onTouch={handleTouch}
        onFocus={handleFocus}
        name={name}
        disabledFields={disabledFields}
        unavailableFields={unavailableFields}
        onValidate={handleValidate}
        dependsOn={dependsOn}
      />
    )
  }
  if (type === 'group array') {
    const { items, addButtonLabel } = field
    return (
      <GroupArray
        {...commonProps}
        items={items}
        onTouch={handleTouch}
        onFocus={handleFocus}
        name={name}
        addButtonLabel={addButtonLabel}
        onValidate={handleValidate}
        tip={tip}
        dependsOn={dependsOn}
      />
    )
  }
  if (type === 'string array') {
    return (
      <StringArray
        {...commonProps}
        inputStyle={{ height: '51px' }}
        placeholder={placeholder}
        limit={limit}
        onTouch={handleTouch}
        validation={validation}
        tip={tip}
      />
    )
  }
  if (type === 'variable') {
    const { typeOptions } = field
    return (
      <Variable
        {...commonProps}
        typeOptions={typeOptions}
        placeholder={placeholder}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onTouch={handleTouch}
        hasFocus={hasFocus}
        validation={validation}
        theme={THEME_FORM_ITEM}
      />
    )
  }
  if (type === 'select') {
    const { options } = field
    return <SelectBox
      {...commonProps}
      theme={THEME_FORM_ITEM}
      options={options}
      onTouch={handleTouch}
      placeholder={placeholder}
      tip={tip}
      disabled={disabled}
    />
  }
  if (type === 'text') {
    return <InputText
      {...commonProps}
      onTouch={handleTouch}
      hasFocus={hasFocus}
      onFocus={handleFocus}
      onBlur={handleBlur}
      validation={validation}
    />
  }
  if (type === 'javascript') {
    return (
      <AceEditor
        {...commonProps}
        validation={validation}
        onValidate={handleValidate}
        isValid={isValid}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onTouch={handleTouch}
        hasFocus={hasFocus}
        height='240px'
        formOffsets={DEFAULT_EDITOR_FORM_OFFSET}
      />
    )
  }
  if (type === 'html') {
    return (
      <AceEditor
        {...commonProps}
        validation={validation}
        onValidate={handleValidate}
        isValid={isValid}
        onFocus={handleFocus}
        onBlur={handleBlur}
        onTouch={handleTouch}
        hasFocus={hasFocus}
        height='240px'
        formOffsets={DEFAULT_EDITOR_FORM_OFFSET}
        mode='html'
        disabled={disabled}
        wrapEnabled
      />
    )
  }
  if (type === 'oauth2 button') {
    return <OAuth2Button {...commonProps} icon={icon} />
  }
  if (type === 'digital data conditions') {
    return <DigitalDataConditions {...commonProps} />
  }
  if (type === 'google drive file picker') {
    return <GoogleDriveFilePicker {...commonProps} />
  }
  if (type === 'dynamic select') {
    return <DynamicSelectBox
      {...commonProps}
      name={name}
      theme={THEME_FORM_ITEM}
      optionsFetcher={optionsFetcher}
      dependsOn={dependsOn}
      disabled={disabled}
      onTouch={handleTouch}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tip={tip}
      placeholder={placeholder}
      hasFocus={hasFocus}
      validation={validation}
      extendedView={extendedView}
      secondaryValue={secondaryValue}
    />
  }
  if (type === 'dynamic select array') {
    const { addButtonLabel, uniqueValues, searchable } = field
    return <DynamicSelectArray
      {...commonProps}
      name={name}
      theme={THEME_FORM_ITEM}
      optionsFetcher={optionsFetcher}
      dependsOn={dependsOn}
      disabled={disabled}
      onTouch={handleTouch}
      onFocus={handleFocus}
      onBlur={handleBlur}
      tip={tip}
      placeholder={placeholder}
      hasFocus={hasFocus}
      validation={validation}
      extendedView={extendedView}
      limit={limit}
      addButtonLabel={addButtonLabel}
      uniqueValues={uniqueValues}
      errorDescription={errorDescription}
      searchable={searchable}
    />
  }
  return null
}

export default FieldGenerator
