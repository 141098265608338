import React, { Component } from 'react'
import FieldGenerator from 'containers/Form/FieldGenerator'
import Space from 'containers/Space'
import { StyledGroup, Label } from './styles'

class Group extends Component {
  constructor (props) {
    super(props)
    this.handleUpdateField = this.handleUpdateField.bind(this)
    this.handleTouch = this.handleTouch.bind(this)
    this.handleFocus = this.handleFocus.bind(this)
    this.validate = this.validate.bind(this)
  }

  handleUpdateField (name, fieldValue) {
    const { onChange, value } = this.props
    onChange({
      ...value,
      [name]: fieldValue
    })
  }

  handleTouch () {
    const { onTouch } = this.props
    onTouch()
  }

  handleFocus () {
    const { onFocus } = this.props
    onFocus()
  }

  validate (name, fieldValue) {
    const { onValidate, value } = this.props
    onValidate({
      ...value,
      [name]: fieldValue
    })
  }

  render () {
    const { locale, items, label, disabledFields, unavailableFields, touched, context, dependsOn = [] } = this.props

    const dependencies = dependsOn.map(dependency => ({
      [dependency]: context.dependentFieldValues[dependency]
    }))

    const fields = items.map((field, index) => {
      const { value } = this.props
      const hidden = disabledFields && disabledFields.includes(field.name)
      const unavailableForPricingPlan = unavailableFields && unavailableFields.includes(field.name)
      if (hidden || unavailableForPricingPlan) {
        return null
      }

      const fieldValue = value ? value[field.name] : undefined
      return (
        <div key={field.name}>
          {index !== 0 && <Space />}
          <FieldGenerator
            locale={locale}
            field={field}
            value={fieldValue}
            onChange={this.handleUpdateField}
            onFocus={this.handleFocus}
            onTouch={this.handleTouch}
            onValidate={this.validate}
            touched={touched}
            context={context}
            dependsOn={dependsOn}
            dependencies={dependencies}
          />
        </div>
      )
    })
    return (
      <div>
        {
          label &&
          <Label>
            <label>{label}</label>
          </Label>
        }
        <StyledGroup>{fields}</StyledGroup>
      </div>
    )
  }
}

export default Group
