import React from 'react'

import ModalWindow from 'containers/ModalWindow'
import { dataStreamConfigurations } from 'dataStreams'

import { ItemContainer } from './styles'
import { DataStreamItem } from './DataStreamItem'
import { DataStreamForm } from '../DataStreamForm'
import { Spinner } from '../../../../../../../uiKit'

export const CreateDataStreamSide = ({
  isLoading,
  isOpen,
  close,
  selectedDataStreamType,
  createDataStream,
  selectDataStreamType,
  trackingBaseUri
}) => {
  const handleSelect = (dataStream) => {
    selectDataStreamType(dataStream)
  }

  const handleCreate = (dataStreamOptions) => {
    createDataStream(selectedDataStreamType, dataStreamOptions)
  }

  const step = () => {
    if (isLoading) {
      return <Spinner />
    }

    if (selectedDataStreamType) {
      return (
        <DataStreamForm
          trackingBaseUri={trackingBaseUri}
          loading={isLoading}
          dataStreamConfiguration={selectedDataStreamType}
          onSubmit={handleCreate}
        />
      )
    }

    return (
      <ItemContainer>
        {dataStreamConfigurations.map(config => (
          <DataStreamItem
            key={config.type}
            dataStream={config}
            onClick={() => handleSelect(config)}
          />
        ))}
      </ItemContainer>
    )
  }

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={close}
      header={'Add Data Stream'}
    >
      { step() }
    </ModalWindow>
  )
}
