import { apiCall } from './apiCall'
import {
  notDeprecated,
  getType,
  handleTemplatizedIds
} from 'utils/gaMetadataHelpers'

export const update = (projectId, id, dataSource) => ({
  url: `/projects/${projectId}/data_sources/${id}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(dataSource)
})

export const create = (projectId, dataSource) => ({
  url: `/projects/${projectId}/data_sources`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(dataSource)
})

export const remove = (projectId, id) => ({
  url: `/projects/${projectId}/data_sources/${id}`,
  method: 'DELETE'
})

export const removeAuth = (projectId, id) => ({
  url: `/projects/${projectId}/data_sources/${id}/auth_options`,
  method: 'DELETE'
})

export const enable = (projectId, id) => ({
  url: `/projects/${projectId}/data_sources/${id}/enable`,
  method: 'POST'
})

export const disable = (projectId, id) => ({
  url: `/projects/${projectId}/data_sources/${id}/disable`,
  method: 'POST'
})

export const fetchFacebookAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchClientLogins = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/clientLogin/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchYandexShopNames = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/shopName/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchVKAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchVKClients = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/clientId/values?accountId=${context.dependentFieldValues.accountId}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAdsCustomers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/customerId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

let gaMetadataPromise

export const fetchGoogleAnalyticsMetadata = context => {
  window.localStorage.removeItem('gaMetadata')

  if (gaMetadataPromise) {
    return gaMetadataPromise
  }

  gaMetadataPromise = new Promise(async (resolve, reject) => {
    const gaMetadataKey = 'gaMetadataBlob'

    let gaMetadata = {}
    try {
      gaMetadata = JSON.parse(window.localStorage.getItem(gaMetadataKey)) || {}
    } catch (e) {
      console.error(e)
    }

    const resp = await apiCall({
      url: `/projects/${context.projectId}/data_sources/${context.id}/options/metadata/values?etag=${(gaMetadata.etag || '')}`,
      method: 'GET'
    })

    if (resp.status !== 200) {
      reject(resp.statusText)
      return
    }

    const respText = await resp.text()
    const respData = JSON.parse(respText)

    if (respData.totalResults) {
      gaMetadata = respData
      window.localStorage.setItem(gaMetadataKey, respText)
    }

    resolve(gaMetadata)
  })

  return gaMetadataPromise
}

export const fetchGoogleAnalyticsDimensions = async (context) => {
  const defaultMetadataItems = ['ga:clientId']
  const metadata = await fetchGoogleAnalyticsMetadata(context)
  const metadataItems = metadata.items
    .filter(i => getType(i, 'DIMENSION') && notDeprecated(i))
    .reduce((acc, i) => handleTemplatizedIds(acc, i), [])
    .map(i => i.id)
  return [
    ...metadataItems,
    ...defaultMetadataItems
  ]
}

export const fetchGoogleAnalyticsMetrics = async (context) => {
  const metadata = await fetchGoogleAnalyticsMetadata(context)
  return metadata.items
    .filter(i => getType(i, 'METRIC') && notDeprecated(i))
    .reduce((acc, i) => handleTemplatizedIds(acc, i), [])
    .map(i => i.id)
}

export const checkAuthOptions = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/checkAuthOptions`,
  method: 'GET'
}).then(resp => resp.text())
  .then(resp => JSON.parse(resp))
  .catch(e => console.error(e))

export const fetchGoogleAnalyticsAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/account/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAnalyticsProperties = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/property/values?account=${context.dependentFieldValues.account}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAnalyticsViews = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/view/values?account=${context.dependentFieldValues.account}&property=${context.dependentFieldValues.property}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchMicrosoftAdvertisingCustomers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/customerId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchMicrosoftAdvertisingAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/account/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchFivetranTables = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/table/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleCampaignManagerProfiles = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/profile/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchGoogleCampaignManagerAdvertisers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/advertiser/values?profileId=${context.dependentFieldValues.profileId}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchGoogleCampaignManagerSites = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/site/values?profileId=${context.dependentFieldValues.profileId}&advertiserId=${context.dependentFieldValues.advertiserId}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchDV360Partners = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/partner/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchDV360Advertisers = (context) => {
  if (!context.dependentFieldValues || !context.dependentFieldValues.partnerId) {
    return Promise.resolve([])
  }

  return apiCall({
    url: `/projects/${context.projectId}/data_sources/${context.id}/options/advertiser/values?partnerId=${context.dependentFieldValues.partnerId}`,
    method: 'GET'
  }).then(resp => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText)
    }
    return resp.json()
  })
}

export const fetchTikTokAdvertisers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/advertiserId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchLinkedInAdAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/adAccountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchQuoraAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchCriteoAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

let fetchCriteoCampaignsPromise
let isFetchCriteoCampaignsPromisePending = false
export const fetchCriteoCampaigns = (context) => {
  if (!context.dependentFieldValues || !context.dependentFieldValues.accountIds || context.dependentFieldValues.accountIds.length === 0) {
    return Promise.resolve([])
  }

  if (fetchCriteoCampaignsPromise !== undefined && isFetchCriteoCampaignsPromisePending) {
    return fetchCriteoCampaignsPromise
  }

  fetchCriteoCampaignsPromise = new Promise((resolve, reject) => {
    isFetchCriteoCampaignsPromisePending = true

    apiCall({
      url: `/projects/${context.projectId}/data_sources/${context.id}/options/campaignId/values?accountIds=${context.dependentFieldValues.accountIds}`,
      method: 'GET'
    }).then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.statusText)
      }
      return resolve(resp.json())
    }).catch(e => {
      return reject(e)
    }).finally(() => { isFetchCriteoCampaignsPromisePending = false })
  })

  return fetchCriteoCampaignsPromise
}

export const fetchTwitterAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchSnapchatOrganizations = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/organizationId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchSnapchatAdAccounts = (context) => {
  if (!context.dependentFieldValues.organizationId) {
    return Promise.resolve([])
  }

  return apiCall({
    url: `/projects/${context.projectId}/data_sources/${context.id}/options/adAccountId/values?organizationId=${context.dependentFieldValues.organizationId}`,
    method: 'GET'
  }).then(resp => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText)
    }
    return resp.json()
  })
}

export const fetchAwinAccounts = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/accountId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchAdRollAdvertisers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/advertiserId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchGoogleAds2Reports = (context) => {
  function createFilterAlreadyUsedReports () {
    if (!context.formData.reports) return () => true

    const alreadyUsed = context.formData.reports.map(r => r.report)
    return (apiResponseReportDatum) => !alreadyUsed.includes(apiResponseReportDatum.value)
  }

  return apiCall({
    url: `/projects/${context.projectId}/data_sources/${context.id}/options/reports/values`,
    method: 'GET'
  }).then(resp => {
    if (resp.status !== 200) {
      throw new Error(resp.statusText)
    }
    return resp.json()
  }).then(data => data.filter(createFilterAlreadyUsedReports()))
}

export const fetchGoogleAds2Customers = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/customerId/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchRtbHouseCampaigns = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/campaign/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.json()
})

export const fetchGoogleAnalytics360BQProjects = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/bqProjects/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAnalytics4BQProjects = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/bqProjects/values`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAnalytics360BQDatasets = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/bqDatasets/values?projectId=${context.dependentFieldValues.projectId}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

export const fetchGoogleAnalytics4BQDatasets = (context) => apiCall({
  url: `/projects/${context.projectId}/data_sources/${context.id}/options/bqDatasets/values?projectId=${context.dependentFieldValues.projectId}`,
  method: 'GET'
}).then(resp => {
  if (resp.status !== 200) {
    throw new Error(resp.statusText)
  }
  return resp.text()
}).then(resp => JSON.parse(resp))

let fetchMyTargetPulseCampaignsPromise
let isFetchMyTargetCampaignsPromisePending = false
export const fetchMyTargetPulseCampaignsCampaigns = (context) => {
  if (fetchMyTargetPulseCampaignsPromise !== undefined && isFetchMyTargetCampaignsPromisePending) {
    return fetchMyTargetPulseCampaignsPromise
  }

  fetchMyTargetPulseCampaignsPromise = new Promise((resolve, reject) => {
    isFetchMyTargetCampaignsPromisePending = true

    apiCall({
      url: `/projects/${context.projectId}/data_sources/${context.id}/options/campaignId/values`,
      method: 'GET'
    }).then(resp => {
      if (resp.status !== 200) {
        throw new Error(resp.statusText)
      }
      return resolve(resp.json())
    }).catch(e => {
      return reject(e)
    }).finally(() => { isFetchMyTargetCampaignsPromisePending = false })
  })

  return fetchMyTargetPulseCampaignsPromise
}
