import styled from 'styled-components'

export const FormWrapper = styled.div`
  position: absolute;
  width: 422px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

export const HeaderWrapper = styled.div`
  text-align: center;
  margin: 15px 0;
`
export const Header = styled.div`
  display: inline-flex;
  margin-top: 5px;
`
