import React, { Component } from 'react'
import { connect } from 'react-redux'
import AuthField from 'containers/Form/AuthField'
import ErrorMessage from 'containers/ErrorMessage'
import getTranslate from 'utils/getTranslate'
import { resetPassword } from 'redux/modules/user/resetPassword'
import ru from './ru.json'
import { FormWrapper, SegmentStreamLogo, ResetPasswordForm, InputLabel, ConfirmButton, Description, SimpleBlueLink } from '../Login/styles'
import { changePage, LOGIN } from 'redux/modules/page/changePage'
import SuccessMessage from 'containers/SuccessMessage/index'

const PENDING = 'pending'
const FAILED = 'failed'
const SUCCESS = 'success'
const PASSWORDS_MISMATCH = 'passwords mismatch'
const FAILED_PASSWORD_LENGTH = 'failed password length'

class ResetPassword extends Component {
  constructor (props) {
    super(props)

    this.state = {
      status: null
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handlePasswordFocus = this.handlePasswordFocus.bind(this)
    this.handlePasswordTouch = this.handlePasswordTouch.bind(this)

    this.handleConfirmPasswordChange = this.handleConfirmPasswordChange.bind(this)
    this.handleConfirmPasswordFocus = this.handleConfirmPasswordFocus.bind(this)
    this.handleConfirmPasswordTouch = this.handleConfirmPasswordTouch.bind(this)

    this.handleLoginPage = this.handleLoginPage.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  async handleSubmit (event) {
    const { onResetPassword, locale } = this.props
    const { password, confirmPassword } = this.state

    const translate = getTranslate(locale, { ru })

    event.preventDefault()
    if (password !== confirmPassword) {
      this.setState({
        status: PASSWORDS_MISMATCH
      })
    } else if ((password || '').length < 6) {
      this.setState({
        status: FAILED_PASSWORD_LENGTH
      })
    } else {
      try {
        this.setState({ status: PENDING })
        const response = await onResetPassword(password)
        if (response === '') {
          this.setState({ status: SUCCESS })
        } else {
          window.alert(translate('The password reset link is no longer valid'))
        }
      } catch (e) {
        this.setState({ status: FAILED })
      }
    }
  }

  handlePasswordChange (value) {
    this.setState({
      password: value
    })
  }

  handlePasswordFocus () {
    this.setState({
      focus: 'password',
      status: null
    })
  }

  handlePasswordTouch () {
    this.setState({ touchedPassword: true })
  }

  handleConfirmPasswordChange (value) {
    this.setState({
      confirmPassword: value
    })
  }

  handleConfirmPasswordFocus () {
    this.setState({
      focus: 'confirmPassword',
      status: null
    })
  }

  handleConfirmPasswordTouch () {
    this.setState({ touchedConfirmPassword: true })
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  handleLoginPage () {
    const { onChangePage } = this.props
    onChangePage(LOGIN)
  }

  render () {
    const {
      password,
      confirmPassword,
      focus,
      touchedPassword,
      touchedConfirmPassword,
      status
    } = this.state
    const { locale } = this.props
    const translate = getTranslate(locale, { ru })
    return (
      <FormWrapper>
        <SegmentStreamLogo />
        <ResetPasswordForm onSubmit={this.handleSubmit}>
          {status === PASSWORDS_MISMATCH && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>{translate('Passwords must match!')}</ErrorMessage>
            </div>
          )}
          {status === FAILED_PASSWORD_LENGTH && (
            <div style={{ textAlign: 'center' }}>
              <ErrorMessage>
                {translate('Password length must be at least 6 characters')}
              </ErrorMessage>
            </div>
          )}
          {status === SUCCESS && (
            <div style={{ textAlign: 'center' }}>
              <SuccessMessage>
                {translate('Password has been changed')} <br />
              </SuccessMessage>
              <SimpleBlueLink onClick={this.handleLoginPage}>
                {translate('Back to Log in')}
              </SimpleBlueLink>
            </div>
          )}
          {status !== SUCCESS && (
            <div>
              <Description>
                {translate('Enter new password')}
              </Description>

              <InputLabel>{translate('Password')}</InputLabel>
              <AuthField
                value={password}
                onChange={this.handlePasswordChange}
                onFocus={this.handlePasswordFocus}
                onTouch={this.handlePasswordTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'password'}
                touched={touchedPassword}
                validation={['required']}
                type='password'
                name='password'
                placeholder={translate('Password')}
                autocomplete='new-password'
              />
              <InputLabel>Confirm&nbsp;password</InputLabel>
              <AuthField
                value={confirmPassword}
                onChange={this.handleConfirmPasswordChange}
                onFocus={this.handleConfirmPasswordFocus}
                onTouch={this.handleConfirmPasswordTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'confirmPassword'}
                touched={touchedConfirmPassword}
                validation={['required']}
                type='password'
                name='confirmPassword'
                placeholder={translate('Confirm password')}
                autocomplete='new-password'
              />
              <ConfirmButton type='submit' pending={status === PENDING}>
                {translate('Reset password')}
              </ConfirmButton>
            </div>
          )}
        </ResetPasswordForm>
      </FormWrapper>
    )
  }
}

export default connect(
  state => ({ locale: state.locale }),
  {
    onResetPassword: resetPassword,
    onChangePage: changePage
  }
)(ResetPassword)
