import reduceReducers from 'reduce-reducers'
import changeStatusReducer, { changeStatus } from './changeStatus'
import changeAuthReducer, { removeAuth, setAuthStatus } from './changeAuth'
import fetchIntegrationsReducer, { fetchIntegrations } from './fetchIntegrations'
import saveEventEnrichmentsReducer, { saveEventEnrichments } from './saveEventEnrichments'
import saveIntegrationToIntegrationsReducer, {
  saveIntegrationToIntegrations
} from './saveIntegrationToIntegrations'
import saveOverrideFunctionsReducer, { saveOverrideFunctions } from './saveOverrideFunctions'
import authCheckErrorReducer, { setAuthCheckError } from './authCheckError'

export {
  changeStatus,
  removeAuth,
  setAuthStatus,
  fetchIntegrations,
  saveEventEnrichments,
  saveIntegrationToIntegrations,
  saveOverrideFunctions,
  setAuthCheckError
}

export default reduceReducers(
  changeStatusReducer,
  changeAuthReducer,
  fetchIntegrationsReducer,
  saveEventEnrichmentsReducer,
  saveIntegrationToIntegrationsReducer,
  saveOverrideFunctionsReducer,
  authCheckErrorReducer
)
