import path from './path'
import { saveScripts as apiSaveScripts } from 'api/project'
import { success, pending, error } from 'redux/modules/proMode/modalWindow'

const SAVE_SCRIPTS = `saveScripts@${path}`

export const saveScripts = (scripts, touchedFields) => async (dispatch, getState) => {
  if (touchedFields) {
    dispatch(pending())
    try {
      const projectId = getState().project.id
      await dispatch(apiSaveScripts(projectId, scripts))
      dispatch({
        type: SAVE_SCRIPTS,
        scripts
      })
      dispatch(success())
    } catch (err) {
      dispatch(error())
    }
  }
}

export default (state = {}, action) =>
  action.type === SAVE_SCRIPTS
    ? {
      ...state,
      scripts: action.scripts,
      isPublished: false
    }
    : state
