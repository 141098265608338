import React, { useCallback } from 'react'

import ModalWindow from 'containers/ModalWindow'
import DocsLink from 'containers/DocsLink'
import StatusBox from 'containers/StatusBox'
import { getDataStreamConfiguration } from 'dataStreams'

import { DataStreamForm } from './DataStreamForm'

export function UpdateDataStreamSide ({
  trackingBaseUri,
  isLoading,
  isOpen,
  selectedDataStream,
  snippet,

  close,
  updateDataStream,
  deleteDataStream
}) {
  const handleChangeStatus = useCallback(
    // don't care if selectedDataStream is null, it will be called only if exists
    () => updateDataStream({ ...selectedDataStream, isEnabled: !selectedDataStream.isEnabled }), [selectedDataStream, updateDataStream]
  )

  if (!selectedDataStream) {
    return null
  }

  const statusBox = (
    <StatusBox
      locale={'en'}
      isEnabled={selectedDataStream.isEnabled}
      onChange={handleChangeStatus}
      disableMessage={`Are you sure that you want to disable ${selectedDataStream.displayName}?`}
    />
  )

  const config = getDataStreamConfiguration(selectedDataStream.type)
  const docsLink = <DocsLink url={config.docsUrl} />

  const content = () => (
    <DataStreamForm
      trackingBaseUri={trackingBaseUri}
      onSubmit={updateDataStream}
      deleteMessage={
        <div>
          Are you sure that you want to remove the {selectedDataStream.displayName} stream?
        </div>
      }
      snippet={snippet}
      loading={isLoading}
      dataStreamOptions={selectedDataStream}
      dataStreamConfiguration={config}
      onRemove={deleteDataStream}
    />
  )

  return (
    <ModalWindow
      isOpen={isOpen}
      onClose={close}
      header={selectedDataStream.type}
      docsLink={docsLink}
      statusBox={statusBox}
    >
      { content() }
    </ModalWindow>
  )
}
