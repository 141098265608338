import React from 'react'
import { connect } from 'react-redux'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import NameCell from 'containers/NameCell'
import Status from 'containers/Status'
import Table from 'containers/Table'
import { open } from 'redux/modules/page/integrations/integrationForm/eventEnrichments'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'

const List = ({ locale, onOpen, list = [] }) => {
  const translate = getTranslate(locale, { ru })
  const handleOpen = () => {
    onOpen()
  }
  const getScopeLabel = item => {
    if (item.scope === 'product') {
      return translate('Product')
    }
    return item.event ? `${translate('Event')}: ${item.event}` : translate('All events')
  }
  const data = list
    .slice(0)
    .sort((itemA, itemB) => {
      const nameA = itemA.prop.toLowerCase()
      const nameB = itemB.prop.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map(item => [
      <Status isEventEnrichment isEnabled={item.isEnabled} />,
      <NameCell name={item.prop} onClick={() => onOpen(item.id)} />,
      getScopeLabel(item)
    ])
  return (
    <div>
      <ConfirmButton onClick={handleOpen}>{translate('Add')}</ConfirmButton>
      <Table
        headers={['', translate('Variable name'), translate('Scope')]}
        isEventEnrichment
        data={data}
      />
    </div>
  )
}

export default connect(
  state => {
    const name = state.page.integrations.integrationForm.name
    const list = state.integrations[name].eventEnrichments
    return {
      locale: state.locale,
      list
    }
  },
  {
    onOpen: open
  }
)(List)
