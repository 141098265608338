import React from 'react'

import { DataStreamsProvider } from '../Context'
import IntegrationSide from '../../Integrations/IntegrationSide'
import { ListContainer } from './ListContainer'
import { CreateContainer } from './CreateContainer'
import { UpdateContainer } from './UpdateContainer'

export function DataStreamsContainer ({ projectId, integrations, onIntegrationOpen, trackingBaseUri }) {
  return (
    <DataStreamsProvider
      trackingBaseUri={trackingBaseUri}
      projectId={projectId}
    >
      <IntegrationSide displayMode={'simple'} />
      <CreateContainer />
      <UpdateContainer />
      <ListContainer
        integrations={integrations}
        onIntegrationOpen={onIntegrationOpen}
      />
    </DataStreamsProvider>
  )
}
