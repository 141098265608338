import React, { useEffect, useState } from 'react'

import * as Yup from 'yup'
import { Formik, Field } from 'formik'
import styled from 'styled-components'

import { StyledInput } from 'containers/Form/Input/styles'
import Button from 'containers/Form/Button/Button'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'

import RadioButtonGroup from 'containers/Form/RadioButtonGroup'
import { HorizontalContainer, Section, SectionLabel } from './styles'

import { FormikFieldContainer } from 'uiKit/formik'
import { FormikSearchableSelect } from 'uiKit/formik/formikSearchableSelect'
import countriesList from 'containers/App/SignUp/countries'

import { BUSINESS, INDIVIDUAL } from '../legalEntities'
import { fetchCountryCode } from 'api/utils'
import { shouldValidateTaxIdForCountry, validateVatId } from '../billingAccount'
import { normalizeTaxId } from '../../../../../../utils/normalizeTaxId'
import { FlexContainer } from '../../ProjectBilling/styles'
import Tooltip from '../../../../../Tooltip'
import cleanObject from '../../../../../../utils/cleanObject'
import { THEME_BILLING_FIELD } from 'containers/Form/SearchableSelect/styles'

const FormContainer = styled.div`
  flex: 1;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 25px;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 7px;
  }
`

export function BillingCheckoutForm ({ billingInfo, onSubmit, onCancel }) {
  const [countryCode, setCountryCode] = useState('')
  const [taxIdValidation, setTaxIdValidation] = useState({
    inProgress: false,
    error: null,
    success: null
  })

  useEffect(() => {
    fetchCountryCode()
      .then(countryCode => {
        setCountryCode(countryCode)
      })
  })

  const handleValidateTaxId = (billingInfo) => {
    setTaxIdValidation({ ...taxIdValidation, inProgress: true })

    const normalizedTaxId = normalizeTaxId(billingInfo.taxId || '', billingInfo.countryCode)

    validateVatId(normalizedTaxId, billingInfo.countryCode)
      .then(result => {
        if (result.valid) {
          setTaxIdValidation({
            success: `VAT/GST ${normalizedTaxId} is valid and applied.`,
            error: null,
            inProgress: false
          })
        } else {
          setTaxIdValidation({
            success: null,
            error: 'Invalid VAT/GST number. Default VAT/GST rate will be used.',
            inProgress: false
          })
        }
      })
      .catch(e => {
        setTaxIdValidation({
          success: null,
          error: 'Failed to validate VAT/GST number',
          inProgress: false
        })
      })
  }

  const initialValues = {
    legalEntity: BUSINESS.value,
    companyEmail: '',
    companyName: '',
    firstName: '',
    lastName: '',
    countryCode: countryCode,
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    phone: '',
    city: '',
    taxId: '',
    ...cleanObject(billingInfo || {})
  }

  const validationSchema = Yup.object().shape({
    legalEntity: Yup.string().required(),

    companyName: Yup.string()
      .when('legalEntity', {
        is: BUSINESS.value,
        then: Yup.string().required('Company name is required')
      }),

    firstName: Yup.string()
      .when('legalEntity', {
        is: INDIVIDUAL.value,
        then: Yup.string().required('First name is required')
      }),

    lastName: Yup.string()
      .when('legalEntity', {
        is: INDIVIDUAL.value,
        then: Yup.string().required('Last name is required')
      }),

    countryCode: Yup.string()
      .required('Country is required'),

    addressLine1: Yup
      .string()
      .required('Address Line 1 is required'),

    companyEmail: Yup
      .string()
      .email()
      .required('Email is required'),

    postalCode: Yup
      .string()
      .required('ZIP / Postal code is required'),

    city: Yup
      .string()
      .required('City is required'),

    phone: Yup
      .string()
      .required('Phone is required')
  })

  const onFormSubmit = async (values) => {
    await onSubmit(values)
  }

  return (
    <Formik
      initialValues={initialValues}
      enableReinitialize
      onSubmit={onFormSubmit}
      validationSchema={validationSchema}
    >
      { props => {
        const {
          values,
          isSubmitting,
          handleSubmit,
          setFieldValue
        } = props
        return (
          <form onSubmit={handleSubmit}>
            <FormContainer style={{ marginTop: '25px' }}>
              <Field>
                {({ form: { handleChange } }) => (
                  <RadioButtonGroup
                    name='legalEntity'
                    options={[BUSINESS, INDIVIDUAL]}
                    value={values.legalEntity}
                    theme={'horizontal'}
                    onChange={value =>
                      handleChange({ target: { name: 'legalEntity', value } })
                    }
                  />
                )}
              </Field>

              { values.legalEntity === BUSINESS.value ? (
                <div>
                  <SectionLabel>Business Details</SectionLabel>
                  <Section>
                    <FormContainer>
                      <FormikFieldContainer label='Company Name' name='companyName'>
                        <StyledInput placeholder='Enter your company name' type='text' />
                      </FormikFieldContainer>

                      <FormikFieldContainer
                        name='companyEmail'
                        label='Billing Email'
                        tip='Email for sending invoices and other information related to payments'
                      >
                        <StyledInput placeholder='Billing email' type='text' />
                      </FormikFieldContainer>
                    </FormContainer>
                  </Section>
                </div>
              ) : null }

              { values.legalEntity === INDIVIDUAL.value ? (
                <div>
                  <SectionLabel>Personal Details</SectionLabel>
                  <Section>
                    <FormContainer>
                      <HorizontalContainer>
                        <FormikFieldContainer label='First name' name='firstName'>
                          <StyledInput placeholder='First name' type='text' />
                        </FormikFieldContainer>

                        <FormikFieldContainer label='Last name' name='lastName'>
                          <StyledInput placeholder='Last name' type='text' />
                        </FormikFieldContainer>
                      </HorizontalContainer>

                      <FormikFieldContainer
                        name='companyEmail'
                        label='Email'
                        tip='Email for sending invoices and other information related to payments'
                      >
                        <StyledInput placeholder='Email' type='text' />
                      </FormikFieldContainer>
                    </FormContainer>
                  </Section>
                </div>
              ) : null }

              <div>
                <SectionLabel>Billing Address</SectionLabel>
                <Section>
                  <FormContainer>
                    <FormikFieldContainer
                      name='countryCode'
                      label='Billing Country'
                      tip={
                        <div>
                          Important! Please, define a billing country. It is required to define billing<br />
                          currency, calculate sales tax and send proper invoices for your accounting<br />
                          once you decide to buy a subscription after the free trial
                        </div>
                      }
                    >
                      <FormikSearchableSelect
                        placeholder='Billing country'
                        options={countriesList.map(v => ({ label: v.name, value: v.alpha2 }))}
                        theme={THEME_BILLING_FIELD}
                      />
                    </FormikFieldContainer>

                    <FormikFieldContainer name='addressLine1' label='Address'>
                      <StyledInput placeholder='Address Line 1' />
                    </FormikFieldContainer>

                    <FormikFieldContainer name='addressLine2'>
                      <StyledInput placeholder='Address Line 2' />
                    </FormikFieldContainer>

                    <HorizontalContainer>
                      <FormikFieldContainer name='city' label='City or town'>
                        <StyledInput placeholder='City or town' />
                      </FormikFieldContainer>

                      <FormikFieldContainer name='postalCode' label='ZIP / Postal code' >
                        <StyledInput placeholder='ZIP / Postal code' />
                      </FormikFieldContainer>

                      <FormikFieldContainer name='phone' label='Phone'>
                        <StyledInput placeholder='Phone' />
                      </FormikFieldContainer>
                    </HorizontalContainer>
                  </FormContainer>
                </Section>
              </div>

              { values.legalEntity === BUSINESS.value ? (
                <div>
                  <SectionLabel>Tax Details</SectionLabel>
                  <Section>
                    <SectionLabel>
                      <label>VAT/GST number</label>
                      <Tooltip tip='If you’re based in the European Union, we need your value-added<br>tax ID for accounting purposes and proper tax calculation.' place='right' />
                    </SectionLabel>
                    <FlexContainer style={{ alignItems: 'flex-start' }}>
                      <FormikFieldContainer
                        name='taxId'
                        error={taxIdValidation.error}
                        success={taxIdValidation.success}
                        style={{ width: '98%' }}
                        tip={
                          <div>
                            If you’re based in the European Union, we need your value-added<br />
                            tax ID for accounting purposes and proper tax calculation.
                          </div>
                        }
                      >
                        <StyledInput
                          style={{ width: '98%' }}
                          placeholder='VAT / GST number'
                        />
                      </FormikFieldContainer>

                      {
                        shouldValidateTaxIdForCountry(values.countryCode) ? (
                          <ConfirmButton
                            disabled={taxIdValidation.inProgress || !values.taxId}
                            pending={taxIdValidation.inProgress}
                            style={{ background: 'none', color: '#6d8df2', border: '1px solid #6d8df2', height: '60px' }}
                            onClick={() => {
                              const countryCode = values.countryCode || ''
                              const taxId = values.taxId || ''
                              const normalizedTaxId = normalizeTaxId(taxId, countryCode)
                              setFieldValue('taxId', normalizedTaxId, false)
                              handleValidateTaxId(values)
                            }}
                          >
                            Validate
                          </ConfirmButton>
                        ) : null
                      }

                    </FlexContainer>
                  </Section>
                </div>
              ) : null }

              <ButtonsContainer>
                <ConfirmButton
                  type='submit'
                  disabled={isSubmitting}
                >
                  Proceed to checkout
                </ConfirmButton>
                <Button
                  style={{ background: 'none', color: '#6d8df2', width: '260px' }}
                  onClick={() => { onCancel() }}
                >
                  Return to pricing plan selection
                </Button>
              </ButtonsContainer>
            </FormContainer>
          </form>
        )
      }}
    </Formik>
  )
}
