import React from 'react'
import getValidationError from 'containers/Form/getValidationError'
import Input from 'containers/Form/Input'
import FieldError from 'containers/Form/FieldError'

const THEME_FORM_FIELD = 'form_field'

const AuthField = ({
  locale,
  disabled,
  hasFocus,
  isNumber,
  label,
  name,
  onBlur,
  onChange,
  onFocus,
  onTouch,
  placeholder,
  style,
  touched,
  type = 'text',
  validation,
  value = '',
  autocomplete = undefined,
  theme
}) => {
  const error =
    touched && !disabled && !hasFocus && validation
      ? getValidationError(validation, value, locale)
      : undefined

  const componentStyle = (theme === THEME_FORM_FIELD)
    ? { width: '100%' }
    : { marginBottom: '17px', fontSize: '16px', lineHeight: '20px' }

  return (
    <div style={{ ...style, ...componentStyle }}>
      {label && <label>{label}</label>}
      <Input
        isNumber={isNumber}
        style={{
          height: '56px',
          padding: '19px 0 0 17px',
          color: '#475666'
        }}
        type={type}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onFocus={onFocus}
        onTouch={onTouch}
        onBlur={onBlur}
        hasFocus={hasFocus}
        touched={touched}
        validation={validation}
        autocomplete={autocomplete}
      />
      {error && <FieldError message={error} />}
    </div>
  )
}

export default AuthField
