import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchGoogleAdsCustomers, fetchGoogleAdsConversions } from 'api/dataDestination'

const config = {
  type: 'Google Ads',
  color: '#1c84c6',
  logo,
  docsUrl: 'https://docs.segmentstream.com/guides/google-ads-marketing-automation',
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Ads',
      validation: ['required']
    },
    {
      label: 'Advertising account',
      type: 'dynamic select',
      name: 'customerId',
      validation: ['required'],
      enable: ['conversionAction'],
      placeholder: 'Select advertising account',
      optionsFetcher: fetchGoogleAdsCustomers,
      description: 'No advertising accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/google-ads/answer/6366720?hl=en-GB" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      extendedView: true,
      secondaryValue: 'loginCustomerId'
    },
    {
      label: 'Conversion',
      type: 'dynamic select',
      name: 'conversionAction',
      validation: ['required'],
      placeholder: 'Select conversion',
      optionsFetcher: fetchGoogleAdsConversions,
      dependsOn: ['customerId'],
      description: 'There were no conversions found for your Google Ads account or access was not granted.',
      extendedView: true
    },
    {
      label: 'BigQuery source table name',
      type: 'string',
      name: 'sourceTable',
      defaultValue: 'googleAdsMLConversionsExport',
      validation: ['required']
    },
    {
      label: 'Schedule',
      type: 'select',
      name: 'schedule',
      defaultValue: 'daily',
      options: [
        {
          label: 'daily',
          value: 'daily'
        },
        {
          label: 'hourly',
          value: 'hourly'
        }
      ],
      validation: ['required']
    }
  ]
}

export default config
