import React from 'react'
import { connect } from 'react-redux'
import AuthField from 'containers/Form/AuthField'
import TextAreaField from 'containers/Form/TextAreaField'
import { InputLabel, ConfirmButton } from '../../../Login/styles'
import { Icon, RequestForm, FeatureRequestIcon, FeatureRequestHeader } from './styles'
import getValidationError from 'containers/Form/getValidationError'
import { sendRequest } from 'redux/modules/feedback'
import IntegrationLogo from 'containers/IntegrationLogo'

const PENDING = 'pending'
const FAILED = 'failed'
const SUCCESS = 'success'

class SendRequestForm extends React.PureComponent {
  constructor (props) {
    super(props)
    this.interval = null

    this.state = {
      remainSeconds: 3,
      step: 1,
      status: null,
      email: props.userEmail,
      name: props.userName
    }

    this.onSendRequest = this.onSendRequest.bind(this)
    this.handleNameChange = this.handleNameChange.bind(this)
    this.handleNameFocus = this.handleNameFocus.bind(this)
    this.handleNameTouch = this.handleNameTouch.bind(this)
    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleEmailFocus = this.handleEmailFocus.bind(this)
    this.handleEmailTouch = this.handleEmailTouch.bind(this)
    this.handleFeatureNameChange = this.handleFeatureNameChange.bind(this)
    this.handleFeatureNameFocus = this.handleFeatureNameFocus.bind(this)
    this.handleFeatureNameTouch = this.handleFeatureNameTouch.bind(this)
    this.handleFeatureDescriptionChange = this.handleFeatureDescriptionChange.bind(this)
    this.handleFeatureDescriptionFocus = this.handleFeatureDescriptionFocus.bind(this)
    this.handleFeatureDescriptionTouch = this.handleFeatureDescriptionTouch.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
  }

  componentWillUnmount () {
    if (this.interval) clearInterval(this.interval)
  }

  async onSendRequest (event) {
    const { onClose, feature, locale, projectId, onSendRequest, featureRequest, requestedDataSource } = this.props
    const { email, name, featureName, featureDescription } = this.state

    let error = false

    event.preventDefault()

    // Email is required
    if (getValidationError(['email'], email, locale)) {
      this.setState({ touchedEmail: true })
      error = true
    }

    // Name is required
    if (!name) {
      this.setState({ touchedName: true })
      error = true
    }

    // Feature description is required for feature request forms
    if (featureRequest && !requestedDataSource && !featureName) {
      this.setState({ touchedFeatureName: true })
      error = true
    }

    // If has error
    if (error) return

    try {
      this.setState({ status: PENDING })

      const payload = {
        feature,
        name,
        email,
        projectId,
        featureRequest,
        featureName: featureName,
        featureDescription,
        predefinedDataSource: requestedDataSource && requestedDataSource.type
      }

      // Send request!
      await onSendRequest(payload)

      // Close after 3 seconds
      this.interval = setInterval(() => {
        const { remainSeconds } = this.state

        this.setState({ remainSeconds: remainSeconds - 1 })

        if (this.state.remainSeconds < 1) {
          clearInterval(this.interval)
          onClose()
        }
      }, 1000)

      this.setState({ status: SUCCESS, email: '', touchedEmail: false, name: '', touchedName: false, step: 2 })
    } catch (e) {
      this.setState({ status: FAILED })
    }
  }

  handleNameChange (value) {
    this.setState({
      name: value
    })
  }

  handleNameFocus () {
    this.setState({
      focus: 'name',
      status: null
    })
  }

  handleNameTouch () {
    this.setState({ touchedName: true })
  }

  handleEmailChange (value) {
    this.setState({
      email: value
    })
  }

  handleEmailFocus () {
    this.setState({
      focus: 'email',
      status: null
    })
  }

  handleEmailTouch () {
    this.setState({ touchedEmail: true })
  }

  handleFeatureNameChange (value) {
    this.setState({
      featureName: value
    })
  }

  handleFeatureNameFocus () {
    this.setState({
      focus: 'featureName',
      status: null
    })
  }

  handleFeatureNameTouch () {
    this.setState({ touchedFeatureName: true })
  }

  handleFeatureDescriptionChange (value) {
    this.setState({
      featureDescription: value
    })
  }

  handleFeatureDescriptionFocus () {
    this.setState({
      focus: 'featureDescription',
      status: null
    })
  }

  handleFeatureDescriptionTouch () {
    this.setState({ touchedFeatureDescription: true })
  }

  handleBlur () {
    this.setState({ focus: undefined })
  }

  render () {
    const { status, step, remainSeconds, name, focus, touchedEmail, touchedName, email, featureName, touchedFeatureName, featureDescription, touchedFeatureDescription } = this.state
    const { featureRequest, featureRequestHeader, featureRequestLabel, requestedDataSource } = this.props

    const confirmationStep = (
      <div>
        <Icon />
        <h2>Thanks! We'll get in touch with you shortly!</h2>
        <p>The window will close automatically after {remainSeconds} {remainSeconds > 1 ? 'seconds' : 'second'}</p>
      </div>
    )

    if (!featureRequest) {
      return step === 1 ? (
        <div>
          <Icon />
          <RequestForm onSubmit={this.onSendRequest}>
            <div>
              <InputLabel>Name</InputLabel>
              <AuthField
                value={name}
                onChange={this.handleNameChange}
                onFocus={this.handleNameFocus}
                onTouch={this.handleNameTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'name'}
                touched={touchedName}
                validation={['required']}
                name='name'
                placeholder='Name'
              />

              <InputLabel>Email</InputLabel>
              <AuthField
                value={email}
                onChange={this.handleEmailChange}
                onFocus={this.handleEmailFocus}
                onTouch={this.handleEmailTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'email'}
                touched={touchedEmail}
                validation={['required', 'email']}
                name='email'
                placeholder='Email'
              />

              <ConfirmButton type='submit' pending={status === PENDING}>
                Send request
              </ConfirmButton>
            </div>
          </RequestForm>
        </div>
      ) : confirmationStep
    } else if (requestedDataSource) {
      return step === 1 ? (
        <div>
          <IntegrationLogo logo={requestedDataSource.logo} style={{ marginBottom: '50px' }} />
          <FeatureRequestHeader>
            {`Send a request to enable ${requestedDataSource.type} data source`}
          </FeatureRequestHeader>
          <RequestForm onSubmit={this.onSendRequest}>
            <div>
              <InputLabel>Name*</InputLabel>
              <AuthField
                value={name}
                onChange={this.handleNameChange}
                onFocus={this.handleNameFocus}
                onTouch={this.handleNameTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'name'}
                touched={touchedName}
                validation={['required']}
                name='name'
                placeholder='Name'
              />

              <InputLabel>Email*</InputLabel>
              <AuthField
                value={email}
                onChange={this.handleEmailChange}
                onFocus={this.handleEmailFocus}
                onTouch={this.handleEmailTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'email'}
                touched={touchedEmail}
                validation={['required', 'email']}
                name='email'
                placeholder='Email'
              />

              <InputLabel>Additional&nbsp;comments</InputLabel>
              <TextAreaField
                value={featureDescription}
                onChange={this.handleFeatureDescriptionChange}
                onFocus={this.handleFeatureDescriptionFocus}
                onTouch={this.handleFeatureDescriptionTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'featureDescription'}
                touched={touchedFeatureDescription}
                name='featureDescription'
              />

              <ConfirmButton type='submit' pending={status === PENDING}>
                Send request
              </ConfirmButton>
            </div>
          </RequestForm>
        </div>
      ) : confirmationStep
    } else {
      return step === 1 ? (
        <div>
          <FeatureRequestIcon />
          <FeatureRequestHeader>
            {featureRequestHeader}
          </FeatureRequestHeader>
          <RequestForm onSubmit={this.onSendRequest}>
            <div>
              <InputLabel>Name*</InputLabel>
              <AuthField
                value={name}
                onChange={this.handleNameChange}
                onFocus={this.handleNameFocus}
                onTouch={this.handleNameTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'name'}
                touched={touchedName}
                validation={['required']}
                name='name'
                placeholder='Name'
              />

              <InputLabel>Email*</InputLabel>
              <AuthField
                value={email}
                onChange={this.handleEmailChange}
                onFocus={this.handleEmailFocus}
                onTouch={this.handleEmailTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'email'}
                touched={touchedEmail}
                validation={['required', 'email']}
                name='email'
                placeholder='Email'
              />

              <InputLabel>{featureRequestLabel}*</InputLabel>
              <AuthField
                value={featureName}
                onChange={this.handleFeatureNameChange}
                onFocus={this.handleFeatureNameFocus}
                onTouch={this.handleFeatureNameTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'featureName'}
                touched={touchedFeatureName}
                validation={['required']}
                name='featureName'
              />

              <InputLabel>Message&nbsp;to&nbsp;the&nbsp;team</InputLabel>
              <TextAreaField
                value={featureDescription}
                onChange={this.handleFeatureDescriptionChange}
                onFocus={this.handleFeatureDescriptionFocus}
                onTouch={this.handleFeatureDescriptionTouch}
                onBlur={this.handleBlur}
                hasFocus={focus === 'featureDescription'}
                touched={touchedFeatureDescription}
                name='featureDescription'
              />

              <ConfirmButton type='submit' pending={status === PENDING}>
                Send request
              </ConfirmButton>
            </div>
          </RequestForm>
        </div>
      ) : confirmationStep
    }
  }
}

export default connect(
  state => ({
    locale: state.locale,
    projectId: state.project.id,
    userEmail: state.user.email,
    userName: state.user.name
  }),
  {
    onSendRequest: sendRequest
  }
)(SendRequestForm)
