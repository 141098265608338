import { setCookie } from 'redux-cookie'
import { BILLING_ACCOUNT_ID } from 'redux/modules/auth/constants'
import path from './path'
import { getProjects as apiGetProjects } from 'api/project'
import { fetch as fetchAllProjects } from '../allProjects/fetch'
import { selectProject } from 'redux/modules/project'
import { changePage, CREATE_BILLING_ACCOUNT } from 'redux/modules/page/changePage'
import { setBillingAccountVariable } from 'utils/digitalDataVariables'

const SELECT_BILLING_ACCOUNT = `selectBillingAccount@${path}`

export const selectBillingAccount = (user, billingAccountId) => async dispatch => {
  const { billingAccounts } = user

  const selectedBillingAccount = billingAccounts.find(billingAccount => billingAccount.id === parseInt(billingAccountId))
  const billingAccount = selectedBillingAccount || billingAccounts[0]

  if (billingAccount) {
    setBillingAccountVariable(billingAccount)
    await dispatch(setCookie(BILLING_ACCOUNT_ID, billingAccount.id, { httpOnly: false }))
  }

  const allProjects = await dispatch(apiGetProjects())
  const availableProjects = await dispatch(fetchAllProjects(allProjects, (billingAccount || {}).id))

  if (availableProjects.length) {
    await dispatch(selectProject(availableProjects.map(p => p.id)))
  } else if (!billingAccount) {
    await dispatch(changePage(CREATE_BILLING_ACCOUNT))
  }

  await dispatch({
    type: SELECT_BILLING_ACCOUNT,
    user: {
      ...user,
      billingAccount
    }
  })
}

export default (state = [], action) => (action.type === SELECT_BILLING_ACCOUNT ? action.user : state)
