import reduceReducers from 'reduce-reducers'

import changeStatusReducer from './changeStatus'
import fetchReducer from './fetch'
import saveReducer from './save'
import createReducer, { create } from './create'
import removeReducer, { remove } from './remove'
import changeAuthReducer, { setAuthStatus, saveAuth, removeAuth } from './changeAuth'
import authCheckErrorReducer, { setAuthCheckError } from './authCheckError'

export { setAuthStatus, saveAuth, removeAuth, create, remove, setAuthCheckError }

export default reduceReducers(
  changeStatusReducer,
  fetchReducer,
  saveReducer,
  changeAuthReducer,
  createReducer,
  removeReducer,
  authCheckErrorReducer
)
