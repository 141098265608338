import { create as apiCreate } from 'api/dataDestination'
import { close } from '../page/dataDestinations/createDataDestinationSide'
import { open } from '../page/dataDestinations/dataDestinationsForm'
import path from './path'

const CREATE = `create@${path}`

export const create = dataDestination => async (dispatch, getState) => {
  const projectId = getState().project.id
  const apiResponse = await dispatch(apiCreate(projectId, dataDestination))
  await dispatch({
    type: CREATE,
    dataDestination: apiResponse
  })
  const { id, type } = apiResponse
  dispatch(close())
  dispatch(open(id, type))
}

export default (state = {}, action) =>
  action.type === CREATE
    ? {
      ...state,
      [action.dataDestination.id]: action.dataDestination
    }
    : state
