import React, { Component } from 'react'
import Status from 'containers/Status'
import getTranslate from 'utils/getTranslate'
import ConfirmationWindow from 'containers/ConfirmationWindow'
import { Switch, Wrapper, Box, Switcher, Triangle } from './styles'
import ru from './ru.json'

class StatusBox extends Component {
  constructor (props) {
    super(props)
    this.state = { isOpen: false }
    this.toggleOpen = this.toggleOpen.bind(this)
    this.handleChange = this.handleChange.bind(this)
    this.handleBlur = this.handleBlur.bind(this)
    this.closeConfirmation = this.closeConfirmation.bind(this)
    this.handleDisableConfirmation = this.handleDisableConfirmation.bind(this)
  }

  handleDisableConfirmation () {
    this.props.onChange()
    this.setState({
      isConfirmationOpen: false
    })
  }

  closeConfirmation () {
    this.setState({
      isConfirmationOpen: false
    })
  }

  toggleOpen () {
    this.setState({
      isOpen: !this.state.isOpen
    })
  }

  handleChange () {
    const { isEnabled, onChange } = this.props
    if (isEnabled) {
      this.setState({
        isConfirmationOpen: true
      })
    } else {
      onChange()
    }
  }

  handleBlur () {
    this.setState({
      isOpen: false
    })
  }

  render () {
    const { isOpen, isConfirmationOpen } = this.state
    const { locale, isEnabled, disableMessage } = this.props
    if (isEnabled === undefined) {
      return null
    }
    const translate = getTranslate(locale, { ru })
    return (
      <React.Fragment>
        <Switch tabIndex='0' onClick={this.toggleOpen} onBlur={this.handleBlur}>
          <Box>
            <Wrapper>
              <Status isEnabled={isEnabled} />
            </Wrapper>
            <div>{isEnabled ? translate('Enabled') : translate('Disabled')}</div>
            <Triangle />
          </Box>
          <Switcher open={isOpen} onClick={this.handleChange}>
            {isEnabled ? translate('Disable') : translate('Enable')}
          </Switcher>
        </Switch>
        <ConfirmationWindow
          messageText={disableMessage}
          confirmButtonText={translate('Yes')}
          cancelButtonText={translate('Cancel')}
          isOpen={isConfirmationOpen}
          onClose={this.closeConfirmation}
          onConfirm={this.handleDisableConfirmation}
          dangerZone
        />
      </React.Fragment>
    )
  }
}

export default StatusBox
