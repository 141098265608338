import styled, { css } from 'styled-components'
import greenCheck from './green-check.png'

export const SubscriptionStatusMessage = styled.div`
  display: flex;
  font-size: 18px;
  color: #1D2C4C;
  border-bottom: 1px solid #E1E7ED;
  padding: 0 33px 20px;
  margin: 0 -33px;
`

export const BillingOptions = styled.div`
  padding: 20px 0 40px;
`

export const Label = styled.div`
  font-size: 20px;
  line-height: 20px;
  color: #1D2C4C;
`

export const SubscriptionEmphasis = styled.div`
  font-weight: 600;
  color: #4D6DD3;
  margin: 0 5px;
`

export const Description = styled.div`
  margin-top: 20px;
  color: #000;
`

export const PaymentForm = styled.form`
  padding: 20px 0;
`

export const SectionLabel = styled.div`
  font-size: 16px;
  margin-bottom: 16px;
  color: #93a2b1;
  font-weight: 600;
  display: flex;
`

export const Section = styled.div`
  border: 1px solid;
  border-radius: 8px;
  border-color: #e1e7ed;
  padding: 22px 19px 22px 19px;
`

export const HorizontalContainer = styled.div`
  display: flex;
  margin-right: -20px;
`

export const InitializingMessage = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const Loader = styled.div`
  margin: 20px 0;
  border: 16px solid #f3f3f3;
  border-top: 16px solid #4D6DD3;
  border-radius: 50%;
  width: 120px;
  height: 120px;
  animation: spin 2s linear infinite;
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
`

export const BillingPeriodSwitcher = styled.div`
  border-bottom: 1px solid #E1E7ED;
  display: flex;
  font-weight: 600;
`

export const BillingPeriod = styled.div`
  padding-bottom: 7px;
  cursor: pointer;
  ${props =>
    props.selected &&
  css`
      border-bottom: 2px solid #4D6DD3;
      color: #4D6DD3;
    `}
  &:last-child {
    margin-left: 30px
  }
`

export const PricingPlan = styled.div`
  background: #fff;
  border: 1px solid #E1E7ED;
  border-radius: 8px;
  padding: 18px 21px 20px 22px;
  margin-bottom: 14px;
`

export const PricingPlanLabel = styled.div`
  font-weight: bold;
  font-size: 26px;
  color: #272E40;
  font-family: 'ProxmiaNovaBold';
`

export const FlexContainer = styled.div`
  display: flex;
  justify-content: space-between;
`

export const Price = styled.div`
  margin-right: 10px;
  font-weight: bold;
  font-size: 34px;
  line-height: 41px;
  color: #85BB4A;
  font-family: 'ProxmiaNovaBold';
`

export const Feature = styled.div`
  margin-bottom: 8px;
  font-size: 12px;
  color: #49536F;
  padding-left: 16px;
  background: url(${greenCheck}) no-repeat;
  background-size: 8px 5px;
  background-position: left center;
`

export const EnterprisePrice = styled.div`
  font-size: 16px;
  color: #85BB4A;
`

export const PlanDescription = styled.div`
  font-size: 12px;
  color: #49536F;
`
