import styled, { css } from 'styled-components'

export const Checkbox = styled.div`
  float: right;
  display: inline-block;
  cursor: pointer;
  width: 47px;
  height: 18px;
  border-radius: 100px;
  ${props =>
    props.checked &&
    css`
      background-color: #7192f9;
    `}
  ${props =>
    !props.checked &&
    css`
      background-color: #dfe3ef;
    `}
  transition: all 0.1s;
`

export const Circle = styled.div`
  margin-top: -3px;
  width: 24px;
  height: 24px;
  border-radius: 12px;
  background-color: #fff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.26);
  ${props =>
    props.checked &&
    css`
      margin-left: 23px;
      background-color: #4d6dd3;
    `};
  transition: all 0.1s;
`

export const Label = styled.label`
  display: inline-block;
  vertical-align: top;
  margin-left: 20px;
`

export const Wrapper = styled.div`
  height: 26px;
  user-select: none;
  width: 100%;
`

export const Description = styled.div`
  margin-bottom: 16px;
  width: 560px;
`
