import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const BUSINESS_TYPE_RETAIL = 'retail'
const BUSINESS_TYPE_CUSTOM = 'custom'

const config = {
  name: 'Google AdWords',
  color: '#5678bd',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/google-adwords',
  form: [
    {
      label: 'SDK version',
      type: 'select',
      name: 'apiVersion',
      options: [
        {
          label: 'classic',
          value: 'classic'
        },
        {
          label: 'gtag.js',
          value: 'gtag.js'
        }
      ],
      defaultValue: 'classic'
    },
    {
      label: 'Conversion ID',
      type: 'string',
      name: 'conversionId',
      validation: ['required']
    },
    {
      label: 'Business type',
      type: 'select',
      name: 'businessType',
      options: [
        {
          label: 'Retail',
          value: BUSINESS_TYPE_RETAIL
        },
        {
          label: 'Custom',
          value: BUSINESS_TYPE_CUSTOM
        }
      ],
      defaultValue: BUSINESS_TYPE_RETAIL
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    }
  ]
}

export default config
