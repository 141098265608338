import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import { DIGITAL_DATA, CONSTANT, EVENT } from 'containers/Form/Variable/options'
import ru from './ru.json'

const config = {
  name: 'myTarget',
  color: '#fe0000',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/mytarget',
  form: [
    {
      label: 'Top@Mail.ru counter settings',
      type: 'group array',
      name: 'counters',
      addButtonLabel: 'Add Top@Mail.ru counter',
      items: [
        {
          label: 'Top@Mail.ru counter ID',
          type: 'string',
          name: 'counterId'
        },
        {
          label: 'Product feed has grouped products',
          type: 'boolean',
          name: 'feedWithGroupedProducts',
          defaultValue: false
        },
        {
          label: 'Pricelist number',
          type: 'variable',
          name: 'listVar',
          defaultValue: {
            type: CONSTANT,
            value: '1'
          },
          typeOptions: [DIGITAL_DATA, CONSTANT, EVENT]
        }
      ]
    },
    {
      label: 'Goals mapping',
      type: 'string mapping',
      name: 'goals',
      placeholderKey: 'DigitalData event',
      placeholderValue: 'Top@Mail.ru goal'
    }
  ]
}

export default config
