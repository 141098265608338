import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Hubspot',
  color: '#000000',
  logo,
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/hubspot',
    google_analytics: 'https://docs.segmentstream.com/datasources/hubspot'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Hubspot Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Hubspot',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Hubspot',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import Contacts',
      type: 'boolean',
      name: 'importContacts',
      defaultValue: true
    },
    {
      label: 'Import Deals',
      type: 'boolean',
      name: 'importDeals',
      defaultValue: true
    }
  ]
}

export default config
