import React from 'react'
import Input from 'containers/Form/Input'
import Divider from 'containers/Form/Mapping/Divider'
import InlineRow from 'containers/Form/InlineRow'
import SelectBox from 'containers/Form/SelectBox'
import { THEME_FORM_ARRAY_ITEM } from 'containers/Form/SelectBox/styles'

const StringMappingRow = ({
  locale,
  hasFocusLeft,
  hasFocusRight,
  mappingKey,
  mappingValue,
  onChangeKey,
  onChangeValue,
  onTouch,
  onFocusLeft,
  onFocusRight,
  placeholderKey,
  placeholderValue,
  touched,
  inputStyle,
  valueOptions,
  defaultValueOption,
  keyInputWidth
}) => {
  const changed =
    (mappingKey !== undefined && mappingKey.length > 0) ||
    (mappingValue !== undefined && mappingValue.length > 0 && mappingValue !== defaultValueOption)

  const keyInputStyle = {
    ...inputStyle,
    ...(keyInputWidth ? { width: `${keyInputWidth}%` } : {})
  }

  return (
    <InlineRow>
      <Input
        locale={locale}
        placeholder={placeholderKey}
        value={mappingKey}
        hasFocus={hasFocusLeft}
        validation={['required']}
        touched={touched && changed}
        onChange={onChangeKey}
        onFocus={onFocusLeft}
        onTouch={onTouch}
        style={keyInputStyle}
      />
      <Divider />
      {valueOptions && (
        <SelectBox
          options={valueOptions}
          value={mappingValue}
          onChange={onChangeValue}
          onTouch={onTouch}
          theme={THEME_FORM_ARRAY_ITEM}
          validation={['required']}
        />
      )}
      {!valueOptions && (
        <Input
          locale={locale}
          placeholder={placeholderValue}
          value={mappingValue}
          hasFocus={hasFocusRight}
          validation={['required']}
          touched={touched && changed}
          onChange={onChangeValue}
          onFocus={onFocusRight}
          onTouch={onTouch}
          style={inputStyle}
        />
      )}
    </InlineRow>
  )
}

export default StringMappingRow
