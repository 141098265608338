import logo from './logo.png'
import sideLogo from './logo-side.png'
import ru from './ru.json'
import { PRODUCT_RECOMMENDATION, PUSH_NOTIFICATIONS } from 'integrations/categories'
import { DIGITAL_DATA, EVENT, PRODUCT } from 'containers/Form/Variable/options'

export default {
  name: 'REES46',
  color: '#00B040',
  category: [PRODUCT_RECOMMENDATION, PUSH_NOTIFICATIONS],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Store key',
      type: 'string',
      name: 'storeKey',
      validation: ['required']
    },
    {
      label: 'Product feed has grouped products',
      type: 'boolean',
      name: 'feedWithGroupedProducts',
      description:
        'If true, product.skuCode will be used instead of product.id for products tracking',
      defaultValue: false
    },
    {
      label: 'Product variables',
      type: 'variable mapping',
      name: 'productVariables',
      typeOptions: [DIGITAL_DATA, EVENT, PRODUCT]
    }
  ]
}
