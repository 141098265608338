import logo from './logo.png'
import sideLogo from './logo-side.png'
import { AFFILIATE_NETWORKS } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Admitad',
  color: '#d7b0e5',
  category: [AFFILIATE_NETWORKS],
  sideLogo,
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/integrations/admitad',
  form: [
    {
      label: 'API version',
      type: 'select',
      name: 'apiVersion',
      options: [
        {
          label: 'pixel',
          value: 'pixel'
        },
        {
          label: 'tagtag',
          value: 'tagtag',
          enable: ['crossDeviceTrackingId']
        }
      ],
      defaultValue: 'pixel'
    },
    {
      label: 'Cross device tracking',
      type: 'select',
      name: 'crossDeviceTrackingId',
      options: ['none', 'userId', 'email'],
      defaultValue: 'none'
    },

    {
      label: 'Campaign code',
      type: 'string',
      name: 'campaignCode'
    },
    {
      label: 'Payment type',
      type: 'select',
      name: 'paymentType',
      options: ['sale', 'lead'],
      defaultValue: 'sale'
    },
    {
      label: 'Default action code',
      type: 'string',
      name: 'defaultActionCode',
      defaultValue: '1'
    },
    {
      label: 'Cookie, which stores admitad_uid',
      type: 'string',
      name: 'cookieName',
      defaultValue: 'admitad_uid'
    },
    {
      label: 'Automatic cookie tracking',
      type: 'boolean',
      name: 'cookieTracking',
      defaultValue: true,
      enable: ['cookieTtl', 'cookieDomain']
    },
    {
      label: 'Cookie time to live',
      type: 'number',
      name: 'cookieTtl',
      defaultValue: 90
    },
    {
      label: 'Cookie domain',
      type: 'string',
      name: 'cookieDomain'
    },
    {
      label: 'Activate deduplication',
      type: 'boolean',
      name: 'deduplication',
      enable: ['deduplicationUtmMedium']
    },
    {
      label: 'utm_medium list, which require deduplication',
      type: 'string array',
      name: 'deduplicationUtmMedium'
    },
    {
      label: 'utm_source from Admitad',
      type: 'string',
      name: 'utmSource',
      defaultValue: 'admitad'
    },
    {
      label: 'Enable ReTag',
      type: 'boolean',
      name: 'reTag',
      defaultValue: false,
      enable: ['reTagCode']
    },
    {
      label: 'ReTag Code',
      type: 'string',
      name: 'reTagCode'
    }
  ]
}

export default config
