import logo from './logo.png'
import sideLogo from './logo-side.png'
import { PERSONALIZATION } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'AnyQuery',
  color: '#33a9ee',
  category: [PERSONALIZATION],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Site ID',
      type: 'string',
      name: 'siteId',
      validation: ['required']
    }
  ]
}

export default config
