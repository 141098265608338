export const DIGITAL_DATA = 'digitalData'
export const EVENT = 'event'
export const PRODUCT = 'product'
export const CONSTANT = 'constant'

export default {
  [DIGITAL_DATA]: 'DigitalData',
  [EVENT]: 'Event',
  [PRODUCT]: 'Product',
  [CONSTANT]: 'Constant'
}
