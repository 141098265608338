import React from 'react'

import uuid from 'uuid'
import * as Yup from 'yup'
import { Formik } from 'formik'
import styled from 'styled-components'
import AceEditor from 'react-ace'

import { snippetCode } from '@segmentstream/segmentstream-tracking-snippet'

import { StyledInput } from 'containers/Form/Input/styles'
import { FormikFieldContainer } from 'uiKit/formik'
import cleanObject from 'utils/cleanObject'
// import { utcOffsets } from 'utils/utcOffsets'
import { FormikSelectBox } from 'uiKit/formik/formikSelectBox'
import { ButtonPrimary } from 'uiKit'
import { ButtonWithConfirmation } from 'uiKit/ButtonWithConfirmation'
import { FormikBooleanField } from 'uiKit/formik/formikBooleanField'

const defaultCookiesWhiteList = [
  '_ym_uid',
  '_ga',
  'ssaid',
  '_fbp',
  '_fbc',
  'tmr_lvi'
]

const FormContainer = styled.div`
  flex: 1;
  flex-direction: column;
  > *:not(:first-child) {
    margin-top: 25px;
  }
`

const ButtonsContainer = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: row;
  > * {
    margin-left: 7px;
  }
`

export function UniversalAnalyticsForm ({ trackingBaseUri, onSubmit, onRemove, dataStream }) {
  const initialValues = {
    id: uuid.v4(),
    displayName: 'My Universal Analytics',
    destinationTableName: 'hitsUA',
    anonymize: false,
    config: {
      uaIntegrationType: 'gtm'
    },
    ...cleanObject(dataStream || {})
  }

  const validationSchema = Yup.object().shape({
    displayName: Yup.string().required(),
    destinationTableName: Yup
      .string()
      .required('Destination table name is required')
      .matches(/^[a-zA-Z0-9_]+$/, 'BigQuery table name can only contain letters (upper or lower case), numbers, and underscores')
  })

  const onFormSubmit = async (values) => {
    await onSubmit(values)
  }

  return (
    <React.Fragment>
      <Formik
        initialValues={initialValues}
        onSubmit={onFormSubmit}
        validationSchema={validationSchema}
      >
        { ({ values, handleSubmit, submitForm, isSubmitting, isValid }) => {
          return (
            <form onSubmit={handleSubmit}>
              <FormContainer>
                <FormikFieldContainer
                  name='displayName'
                  label='Display name'
                  type='string'
                  tip='Pick a name to help you identify this data stream in SegmentStream.'
                >
                  <StyledInput type='string' />
                </FormikFieldContainer>

                <FormikFieldContainer
                  name='destinationTableName'
                  label='Destination table name'
                  type='string'
                  tip='BigQuery table name'
                >
                  <StyledInput type='string' />
                </FormikFieldContainer>

                <FormikFieldContainer
                  name='anonymize'
                  type='boolean'
                >
                  <FormikBooleanField description='Anonymize IP' />
                </FormikFieldContainer>

                <FormikFieldContainer name='config.uaIntegrationType' label='Integration type' style={{ width: '50%' }}>
                  <FormikSelectBox options={[
                    { label: 'GTM', value: 'gtm' },
                    { label: 'GTM (BigQuery only)', value: 'gtmOnlyBQ' },
                    { label: 'analytics.js', value: 'analytics' },
                    { label: 'Yandex Turbo page', value: 'yandexTurboPage' },
                    { label: 'AMP (GTM)', value: 'ampGtm' },
                    { label: 'AMP (HTML)', value: 'ampHtml' },
                    { label: 'Shopify checkout', value: 'shopifyCheckout' }
                  ]} />
                </FormikFieldContainer>

                { values.id && values.config && values.config.uaIntegrationType
                  ? (
                    <div>
                      <FormikFieldContainer
                        label='Snippet'
                        name='snippet'
                        tip='<a href="https://docs.segmentstream.com/datastreams/universal-analytics" target="_blank">Read more about the implementation ▸</a>'
                      />
                      <AceEditor
                        style={{
                          borderColor: '#e3e4e6',
                          borderWidth: '1px',
                          borderStyle: 'solid',
                          borderRadius: '8px'
                        }}
                        theme='tomorrow'
                        width='auto'
                        height='240px'
                        validate={false}
                        wrapEnabled
                        mode='text'
                        setOptions={{ showLineNumbers: false }}
                        value={
                          snippetCode(
                            trackingBaseUri,
                            values.id,
                            defaultCookiesWhiteList,
                            values.config.uaIntegrationType
                          )
                        }
                      />
                    </div>
                  ) : null
                }

                <ButtonsContainer>
                  <ButtonPrimary
                    onClick={submitForm}
                    disabled={isSubmitting || !isValid}
                  >
                    Save
                  </ButtonPrimary>
                  { onRemove ? (
                    <ButtonWithConfirmation
                      dangerZone
                      onConfirm={onRemove}
                      confirmButtonText='Remove'
                      cancelButtonText='Cancel'
                      confirmMessage={`Are you sure that you want to delete the "${dataStream.displayName}" data stream?`}
                    />
                  ) : null }

                </ButtonsContainer>
              </FormContainer>
            </form>
          )
        }}
      </Formik>
    </React.Fragment>
  )
}
