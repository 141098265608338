import styled from 'styled-components'

export const Triangle = styled.div`
  margin-left: 5px;
  top: -1px;
  border: solid #fff;
  border-width: 0 1px 1px 0;
  display: inline-block;
  padding: 3px;
  position: relative;
  transform: rotate(-45deg);
`
