import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import Typography from '@material-ui/core/Typography'

const useStyles = makeStyles({
  card: {
    boxShadow: 'none',
    border: '1px solid #dee0e1'
  },
  content: {
    padding: '12px',

    '&:last-child': {
      paddingBottom: '18px'
    }
  }
})

const DashboardItem = ({ children, title }) => {
  const classes = useStyles()

  return (
    <Card className={classes.card}>
      <CardContent className={classes.content}>
        {title && (
          <Typography style={{ 'textAlign': 'center' }} color='textSecondary' gutterBottom>
            {title}
          </Typography>
        )}
        {children}
      </CardContent>
    </Card>
  )
}

export default DashboardItem
