import Actionpay from './Actionpay'
import Admitad from './Admitad'
import AdSpire from './AdSpire'
import CityAds from './CityAds'
import Criteo from './Criteo'
import DoubleClickFloodlight from './DoubleClickFloodlight'
import Driveback from './Driveback'
import Emarsys from './Emarsys'
import FacebookPixel from './FacebookPixel'
import Flocktory from './Flocktory'
import GdeSlon from './GdeSlon'
import GoogleAdWords from './GoogleAdWords'
import GoogleAnalytics from './GoogleAnalytics'
import GoogleTagManager from './GoogleTagManager'
import Mindbox from './Mindbox'
import MyTarget from './MyTarget'
import OneDMC from './OneDMC'
import Ofsys from './Ofsys'
import OneSignal from './OneSignal'
import OWOXBIStreaming from './OWOXBIStreaming'
import RetailRocket from './RetailRocket'
import RTBHouse from './RTBHouse'
import Segmento from './Segmento'
import DDManagerStreaming from './DDManagerStreaming'
import Sociomantic from './Sociomantic'
import Soloway from './Soloway'
import Vkontakte from './Vkontakte'
import Weborama from './Weborama'
import YandexMetrica from './YandexMetrica'
import Recreativ from './Recreativ'
import AdvCake from './AdvCake'
import JivoChat from './JivoChat'
import Adnetic from './Adnetic'
import AnyQuery from './AnyQuery'
import REES46 from './REES46'
import Get4Click from './Get4Click'
import TradeTracker from './TradeTracker'
import TryFit from './TryFit'
import Getintent from './Getintent'
import K50 from './K50'
import Calltouch from './Calltouch'

const integrations = [
  DDManagerStreaming,
  Driveback,
  Criteo,
  YandexMetrica,
  FacebookPixel,
  GoogleAnalytics,
  GoogleAdWords,
  GoogleTagManager,
  MyTarget,
  Vkontakte,
  Admitad,
  Sociomantic,
  OWOXBIStreaming,
  Emarsys,
  RetailRocket,
  OneSignal,
  Mindbox,
  OneDMC,
  Ofsys,
  RTBHouse,
  DoubleClickFloodlight,
  Actionpay,
  Soloway,
  AdSpire,
  CityAds,
  Flocktory,
  Weborama,
  Segmento,
  GdeSlon,
  Recreativ,
  AdvCake,
  JivoChat,
  Adnetic,
  AnyQuery,
  REES46,
  Get4Click,
  TradeTracker,
  TryFit,
  Getintent,
  K50,
  Calltouch
]

export const sandboxIntegrations = []

export const topIntegrationsName = [
  Weborama.name,
  OneSignal.name,
  Sociomantic.name,
  RTBHouse.name,
  GoogleAdWords.name,
  Emarsys.name,
  Criteo.name,
  GoogleAnalytics.name,
  FacebookPixel.name
]

export default integrations
