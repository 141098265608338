export const create = (projectId, event) => ({
  url: `/projects/${projectId}/events/`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(event)
})

export const update = (projectId, event) => ({
  url: `/projects/${projectId}/events/${event.id}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(event)
})

export const remove = (projectId, eventId) => ({
  url: `/projects/${projectId}/events/${eventId}`,
  method: 'DELETE'
})

export const enable = (projectId, eventId) => ({
  url: `/projects/${projectId}/events/${eventId}/enable`,
  method: 'PUT'
})

export const disable = (projectId, eventId) => ({
  url: `/projects/${projectId}/events/${eventId}/disable`,
  method: 'PUT'
})
