import React from 'react'
import { connect } from 'react-redux'

import { changePage, DATA_SOURCES } from '../../../../redux/modules/page/changePage'
import { EmailConfirmationSuccessPage } from '../Components/email-confirmation-success-page'

function EmailConfirmationSuccessPageContainer ({ user, changePage }) {
  const handleGetStarted = () => {
    changePage(DATA_SOURCES)
  }

  return (
    <EmailConfirmationSuccessPage
      user={user}
      onGetStarted={handleGetStarted}
    />
  )
}

export default connect(
  state => ({
    user: state.user
  }),
  {
    changePage
  }
)(EmailConfirmationSuccessPageContainer)
