import path from './path'

const CHANGE_NAME_FILTER = `changeNameFilter@${path}`

export const changeNameFilter = name => ({
  type: CHANGE_NAME_FILTER,
  name
})

export default (state = {}, action) =>
  action.type === CHANGE_NAME_FILTER
    ? {
      ...state,
      filter: {
        ...state.filter,
        name: action.name
      }
    }
    : state
