import reduceReducers from 'reduce-reducers'
import authenticateReducer, { authenticate } from './authenticate'
import loginReducer, { login } from './login'
import resetReducer, { authenticatePasswordReset } from './reset'
import registerReducer, { authenticateUserRegister } from './register'
import { logout } from './logout'

export { authenticate, login, logout, authenticatePasswordReset, authenticateUserRegister }

export default reduceReducers(authenticateReducer, loginReducer, resetReducer, registerReducer)
