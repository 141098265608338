import { queries } from '@segmentstream/cubejs-client'
import { campaignNameTable } from './campaignName'

export const sourceMediumTable = (dateRange, filters) => {
  const campaignNameTableInstance = campaignNameTable(dateRange, filters)
  return {
    id: `tables_source_medium`,
    name: queries.legacy.attributionsTables.sourceMediumTable.name,
    vizState: {
      child: {
        name: campaignNameTableInstance.name,
        data: (sourceMedium) => {
          const campaignNameTableChildInstance = campaignNameTable(dateRange, [
            ...filters,
            {
              member: 'Attribution.sourceMedium',
              operator: 'equals',
              values: [sourceMedium]
            }
          ])
          return campaignNameTableChildInstance.vizState
        }
      },
      query: queries.legacy.attributionsTables.sourceMediumTable.query(dateRange, filters),
      size: 12,
      chartType: 'table'
    }
  }
}
