import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'amoCRM',
  logo,
  locale: { ru },
  docsUrl: 'https://docs.segmentstream.com/datasources/amocrm',
  authType: 'OAuth2',
  authIcon,
  authName: 'amoCRM Account',
  authForm: [
    {
      label: 'Click to authenticate with amoCRM',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My amoCRM',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import leads',
      type: 'boolean',
      name: 'importLeads',
      defaultValue: true,
      enable: ['leadsCustomFields', 'leadsImportName']
    },
    {
      label: 'Leads custom fields',
      type: 'string array',
      name: 'leadsCustomFields'
    },
    {
      label: 'Import lead name',
      type: 'boolean',
      name: 'leadsImportName',
      defaultValue: true
    },
    {
      label: 'Import lead events report',
      type: 'boolean',
      name: 'importEventsReport',
      defaultValue: false
    }
  ]
}

export default config
