import styled from 'styled-components'
import check from './check.svg'
import close from './close.svg'

export const NotificationTab = styled.div`
  width: 463px;
  border-radius: 5px;
  padding: 28px 23px;
  display: flex;
  align-items: center;
  box-shadow: 0px 0px 15px 1px rgba(0,0,0,0.15);
  background: #4D6DD3;
  color: #FFF;  
  font-size: 14px;
  line-height: 18px;
  position: fixed;
  right: 18px;
  top: 200px;
`

export const NotificationContent = styled.div`
  display: flex;
`

export const NotificationIcon = styled.div`
  width: 23px;
  height: 23px;
  background: url(${check}) no-repeat;
  background-size: 23px 23px;
  margin-right: 20px;
`

export const NotificationText = styled.div`
  width: 350px;
`

export const CloseButton = styled.div`
  width: 8px;
  height: 8px; 
  background: url(${close}) no-repeat;
  background-size: 8px 8px;
  top: 8px;
  right: 8px;
  position: absolute;
  cursor: pointer;
`
