import logo from './logo.png'

const config = {
  type: 'Pipedrive',
  authType: 'Basic',
  authName: 'Pipedrive API token',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datasources/pipedrive',
  authForm: [
    {
      label: 'API token',
      type: 'string',
      name: 'apiToken',
      validation: ['required'],
      tip: '<a href="https://pipedrive.readme.io/docs/how-to-find-the-api-token" target="_blank" rel="noopener noreferrer">How to find the API token ▸</a>'
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Pipedrive',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Import Deals',
      type: 'boolean',
      name: 'deals',
      defaultValue: true
    }
  ]
}

export default config
