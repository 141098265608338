import React from 'react'

import EllipsisText from 'react-ellipsis-text'

import IntegrationLogo from 'containers/IntegrationLogo'
import { getDataStreamConfiguration } from 'dataStreams'

import { DataStream, Title, Name, LogoContainer, Signal } from './styles'
import Status from '../../../../../Status'

export function DataStreamItem ({ dataStream, onClick }) {
  const config = getDataStreamConfiguration(dataStream.type)

  return (
    <DataStream onClick={onClick}>
      <LogoContainer>
        <IntegrationLogo logo={config.logo} />
        <Signal>
          <Status isEnabled={dataStream.isEnabled} />
        </Signal>
      </LogoContainer>
      <Title>
        <EllipsisText text={dataStream.displayName || `My ${dataStream.type}`} length={16} />
      </Title>
      <Name>{dataStream.type}</Name>
    </DataStream>
  )
}
