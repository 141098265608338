import styled from 'styled-components'

export const SearchBox = styled.input`
  width: 100%;
  height: 54px;
  padding: 20px 20px 20px 25px;
  border: 1px solid #e1e7ed;
  border-radius: 3px 3px 0 0;
  line-height: 20px;
  color: #1d2c4c;
  background-color: #fff;
  outline: none;
  cursor: text;

  &:focus {
    border-color: #3bc569;
  }

  ::placeholder {
    color: #778fa9;
  }
`
