import logo from './logo.png'
import sideLogo from './logo-side.png'
import { ADVERTISING } from 'integrations/categories'
import ru from './ru.json'

const config = {
  name: 'Sociomantic',
  color: '#279BC8',
  category: [ADVERTISING],
  sideLogo,
  logo,
  locale: { ru },
  form: [
    {
      label: 'Sociomantic ID',
      type: 'string',
      name: 'advertiserToken',
      validation: ['required']
    },
    {
      label: 'Tracking code region',
      type: 'string',
      name: 'region'
    },
    {
      label: 'Prefix for global JavaScript object',
      type: 'string',
      name: 'prefix'
    }
  ]
}

export default config
