import styled from 'styled-components'

export const Button = styled.div`
  width: 100%;
  height: 60px;
  padding: 18px 0;
  margin-top: 15px;
  border: 1px dashed #96a5b4;
  border-radius: 8px;
  font-size: 18px;
  font-weight: 200;
  color: #96a5b4;
  text-align: center;
  cursor: pointer;
  outline: none;
  user-select: none;
  opacity: ${props => (props.disabled ? '0.5;' : '1')};
  cursor: ${props => (props.disabled ? 'default' : 'pointer')};
`
