import React, { Component } from 'react'
import { connect } from 'react-redux'
import { saveIntegration } from 'redux/modules/page/integrations/integrationForm'
import Form from 'containers/Form'
import getTranslate from 'utils/getTranslate'
import translateFormItem from 'utils/translateFormItem'
import { setAuthStatus, removeAuth, setAuthCheckError } from 'redux/modules/integrations'
import _ from 'lodash'
import { getFreshAccessToken } from '../../../../../../api/apiCall'
import { checkAuthOptions } from 'api/integration'

class IntegrationForm extends Component {
  constructor (props) {
    super(props)
    this.state = {}

    this.handleAuthorizationCheck = this.handleAuthorizationCheck.bind(this)
  }

  componentDidMount () {
    const { isAuthenticated, integration } = this.props
    if (isAuthenticated && integration.authError) {
      this.handleAuthorizationCheck()
    }
  }

  async handleAuthorizationCheck () {
    const { onAuthCheckError, integration, project } = this.props

    this.setState({
      loading: true
    })

    const authOptions = await checkAuthOptions({
      projectId: project.id,
      integration: { name: integration.name }
    })
    if (authOptions && authOptions.error && authOptions.error.code === 'UNAUTHORIZED') {
      await onAuthCheckError(
        integration.name,
        integration.authError
      )
    }

    this.setState({
      loading: false
    })
  }

  render () {
    const {
      locale,
      integration,
      isAuthenticated,
      initialData,
      project,
      displayMode,
      onSave,
      onSetAuthStatus,
      authenticationFailMessage
    } = this.props
    const { loading } = this.state

    const handleSave = (data, touchedFields) => {
      const isIntegrationEnabled = initialData !== undefined
      onSave(integration.name, data, isIntegrationEnabled, touchedFields)
    }
    const translate = getTranslate(locale, integration.locale)

    const selectedForm = (integration.authForm && !isAuthenticated) || authenticationFailMessage ? integration.authForm : integration.form

    const filteredSelectedForm = selectedForm.filter((formItem) => {
      const isVisible = _.get(formItem, ['configurations', displayMode, 'isVisible'], true)
      return (displayMode === 'default' || isVisible)
    })

    const translatedSelectedForm = filteredSelectedForm.map(formItem => translateFormItem(formItem, translate))

    const context = {
      authorizeEndpoint: `/projects/${project.id}/integrations/${integration.name}/authorize`,
      getFreshAccessToken,
      integration: { name: integration.name },
      projectId: project.id,
      formData: initialData,
      authenticationFailMessage
    }

    const handleFormDataChange = data => {
      if (data && data.isAuthenticated === true) {
        onSetAuthStatus(integration.name, true)
      }
      context.formData = { ...initialData, ...data }
    }

    return (
      <Form
        context={context}
        locale={locale}
        form={translatedSelectedForm}
        data={initialData}
        compatibilityMap={integration.compatibilityMap}
        onSave={((isAuthenticated && !authenticationFailMessage) || !integration.authForm) && handleSave}
        onChange={handleFormDataChange}
        loading={loading}
      />
    )
  }
}

const mapStateToProps = state => {
  const { name } = state.page.integrations.integrationForm
  const isEnabled = (state.integrations[name] || {}).isEnabled
  const isAuthenticated = (state.integrations[name] || {}).isAuthenticated
  return {
    locale: state.locale,
    // For clients with enabled integrations
    isAuthenticated: isAuthenticated || isEnabled,
    initialData: (state.integrations[name] || {}).options,
    project: state.project,
    authenticationFailMessage: (state.integrations[name] || {}).authenticationFailMessage
  }
}

const mapDispatchToProps = () => ({
  onSave: saveIntegration,
  onSetAuthStatus: setAuthStatus,
  onRemoveAuth: removeAuth,
  onAuthCheckError: setAuthCheckError
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(IntegrationForm)
