import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchGoogleAnalyticsAccounts, fetchGoogleAnalyticsProperties, fetchGoogleAnalyticsDataSets } from 'api/dataDestination'

const config = {
  type: 'Google Analytics',
  color: '#1c84c6',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datadestinations/google-analytics',
    google_analytics: 'https://docs.segmentstream.com/google-analytics/connecting-google-analytics'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Google Account',
  isPublished: true,
  authForm: [
    {
      label: 'Authenticate with Google',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'Google Analytics account was disconnected. Please, reconnect it using an account with sufficient access rights.',
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Google Analytics',
      validation: ['required'],
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Account',
      type: 'dynamic select',
      name: 'accountId',
      validation: ['required'],
      placeholder: 'Select Account',
      optionsFetcher: fetchGoogleAnalyticsAccounts,
      description: 'There were no accounts found or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://support.google.com/analytics/answer/1008015" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      enable: ['propertyId', 'dataSetId', 'dataSetType'],
      extendedView: true
    },
    {
      label: 'Property',
      type: 'dynamic select',
      name: 'propertyId',
      validation: ['required'],
      placeholder: 'Select Property',
      optionsFetcher: fetchGoogleAnalyticsProperties,
      dependsOn: ['accountId'],
      description: 'There were no properties found for your Google Analytics account or access was not granted. Please, make sure you have access to the required properties or <a class="description" href="https://support.google.com/analytics/answer/1042508" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      enable: ['dataSetId', 'dataSetType'],
      extendedView: true
    },
    {
      label: 'Data Set Type',
      type: 'select',
      name: 'dataSetType',
      validation: ['required'],
      options: [
        {
          label: 'Cost Data',
          value: 'costData',
          enable: ['dataSetId']
        },
        {
          label: 'User Data',
          value: 'userData',
          enable: ['bigQuerySourceTableName', 'dataSetSchemaMapping', 'dataSetId']
        }
      ],
      defaultValue: undefined,
      configurations: {
        google_analytics: {
          isVisible: false,
          defaultValue: 'costData'
        }
      }
    },
    {
      label: 'Data Set',
      type: 'dynamic select',
      name: 'dataSetId',
      validation: ['required'],
      placeholder: 'Select Data Set',
      optionsFetcher: fetchGoogleAnalyticsDataSets,
      dependsOn: ['propertyId', 'accountId', 'dataSetType'],
      description: 'There are no data sets in the selected property or access is not granted. Please, <a class="description" href="https://docs.segmentstream.com/google-analytics/creating-google-analytics-data-set" target="_blank" rel="noopener noreferrer">create a new Data Set</a> and make sure you have “Edit” permission to the selected property.',
      extendedView: true,
      enable: ['bigQuerySourceTableName', 'dataSetSchemaMapping']
    },
    {
      label: 'BigQuery source table name',
      type: 'string',
      name: 'bigQuerySourceTableName',
      validation: ['required', /^[a-zA-Z0-9_]+$/],
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Data Set Schema mapping',
      type: 'string mapping',
      name: 'dataSetSchemaMapping',
      placeholderKey: 'BigQuery column name',
      placeholderValue: 'Dimension or metric',
      configurations: {
        google_analytics: {
          isVisible: false
        }
      }
    },
    {
      label: 'Landing page domains filter (optional)',
      type: 'string array',
      name: 'domainsFilter',
      tip: 'Only use this setting only if you would like to import cost data<br/>' +
        'for campaigns that lead to the specific domain name.<br/>' +
        'Wildcards for subdomains are supported.<br/>' +
        'For example, *.example.com will import all costs that lead to<br/>' +
        'any example.com subdomain (but not example.com domain).'
    }
  ]
}

export default config
