export const create = (projectId, enrichment) => ({
  url: `/projects/${projectId}/enrichments/`,
  method: 'POST',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(enrichment)
})

export const update = (projectId, enrichment) => ({
  url: `/projects/${projectId}/enrichments/${enrichment.id}`,
  method: 'PUT',
  headers: { 'Content-Type': 'application/json' },
  body: JSON.stringify(enrichment, (k, v) => v === undefined ? null : v)
})

export const remove = (projectId, enrichmentId) => ({
  url: `/projects/${projectId}/enrichments/${enrichmentId}`,
  method: 'DELETE'
})

export const enable = (projectId, enrichmentId) => ({
  url: `/projects/${projectId}/enrichments/${enrichmentId}/enable`,
  method: 'PUT'
})

export const disable = (projectId, enrichmentId) => ({
  url: `/projects/${projectId}/enrichments/${enrichmentId}/disable`,
  method: 'PUT'
})
