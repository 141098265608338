import React from 'react'
import { connect } from 'react-redux'
import Form from './Form'
import List from './List'
import idx from 'idx'

const EventEnrichments = ({ isOpen }) => (!isOpen ? <List /> : <Form />)

export default connect(state => ({
  isOpen: idx(state, _ => _.page.integrations.integrationForm.eventEnrichments.isOpen)
}))(EventEnrichments)
