import React from 'react'
import IntegrationLogo from 'containers/IntegrationLogo'

import { DataStream, Name, Signal, Plus, LogoContainer } from '../styles'

export const DataStreamItem = ({ dataStream, onClick }) => {
  return (
    <DataStream onClick={() => onClick(dataStream)}>
      <LogoContainer>
        <IntegrationLogo logo={dataStream.logo} />
        <Signal>
          <Plus />
        </Signal>
      </LogoContainer>
      <Name>{dataStream.type}</Name>
    </DataStream>
  )
}
