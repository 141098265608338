import Cookies from 'js-cookie'

import apiEndpoint from '../apiEndpoint'
import { getAccessTokenName, getRefreshTokenName, getDomain } from '../redux/modules/auth/utils'
import { getNewTokens } from './auth'
import { getUser } from './user'
import { AuthenticationError } from './api-errors'

const rawApiCall = async (accessToken, { url, method, headers, body, endpointToRequest }) => {
  const options = {
    method: method || 'GET',
    headers: {
      ...(
        accessToken ? {
          Authorization: accessToken ? `Bearer ${accessToken}` : undefined
        }
          : {}
      ),
      ...headers
    },
    body
  }

  return window.fetch(`${endpointToRequest || apiEndpoint}${url}`, options)
}

const getAccessToken = () => {
  return Cookies.get(getAccessTokenName())
}

const getRefreshToken = () => {
  return Cookies.get(getRefreshTokenName())
}

export const updateAccessToken = async () => {
  const refreshToken = getRefreshToken()

  if (!refreshToken) {
    throw new AuthenticationError('No refresh token')
  }

  const response = await rawApiCall(undefined, getNewTokens(refreshToken))

  if (response.status !== 200) {
    throw new AuthenticationError()
  }

  const responseBody = await response.text()
  const tokens = JSON.parse(responseBody)

  const domain = getDomain()

  Cookies.set(getAccessTokenName(), tokens.access_token, { domain, expires: 1 / 24 })
  Cookies.set(getRefreshTokenName(), tokens.refresh_token, { domain, expires: 90 })
}

export const apiCall = async (options) => {
  const accessToken = getAccessToken()

  const res = await rawApiCall(accessToken, options)
  if (res.status === 401) {
    await updateAccessToken()
    return rawApiCall(getAccessToken(), options)
  }

  return res
}

export const getFreshAccessToken = async () => {
  await apiCall(getUser())
  return getAccessToken()
}
