import { CONNECT_PRICING_PLANS } from 'utils/pricingPlans'

export const loadZendeskWidget = (pricingPlan, { name, email }, accountName, projectType, projectName) => {
  const zendeskSettings = {
    webWidget: {
      chat: {
        suppress: true,
        tags: ['page_admin']
      },
      contactForm: {
        suppress: false,
        fields: [
          { id: 360014977439, prefill: { '*': accountName } },
          { id: 360011482979, prefill: { '*': projectName } },
          { id: 360012170519, prefill: { '*': projectType } }
        ]
      },
      helpCenter: {
        suppress: false
      },
      talk: {
        suppress: true
      },
      answerBot: {
        suppress: false
      },
      offset: { vertical: '10px' }
    }
  }
  if (CONNECT_PRICING_PLANS.includes(pricingPlan)) {
    zendeskSettings.webWidget.chat = {
      suppress: false,
      tags: ['page_admin', 'plan_connect']
    }
    zendeskSettings.webWidget.helpCenter = {
      suppress: true
    }
  }
  window.zESettings = zendeskSettings
  const script = document.createElement('script')
  script.src = 'https://static.zdassets.com/ekr/snippet.js?key=8c2f7d21-d793-4908-84b7-e7c666b16a49'
  script.id = 'ze-snippet'
  document.body.appendChild(script)
  if (typeof zE !== 'function') {
    script.addEventListener('load', function () {
      window.zE(function () {
        window.zE.identify({
          name,
          email,
          organization: accountName
        })
      })

      window.zE('webWidget:on', 'open', function () {
        let style = document.createElement('style')
        style.innerHTML = `
          .sc-jqCOkK.cUGokJ > div:nth-child(3), .sc-jqCOkK.cUGokJ > div:nth-child(4), .sc-jqCOkK.cUGokJ > div:nth-child(5) {
            display: none;
          }
        `
        window.document.getElementById('webWidget').contentDocument.head.appendChild(style)
      })
    })
  } else {
    window.zE(function () {
      window.zE.identify({
        name,
        email,
        organization: accountName
      })
    })

    window.zE('webWidget', 'updateSettings', {
      webWidget: {
        contactForm: {
          fields: [
            { id: 360014977439, prefill: { '*': accountName } },
            { id: 360011482979, prefill: { '*': projectName } },
            { id: 360012170519, prefill: { '*': projectType } }
          ]
        }
      }
    })
  }
}
