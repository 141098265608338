import React from 'react'
import { connect } from 'react-redux'
import getTranslate from 'utils/getTranslate'
import ConfirmationWindow from 'containers/ConfirmationWindow'
import { removeProjectInvitation } from 'redux/modules/project'
import {
  MemberBlock,
  MemberDetails,
  MemberContacts,
  UnknownSign,
  MemberEmail,
  DeleteMember
} from './styles'
import ru from './ru.json'

class Invitation extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      isConfirmationOpen: false
    }

    this.closeConfirmation = this.closeConfirmation.bind(this)
    this.openConfirmation = this.openConfirmation.bind(this)
    this.handleRemoveUser = this.handleRemoveUser.bind(this)
  }

  closeConfirmation () {
    this.setState({ isConfirmationOpen: false })
  }

  openConfirmation () {
    this.setState({ isConfirmationOpen: true })
  }

  handleRemoveUser () {
    const { onRemove, permissionId } = this.props
    onRemove(permissionId)
  }

  render () {
    const { locale, email, showRemoveButton, projectName } = this.props
    const { isConfirmationOpen } = this.state
    const translate = getTranslate(locale, { ru })

    const confirmationText = translate('Are you sure that you want to <b>REVOKE INVITATION</b> for <b>{{EMAIL}}</b> user from the <b>{{PROJECT_NAME}}</b> project?', {
      EMAIL: email,
      PROJECT_NAME: projectName
    })

    return (
      <MemberBlock>
        <MemberDetails>
          <UnknownSign />
          <MemberContacts>
            <MemberEmail>
              {email}
            </MemberEmail>
          </MemberContacts>
        </MemberDetails>
        {
          showRemoveButton &&
          <div>
            <DeleteMember onClick={this.openConfirmation}>
              {translate('revoke')}
            </DeleteMember>
            <ConfirmationWindow
              messageText={confirmationText}
              confirmButtonText={translate('Yes')}
              cancelButtonText={translate('Cancel')}
              isOpen={isConfirmationOpen}
              onClose={this.closeConfirmation}
              onConfirm={this.handleRemoveUser}
              dangerZone
            />
          </div>
        }
      </MemberBlock>
    )
  }
}

const mapStateToProps = state => ({
  locale: state.locale,
  projectName: state.project.name
})

const mapDispatchToProps = () => ({
  onRemove: removeProjectInvitation
})

export default connect(
  mapStateToProps,
  mapDispatchToProps()
)(Invitation)
