import React from 'react'
import styled from 'styled-components'

const Error = styled.div`
  padding-top: 5px;
  text-align: right;
  color: #ff706b;
`

export default ({ message, width }) => <Error style={{ width: width || '100%' }}>* {message}</Error>
