import ru from './ru'
import logo from './logo.png'
import authIcon from './authIcon.png'
import { fetchFacebookAccounts } from 'api/dataSource'

const config = {
  type: 'Facebook',
  logo,
  locale: { ru },
  docsUrl: {
    bigquery: 'https://docs.segmentstream.com/datasources/facebook',
    google_analytics: 'https://docs.segmentstream.com/datasources/facebook'
  },
  authType: 'OAuth2',
  authIcon,
  authName: 'Facebook Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Facebook',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  authError: 'The data source was disconnected. <br>Please, reconnect it using an account with sufficient access rights.',
  enabledForGoogleAnalyticsProjects: true,
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Facebook',
      validation: ['required'],
      tip: 'Pick a name to help you identify this data source in SegmentStream.'
    },
    {
      label: 'Account ID(s)',
      type: 'dynamic select array',
      name: 'accountIds',
      validation: ['required'],
      placeholder: 'Select Account Id',
      optionsFetcher: fetchFacebookAccounts,
      description: 'No ad accounts were found for this user or access was not granted. Please, make sure you have access to the required accounts or <a class="description" href="https://www.facebook.com/business/help/910137316041095?id=420299598837059&helpref=typeahead_suggestions&sr=6&query=create%20account" target="_blank" rel="noopener noreferrer">create a new one</a> and click “Refresh”.',
      addButtonLabel: 'Add account ID',
      extendedView: true,
      uniqueValues: true
    },
    {
      label: 'Default UTM source (optional)',
      type: 'string',
      name: 'defaultUTMSource',
      placeholder: 'facebook',
      tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
        'without utm_source tracking parameter and campaigns<br/>' +
        'which do not lead to the website<br/>' +
        '(for example Facebook page promo, Facebook post promo,<br/>' +
        'Event promo, etc).',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Default UTM medium (optional)',
      type: 'string',
      name: 'defaultUTMMedium',
      placeholder: 'cpc',
      tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
        'without utm_medium tracking parameter and campaigns<br/>' +
        'which do not lead to the website<br/>' +
        '(for example Facebook page promo, Facebook post promo,<br/>' +
        'Event promo, etc).',
      configurations: {
        bigquery: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: true
        }
      }
    },
    {
      label: 'Import "Ads Insights" report',
      type: 'boolean',
      name: 'rawReport',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      }
    },
    {
      label: 'Additional transformation settings',
      type: 'group',
      flattenOptions: true,
      name: 'additionalTransformationSettings',
      configurations: {
        google_analytics: {
          isVisible: false
        },
        rawReportsUnavailable: {
          isVisible: false
        }
      },
      items: [
        {
          label: 'Import cost data grouped by UTM',
          type: 'boolean',
          name: 'normalizedReport',
          enable: ['costMultiplier'],
          configurations: {
            google_analytics: {
              defaultValue: true
            },
            rawReportsUnavailable: {
              defaultValue: true
            }
          }
        },
        {
          label: 'Default UTM source (optional)',
          type: 'string',
          name: 'defaultUTMSource',
          placeholder: 'facebook',
          tip: 'Defined UTM source value will be applied to all campaigns<br/>' +
            'without utm_source tracking parameter and campaigns<br/>' +
            'which do not lead to the website<br/>' +
            '(for example Facebook page promo, Facebook post promo,<br/>' +
            'Event promo, etc).'
        },
        {
          label: 'Default UTM medium (optional)',
          type: 'string',
          name: 'defaultUTMMedium',
          placeholder: 'cpc',
          tip: 'Defined UTM medium value will be applied to all campaigns<br/>' +
            'without utm_medium tracking parameter and campaigns<br/>' +
            'which do not lead to the website<br/>' +
            '(for example Facebook page promo, Facebook post promo,<br/>' +
            'Event promo, etc).'
        },
        {
          label: 'Cost multiplier',
          type: 'string',
          name: 'costMultiplier',
          defaultValue: '1.00',
          validation: ['decimal']
        }
      ]
    }
  ]
}

export default config
