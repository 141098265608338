export default form =>
  form.reduce((acc, field) => {
    if (field.type === 'boolean' && field.defaultValue === undefined) {
      return { ...acc, [field.name]: false }
    }
    if (field.defaultValue !== undefined) {
      return { ...acc, [field.name]: field.defaultValue }
    }
    return acc
  }, {})
