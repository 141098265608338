import logo from './logo.png'
import authIcon from './authIcon.png'

const config = {
  type: 'Facebook',
  logo,
  docsUrl: 'https://docs.segmentstream.com/datadestinations/facebook',
  authType: 'OAuth2',
  authIcon,
  authName: 'Facebook Account',
  isPublished: true,
  authForm: [
    {
      label: 'Click to authenticate with Facebook',
      type: 'oauth2 button',
      name: 'isAuthenticated',
      icon: authIcon
    }
  ],
  form: [
    {
      label: 'Display name',
      type: 'string',
      name: 'title',
      defaultValue: 'My Facebook',
      validation: ['required']
    },
    {
      label: 'Facebook Pixel ID',
      type: 'string',
      name: 'pixelId',
      validation: ['required']
    },
    {
      label: 'Event name',
      type: 'string',
      name: 'eventName',
      defaultValue: 'Purchase Score',
      validation: ['required']
    },
    {
      label: 'BigQuery source table name',
      type: 'string',
      name: 'sourceTable',
      defaultValue: 'facebookMLConversionsExport',
      validation: ['required']
    },
    {
      label: 'Schedule',
      type: 'select',
      name: 'schedule',
      defaultValue: 'daily',
      options: [
        {
          label: 'daily',
          value: 'daily'
        },
        {
          label: 'hourly',
          value: 'hourly'
        }
      ],
      validation: ['required']
    }
  ]
}

export default config
