import cleanObject from 'utils/cleanObject'

export const getType = (path = '') => {
  switch (true) {
    case path.includes('event'):
      return 'Event'
    case path.includes('enrichment'):
      return 'Variable'
    case path.includes('script'):
      return 'Script'
    case path.includes('integration'):
      return 'Integration'
    case path.includes('projectCreation'):
      return 'Project Creation'
    case path.includes('project'):
      return 'Project Settings'
    case path.includes('dataSources'):
      return 'Data Source'
    case path.includes('dataDestinations'):
      return 'Data Destination'
    case path.includes('report'):
      return 'Data Studio'
    case path.includes('createBillingAccount'):
      return 'Create Billing Account'
    case path.includes('dataStream'):
      return 'Data Stream'
    default:
      return 'Other'
  }
}

export const getCategory = (page = '') => {
  switch (true) {
    case page.includes('event'):
      return 'Events'
    case page.includes('enrichment'):
      return 'Variables'
    case page.includes('script'):
      return 'Scripts'
    case page.includes('integration'):
      return 'Integrations'
    case page.includes('priority'):
      return 'Priorities'
    case page.includes('authorization'):
      return 'Authorization'
    case page.includes('projectCreation'):
      return 'Project Creation'
    case page.includes('project'):
      return 'Project Settings'
    case page.includes('dataSources'):
      return 'Data Sources'
    case page.includes('dataDestinations'):
      return 'Data Destinations'
    case page.includes('report'):
      return 'Data Studio'
    case page.includes('createBillingAccount'):
      return 'Create Billing Account'
    case page.includes('dataStream'):
      return 'Data Stream'
    default:
      return 'Other'
  }
}

const event = ({ name, category, label }, digitalDataOnly) => {
  window.digitalData.events.push({ name, category, label })
  if (digitalDataOnly) return

  window.dataLayer.push({
    event: name,
    event_category: category,
    event_label: label
  })
}

export const savedEvent = (name, path, category) => {
  event(
    cleanObject({
      category: category || getCategory(path),
      name: ['Saved', getType(path)].join(' '),
      label: name
    })
  )
}

export const addedEvent = path => {
  event({
    category: getCategory(path),
    name: ['Added', getType(path)].join(' ')
  })
}

export const deletedEvent = (name, path) => {
  event({
    category: getCategory(path),
    name: ['Deleted', getType(path)].join(' '),
    label: name
  })
}

export const changedStatusEvent = (name, path, status) => {
  event({
    category: getCategory(path),
    name: [status ? 'Enabled' : 'Disabled', getType(path)].join(' '),
    label: name
  })
}

export const openedEvent = (name, path, category) => {
  event(
    cleanObject({
      category: category || getCategory(path),
      name: ['Opened', getType(path)].join(' '),
      label: name
    })
  )
}

export const loggedIn = () => {
  event({
    category: 'Auth',
    name: 'Logged In'
  })
}

export const publishedVersion = () => {
  event({
    category: 'Behaviour',
    name: 'Published Version'
  })
}

export const switchedPriorities = () => {
  event({
    category: 'Integrations',
    name: 'Switched Priorities'
  })
}

export const viewedPage = page => {
  const type = page !== 'authorization' ? 'listing' : 'content'
  const category = getCategory(page)
  window.digitalData.changes.push([
    'page',
    {
      type,
      path: ['/', category.toLowerCase()].join(''),
      category
    }
  ])
  event({
    name: 'Viewed Page',
    source: 'code'
  }, true)
}

export const viewedEvent = (name, path, category) => {
  event(
    cleanObject({
      category: category || getCategory(path),
      name: ['Viewed', getType(path)].join(' '),
      label: name
    })
  )
}

export const changedEvent = (name, path, category) => {
  event(
    cleanObject({
      category: category || getCategory(path),
      name: ['Changed', getType(path)].join(' '),
      label: name
    })
  )
}

export const registeredEvent = (user, billingAccount) => {
  event({
    category: 'Conversions',
    name: 'Registered'
  }, true)

  window.dataLayer.push({
    event: 'Registered',
    eventCategory: 'Conversions',
    user: {
      id: user.id || user.userId,
      email: user.email,
      role: user.role,
      firstName: user.firstName || user.name,
      lastName: user.lastName
    },
    account: {
      id: billingAccount.id,
      name: billingAccount.name || billingAccount.accountName
    }
  })
}

export const createdProjectEvent = (project) => {
  event({
    category: 'Conversions',
    name: 'Created Project'
  })

  window.dataLayer.push({
    event: 'Created Project',
    eventCategory: 'Conversions',
    project
  })
}

export const clickedBookOnboardingCallEvent = (projectName) => {
  event({
    category: 'Behaviour',
    name: 'Clicked Book an Onboarding Call',
    label: projectName
  })
}
