import styled from 'styled-components'
import { enabledGreen } from 'uiKit/colors'

export const ButtonContainer = styled.div`
  position: relative;
  text-align: center;
  box-sizing: border-box;
  border: 1px solid #e3e4e6;
  border-radius: 5px;
  font-size: 16px;
  line-height: 18px;
  background-color: #fff;
  outline: none;
  user-select: none;
  height: 44px;
  margin-bottom: 18px; 
  margin-left: 23px; 
  display: flex;
  align-items: center;  
  color: #1d2c4c;
  padding: 15px;
  cursor: pointer;
`

export const ButtonTitle = styled.div`
  padding: 0 10px;
`

export const StatusEnabled = styled.div`
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: ${enabledGreen};
`
