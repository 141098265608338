export const normalizeTaxId = (taxId, countryCode) => {
  if (taxId.indexOf(countryCode) === 0) {
    return taxId
  }
  switch (true) {
    case countryCode === 'GR':
      return 'EL' + taxId
    default:
      return countryCode + taxId
  }
}
