import React from 'react'
import { connect } from 'react-redux'
import SectionHeader from 'containers/SectionHeader'
import ConfirmButton from 'containers/Form/Button/ConfirmButton'
import Table from 'containers/Table'
import NameCell from 'containers/NameCell'
import Status from 'containers/Status'
import ScriptsForm from './ScriptsForm'
import { openForm } from 'redux/modules/page/scripts/openForm'
import Date from 'containers/Date'
import ru from './ru.json'
import getTranslate from 'utils/getTranslate'
import { isProMode, filterProModeItems } from 'utils/proMode'
import { addedEvent } from 'utils/digitalDataEvents'

const Scripts = ({ locale, list, isProMode, onOpenForm }) => {
  const translate = getTranslate(locale, { ru })
  const handleOpenForm = () => {
    addedEvent('script')
    onOpenForm()
  }

  const data = filterProModeItems(list, isProMode)
    .sort((itemA, itemB) => {
      const nameA = itemA.name.toLowerCase()
      const nameB = itemB.name.toLowerCase()
      if (nameA < nameB) {
        return -1
      }
      if (nameA > nameB) {
        return 1
      }
      return 0
    })
    .map(item => [
      <Status isEnabled={item.isEnabled} />,
      <NameCell
        name={item.name}
        isEnabled={item.isEnabled}
        onClick={() => onOpenForm(item.id, item.name)}
      />,
      `${translate('Event')}: ${item.event || 'Viewed Page'}`,
      <Date date={item.updatedAt} />
    ])
  const confirmButton = (
    <ConfirmButton onClick={handleOpenForm}>{translate('Add')}</ConfirmButton>
  )
  return (
    <div>
      <SectionHeader leftContent={confirmButton} />
      <Table
        headers={['', translate('Script name'), translate('Trigger'), translate('Updated')]}
        data={data}
      />
      <ScriptsForm />
    </div>
  )
}

export default connect(
  state => ({
    locale: state.locale,
    list: state.scripts,
    isProMode: isProMode(state.proMode.status, state.user.role)
  }),
  {
    onOpenForm: openForm
  }
)(Scripts)
